import axios from "axios";
import { authStorage } from "../utils/login";
import { STAR_AUTH_SERVICE } from "../config/url.config";
import { worker } from "../utils/networkDetector";
const errorMessages = [
  "Unauthorized Request",
  "jwt malformed",
  "Invalid Access or Refresh Token",
  "invalid token",
];

let isRefreshing = false;
let failedQueue = [];
export default class Services {
  axiosInstance;

  constructor() {
    this.init();
  }

  init() {
    this.axiosInstance = axios.create();
    this.registerRequestInterceptor();
    this.registerResponseInterceptor();
  }

  getServiceInstance() {
    return this.axiosInstance;
  }

  registerRequestInterceptor() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const tokenStr = "Bearer " + authStorage.authToken;
        if (tokenStr) {
          config.headers["Authorization"] = tokenStr;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    })
    failedQueue = [];
  }

  registerResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        worker.postMessage("Network Back");
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.message === "Network Error") {
          worker.postMessage("Network Error");
        } else {
          worker.postMessage("Network Back");
        }
        if (errorMessages.includes(error.response?.data.message)) {
          window.location.replace("/logout");
        }
        else if(error.response?.data?.code === 2001)
        {
          window.location.replace("404/error");
        }
         else if (
          (error.response.status === 401 && !originalRequest._retry) ||
          error.response.message === "Unauthorized Access"
        ) {
          if (isRefreshing) {
            return new Promise(function(resolve, reject) {
              failedQueue.push({resolve, reject})
            }).then(token => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return axios(originalRequest);
            }).catch(err => {
              return Promise.reject(err);
            })
          }
          originalRequest._retry = true;
          isRefreshing = true;
          try {
            const newToken = await this.getNewAuthToken();
            if (newToken) {
              originalRequest.headers["Authorization"] = "Bearer " + newToken;
              this.processQueue(null, newToken);
              return axios(originalRequest);
            }
          } catch (e) {
            if (e.message === "token expired") {
              window.location.replace("/logout");
            }
          } finally{
            isRefreshing = false 
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getNewAuthToken() {
    let config = {
      method: "post",
      url: STAR_AUTH_SERVICE + "/refresh-access-token",
      headers: {
        Authorization: `Bearer ${authStorage.authToken}`,
      },
      data: {
        refreshToken: authStorage.refreshToken,
      },
    };
    try {
      const { data } = await axios.request(config);
      const newToken = data.accessToken;
      authStorage.setAuthDetails(newToken, data.refreshToken);
      return newToken;
    } catch (err) {
      const _e = new Error("Bad Request");
      _e.name = err.status;
      _e.message = "token expired";
      throw _e;
    }
  }
}
