import { LinearProgress, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Loader from 'react-loader-spinner'
import { styles } from "./styles";
import pageloader from "../../../assets/images/loader/star_blue_loader.gif";
const useStyles = makeStyles(styles);
const FullScreenLoader = React.forwardRef(({ className, ...props }, ref) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={pageloader} style={{width: '100px'}} />
    </div>
  );
});

export default FullScreenLoader;
