import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, DialogActions } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import React from 'react';
import { styles } from "./styles";

const useStyles = makeStyles(styles);
const CustomDialog = ({ title, children, onClose, size, autoWidth, enableFooter, CloseIcon, dialogFooter = <span></span>, ...rest }) => {
    const classes = useStyles()
    const titleColor=rest?.titleColor
    return (
        <Dialog {...rest} maxWidth={size} fullWidth={autoWidth} className={classes.root}>
            <DialogTitle style={{color: titleColor ? titleColor : ''}}>{title}
                <IconButton className="close-btn" onClick={onClose}>
                    {CloseIcon ? CloseIcon : <HighlightOff />}
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {
                Object.keys(dialogFooter.props).length > 0 &&
                <DialogActions>
                    {dialogFooter}
                </DialogActions>
            }
        </Dialog>
    )
}

export default CustomDialog;