import Services from "./services";
import { DASHBOARD_SERVICE, UPCOMING_APPOINTMENTS,CARE_PROGRAM_QUESTION_SERVICE_BASEURL} from "../config/url.config";
import { AxiosInstance } from "axios";

const instance : AxiosInstance = new Services().getServiceInstance();

export const GetDashboardCount = async (key:string, payload:any)=>{
    const { data } = await instance.post(`${DASHBOARD_SERVICE}/getDashBoardCount`,payload);
    return data
}

export const GetDashBoardFacilityCount = async (key:string, params:any)=>{
    const {data} = await instance.get(`${DASHBOARD_SERVICE}/getDashboardFacilityStatsCount`,{params})
    return data
}

export const GetAllCarePrograms = async(params:any)=>{
    const {data} = await instance.get(`${CARE_PROGRAM_QUESTION_SERVICE_BASEURL}/getcareprogramme/count?pageSize=8&pageNumber=1&facilityId=${params.facilityId}`)
    return data
}

export const Upcoming_Appointments = async (facilityPractitioner:string, numberOfdays:number)=>{
    const { data } = await instance.get(`${UPCOMING_APPOINTMENTS}/upcomingAppointments?facilityPractitioner=${facilityPractitioner}&numberOfdays=${numberOfdays}`);
    return data
}

export const getTotalBookedAppointment = async (userId:string)=>{
    const data = await instance.get(`${UPCOMING_APPOINTMENTS}/getTotalBookedAppointment?userId=${userId}`);
    return data
}

export const getDashBoardFacilityStatsCount = async(param:any)=>{
    const {data} = await instance.get(`${DASHBOARD_SERVICE}/getDashboardFacilityStatsCount?facility=${param.facility}`);
    return data
}

export const getDashBoardActiveCareProgramCount = async(param:any)=>{
    const {data} = await instance.get(`${CARE_PROGRAM_QUESTION_SERVICE_BASEURL}/getcareprogramme/count?facilityId=${param.facilityId}&pageSize=${param.pageSize}&pageNumber=${param.pageNumber}`);
    return data
}