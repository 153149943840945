import useTrack from '../../../hooks/useTrack/useTrack';
import AudioTrack from '../AudioTrack/AudioTrack';
import VideoTrack from '../VideoTrack/VideoTrack';
import { LocalVideoTrack, RemoteVideoTrack } from 'twilio-video';

//import { IVideoTrack } from '../../types';
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
  Track,
} from 'twilio-video';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocalParticipant?: boolean;
  videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
  videoType?: string;
}

export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

export default function Publication({
  publication,
  isLocalParticipant,
  videoOnly,
  videoPriority,
  videoType,
}: PublicationProps) {
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case 'video':
      return (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes('camera') && isLocalParticipant}
          videoType={videoType}
        />
      );
    case 'audio':
      return <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
}
