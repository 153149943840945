import { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MenuBar from '../../components/consultation/MenuBar/MenuBar';
import MobileTopMenuBar from '../../components/consultation/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from '../../components/consultation/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from '../../components/consultation/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from '../../components/consultation/RecordingNotifications/RecordingNotifications';
import Room from '../../components/consultation/Room/Room';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import clsx from 'clsx';
import Draggable from 'react-draggable';
import OnlineDoctorlist from '../../components/OnlineDoctorlist';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import Notify from '../../components/common/CommonToast';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { bookConsultationInstant } from '../../api/Instant/InstantApi';
import { UpdateBookId, UpdateCallId } from '../../store/actions';
import useChatContext from '../../hooks/useChatContext/useChatContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateRows: '1fr auto',
    },
    main: {
      display: 'block',
      overflow: 'hidden',
      paddingBottom: `72px`, // Leave some space for the footer
      background: 'black',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: `108px`, // Leave some space for the mobile header and footer
      },

      "& .MuiDrawer-root": {
        display: 'none'
      }
    },
    containerCollapse: {
      width: 'calc(330px - 1em)',
      height: 'calc(215px - 1em)',
      position: 'absolute',
      justifyContent: 'flex-end',
      bottom: 0,
      right: 0,
      padding: '0 16px 16px 0',
      cursor: 'grab',
      zIndex: 1
    },
    AddDoctor: {
      height: "370px",
      "&.MuiDialog-paper": {
        width: "500px !important",
      },
      "& .MuiDialogTitle-root":
      {
        color: "#32529D !important",
      },
      "& .MuiDialogContent-root":
      {
        padding: '8px 0px !important',
        overflow: 'hidden !important',
      },
    },
    buttonWrapper: {
      background: 'transparent',
      color: '#34519C',
      border: '1px solid #B5C3E8',
      borderRadius: '21.5px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    DoctorGrid: {
      display: 'flex',
      flexDirection: 'row'
    },
    Doctorimg: {
      border: '1px solid grey',
      height: '60px',
      width: '60px',
      borderRadius: '2rem'
    },
    DoctorOnlinestatus: {
      height: '10px',
      width: '10px',
      background: 'green',
      borderRadius: '5px',
      position: 'relative',
      top: '-15px',
      left: '48px'
    },
    DoctorOfflinestatus: {
      height: '10px',
      width: '10px',
      background: 'red',
      borderRadius: '5px',
      position: 'relative',
      top: '-15px',
      left: '48px'
    },
    DoctorDetail: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '10px',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingBottom: '3%'
    },
    DoctorName: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '14px',
      color: '#151515'
    },
    DoctorSpeciality: {
      fontWeight: 500,
      fontSize: '13px !important',
      lineHeight: '14px',
      color: '#717171'
    },
    Title: {
      "& .MuiDialogTitle-root": {
        color: '#000000 !important',
        backgroundColor: '#EDEFF3 !important'
      }
    },
    DoctorBody:
    {
      overflow: 'auto', height: '280px', padding: '0px 20px'
    },
    progressBar: {
      AlignItems: "center",
      display: "flex",
      left: "50%",
      margin: "0px auto"
    },
    transform: {
      transform: 'translate(0px, 0px) !important',
      display: 'block',
      overflow: 'hidden',
      paddingBottom: `72px`, // Leave some space for the footer
      background: 'black',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: `108px`, // Leave some space for the mobile header and footer
      },

      "& .MuiDrawer-root": {
        display: 'none'
      }
    }
  })
);

var UnmountTrack;
export default function ConsultationVideoCall({ isVideoCollapsed, setVideoCollapsed, setCallJoined, isCallJoined, check, setshow, setError, changeRoomState, setIsCallBackUserJoined, isCallBackUserJoind }: any) {
  const dispatch = useDispatch();
  const roomState = useRoomState();
  const [open, setOpen] = useState<boolean>(false);
  const { isFetching } = useAppState();
  const { isConnecting, localTracks } = useVideoContext();
  const classes = useStyles();
  const participants = useParticipants();
  const { details } = useSelector((state: any) => state?.clinic);
  const { userRole, user } = useSelector((state: RootStateOrAny) => state?.auth);
  const location = useLocation();
  const { row }: any = location?.state || {}
  const [request, setRequest] = useState({
    message: "",
    loading: false,
    severity: "",
  });
  const { connect: chatConnect } = useChatContext();
  const { callBackData } = useSelector((state: any) => state?.instantVideo)
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  useEffect(() => {
    if (participants.length > 0) {
      const partici = participants[0]
      if (JSON.parse(partici.identity).role === 'consumer' && !isCallBackUserJoind && (callBackData?.type === 'CALLBACK' && callBackData?.status === 'CALL_BACK_TOKEN_STORED')) {
        BookConsultation();
      }
    }
  }, [participants.length])

  const OpenDialog = () => {
    setOpen(true);
  }
  const closeDialog = () => {
    setOpen(false);
  }

  useEffect(() => {
    UnmountTrack = localTracks;
  }, [localTracks])

  useEffect(() => {
    return () => {
      UnmountTrack && UnmountTrack.forEach((tracks) => {
        tracks.stop();
        tracks.detach();
      })
    }
  }, [])

  useEffect(() => {
    changeRoomState(roomState);
  }, [roomState])

  const getInMilliseconds = (time: Record<string, any> | undefined): number => {
    let milliseconds = 0
    if (!!time) {
      const hours = time.get("hour")
      const minutes = time.get("minute");
      milliseconds = (hours * 60 * 60 * 1000) + (minutes * 60 * 1000)
    }
    return milliseconds
  }

  const BookConsultation = async () => {
    setRequest({
      message: "",
      loading: true,
      severity: "",
    });

    let Receptionistpayload = {
      "facility": details?.id,
      "roleId": userRole,
      "practitioner": user?._id,
      "primaryUser": row?.row?.data?.userId,
      "consultationType": "INSTANT",
      "appointmentCreatedBy": user?._id,
      "appointmentCreatedPersonRole": userRole,
      "date": moment().format('YYYY/MM/DD'),
      "slotStartTime": getInMilliseconds(moment()),
    }
    try {
      const res = await bookConsultationInstant('AppoinmentCreate', Receptionistpayload);
      if (res && res?.data) {
        dispatch(UpdateCallId(res?.data?.bookedId + '/' + row?.row?.data?._id));
        dispatch(UpdateBookId(res?.data?.bookedId));
        sessionStorage.setItem('LinkCallID', 'true');
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect?.(callBackData?.data?.token);
        setIsCallBackUserJoined(true);
        setCallJoined(true);
      }
      else {
        setRequest({
          message: res?.data?.message || "Something went wrong",
          loading: false,
          severity: "error",
        });
      }
    }
    catch (e) {
      setRequest({
        message: e?.response?.message || "Something went wrong",
        loading: true,
        severity: "error",
      });
    }
  }

  return (
    <>
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      {
        (roomState === 'disconnected' || !isCallBackUserJoind) ? (
          (!isCallBackUserJoind || !isCallJoined || isFetching || isConnecting) ? <PreJoinScreens setCallJoined={setCallJoined} setError={setError} isCallJoined={isCallJoined} setIsCallBackUserJoined={setIsCallBackUserJoined} isCallBackUserJoind={isCallBackUserJoind} /> : <></>
        ) :
          (
            <Draggable bounds={'body'} position={!isVideoCollapsed ? { x: 0, y: 0 } : undefined}>
              <main className={clsx(isVideoCollapsed ? (classes.main, classes.containerCollapse) : (classes.transform))}>
                {!isVideoCollapsed && <ReconnectingNotification />}
                <RecordingNotifications />
                <MobileTopMenuBar />
                <Room OpenDialog={OpenDialog} isVideoCollapsed={isVideoCollapsed} reference={check} setshow={setshow} />
                <MenuBar OpenDialog={OpenDialog} closeDialog={closeDialog} reference={check} isVideoCollapsed={isVideoCollapsed} />
                <OnlineDoctorlist open={open} closeDialog={closeDialog} />
              </main>
            </Draggable>
          )
      }
    </>
  );
}
