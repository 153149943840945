import * as actionTypes from "./actionTypes";

export const openWebSocket = (data) => ({
    payload: data,
    type: actionTypes.OPEN_WEBSOCKET,
  });

export const setChatRoomList = (data) => ({
    payload: data,
    type: actionTypes.CHAT_ROOM_LIST,
});

export const setChatRoomInfo = (data) => ({
  payload: data,
  type: actionTypes.CHAT_ROOM_INFO,
});

export const setChatParticipantInfo = (data) => ({
  payload: data,
  type: actionTypes.CHAT_ROOM_PARTICIPANT_INFO,
});

export const setMessageList = (data) => ({
  payload: data,
  type: actionTypes.CHAT_MESSAGE_LIST,
});

export const setOtherInfo = (data) => ({
  payload: data,
  type: actionTypes.CHAT_OTHER_INFO,
});

export const setOpenChatList = (data) => ({
  payload: data,
  type: actionTypes.OPEN_CHAT_LIST,
});

export const setEnableWidget = (data) => ({
  payload: data,
  type: actionTypes.ENABLE_CHAT_WIDGET,
});

export const setOpenChatDetails = (data) => ({
  payload: data,
  type: actionTypes.OPEN_CHAT_DETAILS,
});

export const setOpenChatRooms = (data) => ({
  payload: data,
  type: actionTypes.OPEN_CHAT_ROOMS,
});
export const  messageSendError = (data) => ({
  type: actionTypes.MESSAGE_SEND_REQUEST,
  payload : data
});