import { isValidPhoneNumber as validateMobileNumber} from "react-phone-number-input/mobile";
import { isValidPhoneNumber as validatePhoneNumber} from "react-phone-number-input";
import moment from "moment"
import * as yup from "yup";

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
export const PHONE_NUMBER_REGEX = /^(\+91?|[0]?)?[6789]\d{9}$/gm;
export const MOBILE_NUMBER_REGEX = /^[0]?[6789]\d{9}$/gm;
const PASSWORD_REGEX = /^(?=.*\d)(?!.* )(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,15}$/
const GROUP_NAME_REGEX = /^[a-zA-Z "'\-.]*$/
const NAME_REGEX = /^[a-zA-Z ]*$/
const TAG_NAME_REGEX = /^[a-zA-Z 0-9_&-]*$/
const USERNAME_REGEX = /^[A-Za-z0-9_.@]{1,30}$/
const DEPARTMENT_REGEX = /^[a-zA-Z,&() -]*$/
const PIN_CODE_REGEX = /^[1-9][0-9]*$/
const CLINIC_NAME_REGEX = /^([a-zA-Z]+\s)*[a-zA-Z]+$/
const CAREPROGRAM_NAME_REGEX = /^(?!^\\s*$)[A-Za-z\s]*$/
const PARAMETER_NAME = /^[a-zA-Z0-9-/() ]*$/
const CODE= /^[a-zA-Z0-9- ]*$/
const STRING_NUMBER_REGEX= /^[a-zA-Z0-9-/() ]*$/
const COMMON_PATTERN = /^(?!^\\s*$)(?!.*\\s{2,})[A-Za-z.,\s-]*$/
const ASSEST_URL = /^(https?:\/\/)(www.)?([a-zA-Z0-9-]+.){0,2}[a-zA-Z-]{2,}(\/[^\\]*)?$/
const DOOR_STREET = /^[a-zA-Z0-9\/\,-.](?!.*\s$)(?!.*\s{2,})[a-zA-Z0-9\/\,-.\s]{0,28}[a-zA-Z0-9\/\,-.]$/
export const isValidPhoneInput = (number)=>{
        return process.env.REACT_APP_PHONE_NUMBER_VALIDATOR_TYPE === "mobile"
        ? validateMobileNumber(number)
        : validatePhoneNumber(number);
}

export const validations = {
    firstname: yup.string().matches(NAME_REGEX, 'Please enter a valid name').min(2, 'Name is too short').max(60, 'Name is too long'),
    firstnameRequired: yup.string().required("Required field cannot be left blank").matches(NAME_REGEX, 'Please enter a valid name').min(2, 'Name is too short').max(30, 'Name is too long').trim(),
    lastname: yup.string().matches(NAME_REGEX, 'Please enter a valid name').min(2, 'Name is too short').max(60, 'Name is too long'),
    lastnameRequired: yup.string().required("Required field cannot be left blank").matches(NAME_REGEX, 'Please enter a valid name').min(2, 'Name is too short').max(30, 'Name is too long').trim(),
    phone: yup.string().test('phone-validation', 'Please enter valid mobile number.', (value) => value && isValidPhoneInput(value) || !value),
    phoneRequired: yup.string().required("Required field cannot be left blank").test('phone-validation', 'Please enter valid mobile number.', (value) => value && isValidPhoneInput(value)),
    mobileRequired: yup.string().required("Required field cannot be left blank").matches(MOBILE_NUMBER_REGEX, 'Please enter valid mobile number.'),
    password: yup.string().required("Required field cannot be left blank").matches(PASSWORD_REGEX, "Password should be minimum 8 characters, maximum 15 characters, no spaces, at least one uppercase, one number and one special character (eg : Abcd@123)"),
    userName: yup.string().required("Required field cannot be left blank").matches(USERNAME_REGEX, "Please enter a valid user name"),
    email: yup.string().email("Please enter a valid email id"),
    address: yup.string().min(1, 'Required field cannot be left blank'), 
    emailRequired: yup.string().required("Required field cannot be left blank").max(100, 'Email is too long').email("Please enter a valid email id").matches(EMAIL_REGEX, 'Please enter a valid email id').trim(),
    pincode: yup.string().min(4, 'Please enter a valid pincode').max(16, 'Please enter a valid pincode'),
    pincodeRequired: yup.string().required("Required field cannot be left blank").min(6, 'Please enter a valid pincode').max(6, 'Please enter a valid pincode').matches(PIN_CODE_REGEX, 'Please enter a valid pincode'),
    height: yup.number().typeError('Please enter valid height'),
    weight: yup.number().typeError('Please enter valid weight'),
    commonRequired: yup.string().trim().required("Required field cannot be left blank").nullable(),
    genderRequired: yup.string().required("Required field cannot be left blank").oneOf(['male', 'female', 'other','non-binary'], "Required field cannot be left blank"),
    dobRequired: yup.mixed().required("Required field cannot be left blank").test('dob-validation', 'Please select valid date of birth', (value) => value && moment(value).isValid() && moment(value).isSameOrBefore(moment(), 'D')),
    doqRequired: yup.mixed().required("Required field cannot be left blank").test('doq-validation', 'Please select valid date of qualification', (value) => value && moment(value).isValid() && moment(value).isSameOrBefore(moment(), 'D')),
    timeRequired: yup.mixed().required("Required field cannot be left blank").test('dob-validation', 'Please select valid time', (value) => value && moment(value).isValid()),
    freeTextInput: yup.string().required("Required field cannot be left blank").matches(NAME_REGEX, 'Please enter a valid bank name').min(2, 'Too Short').max(250, 'Too Long'),
    validPositiveNumber: yup.number().typeError('Required field cannot be left blank').required("Required field cannot be left blank").positive('Please enter a valid number'),
    careProgramName: yup.string().trim().required("Required field cannot be left blank").min(2, "Too short").max(80, "Program name is too long").matches(CAREPROGRAM_NAME_REGEX, 'Enter valid name.'),
    boolean: yup.boolean(),
    experienceRequired: yup.number().integer().required("Required field cannot be left blank").typeError('Please enter valid experience').min(0, 'Please enter valid experience').max(99, 'Please enter valid experience'),
    bio: yup.string().trim().required("Required field cannot be left blank").min(2, 'Too Short').max(200, 'Bio is too long'),
    primaryspeciality: yup.string().trim().required("Required field cannot be left blank").max(40, 'primaryspecialty is too long').matches(DEPARTMENT_REGEX,"Please enter a valid primary specialty"),
    parameterName: yup.string().trim().required("Required field cannot be left blank").nullable().max(60, 'Parameter name is too long').matches(PARAMETER_NAME, "Please enter a valid parameter name"),
    code: yup.string().trim().required("Required field cannot be left blank").nullable().max(15, 'Code is too long').min(3,'Code is too short').matches(CODE, "Please enter a valid code"),
    units: yup.array().min(1, 'Required field cannot be left blank').max(10, 'you can select maximum 10 units'),
    uploadFormat: yup.array().required("Required field cannot be left blank"),
    aadharNumber:yup.number().typeError('Required field cannot be left blank').required("Required field cannot be left blank"),
    ifscCode: yup.string().required("Required field cannot be left blank").nullable().trim().matches(/^[A-Z]{4}0[A-Z0-9]{6}$/,"Enter Valid IFSC Code"),
    panNumber : yup.string().required("Required field cannot be left blank").nullable().trim().matches(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/,"Enter Valid PanNumber"),
    websiteName:yup.string().nullable().matches(/((?:(http|ftp|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gm,"Enter Valid Website"),
    bankNumber: yup.string().required("Required field cannot be left blank").nullable().trim().matches(/^\d{9,18}$/,"Enter Valid AccountNumber"),
    inConsulationFee : yup.number().transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value).min(1,"Minimum value should be 1").max(9999,"Maximum value should be 9999"),
    virtualFee : yup.number().transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value).min(1,"Minimum value should be 1").max(9999,"Maximum value should be 9999"),
    facilityName: yup.string().required("Required field cannot be left blank").matches(/^[a-zA-Z0-9 ,.""-''&()\s]*$/, 'Please enter a valid name').min(2, 'Name is too short').max(256, 'Name is too long'),
    establishmentNumber: yup.string().required("Required field cannot be left blank").matches(/^[a-zA-Z0-9 ]*$/, 'Please enter a valid name').min(4, 'Number is too short').max(25, 'Number is too long'),
    city: yup.string().required("Select City").nullable(),
    state: yup.string().required("Select State").nullable(),
    country: yup.string().required("Select Country").nullable(),
    specialisation: yup.string().required("Select facility type").nullable(),
    emailcheck:yup.string().required("Required field cannot be left blank").email("Please enter a valid email id"),
    healthProfilerDescription : yup.string().trim().required("Required field cannot be left blank").min(3,"Description is too short").max(300, "Description is too long"),
    frequncyCount:yup.number().positive("Please enter valid count").integer("Please enter valid count").typeError('Please enter valid count').required("Required field cannot be left blank"),
    hintTypeUrl:yup.string().required("Required field cannot be left blank").matches(ASSEST_URL,"Please enter a valid url"),
    hintTypeText:yup.string().required("Required field cannot be left blank").min(3,"Text is too short").test('len', 'Text is too long', val => val && val.replace(/(\r\n|\n|\r)/gm,"").toString().length <= 300 ),
    numberTypeDecimal: yup.number().typeError('Required field cannot be left blank').required("Required field cannot be left blank").min(0,"Value must be greater than or equal to 0").max(999999999,"Value must be less than or equal to 999999999"),
    responseValues: yup.array().max(20).min(1).of(yup.object().shape({value: yup.string().required("Required field cannot be left blank").max(60,"Value is too long").trim(), code: yup.string().required("Required field cannot be left blank").max(15,"Code is too long").min(3,"Code is too short").trim()})),
    linkertscaleValues: yup.array().of(yup.object().shape({value: yup.string().required("Required field cannot be left blank").trim().max(60,"Title is too long")})),
    textType: yup.number().integer().typeError('Please enter valid limit').required("Required field cannot be left blank").nullable().min(2, 'Maximum character limit should be more than 1').max(140, 'Minimum character limit should be less than 140'),
    textAreaType: yup.number().integer().typeError('Please enter valid limit').required("Required field cannot be left blank").nullable().min(2, 'Maximum character limit should be more than 1').max(300, 'Minimum character limit should be less than 300'),
    numberType: yup.number().integer("Please enter valid number").typeError('Please enter valid number').required("Required field cannot be left blank").max(5, "Maximum file size limit should be 5 Mb"),
    uploadFormat: yup.array().required("Required field cannot be left blank"),
    healthProfilerQuestionList:yup.array().of(yup.object().shape({questionName: yup.string().required("Required field cannot be left blank").trim().min(3,"Title is too short").max(140, "Title is too long")})),
    healthProfilerQuestionTitle:yup.string().required("Required field cannot be left blank").min(3,"Title is too short").max(140, "Title is too long"),
    questionSetTags : yup.array().max(3, 'Maximum 3 tags allowed'),
    healthProfilerTag:yup.array().required("Required field cannot be left blank").max(3, 'Maximum 3 tags allowed'),
    validHeightRequired: yup.number().integer("Decimal not allowed").required("Required field cannot be left blank").max(280, 'Invalid format. Enter value between 20 - 280 cm').min(20, 'Invalid format. Enter value between 20 - 280 cm').typeError('Please enter valid height'),
    validWeightRequired: yup.number().integer("Decimal not allowed").required("Required field cannot be left blank").max(800, "Invalid format. Enter value between 2 - 800 kg").min(2, "Invalid format. Enter value between 2 - 800 kg").typeError('Please enter valid weight'),
    noteTitle: yup.string().required("Required field cannot be left blank").max(125, "Title must be less than 125 characters").trim(),
    noteDescription: yup.string().required("Required field cannot be left blank").min(2, 'Too Short').trim(),
    maxTagLimit: yup.array().max(2,"Limit tags to a maximum of two"),
    groupnameRequired: yup.string().required("Required field cannot be left blank").min(4, 'Name is too short & Please enter a valid name').max(25, 'Name is too long').matches(GROUP_NAME_REGEX, 'Please enter a valid name').trim(),
    groupDescriptionRequired: yup.string().required("Required field cannot be left blank").min(2, 'description is too short').max(500, 'description is too long').matches(COMMON_PATTERN,"Please enter a valid description").trim(),
    validTempRequired: yup.number().integer().nullable().transform((_, val) => (val !== "" ? Number(val) : null)).moreThan(33,"Minimum value should be minimum 34").lessThan(44,"Maximum value should be maximum 43"),
    childrenRequired: yup.string().nullable().required("Required field cannot be left blank").matches(/^(?:[0-9]|10)$/,'Please enter valid count').max(10, 'Please enter valid count').min(0, 'Please enter valid count'),
    policyValidation: yup.string().nullable().required("Required field cannot be left blank").trim(),
    aadharNumberRequired: yup.string().trim().required("Required field cannot be left blank").matches(/[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/, "Please enter valid Aadhar number").max(12, "Please enter valid Aadhar number").typeError('Required field cannot be left blank'),
    durationRequired: yup.number().required("Required field cannot be left blank").typeError('Please enter valid duration').min(1, 'Please enter valid duration').max(12,'Please enter valid duration'),
    groupinstructionsRequired: yup.string().required("Required field cannot be left blank").min(2, 'instruction is too short').max(250, 'instruction is too long').matches(COMMON_PATTERN,"Please enter a valid instruction").trim(),
    doorNo: yup.string().required("Required field cannot be left blank").max(30, 'Maximum character limit should be 30').min(2,'Minimum character limit should be 2').matches(DOOR_STREET,"please enter valid adress").trim(),
    streetRequired : yup.string().required("Required field cannot be left blank").max(256, 'Maximum character limit should be 256').min(2,'Minimum character limit should be 2').matches(/^[a-zA-Z0-9\/\,-.](?!.*\s$)(?!.*\s{2,})[a-zA-Z0-9\/\,-.\s]{0,254}[a-zA-Z0-9\/\,-.]$/,"please enter valid adress").trim(),
    street: yup.string().trim().required("Required field cannot be left blank").max(100, 'Maximum character limit should be 100'),
    careProgram_Benefits: yup.string().required("Required field cannot be left blank").min(2, 'Benefit is too short').max(200, 'Benefit is too long').matches(COMMON_PATTERN, 'Please enter a valid text').trim(),
    scaleLimit: yup.string().required("Required field cannot be left blank").max(20, 'Maximum character limit should be 20'),
    hintTypeWebsite:yup.string().nullable().matches(ASSEST_URL,"Please enter a valid url"),
    facilityPhoneRequired: yup.string().required("Required field cannot be left blank").matches(PHONE_NUMBER_REGEX, 'Please enter valid mobile number.').test('phone-validation', 'Please enter valid aaaa mobile number.', (value) => value && validatePhoneNumber(value)),
    tagnameRequired: yup.string().trim().required("Required field cannot be left blank").min(3, 'Name is too short').max(30, 'Name is too long').matches(TAG_NAME_REGEX, 'Please enter a valid name'),
}