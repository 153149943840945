import { Typography } from '@material-ui/core'
import moment from 'moment'
import { useState, useEffect } from 'react'
import CommonFilter from '../../../components/common/CommonFilter'
import CommonDatePicker from '../../../components/common/CommonDatePicker'
// import CustomDropDownWhite from '../../../components/common/CustomDropDownWhite'
// import CustomMenuItemWhite from '../../../components/common/CustomDropDownWhite/CustomMenuItemWhite'
import styles from "./style"



interface ICustomFilter {
    DoctorFilterObj: (value: any) => void,
    setTablePage:any
    tab: any,
}

const FilterAppointment = (props: ICustomFilter) => {
    const classes = styles()
    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setToDate] = useState<any>("");
    const [status, setStatus] = useState<any>("");
    const [appointmentType, setAppointmentType] = useState<string>("");
    const  currentDate = new Date();
    const  futureDate = moment(fromDate?._d).add(89, 'days').startOf('day');
    const  currentFutureDate = moment(currentDate).add(89, 'days').startOf('day');
    const  currentPastDate = moment(currentDate).subtract(89, 'days').startOf('day');
 
   
  const handleResetFilter = () => {
        setFromDate("")
        setToDate("")
        setStatus("")
        setAppointmentType("")
        props.DoctorFilterObj({appointmentType: '',fromDate: '',toDate: '',status: ''})
        props.setTablePage(1)
    }

    const handleApplyFilter = () => {
        fromDate && toDate === "" && setToDate(fromDate);
        props.DoctorFilterObj({
            appointmentType: appointmentType,
            fromDate: fromDate? moment(fromDate).format("YYYY/MM/DD") : "",
            toDate: toDate ? moment(toDate).format("YYYY/MM/DD") : fromDate && toDate === "" ? moment(fromDate).format("YYYY/MM/DD") : "",
            status: status
        })
        props.setTablePage(1);
       
    }

    useEffect(() => {
        handleResetFilter();
      }, [props.tab]);


    // const AppointmentType = () => {
    //     return (
    //         <CustomDropDownWhite
    //             placeholder="Select Type"
    //             value={appointmentType}
    //             onChange={(e: { target: { value: string }; }) => {
    //                 setAppointmentType(e.target.value)
    //             }}
    //         >
    //             <CustomMenuItemWhite value="VIRTUAL_CONSULTATION">Virtual Consult</CustomMenuItemWhite>
    //              {/**
    //               * Temperorily Instant call is disabled.
    //              */ }  
    //             {/* <CustomMenuItemWhite value="INSTANT">Instant Call</CustomMenuItemWhite> */}
    //             {/* <CustomMenuItemWhite value="CHAT">Instant Chat</CustomMenuItemWhite> */}
    //             {/* Will enable the chat in next phase */}
    //         </CustomDropDownWhite>
    //     )
    // }
    // Commenting this and will use it in next phase

    const DateRange = () => {
        return (
            <div className={classes.dateRangeWrapper}>
                <div className={classes.datePicker}>
                    <Typography className={classes.datePickerLabel}>From</Typography>
                    <CommonDatePicker
                        format="DD/MM/YYYY"
                        placeholder="Select Date"
                        value={fromDate || null}
                        minDate={props.tab===1?currentDate:currentPastDate}
                        maxDate={props.tab===1?currentFutureDate:futureDate}
                        onChange={(e: any) => {
                            setFromDate(e);
                            setToDate("");
                        }}
                        inputVariant="outlined"
                        inputProps={{ readOnly: true }}
                        InputAdornmentProps={{ position: 'start' }}
                    />
                </div>
                <div className={classes.datePicker}>
                    <Typography className={classes.datePickerLabel}>To</Typography>
                    <CommonDatePicker
                        format="DD/MM/YYYY"
                        placeholder="Select Date"
                        value={toDate || null}
                        disabled = {!fromDate}
                        minDate={fromDate}
                        maxDate={futureDate}
                        // minDateMessage="Date should not be before start date"
                        inputProps={{ readOnly: true }}
                        onChange={(e: any) => {   
                            setToDate(e);
                        }}
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: 'start' }}
                        initialFocusedDate={fromDate}
                    />
                </div>
            </div>
        )
    }



  

    const filterTypes= props.tab === 1 ? [   
        { label: "Date Range", component: <DateRange /> },
    ]:[
        // { label: "Consultation Type", component: <AppointmentType /> },
        { label: "Date Range", component: <DateRange /> },
    ]

   
    return (
        <CommonFilter
            filterTypes={filterTypes}
            applyFilter={handleApplyFilter}
            resetFilter={handleResetFilter}
        />
    )
}

export default FilterAppointment