export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  localStorage.setItem('cookie-expires', date.toUTCString())
  document.cookie = name + "=" + (value || "") + expires + "; path=/;Secure=true;SameSite=Strict";
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function setrememberKey(name, value, days){
  var expireKey = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expireKey = "; expires=" + date.toUTCString();
  }
  localStorage.setItem('rememberKey-expires', date.toUTCString())
  document.cookie = name + "=" + (value || "") + expireKey + "; path=/;Secure=true;SameSite=Strict";
}

export function getrememberKey(name) {
let key  = `${name}=`;
let array = document.cookie.split(";");
if(array)
{
  let filtervalue = array.filter((element) => element.includes(`${process.env.REACT_APP_REMEMBER_KEY}`))
  let value = filtervalue[0] ? filtervalue[0].split(key) : [];
  return value[1] ?? null ;
}
 return null;
}

export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
