import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import FullScreenLoader from "./components/common/FullScreenLoader";
import { setAuthCompleted } from "./store/actions";
import { USER_ROLES } from "../src/utils/constants";




const AdminLogin = React.lazy(() => import("./pages/NewAdmin/Login"));
const Logout = React.lazy(() => import("./pages/Logout"));
/* ( path="/onboarding" path="/signup") Forgot Password Flow commented Temporarily */
// const AdminOnboarding = React.lazy(() => import("./pages/NewAdmin/Onboarding"));
//const AdminSignUp = React.lazy(() => import("./pages/NewAdmin/SignUp"));
const AdminSlider = React.lazy(() => import("./pages/NewAdmin/Slider"));
const GetStarted = React.lazy(() => import("./pages/NewDoctor/GetStarted"));
const Receptionist=React.lazy(() => import("./pages/Receptionist/Dashboard"));
const RolesList = React.lazy(() => import("./pages/NewAdmin/RolesList"));
const ErrorPage = React.lazy(()=> import("./pages/NewAdmin/404Error"))
const FacilityAdmin = React.lazy(() => import("./pages/ManageUser/Admin"));
const FacilityDoctor = React.lazy(() => import("./pages/ManageUser/Doctor"));
const FacilityAhp = React.lazy(() => import("./pages/ManageUser/AHP"));
const FacilityOthers = React.lazy(() => import("./pages/ManageUser/Others"));
const AdminDetail=React.lazy(() => import("./pages/ManageUser/Admin/AdminDetail"));
const AhpDetail=React.lazy(()=> import("./pages/ManageUser/AHP/AhpDetails"))
const OthersDetail=React.lazy(()=> import("./pages/ManageUser/Others/OthersDetail"))
const missedcallDetails = React.lazy(()=>import("./pages/Receptionist/missedcalls"))
const missedcallDetail = React.lazy(()=>import("./pages/FacilityDoctor/missedcalls"))
const Reports = React.lazy(()=>import("./pages/Reports"))
const LabOrders = React.lazy(()=>import("./pages/LabOrders"));

// ---> Commenting this and will use in next Phase <---//
// const LabTranscriber = React.lazy(() => import("./pages/LabTest"));
// const Empanelmentfacility=React.lazy(()=> import ("./pages/Empanelment/facilityPending"));
// const EmpanelmentDoctor =React.lazy(()=> import ("./pages/Empanelment/DoctorPending"));
// const FacilityDetail=React.lazy(() => import("./pages/Empanelment/FacilityDetail"))
// const DoctorDetail=React.lazy(() => import("./pages/Empanelment/DoctorDetail"))
// const AdminFacilityList = React.lazy(() => import("./pages/NewAdmin/FacilityList"));
const CreateFacility=React.lazy(() => import("./pages/NewAdmin/CreateFacility"));
// const CreatePassword = React.lazy(() => import("./pages/NewDoctor/CreatePassword"));
//const AdminOtp = React.lazy(() => import("./pages/NewAdmin/Otp"));
// const OperationalReceptionist = React.lazy(() => import("./pages/OperationalReceptionist/Dashboard"));


const AppointmentsManagement = React.lazy(() => import("./pages/AppointmentManagement/Appointment"));
const FollowupRequests = React.lazy(() => import("./pages/AppointmentManagement/FollowupRequest"));
const Consultation = React.lazy(() => import("./pages/AppointmentManagement/Consultation"));
const FacilityPatients = React.lazy(() => import("./pages/ManageUser/Patients"));
const FacilityPatientDetail = React.lazy(() => import("./pages/ManageUser/Patients/PatientDetails"));
const PatientList = React.lazy(()=> import ("./pages/ManageUser/Patients/PatientList"))
const PatientProfile = React.lazy(() => import("./pages/ManageUser/Patients/PatientList/PatientProfile"));
const PatientCareProgramEnroll = React.lazy(() => import("./pages/ManageUser/Patients/PatientList/ProgramAssociation"));

// Availability management
const Scheduler = React.lazy(() => import("./pages/SlotManagement"));

// Empanelment
const EmpanelDashboard = React.lazy(() => import("./pages/Empanelment/Dashboard"))
const AdminFacilityDetail = React.lazy(() => import("./pages/NewAdmin/FacilityDetail"));
const AdminAddDoctor = React.lazy(() => import("./pages/NewAdmin/CreateDoctor"));
const CreateAHP = React.lazy(()=>import("./pages/NewAdmin/CreateAhp"))
const AdminDoctors = React.lazy(() => import("./pages/NewAdmin/Doctors"));

//Profiles - Doctor, Admin, Wellness Coach and Facility.
const MyDoctorProfile = React.lazy(()=> import("./pages/NewProfiles/DoctorProfile/myProfile"));
const MyAdminProfile = React.lazy(()=> import("./pages/NewProfiles/AdminProfile/myProfile"));
const ManageUserDoctor = React.lazy(()=> import("./pages/NewProfiles/DoctorProfile/manageUserDoctor"));
const ManageUserAdmin = React.lazy(()=> import("./pages/NewProfiles/AdminProfile/manageUserAdmin"));
const ManageUserReceptionist = React.lazy(()=> import("./pages/NewProfiles/ReceptionistProfile/manageUserReceptionist"));
const ReceptionistProfile = React.lazy(()=> import("./pages/NewProfiles/ReceptionistProfile/myProfile"));
const ManageUserWellness = React.lazy(()=> import("./pages/NewProfiles/WellnessProfile/manageUserAhp"));
const myWellnessProfile = React.lazy(()=> import("./pages/NewProfiles/WellnessProfile/myProfile"));
const AdminProfile = React.lazy(()=> import("./pages/NewProfiles/AdminProfile"));
const WellnessProfile = React.lazy(()=> import("./pages/NewProfiles/WellnessProfile"));
const FacilitySettings = React.lazy(()=> import("./pages/FacilitySettings"));
const PatientEnrollment =React.lazy(()=> import("./pages/Enrollment/patients"))
const Dashboard = React.lazy(()=> import("./pages/Dashboard"));
const CreateOtherThanDoctor = React.lazy(()=>import("./pages/NewAdmin/CreateOtherThanDoctor"))
const MyOperationalReceptionistProfile = React.lazy(()=> import("./pages/NewProfiles/OperationalReceptionistProfile"));
//claims
const ClaimsDashBoard = React.lazy(()=> import("./pages/Claims/ClaimsDashboard"));
const ClaimsConsultation=React.lazy(()=> import("./pages/Claims/Consultation"));
const EpharmacyList=React.lazy(()=> import("./pages/Claims/Epharmacy"));
const DiagnosticsList=React.lazy(()=> import("./pages/Claims/Diagnostics"));
const CreatePatient =React.lazy(()=>import("./pages/NewAdmin/CreatePatient"));
//consultation
const VideoConsultation=React.lazy(()=> import("./pages/consultation/ConsultationRender"));

//baros
const DataParmList = React.lazy(() => import("./pages/HealthProfiler/DataParameter"));
const HealthProfilerConsole = React.lazy(() => import("./pages/HealthProfiler"));
const HealthProfilerQuestions = React.lazy(() => import("./pages/HealthProfiler/Questions/QuestionLibrary"));
const HealthProfilerQuestionSet = React.lazy(() => import("./pages/HealthProfiler/Questions/QuestionSetLibrary"));
const HealthProfilerTemplate = React.lazy(() => import("./pages/HealthProfiler/Template"));
const QuestionForm = React.lazy(() => import("./pages/HealthProfiler/Questions/QuestionForm"));
const QuestionSetForm = React.lazy(() => import("./pages/HealthProfiler/Questions/QuestionSetForm"));
const TemplateForm = React.lazy(() => import("./pages/HealthProfiler/Template/TemplateForm"));
const HealthProfilerAssociations = React.lazy(() => import("./pages/HealthProfiler/Associations"));
const Chats = React.lazy(() => import("./pages/Chats"))
const CreateCareProgram = React.lazy(() =>
  import("./pages/CareProgram/CreateProgram")
);
const CareProgramList = React.lazy(() =>
  import("./pages/CareProgram/CareProgramList")
);
const EnrollmentRequest = React.lazy(() => import('./pages/CareProgram/EnrollmentRequset'))
const CareProgramGroups = React.lazy(() => import("./pages/CareProgram/Groups/GroupList"))
const CareProgramGroupsEdit = React.lazy(() => import("./pages/CareProgram/Groups/GroupListEdit"))
const CareProgramGroupsAddmembers = React.lazy(() => import("./pages/CareProgram/Groups/AddMembers"))
const TagsList = React.lazy(() => import("./pages/Tagslist/index.tsx"))



export default function Routes({ isAuthenticated }) {
  const { userRole, isLoggedUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const DashboardRedirect = location.pathname === '/';

  useEffect(() => {
    if (isAuthenticated && location?.pathname == "/clinic/list") {
      dispatch(setAuthCompleted(true));
    }
  }, [isAuthenticated, location.pathname]);

  const restrictedRoutes = [
  //<-- Commenting this and will use in next Phase -->
    // {
    //   path: "/labTranscriber",
    //   component: LabTranscriber,
    //   roles: [USER_ROLES.transcription_agent],
    // },
      // {
    //   path: "/Empanelment/facilityEmpanelment",
    //   component: Empanelmentfacility,
    //   roles: [USER_ROLES.empanelment_admin],
    // },
    // {
    //   path: "/Empanelment/DoctorEmpanelment",
    //   component: EmpanelmentDoctor,
    //   roles: [USER_ROLES.empanelment_admin],
    // },
    // {
    //   path:"/Empanelment/facilityEmpanelment/FacilityDetail",
    //   component:FacilityDetail,
    //   roles:[USER_ROLES.empanelment_admin],
    // },
    // {
    //   path:"/Empanelment/DoctorEmpanelment/DoctorDetail",
    //   component:DoctorDetail,
    //   roles:[USER_ROLES.empanelment_admin],
    // },
      // { path: "/createfacility", component: CreateFacility, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.field_agent] },
    // {path:'/onboarding' ,component:AdminOnboarding ,roles:['All']},
    // {path:'/admin/reset-password' ,component:AdminResetPassword ,roles:['All']},
    // {path:'/admin/otp' ,component:AdminOtp ,roles:['All']},
    // { path: "/admin/facility-list", component: AdminFacilityList, exact : true,roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.field_agent,USER_ROLES.telemedicine_admin,USER_ROLES.operations_admin,USER_ROLES.receptionist, USER_ROLES.facility_receptionist] },
    // { path: "/admin/reset-password", component: AdminResetPassword, roles: ["All"] },
    {path: "/ManageUsers/Patients/create/:userType",component:CreatePatient,roles:[USER_ROLES.facility_admin , USER_ROLES.Wellness_support_interim, USER_ROLES.wellness_counselor_interim, USER_ROLES.wellness_nutritionist_interim, USER_ROLES.wellness_coach]},
    {path: "/ManageUsers/Doctor/create/:userType",component:AdminAddDoctor,roles:[USER_ROLES.facility_admin]},
    {path: "/ManageUsers/Admin/create/:userType",component:CreateOtherThanDoctor,roles:[USER_ROLES.facility_admin]},
    {path: "/ManageUsers/Others/create/:userType",component:CreateOtherThanDoctor,roles:[USER_ROLES.facility_admin]},
    {path: "/ManageUsers/AHP/create/:userType",component:CreateAHP,roles:[USER_ROLES.facility_admin]},
    {path: "/ManageUsers/Patients/CareProgramEnroll/:user_id",component:PatientCareProgramEnroll,roles:[USER_ROLES.facility_admin,USER_ROLES.doctor, USER_ROLES.Wellness_support_interim, USER_ROLES.wellness_counselor_interim, USER_ROLES.wellness_nutritionist_interim, USER_ROLES.wellness_coach]},

    // { path: "/OperationalReceptionist/dashboard", component: OperationalReceptionist, roles: [USER_ROLES.opd_ops] },

    /* { path: "/login", component: AdminLogin, roles: ["All"] }, */
    { path: "/logout", component: Logout, roles: ["All"], },
    {
      path: "/ManageUsers/Admin",
      component: FacilityAdmin,
      exact: true,
      roles: [USER_ROLES.facility_admin],
    },
    {
      path: "/ManageUsers/Doctor",
      component: FacilityDoctor,
      exact: true,
      roles: [USER_ROLES.facility_admin],
    }, 
    {
      path: "/ManageUsers/AHP",
      component: FacilityAhp,
      exact: true,
      roles: [USER_ROLES.facility_admin],
    }, 
    {
      path: "/ManageUsers/Others",
      component: FacilityOthers,
      exact: true,
      roles: [USER_ROLES.facility_admin],
    },
    // {
    //   path: "/ManageUsers/Patients",
    //   component: FacilityPatients,
    //   exact: true,
    //   roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    // },
    {
      path: "/ManageUsers/Patients",
      component: PatientList,
      exact: true,
      roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.Wellness_support_interim],
    },
    {
      path: "/enrollment/patients",
      component: PatientEnrollment,
      roles: ["All"],

    },
    {
      path: "/ManageUsers/Patients/Detail",
      component: PatientProfile,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.receptionist,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.Wellness_support_interim],
    },
    {
      path: "/ManageUsers/AdminDetail/:id?",
      component: AdminDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    },  
    {
      path: "/ManageUsers/AhpDetail/:id?",
      component: AhpDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    }, 
    {
      path: "/ManageUsers/OthersDetail/:id?",
      component: OthersDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    }, 
    {
      path: "/Appointments",
      component: AppointmentsManagement,
      roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.receptionist,USER_ROLES.opd_ops,USER_ROLES.facility_receptionist,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    },
    // {
    //   path: "/Reports",
    //   component: Reports,
    //   roles: [USER_ROLES.facility_admin],
    // },
    {
      path: `/lab-orders`,
      component: LabOrders,
      roles:["All"]
    },
    {
      path: "/clinic/patients/:id",
      component: PatientProfile,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/Appointments/consultation",
      component: Consultation,
      roles: ["All"],
    },
    {
      path: "/Followup_requests",
      component: FollowupRequests,
      roles: ["All"],
    },
    {
      path:'/chat-consulation',
      component : Consultation,
      roles : [USER_ROLES.doctor]
    },
    { path: "/Receptionist/dashboard", component: Receptionist, roles: [USER_ROLES.receptionist,USER_ROLES.facility_receptionist] },
    { path: "/Empanelment/dashboard", component: EmpanelDashboard, roles: [USER_ROLES.empanelment_admin] },
    // {path:'/signup' ,component:AdminSignUp ,roles:['All']}, /* ( path="/onboarding" , path="/signup") Forgot Password Flow commented Temporarily */
    { path: "/admin/roles-list", component: RolesList, roles: ["All"] },
    { path: "/admin/invite", component: GetStarted, roles: ["All"] },
    { path: "/admin/facility-detail/:facilityid?", component: AdminFacilityDetail, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin] },

    { path: "/admin/add-doctor/:facilityid", component: AdminAddDoctor, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.field_agent]},

    { path: "/admin/doctors-list", component: AdminDoctors, roles: ["All"] },

    { path: "/claims/dashboard", component: ClaimsDashBoard, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },

    {
      path: "/appointment-scheduler",
      component: Scheduler,
      roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.doctor,USER_ROLES.receptionist,USER_ROLES.super_admin,USER_ROLES.opd_ops, USER_ROLES.facility_receptionist,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    },
    { path: "/Dashboard", component: Dashboard,exact : true, roles: ["All"] },
    { path: "/Empanelment/index", component: EmpanelDashboard, roles: ["All"] },
    { path: "/Dashboard/newprofile/doctor", component: MyDoctorProfile, roles: [USER_ROLES.doctor,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.Wellness_support_interim] },
    { path: "/Dashboard/newprofile/wellnessCoach", component: ManageUserWellness, roles: [USER_ROLES.wellness_coach] },
    { path: "/Dashboard/newprofile/wellnessCounselor", component: ManageUserWellness, roles: [USER_ROLES.wellness_counselor_interim] },
    { path: "/Dashboard/newprofile/wellnessNutritionist", component: ManageUserWellness, roles: [USER_ROLES.wellness_nutritionist_interim] },
    { path: "/Dashboard/newprofile/wellnessSupport", component: ManageUserWellness, roles: [USER_ROLES.Wellness_support_interim] },
    { path: "/newprofile/operational-recepptionist", component: MyOperationalReceptionistProfile, roles: [USER_ROLES.opd_ops] },
    { path: "/Dashboard/newprofile/admin", component: MyAdminProfile, roles: [USER_ROLES.facility_admin] },
    { path: "/Dashboard/newprofile/receptionist", component: ReceptionistProfile, roles: [USER_ROLES.receptionist, USER_ROLES.facility_receptionist] },
    { path: "/ManageUsers/Doctor/profile:doctorId?", component: ManageUserDoctor, roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim] },
    { path: "/ManageUsers/Admin/profile:adminId?", component: ManageUserAdmin, roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim] },
    { path: "/ManageUsers/Others/profile:receptionistId?", component: ManageUserReceptionist, roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim] },
    { path: "/ManageUsers/AHP/profile:wellnessId?", component: ManageUserWellness, roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim] },
    { path: "/newprofile/wellness", component:myWellnessProfile, roles: ["All"] },
    { path: "/newprofile/admin", component: AdminProfile, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.field_agent]},
    { path: "/newprofile/wellness", component: WellnessProfile, roles: ["All"] },
    { path: `/newprofile/facility`, component: FacilitySettings, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin] },
    { path: "/Admin/Slider", component: AdminSlider, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin] },
    { path: "/claims/consultation", component: ClaimsConsultation, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },
    { path: "/claims/epharmacylist", component: EpharmacyList, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },
    { path: "/claims/diagnostics", component: DiagnosticsList, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },
    { path: "/Appointments/room:urlname?", component: VideoConsultation, roles: [USER_ROLES.doctor,USER_ROLES.receptionist,USER_ROLES.wellness_coach ,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim] },
    { path: "/InstantCall/room:urlname?", component: VideoConsultation, roles: [USER_ROLES.doctor,USER_ROLES.receptionist,USER_ROLES.wellness_coach ,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim] },
    { path:'/404/error', component:ErrorPage, roles: ["All"] },
    { path: "/Dashboard/missedCalls", component: missedcallDetails, roles: ["All"] },
    { path: "/Dashboard/missedCall", component: missedcallDetail, roles: ["All"] },
    {
      path: "/health-profiler",
      component: HealthProfilerConsole,
      roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    },
    {
      path: "/health-profiler/hp-questions",
      component: HealthProfilerQuestions,
      roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    },
    {
      path: "/health-profiler/hp-question-set",
      component: HealthProfilerQuestionSet,
      roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    },
    {
      path: "/tags",
      component: TagsList,
      exact: true,
      roles: [USER_ROLES.facility_admin],
    },
    {
      path: "/health-profiler/template",
      component: HealthProfilerTemplate,
      roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    },
    {
      path: "/health-profiler/associations",
      component: HealthProfilerAssociations,
      roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    },   
    {
      path: "/health-profiler/data-master",
      component: DataParmList,
      exact: true,
      roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
    },
    {
      path: "/chats/:roomId?",
      component: Chats,
      roles: [USER_ROLES.facility_admin,USER_ROLES.Wellness_support_interim,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.wellness_coach,USER_ROLES.doctor],
    },
    {
         path: "/health-profiler/hp-questions/create-question/:questionId?",
         component: QuestionForm,
         roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
        },
        {
         path: "/health-profiler/hp-question-set/view-template/:questionSetId",
         component: TemplateForm,
         roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
        },
        {
         path: "/health-profiler/template/view-template/:questionSetId",
         component: TemplateForm,
         roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
        },
        {
         path: "/health-profiler/hp-question-set/create-question-set/:questionSetId?",
         component: QuestionSetForm,
         roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim],
        },
        {
          path: "/care-program/wellness-program",
          component: CareProgramList,
          exact: true,
          roles: [USER_ROLES.facility_admin],
        },
        {
          path: "/care-program/groups/:id?",
          component: CareProgramGroups,
          exact: true,
          roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.Wellness_support_interim,USER_ROLES.doctor],
        },
        {
          path: "/care-program/groups/edit-group/:groupId/:careProgramId",
          component: CareProgramGroupsEdit,
          exact: true,
          roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.Wellness_support_interim,USER_ROLES.doctor],
        },
        {
          path: "/care-program/groups/add-members/:groupId/:careProgramId",
          component: CareProgramGroupsAddmembers,
          exact: true,
          roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.Wellness_support_interim,USER_ROLES.doctor],
        },
        {
          path: "/care-program/wellness-program/create/:id?",
          component: CreateCareProgram,
          exact: true,
          roles: [USER_ROLES.facility_admin],
        },
        {
          path: "/care-program/enrollment-request",
          component: EnrollmentRequest,
          exact: true,
          roles: [USER_ROLES.facility_admin,USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.Wellness_support_interim,USER_ROLES.doctor],
        },
  ];
  
/* ( path="/onboarding" , path="/signup") Forgot Password Flow commented Temporarily */
  let routes = (
    <Switch>
      <Route path='/login' exact = {true} component={AdminLogin} />
      {/* <Route path="/onboarding" exact={true} component ={AdminOnboarding} /> */} 
      {/* <Route path="/signup" exact={true} component ={AdminSignUp} /> */}
      {/* { !isLoggedUser  && <Redirect path = '/'  to={location.pathname.includes('/onboarding') ? '/onboarding' : '/login' } />} */}
      {!isLoggedUser && <Redirect path = '/' to={'/login'} />}
    </Switch>
  );
  if (isLoggedUser) {
    routes = (
       <Switch>
        {restrictedRoutes
          .filter((route) => {
            if(route.path === '/chat-consulation' && !location.search.includes('roomID')) return false
            if (route.roles.includes("All")) return true;
            if (route.roles.includes(userRole)) return true;
          })
          .map((route) => {
            return (
              <Route path={route.path} component={route.component} exact />
            );
          })}
           <Redirect from="/" to={isAuthenticated ? (DashboardRedirect || location.pathname === '/login') ? '/Dashboard' : "/404/error" : "/login"} />
      </Switch>
    );
  }

  return <Suspense fallback={<FullScreenLoader />}>{routes}</Suspense>;
}