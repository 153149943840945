import * as actionTypes from "../actions/actionTypes";

const initialstate = {
    Notifylist :{}
};

const NotifyList = (state = initialstate, action) => {
    switch (action.type) {
        case actionTypes.UPD_NOTIFY_LIST:
            return { ...state, Notifylist: action.payload }
        case actionTypes.NEW_NOTIFY_UPDATE:
            return {...state,Notifylist:{...action.payload,...state.Notifylist}}
        default:
            return state;
    }
}

export default NotifyList;