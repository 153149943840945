export const USER_ROLES = {
  nutritionist: "UNIFIED_APP_NUTRITIONIST",
  wellness_coach: "UNIFIED_APP_WELLNESS_COACH",
  diet_coach: "UNIFIED_APP_DIET_COACH",
  field_agent: "UNIFIED_APP_FIELD_AGENT",
  facility_admin: "UNIFIED_APP_FACILITY_ADMIN",
  super_admin: "UNIFIED_APP_SUPER_ADMIN",
  care_admin: "UNIFIED_APP_CARE ADMIN",
  telemedicine_admin: "UNIFIED_APP_TELEMEDICINE_ADMIN",
  opd_admin: "UNIFIED_APP_OPD_ADMIN",
  default: "UNIFIED_APP_DEFAULT",
  lab_vendor: "UNIFIED_APP_LAB_VENDOR",
  claims_admin: "UNIFIED_APP_CLAIMS_ADMIN",
  wellness_manager: "UNIFIED_APP_WELLNESS_MANAGER",
  consumer: "UNIFIED_APP_CONSUMER",
  empanelment_admin: "UNIFIED_APP_EMPANELMENT_ADMIN",
  doctor: "UNIFIED_APP_DOCTOR",
  phc_agent: "UNIFIED_APP_PHC_AGENT",
  transcription_agent: "UNIFIED_APP_TRANSCRIPTION_AGENT",
  claims_processor: "UNIFIED_APP_CLAIMS_PROCESSOR",
  operations_admin: "UNIFIED_APP_OPERATIONS_ADMIN",
  receptionist: "UNIFIED_APP_TELEMEDICINE_RECEPTIONIST",
  phc_admin: "UNIFIED_APP_PHC_ADMIN",
  opd_ops: "UNIFIED_APP_OPERATIONAL_RECEPTIONIST",
  facility_receptionist: "UNIFIED_APP_FACILITY_RECEPTIONIST",
  wellness_counselor_interim : "UNIFIED_APP_WELLNESS_COUNSELOR",
  wellness_nutritionist_interim : "UNIFIED_APP_WELLNESS_NUTRITIONIST",
  Wellness_support_interim : "UNIFIED_APP_WELLNESS_SUPPORT",
};

export const ROLES_GROUP = {
  Admin_group : 'ADMIN',
  Doctor_group: 'DOCTOR',
  Ahp_group: 'PROVIDER',
  Others_group : 'OTHER',
  Field_group: 'AGENT',
  Default_group:'DEFAULT',
  Consumer_group : 'USER',
  Receptionist_group: 'RECEPTIONIST'
}

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';

export  const fileFormat = ["doc","docx", "ppt","pptx", "pdf", "xls", "xlsx","mp3","mp4","m4a","mov","jpg", "jpeg", "heif", "heic", "png"]
export  const healthvault = ["doc","docx", "ppt","pptx", "pdf","jpg", "jpeg", "heif", "heic", "png"]