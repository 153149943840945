import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import useMainParticipant from '../../../hooks/useMainParticipant/useMainParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { makeStyles, Theme } from '@material-ui/core';
import ReConnect from '../ReConnect';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme: Theme) => ({
   splitWidth: {
    display: 'grid',
    gridTemplateColumns: `50% 50%`,
    gridTemplateRows: '100%'
   },
   fullWidth :{
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns:'100%',
    gridTemplateRows:'100%',
    background:'black'
   }
}));



export default function MainParticipant({ isVideoCollapsed,OpenDialog,setshow,reference}: any) {
  const mainParticipantList : any = useMainParticipant(isVideoCollapsed,reference);
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const { instantdata, connecting } = useSelector((state : any)=> state?.instantVideo)
  const classes = useStyles();
  const videoPriority = null;
 
  return (
    <div key ={instantdata} className={((connecting?.split && !instantdata?.doctorConnected)  || mainParticipantList.length >= 2) && !isVideoCollapsed ? classes.splitWidth : classes.fullWidth }>
    {mainParticipantList.map((mainParticipant: any) => {
      return <MainParticipantInfo participant={mainParticipant} isVideoCollapsed={isVideoCollapsed} setshow={setshow}>
        <ParticipantTracks
          participant={mainParticipant}
          videoOnly
          enableScreenShare={mainParticipant !== localParticipant}
          videoPriority={videoPriority}
          isLocalParticipant={mainParticipant === localParticipant}
          videoType="mainRef"
        />
        <></>
      </MainParticipantInfo>
    }) }
     {((connecting?.split && !instantdata?.doctorConnected) && mainParticipantList.length < 2 && !isVideoCollapsed) && <ReConnect OpenDialog={OpenDialog} /> } 
    </div>
  );
}
