import { Avatar } from '@mui/material'
import participantAvtar from '../../../assets/images/chat/participant-avtar.svg'
import useStyles from './style';

type AvatarProps={
    displayPicture: string | undefined;
    name: string | undefined;
    classname?: string | undefined;
}
const CommonAvatar = ({displayPicture,name,classname}:AvatarProps) =>{
    const classes = useStyles();
    var firstInitial,lastInitial;
   try{ 
        if(!displayPicture && name){
            firstInitial= name?.split(" ")[0][0]
            lastInitial=name?.split(" ")[1][0]
        }
        return displayPicture ?
        <Avatar src={displayPicture} className={classname || ""} />  :
        <div className={classname || classes.initialsDisplay} >
            {firstInitial ? firstInitial?.toUpperCase() : ""}
            {lastInitial ? lastInitial?.toUpperCase() : ""}
        </div>
    }
    catch(err){
        return <Avatar src={participantAvtar} className={classname || ""} />
    }
  }
  

export default CommonAvatar