import { makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles<Theme>((theme:any) => ({
    topGrid: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 20,
    },
    flexWrapper: {
        justifyContent: "space-between",
        alignItems: "center",
    },
    buttonGrid: {
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    submitButton: {
        marginLeft: '30%',
        fontSize: "11px"
    },
    tabBar: {
        background: "none",
        boxShadow: "none",
        borderBottom: "3px solid #c3cdd9",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        overflow: "hidden",
        "& .MuiTabs-indicator": {
            backgroundColor: "#35C5EA",
            height: 3
        },
        "& .MuiTabScrollButton-root.Mui-disabled": {
            display: "none"
        },
        "& button": {
            fontSize: "14px",
            color: "#808A98",
            textTransform: "capitalize",
            transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            "&.Mui-selected": {
                backgroundColor: "#32529D",
                color: "white"
            }
        }
    },
    actionIcon: {
        color: '#32529D',
        marginRight: 10,
        ['@media (max-width : 1366px)']: {
            padding: 0
        }
    },
    listView: {
        "& .MuiButton-root": {
            marginLeft: '16px',
            background: '#32529D',
            color: '#FFFFFF'
        }
    },
    gridFooter: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '26px',
        color: '#777777'
    },
   
    customRightSlideDialog: {
        "& .MuiDialogTitle-root": {
            border: '1px solid #ECECEC',
            zIndex: '999',
            backgroundColor: '#fff',
            color: "black"
        },
        "& .MuiDialogActions-root": {
            height: '70px',
            border: '1px solid #ECECEC'
        },
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '745px'
        },
        "& .MuiDialogContent-root": {
           
            padding: "8px 20px !important",
          
        },
    },
    customFollowRightSlideDialog: {
        "& .MuiDialogTitle-root": {
            border: '1px solid #ECECEC',
            zIndex: '999',
            backgroundColor: '#fff',
            color: "black"
        },
        "& .MuiDialogActions-root": {
            height: '70px',
            border: '1px solid #ECECEC'
        },
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '745px'
        },
        '& .MuiTypography-h6':{
            color:"black !important"
        },
        "& .MuiDialogContent-root": {      
            padding: "8px 20px !important",      
        },
    },
    
    consultationFee: {
        color: "#64707D",
        "& .MuiTypography-body2": {
            fontSize: "14px !important"
        },
        "& .MuiTypography-body1": {
            fontSize: "16px !important",
            fontWeight: 600
        }
    },

    // calendar view
    customEventWrapper: {
        borderWidth: '2px 2px 2px 4px !important'
    },
    eventContentWrapper: {
        padding: '5px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '100%'
    },
    eventTypeInfo: {
        display: 'flex',
        alignItems: 'center',
        color: '#000',
        '& img': {
            marginRight: 15,
            width: '25px',
            flexShrink: 0
        },
        '& p:last-child': {
            marginBottom: 0
        }
    },
    eventTime: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& svg': {
            marginRight: '8px',
            width: '16px'
        }
    },
    eventDetails: {
        '& h5': {
            margin: '0 0 5px 0',
            fontWeight: 600,
            fontSize: 16,
        }
    },
    eventJoinInfo: {
        display: 'flex'
    },
    eventJoinBtn: {
        borderRadius: 0,
        marginRight: 20,
        "& .MuiTypography-root": {
            marginLeft: 5
        }
    },
    customEventDialog: {
        '& .MuiPaper-root': {
            maxWidth: 350,
        },
        '& .MuiDialogContent-root': {
            padding: 0
        }
    },
    eventHostName: {
        fontWeight: 600,
        fontSize: '16px',
        margin: '0 0 3px 0'
    },
    eventDialogDuration: {
        color: '#0369A1',
        fontWeight: 500,
        fontSize: 12,
        marginBottom: '10px',
        display: 'block'
    },
    eventDialogInfoLabel: {
        display: 'flex',
        fontSize: 13,
        '& label': {
            color: '#000000',
            fontWeight: 500,
            fontSize: '13px',
            display: 'inline-block',
            minWidth: '85px',
            '&:after': {
                content: "':'",
                float: 'right',
                marginRight: '8px',
                fontSize: '13px'
            }
        }
    },
    eventDialoagDetails: {
        padding: "20px 24px"
    },
    eventDialogActionWrapper: {
        borderTop: '1px solid #E7E7E7',
        justifyContent: 'space-around',
        display: 'flex',
        '& button': {
            flex: '1 0 50%',
            padding: '13px 8px',
            textTransform: "none",
            "& .MuiButton-label": {
                alignItems: 'unset',

                "& .cancelBtnLabel": {
                    color: '#E02F3F'
                },

                "& .rescheduleBtnLabel": {
                    color: '#32529D'
                },
            },
            '&:not(:last-child)': {
                borderRight: '1px solid #E7E7E7',
                borderRadius: '0'
            }
        }
    },
    eventDialoagJoinBtn: {
        background: '#32529D',
        borderRadius: '0px 0px 8px 8px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '10px',
        textTransform: "none",
        "&:hover": {
            background: '#32529D',
        }
    },
    eventDialogActionLabel: {
        marginLeft: 5,
        fontWeight: 600
    },
    eventDialogContentWrapper: {
        position: 'relative',
    },
    eventHostAvtar: {
        width: '48px',
        position: 'absolute',
        height: '48px',
        right: '20px',
        top: '40px'
    },
    detailCancelButton: {
        backgroundColor: '#E02F3F !important',
        padding: '8.5px 25px',
        color: '#FFFFFF',
        borderRadius: "4px",
        lineHeight: "26px",
        fontSize: "12px",
        fontWeight: 600,
        textTransform: "none",
    },
    paper: {
        width: '65%'
    },
    cancelbtn: {
        minWidth: '32px',
        marginLeft: '24px'
    },
    approvebtn: {
        minWidth: '32px',
        marginLeft: '8px'
    },
    TagCustomModel: {
        '& .MuiDialogTitle-root': {
            borderBottom: '1px solid #ddd'
        }
    },
    TagcustomDialog: {
        maxWidth: '485px',
        '& .MuiDialogTitle-root': {
            borderBottom: '1px solid rgb(184 207 225 / 50%)',
            padding: '15px 25px',
            '& h2': {
                fontSize: '18px'
            }
        },
        '& .close-btn': {
            padding: 0,
            top: '18px !important'
        },
        '& .MuiDialogContent-root': {
            padding: '20px',
            textAlign: 'center'
        },
        '& .MuiDialogActions-root': {
            padding: '20px !important',
        },
    },
    fullWidthButton: {
        display: 'flex',
        justifyContent: 'center',

        '& button': {
            flex: 1,
            fontSize: 18,
            padding: '10px 25px'
        }
    },
    title: {
        fontSize: '18px',
        fontWeight: 600,
        marginTop: 0,
        textAlign: 'left',
        paddingLeft: '4px'
    },
    select: {
        "& .MuiInputBase-input": {
            paddingTop: "6px !important",
            paddingBottom: "6px !important",
            fontWeight: '400 !important',
            fontSize: '16px !important',
            lineHeight: '26px'
        },
        "& .MuiOutlinedInput-root": {
            background: '#F4F4F4 !important'
        },
        "& label":{
            width: "auto !important",
            background: "#f4f4f4 !important"
        }
    },
    completed: {
        padding: '8px',
        background: '#E4FCEA',
        marginLeft: '16px',
        display: 'inline',
        borderRadius: '8px',
        fontSize: '14px',
        color: '#3E784C'
    },
    cancelled: {
        padding: '8px',
        background: '#FFEFEF',
        marginLeft: '16px',
        display: 'inline',
        borderRadius: '8px',
        fontSize: '14px',
        color: '#A94B4B'
    },
    pending: {
        padding: '8px',
        background: '#FFF4E0',
        marginLeft: '16px',
        display: 'inline',
        borderRadius: '8px',
        fontSize: '14px',
        color: '#D3951A'
    },
    followUp: {
        padding: '8px',
        background: '#E7EDFF',
        marginLeft: '16px',
        display: 'inline',
        borderRadius: '8px',
        fontSize: '14px',
        color: '#34519C'
    },
    textSearch: {
        "& input": {
            paddingTop: "14px !important",
            paddingBottom: "14px !important",
            fontWeight: '400 !important',
            fontSize: '14px !important',
            lineHeight: '26px',
            color: '#717171'
        },
        "& .MuiInputBase-root": {
            background: '#F4F4F4 !important'
        },
        "& fieldset": {
            borderRadius: '4px !important',
        }
    },
    textarea: {
        "& div": {
            padding: 0,
        },
        "& textarea": {
            height: '120px !important',
        },
        marginTop: '16px'
    },
    chatHButton: {
        backgroundColor: "#34519C !important",
        color: '#FFFFFF !important',
        border: '1px solid #34519C !important',
        '&:focus': {
            backgroundColor: "#34519C !important",
            color: '#FFFFFF !important',
        }
    },
    CustomizeOuterBox: {
        position: "fixed",
        bottom: 0,
        right: '430px',
        zIndex: 1300,
        borderRadius: '8px 8px 0px 0px',
        "& .MuiPaper-root":{
            boxShadow: '0px 4px 44px rgba(0, 0, 0, 0.21) !important'
          }
    },
    customLayoutWrap: {
        background: "#fff",
        borderRadius: 4,
        paddingBottom: 20,
      },

      tabBarNew: {
        background: "none",
        color: theme.palette.text.mainLableBold,
        boxShadow: "none",
        borderBottom: "1px solid #c3cdd9",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        overflow: "hidden",
        "& .MuiTabs-indicator": {
          backgroundColor: "#35C5EA",
        },
        "& .MuiTabScrollButton-root.Mui-disabled": {
          display: "none",
        },
        "& button": {
          fontSize: "14px",
          color: "#808A98",
          textTransform: "capitalize",
          transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          "&.Mui-selected": {
            backgroundColor: "#32529D",
            color: "white",
          },
        },
      },
      facilitySearch:{
        "& .MuiOutlinedInput-root":{
            backgroundColor: "#F4F4F4 !important",
        },
        "& .MuiFormLabel-root":{
            fontSize: "13px !important", 
            [theme.breakpoints.between('sm','lg')]:{
                fontSize: "12px !important"
             }
        },
        "& label":{
            paddingLeft: "24px !important"
        }
        },
        selectDropdown: {
            minWidth: 100,
            height: 50,
            textAlign: 'center',
            margin: '0 20px',
            backgroundColor: 'transparent !important',
            border: '1px solid #ddd',
            '& div': {
                textAlignLast: 'center',
                backgroundColor: 'transparent !important',
                fontSize: 18,
                lineHeight: 'normal'
            }
        },
    ellipsisText: {
        width:"150px",
        overflow:"hidden",
        whiteSpace:"nowrap",
        textOverflow:"ellipsis",
        [theme.breakpoints.down("md")]:{
            width:"90px"
        }
    }
        
}))

export default styles;