import React ,{ useState, useEffect} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./styles";
import DummyDoctor from "../../../assets/images/dummy_doctor.jpg";
import { useDispatch, useSelector } from "react-redux";
import FileUploadButton from "../Consultation/InstantChatUpload";
import { getIcon } from "../../../utils";
import DocumentViewer from "../../../components/common/DocumentViewer";
import { CloseOutlined } from "@material-ui/icons";
import Notify from "../../../components/common/CommonToast";
import { ChatRoomDetails, ValueSet, sendMsg, setcurrentRoomID } from '../../../components/Websocket/InstantChatIO';
import clsx from "clsx";
import CloseIcon from '@mui/icons-material/Close';
import { chatHistoryList } from "../../../api/consultation/consultation";
import { EmptyMessagelist, NewMsgReceived, replaceMessagelist} from "../../../store/actions";
import { CircularProgress } from "@mui/material";
import ReactDOM from "react-dom";
import SendIcon from '@mui/icons-material/Send';
import moment from "moment";
import {fileUploadCount} from "../../../api/InstantVideo/Instant"



interface InstantChat {
  customizeClass?: string,
  hideChat?: boolean,
  sethideChat?: (value: boolean) => void,
  chathistory?: boolean,
  PatientName?: string,
  roomId?: string | undefined | null,
  isFollowupChat?:boolean,
}

const InstantChat = (props: InstantChat) => {
  const classes = styles();
  const dispatch = useDispatch();
  const fileCount = React.useRef(0);
  const [typeMessage, setTypeMessage] = useState<string>('');
  const [chatDetails, setChatDetails] = useState({patientID:{id:''}});
  const { userRole, user } = useSelector((state: any) => state?.auth);
  const { connected, chatdetails, Chatmessages, newMsgReceived ,reconnecting} = useSelector((state: any) => state?.instantChat);
  const [urlPath,setUrlpath] = useState({url:[],KeyPath:[]});
  const [showDoc, setShowDoc] = useState(null);
  const [open, setOpen] = useState(true);
  const [pageNumber, setpageNumber] = useState(1);
  const [hasNext, sethasNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrollposition, setScrollPosition] = useState(false);
  const Date ={};
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: "",
  });
  
  const popMessage = (value) => {
    setRequest(value);
  }
  useEffect(() => {
    if (props.chathistory) {
      if (props?.hideChat) {
        OpenAccordian(true);
      }
    }
  }, [props?.hideChat])

  useEffect(()=>{
    if(props?.isFollowupChat)
    {
      if(connected)
      {
        sendMsg('join',{roomId:props?.roomId,type:"join"});
      } 
    }
  },[props?.isFollowupChat,connected])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (props?.roomId) {
          setcurrentRoomID(props?.roomId);
          const response = await chatHistoryList('GET-CHAT_HISTORY', { roomId: props?.roomId, pageSize: 10, pageNumber: 1 ,skip: Chatmessages.length,roleId:userRole})
          if(response && response?.data?.roomData)
          {
            let roomData = {};
            const doctorData  = response?.data?.roomData?.doctor;
            const patientData = response?.data?.roomData?.user;
            if(doctorData && JSON.stringify(doctorData) !== '{}' && patientData && JSON.stringify(patientData) !== '{}')
            {
              roomData[doctorData._id] = {name : doctorData?.name};
              roomData[patientData._id] ={name : patientData?.name};
              roomData['patientID'] = {id:patientData._id}
            }
            ValueSet(roomData);
          }
          if (response && response?.data?.history) {
            const array: Array<Object> = response?.data?.history;
            const reverse = array.reverse();
            const reorderArray = [...reverse, ...Chatmessages];
            dispatch(replaceMessagelist(reorderArray));
            sethasNext(response?.hasNextPage);
          }
        }
        setLoading(false);
      }
      catch (e) { setLoading(false) }
    })()
  }, [props?.roomId,pageNumber])

  const getFileName = (filename) => {
    if(filename)
    {
      const regex = /_[0-9]+.[a-z]+$/
      const url = new URL(filename);
      const name = url.pathname.substring(url.pathname.lastIndexOf('/') + 1);
      const type = name.split('.').pop();
      const removeTimestamp = name.replace(regex, '') + '.' + type
      return removeTimestamp || '';
    }
   else
   return '';
  }
  useEffect(() => {
    if (showDoc) {
      OpenAccordian(false)
    }
  }, [showDoc])
  useEffect(() => {
    Chatmessages && setDataList(Chatmessages);
    let chatdetail = ChatRoomDetails();
    JSON.stringify(chatdetail) !== '{}' && setChatDetails({ ...chatDetails, ...chatdetail });
    OpenAccordian(true);
  }, [Chatmessages])
  const [datalist, setDataList] = useState<Array<object>>();

  const OpenAccordian = (value) => {
    setOpen(value);
  }

  useEffect(() => {
    if (open && datalist && datalist.length > 0) {
      var objDiv = document.getElementById("chatDetails");
      if (objDiv !== null) {
        if (!newMsgReceived)
          objDiv.scrollTop = scrollposition ? Math.round(objDiv.scrollHeight / 3) : objDiv.scrollHeight;
        else {
          objDiv.scrollTop = objDiv.scrollHeight;
          dispatch(NewMsgReceived(false));
        }
        setScrollPosition(true);
      }
      getURLText()
    }
    else if (!open)
      setScrollPosition(false);
  }, [datalist, open])

  const sendFile = () =>
  {
    if(urlPath?.KeyPath?.length !== 0)
    {
      urlPath?.KeyPath.map((key : string) => {
        sendMsg('sendMessage', {
          userId: user?._id,
          // name: userName,
          room: props?.chathistory ? props?.roomId : chatdetails?.roomId,
          text: key,
          isMultimedia: true,
        })
        fileCount.current = fileCount.current + 1;
      })
     
    }
    setUrlpath({url:[],KeyPath:[]});
  }

  const handleKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendFile();
      e.preventDefault();
    }
  };
  const userName = (value) => {
    return value?.name?.first + ` ${value?.name?.last ? value?.name?.last : ''}`
  }
 useEffect(()=> { 
  (async ()=>{
    const payload = 
    {
      "senderId":user?._id,
      "roomId":props?.roomId,
      "isMultimedia":true
  }
  try
  {
    const response = await fileUploadCount ('File-Count',payload);
    if(response){
    fileCount.current = response?.data?.chatHistoryCount;
    }
  }catch (err){
    popMessage({
      loading: false,
      message: err?.response?.data?.message || "Error Occured while uploading!",
      severity: "",
    });
  }
})()
return () => {
  dispatch(EmptyMessagelist());
}
},[]);

  const RenderIcon =(imageUrl) =>{
   return (<a href={imageUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src={getIcon(imageUrl)}
                    alt="document"
                    width="40"
                    height="40"
                  />
                </a>) ;
  }

  const getURLText = () => {
    datalist?.forEach((ele:any, index:any) => {
      const message = ele?.text;
      var msgWithChar60or62 = message.replace(/<|>/g, (char) => {
        var char60or62 = char
        if (char60or62 === '<') {
            return '<span>&lt;</span>'
        } else if (char60or62 === '>') {
            return '<span>&gt;</span>'
        }
      })
      var urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)/ig;
      var msg =  msgWithChar60or62.replace(urlRegex, function (url) {
        var hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        const color = ele?.user === user?._id ? '#ffffff !important' : '#000000 !important' 
        return `<a href=${hyperlink} style="color: ${color}; text-decoration: underline" target="_blank" rel="noopener noreferrer">${url}</a>`
   
      });
      const value = document?.getElementById(`message-${index}`)
      if(value !== null){
        value.innerHTML = msg
      }
    })
  }

  return ReactDOM.createPortal(
    <div>
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <div className={props.customizeClass || classes.outerBox}>
        <Accordion className={clsx(classes.mainNormalAccordion, classes.IconButton)}
          expanded={open}
        >
          <AccordionSummary
            aria-controls={`accordion-content}`}
            id={`accordion-header`}
            expandIcon={<ExpandMoreIcon style={{ fontSize: '30px' }} />}
            onClick={() => { OpenAccordian(!open) }}
          >
            <div>
              <img
                className={classes.userPhoto}
                src={chatdetails?.photo || DummyDoctor}
                loading="lazy"
                decoding="async"
              />
            </div>
            <Typography className={classes.chatUserName}>{props?.chathistory ?  props?.PatientName : chatdetails?.name}</Typography>
            {props?.chathistory && <IconButton onClick={() => { props.sethideChat && props.sethideChat(false) }}><CloseIcon /></IconButton>}
          </AccordionSummary>
          <AccordionDetails className={classes.chatDetails} id="chatDetails" onScroll={(event) => {
            const listboxNode = event.currentTarget;
            if (listboxNode.scrollTop < 1 && hasNext && scrollposition) {
              setpageNumber(pageNumber + 1)
            }
          }}>
            {reconnecting && <div className={classes.reconnect}><CircularProgress color="error" className="loader"/><label className="text">Reconnecting</label></div>}
            <Grid style={{ width: '-webkit-fill-available' }}>
              {loading && <CircularProgress style={{ marginLeft: '40%' }} />}
              {datalist && datalist.length > 0 && datalist.map((ele: any, index: any) => {
                const date = moment(ele?.createdAt).format('DD/MM/YYYY');
                !Date[date] ? Date[date] = 1 : Date[date] = Date[date] + 1 ;
                return (
                  <div>
                    <div className ={classes.Date}>{Date[date] === 1 ? moment().format('DD/MM/YYYY') === date ? 'Today' : date : ''}</div>
               {ele?.user === user?._id ? (
                  <div>
                    <Grid className={classes.senderStyle}>
                      <Grid>
                        <Typography component="p" className={classes.sendername}>
                         { `Dr. ${userName(chatDetails[ele?.user])}`}
                        </Typography>
                      </Grid>
                      <Grid className={classes.sendermessage}>
                        {!ele?.isMultimedia ? <span className="TextWrap">
                          <p style={{whiteSpace: 'pre-wrap', margin: 0}} id={`message-${index}`}>{ele?.text}</p>
                        </span> :
                          <div className={classes.DocumentName} onClick={() => {
                            setShowDoc(ele?.text);
                          }}>
                            <img
                              src={ getIcon(ele?.text) }
                              alt="document"
                              width="40"
                              height="40"
                            />
                            <Tooltip title={getFileName(ele?.text)}><p className={'filename'}>{getFileName(ele?.text)}</p></Tooltip>
                          </div>
                        }
                      </Grid>
                      <div className="DateTime">{moment(ele?.createdAt).format('LT')}</div>
                    </Grid>
                  </div>
                ) : (
                  <Grid className={classes.receiverStyle}>
                    <Grid>
                      <Typography component="p" className={classes.receivername}>
                        {userName(chatDetails[ele?.user])}
                      </Typography>
                    </Grid>
                    <Grid className={classes.receivermessage}>
                      {!ele?.isMultimedia ? <span className="TextWrap">
                        <p style={{whiteSpace: 'pre-wrap', margin:0}} id={`message-${index}`}>{ele?.text}</p>
                      </span> :
                        <div className={classes.DocumentName} onClick={() => {
                          setShowDoc(ele?.text);
                        }}>
                          <img
                            src={ getIcon(ele?.text) }
                            alt="document"
                            width="40"
                            height="40"
                          />
                          <Tooltip title={getFileName(ele?.text)}><p className={'filename'}>{getFileName(ele?.text)}</p></Tooltip>
                        </div>}
                    </Grid>
                    <div className="DateTime">{moment(ele?.createdAt).format('LT')}</div>
                  </Grid>
                ) }
                </div>
                ) ;
              })}
            </Grid>
          </AccordionDetails>
          {(props?.chathistory ? props?.isFollowupChat : true ) && <AccordionDetails>
            {<FileUploadButton fileCount ={fileCount} roomId = {props?.roomId} connected={!connected} popMessage={popMessage}  chatParticipantInfo={{ isGroup: false, chatUserId: '', isActive: false }} handleKeyDown={handleKeyDown} setUrlpath = {(value) =>{setUrlpath({url:value?.url,KeyPath:value?.keypath})}}  user_id={props?.chathistory ? chatDetails?.patientID?.id : chatdetails?.user} />}
            {urlPath?.url?.length === 0 ? <TextareaAutosize
              className={classes.chatText}
              style={{ overflow: 'auto' }}
              id='text'
              placeholder="Type a message"
              value={typeMessage}
              disabled={!connected}
              onKeyPress={(e) => {
                if (e?.key === "Enter" && typeMessage.trim() && !e.shiftKey) {
                  e.preventDefault();
                  setTypeMessage('')
                  sendMsg('sendMessage', {
                    userId: user?._id,
                    text: typeMessage.trim(),
                    isMultimedia: false,
                    room: props?.roomId
                  })
                }
              }}
              onChange={(e) => {
                if (!typeMessage)
                  e.target.value !== '\n' && setTypeMessage(e.target.value)
                else
                  setTypeMessage(e.target.value)
              }}
            /> :
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
               {urlPath?.url?.map(RenderIcon)}
                <IconButton onClick={() => { setUrlpath({url:[],KeyPath:[]}); }}><CloseOutlined /> </IconButton> 
              </div>}
              <IconButton onClick={() =>{
                if(urlPath?.url.length === 0)
                {
                  if(typeMessage.trim())
                  {
                    sendMsg('sendMessage', {
                      userId: user?._id,
                      text: typeMessage.trim(),
                      isMultimedia: false,
                      room: props?.roomId
                    })
                    setTypeMessage('');
                  }
                }  
                else
                {
                  sendFile();
                }
              
              }}><SendIcon color="primary" style={{marginTop:'6px'}}/></IconButton>
          </AccordionDetails>}
        </Accordion>
      </div>
      {showDoc && (
        <DocumentViewer
          open={showDoc}
          onClose={() => {
            setShowDoc(null);
          }}
          title={"View Document"}
          documents={[{ uri: showDoc }]}
        />
      )}
    </div>
  ,document.body);
};

export default InstantChat;
