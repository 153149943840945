import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import styles from "./styles";
import PageHeader from "../../components/common/PageHeader";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import LayoutContainer from "../../components/common/LayoutContainer";
import CustomSubmitButton from "../../components/common/CustomSubmitButton";
import ListView from "./ListView";
import CustomRightSlideDialog from "../../components/common/CustomRightSlideDialog";
import CustomCancelButton from "../../components/common/CustomCancelButton";
import BookAppointment from "./BookAppointment";
/* import CalendarView from "./CalendarView"; */
import FilterAppointment from "./FilterAppointment";
import { IAppointmentPayload, Irequest } from "../../models/common";
import Notify from "../../components/common/CommonToast";
import DetailsPopup from "./DetailsPopup";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomIconButton from "../../components/common/CustomIconButton";
import CustomDialog from "../../components/common/CustomDialog";
import CommonSearchMultiselect from "../../components/common/CommonSearchMultiselect";
import { useQuery } from "react-query";
import { USER_ROLES } from "../../utils/constants";
import * as yup from "yup";
import { validations } from "../../config/validations.config";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getDoctorlistview,
  getAdminListView,
  getFollowUpList,
  acceptFollowUpRequest,
  rejectFollowUpRequest,
  fixAppointment,
  approveRejectAppointment,
  fixFollowUpAppointment,
  getFollowUpDetails,
  appointmentDetail
} from "../../api/AppointmentManagement/Appointment";
import { getAllFacilities } from "../../api/scheduler/slot-management";
import { Controller, useForm } from "react-hook-form";
import CommonTextField from "../../components/common/CommonTextField";
import InstantChat from "./Consultation/InstantChat";
import { followupReason} from './Reason'
import { endCallReasons }from '../../api/consultation/consultation';
import { EmptyMessagelist } from "../../store/actions";
import { CircularProgress } from "@mui/material";
import CustomTextFieldSearchWhite from "../../components/common/CustomTextFieldSearchWhite";

const index = ({ type, isInstant, setOpenAppointment, openAppointment, userData }: any) => {
  const classes = styles();
  const dispatch =useDispatch();
  //const [view, setView] = React.useState("list");
  const view = "list"
  const [openFixAppointment, setOpenFixAppointment] = useState<boolean>(false);
  const [openDetilsPopup, setOpenDetilsPopup] = useState<boolean>(false);
  const [bookingState, setBookingState] = useState<string>("")
  const { userRole, user } = useSelector((state: RootStateOrAny) => state?.auth);
  const clinic = useSelector((state: RootStateOrAny) => state?.clinic?.details);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [conformation, setConformation] = useState<String>("")
  const [request, setRequest] = useState<Irequest>({
    title:"",
    message: "",
    loading: false,
    severity: "",
  });
  const [consultationFee, setConsultationFee] = useState<any>("");
  const location = useLocation<any>();
  const chatNotifyId = location?.state?.chatNotifyId || {};
  const { response } = location?.state || {};
  const [fees, setFees] = useState<string>("");
  const [userRole_Static, setUserRole_Static] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<Boolean>(false)
  const [activeTab, setActiveTab] = useState(1);
  const [selectedRole, setSelectedRole]=useState<any>({})
  const [facilityId, setFacility] = useState<string>("");
  const initialPayload: IAppointmentPayload = {
    facility: "",
    practitionerRoleId: "",
    practitioner: "",
    user: "",
    consultationType: "",
    state: "",
    reason: [],
    scheduledAt: "",
    scheduledBy: "",
    patientType: "",
    policyNumber: "",
    policyUser: "",
    consultationFee: "",
    slotId: "",
    slotTime: "",
    roleId:"",
    specialityId: ""
  };
  const [fixAppointmentPayload, setFixAppointmantPayload] = useState<IAppointmentPayload>(initialPayload);
  const [searchTextView, setSearchTextView] = useState<string>('');
  const [selData, setSelData] = useState<any>({})
  const [tablePage, setTablePage] = useState<number>(1);
  const [detailsData, setDetailsData] = useState<any>({})
  const [isReasonEnable, setIsReasonEnable] = useState<boolean>(false);
  const { details } = useSelector((state: any) => state?.clinic);
  const [clinicDetails, setclinicDetails] = useState<any>(null);
  const [showInstant,setshowInstant] =useState(false);
  const[rowsPerPage,setRowsPerPage] = useState(10)
  const [DoctorFilterObj, setDoctorFilterObj] = useState({
    appointmentType: '',
    fromDate: '',
    toDate: '',
    status: ''
  });
  const [FacilityItems, setFacilityItems] = useState<any>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [selFacility, setSelFacility] = useState<Record<string, any> | null>(
    null
  );
  const [searchFacility, setsearchFacility] = useState<string | null>(
    ''
  );
  const [listPage, setlistPage] = useState<number>(1);
  const [showFixAppointment, setShowFixAppointment] = useState<boolean>(false);
  const [rescheduleReasons, setRescheduleReasons] = useState<any>([]);
  const [cancellationReason, setCancellationReason] = useState<any>([]);
  

  const { data: facilitiesArr = [] } = useQuery(
    ["GET-ALL-FACILITIES", { search: searchFacility, pageSize: 10, pageNumber: listPage }],
    getAllFacilities, { refetchOnWindowFocus: false, enabled: userRole === USER_ROLES.opd_ops }
  );
  
  const { data: rescheduleReasonsInitial = [] } = useQuery(
    ["GetRescheduleReasons", { 
      role: userRole,
      type: "RESCHEDULE" }],
    endCallReasons 
  );
  const { data: cancellationReasonInitial = [] } = useQuery(
    ["GetCancellationReason", { 
      role: userRole,
      type: "CANCELLED" }],
    endCallReasons 
  );

  useEffect(()=>{
    if(rescheduleReasonsInitial?.data){
      setRescheduleReasons(rescheduleReasonsInitial?.data.map((value:any)=>{
        return {id: value?.id, name: value?.reason}
      }))
    }
  },[rescheduleReasonsInitial])

  useEffect(()=>{
    if(cancellationReasonInitial?.data){
      setCancellationReason(cancellationReasonInitial?.data.map((value:any)=>{
        return {id: value?.id, name: value?.reason}
      }))
    }
  },[cancellationReasonInitial])

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    gender: "",
    appointmentTime: "",
    searchPatient: "",
    appointmentType: "",
    doctor: {},
    specialities: undefined,
    roles:undefined,
    reasonForVisit: '',
    followupReason: '',
    rescheduleReason: '',
    appointmentDate: undefined,
    groups:{}
  };

  const schema = yup.object().shape({
    firstName: validations.firstnameRequired,
    lastName: validations.lastnameRequired,
    phone: validations.phoneRequired,
    gender: validations.commonRequired,
    relationship: validations.commonRequired,
    roles: validations.commonRequired,
    group: validations.commonRequired,
    careProgram: validations.commonRequired,
    reasonForVisit: validations.commonRequired,
    followupReason: validations.commonRequired,
  });

  const { control, errors, getValues, trigger, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: initialValues,
  });

  useEffect(() => {
    fixAppointmentPayload.scheduledBy = user.userId;
    if(bookingState==="NEW"){
      if(fixAppointmentPayload.practitioner!== undefined  && fixAppointmentPayload.practitioner!=="" && fixAppointmentPayload.scheduledAt !== "" && fixAppointmentPayload?.reason?.length !== 0){
        setDisabled(false)
      }else{
        setDisabled(true)
      }
    }else if(bookingState==="reschedule"){
      if(fixAppointmentPayload.scheduledAt !== ""){
        setDisabled(false)
      }else{
        setDisabled(true)
      }
    }else{
      if(fixAppointmentPayload.scheduledAt !== ""){
        setDisabled(false)
      }else{
        setDisabled(true)
      }
    }
   
  }, [fixAppointmentPayload]);

  useEffect(() => {
    if (userRole === USER_ROLES.doctor) {
      setUserRole_Static("Doctor")
     setShowFixAppointment(false) 
    } else if (userRole === USER_ROLES.super_admin || userRole === USER_ROLES.telemedicine_admin ||
      userRole === USER_ROLES.opd_admin || userRole === USER_ROLES.facility_admin) {
      setUserRole_Static("Admin")
      setShowFixAppointment(false)
    } else if (userRole === USER_ROLES.receptionist) {
      setUserRole_Static("Receptionist")
      setShowFixAppointment(true)
    } else if (userRole === USER_ROLES.wellness_nutritionist_interim) {
      setUserRole_Static("Nutritionist")
      setShowFixAppointment(true)
    }else if (userRole === USER_ROLES.wellness_counselor_interim) {
      setUserRole_Static("counselor")
      setShowFixAppointment(true)
    }else if (userRole === USER_ROLES.wellness_coach) {
      setUserRole_Static("coach")
      setShowFixAppointment(true)
    }else if (userRole === USER_ROLES.opd_admin) {
      setUserRole_Static("Operator")
      setShowFixAppointment(true)
    } else if (userRole === USER_ROLES.opd_ops) {
      setUserRole_Static("Operatornal_Receptionist")
      setShowFixAppointment(true)
    } else if (userRole === USER_ROLES.facility_receptionist) {
      setUserRole_Static("Facility_Receptionist")
      setShowFixAppointment(true)
    }
  }, [userRole,clinic])

  useEffect(() => {
    if (details) {
      setclinicDetails(details)
      setFixAppointmantPayload({
        ...fixAppointmentPayload,
        facility: details?.id,
      });
      setFacility(details?.id)
    }
  }, [location, details])

  useEffect(() => {
    if (facilitiesArr.length > 0 && userRole === USER_ROLES.opd_ops) {
      if (listPage === 1) {
        setFacilityItems([...facilitiesArr]);
      }
      else {
        setFacilityItems([...FacilityItems, ...facilitiesArr]);
      }
    }
  }, [facilitiesArr])

  useEffect(() => {
    if (FacilityItems?.length > 0 && !selFacility && userRole === USER_ROLES.opd_ops) {
      setSelFacility({ _id: details?.id, name: details?.name });
    }
  }, [facilitiesArr, selFacility, FacilityItems]);

  useEffect(()=>{
    if(isInstant && openAppointment){
      setOpenFixAppointment(true)
      setDisabled(true);
      setChecked(false)
      setBookingState("NEW")
    }
  },[isInstant, openAppointment])

  useEffect(()=>{
    if(!openDetilsPopup)
    {
      hideChat(false)
    }
  },[openDetilsPopup])
  const makePayload = () => {
    let payload = { 
      state: DoctorFilterObj.status,
      consultationType: DoctorFilterObj.appointmentType,
      fromDate: DoctorFilterObj.fromDate,
      toDate:  DoctorFilterObj?.toDate,
      search: searchTextView?.trim(),
      pageNumber: tablePage,
      facility: facilityId,
      pageSize: rowsPerPage,
      isUpcoming: activeTab === 1 ? true : false,
      sortBy:'date',
      orderBy: activeTab === 1 ? 'asc' : 'dsc'
    }
    if (userRole_Static === 'Doctor' || userRole_Static === 'coach' ||userRole_Static === 'Nutritionist' ||userRole_Static === 'counselor') {
      payload['practitioner'] = user?._id
    }
    return payload
  }

  const followUpPayload = {
    state: DoctorFilterObj.status,
    consultationType: DoctorFilterObj.appointmentType,
    fromDate: DoctorFilterObj.fromDate,
    toDate: DoctorFilterObj.toDate,
    facility: facilityId,
    pageSize: rowsPerPage,
    pageNumber: 1
  }

  const { data: listView = [], isLoading, refetch: listRefetch, } = useQuery(["listView", userRole_Static === "Operator" ? followUpPayload : makePayload(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  }], userRole_Static === 'Doctor' || userRole_Static === 'Nutritionist' || userRole_Static === 'coach' ||userRole_Static === 'counselor' ? getDoctorlistview :
    (userRole_Static === "Admin" || userRole_Static === "Receptionist" || userRole_Static === "Operatornal_Receptionist" || userRole_Static === "Facility_Receptionist") ? getAdminListView : getFollowUpList, { enabled: facilityId !== "" });

    const {
      data: followUpDetail
    } = useQuery(
      [
        "GET_APPOINTMENT_DETAIL",
        {
          appointmentId: selData?._id ,
        },
      ],
      getFollowUpDetails, { enabled: type === "FollowupRequest" && selData?._id }
    );
   
    const {
      data: detailData,
      isFetching:isDetailFetching,
      refetch: refetchDetail,
    } = useQuery(
      [
        "GET_APPOINTMENT_DETAIL",
  
        {
          appointmentId: selData?._id || chatNotifyId?.appointmentID,
        },
      ],
      appointmentDetail, { enabled: type === "Appointment" && (selData?._id  || chatNotifyId?.appointmentID) }
    );
  const statusState=detailData?detailData?.gridStatus:followUpDetail?.gridStatus
  const cancelCondition = detailData ? detailData?.canCancel : followUpDetail?.canCancel
  const reschCondition = detailData ? detailData?.canReschedule : followUpDetail?.canReschedule
  const followupCondition = detailData ? detailData?.canFollowUp : followUpDetail?.canFollowUp
  


/* Open Chat Dialog while Notify Clicked */

useEffect(()=>{
if(location?.state?.chatNotifyId && chatNotifyId?.appointmentID)
{
  setActiveTab(2);
  refetchDetail();
  setOpenDetilsPopup(true);
  sessionStorage.setItem('OpenChat','true'); /*Initailly Open the Chat */
  window.history.replaceState({}, document.title);
}
},[location?.state])

  const approveForm = async () => {
    setRequest({
      message: "",
      loading: false,
      severity: "",
    });
    const payload = {
      state: "approved",
      payment: {
        total: {
          amount: parseInt(fees),
          currency: "INR"
        }
      },
      requestedBy: {
        practitionerId: selData?.practitioner?.name?._id,
        roleId: userRole,
      }

    };
    const res = await acceptFollowUpRequest("followup", payload, "637bbd2ea196c9b6ebdf7f0c");
    if (res.status === 200) {
      // resetDirty();
      setRequest({
        message: "FollowUp Requested Accepted",
        loading: false,
        severity: "success",
      });
      setOpenDetilsPopup(false)

    } else {
      setRequest({
        message: "Something went wrong.",
        loading: false,
        severity: "error",
      });
    }

  }

  const rejectForm = async () => {
    setRequest({
      message: "",
      loading: false,
      severity: "",
    });
    const payload = {
      state: "rejected",
      payment: {
        total: {
          amount: fees ? parseInt(fees) : 0,
          currency: "INR"
        }
      },
      requestedBy: {
        practitionerId: selData?.practitioner?.name?._id,
        roleId: userRole,
      }

    };
    const res = await rejectFollowUpRequest("followup", payload, "637bbd2ea196c9b6ebdf7f0c");
    if (res.status === 200) {
      // resetDirty();
      setRequest({
        message: "FollowUp Requested Rejected",
        loading: false,
        severity: "error",
      });
      setOpenDetilsPopup(false)

    } else {
      setRequest({
        message: "Something went wrong.",
        loading: false,
        severity: "error",
      });
    }

  }
  const handlePageChange = (e: number) => {
    setTablePage(e);
  };

  const getCliamAmount = () => {
    return consultationFee > fixAppointmentPayload?.walletBalence ? fixAppointmentPayload?.walletBalence : consultationFee
  }

  const bookConsultation = async () => {
    const appointmentReason = getValues([(bookingState === "reschedule" || bookingState === "NEW") ?"reasonForVisit": "followupReason"]);
    if (Object.values(appointmentReason).every((value) => value) || bookingState === "FOLLOW_UP") {
    try {
      let payLoad = {
        appointmentType: (bookingState === "reschedule" || bookingState === "NEW") ? "NEW" : "FOLLOW_UP",
        modeOfBooking: "TELEBOOKING",
        appointmentCreatedBy: user._id,
        appointmentCreatedPersonRole: userRole,
        state: "APPOINTMENT_REQUESTED",
        consultationFee: 0,
        claimAmount: (fixAppointmentPayload?.patientType === 'policyHolder' && checked) ? getCliamAmount() : 0,
        slot: fixAppointmentPayload.slotId,
        dayOfTheWeek: 0,
        date: fixAppointmentPayload.scheduledAt,
        slotStartTime: fixAppointmentPayload.slotTime,
        isTelemedicine: true,
        careProgramId:fixAppointmentPayload?.careProgramId,
        groupId:fixAppointmentPayload?.groupId,
      }
      if(bookingState === "reschedule" || bookingState === "NEW"){
        payLoad['reason'] = fixAppointmentPayload?.reason;
        payLoad['facility'] =  facilityId;
        payLoad['roleId'] =  fixAppointmentPayload?.roleId;
        payLoad['practitioner'] =  '';
        payLoad['isPrimary'] = true;
        payLoad['primaryUser'] = fixAppointmentPayload?.user;
        payLoad['consultationType'] = fixAppointmentPayload?.consultationType;
        payLoad['consultationFeeType'] = "POSTPAID";
        payLoad['speciality'] =  fixAppointmentPayload?.specialityId;
        payLoad['practitioner'] = userRole === "UNIFIED_APP_DOCTOR" ? user._id : fixAppointmentPayload?.practitioner;
      } else {
        payLoad['parentAppointmentId'] = detailsData?.bookedSlotId
      }
      if (fixAppointmentPayload?.patientType === 'policyHolder') {
        payLoad['facilityPractitioner'] = clinicDetails?.roles[0]?.facilitypractitioner;
        payLoad['policyInformation'] = fixAppointmentPayload?.policyNumber;
        payLoad['healthCardNo'] = fixAppointmentPayload?.healthCardNo;
        payLoad['mobileNumber'] = fixAppointmentPayload?.mobile;
      }
      setRequest({ message: "", loading: true, severity: "" });
      const res = (bookingState === "reschedule" || bookingState === "NEW") ? await fixAppointment(payLoad): await fixFollowUpAppointment(payLoad)
      if (res) {
        setRequest({ message: "You have successfully scheduled your appointment.", title:"Scheduled Appointment Successfully", loading: false, severity: "success" });
        setBookingState("")
        if(isInstant){
          setOpenAppointment(false)
        }
        setOpenFixAppointment(false);
        setFixAppointmantPayload(initialPayload);
        listRefetch()
        setSelectedRole({})
        if(bookingState !== "NEW"){
          refetchDetail()
        }
      } else {
        setRequest({ message: res?.data?.message || "Something went wrong", loading: false, severity: "" });
      }
    } catch (err) {
      setRequest({ message: err?.response?.data?.message || "Something went wrong", loading: false, severity: "error" });
    } 
  }else{
    trigger();
  }
  };

  const detailsPopUpHeader = () => {
    return (
      <div style={{fontSize: "18px"}}>Appointment Details
        {statusState === "Completed" ? <div className={classes.completed}>Completed</div> : ""}
        {statusState === "Cancelled" ? <div className={classes.cancelled}>Cancelled</div> : ""}
        {statusState === "Confirmed" ? <div className={classes.completed}>Confirmed</div> : ""}
        {statusState === "Incomplete" ? <div className={classes.followUp}>Incomplete</div> : ""}
        {statusState === "Closed" ? <div className={classes.cancelled}>Closed</div> : ""}
      </div>
    )
  }
const hideChat =(value)=>{
  setshowInstant(value);
}
  const detailsPopUpFooter = () => {
    return (
      <>
      <Grid container justifyContent="space-between" xs={12} direction="row-reverse">
        <Grid item>
          {(activeTab === 2 && userRole===USER_ROLES.doctor && (detailData?.isFollowupChat || detailData?.consultationType === 'CHAT')  && 
            <CustomSubmitButton onClick ={()=>{!showInstant && dispatch(EmptyMessagelist()) && hideChat(true)}}className={classes.chatHButton}>
              View Chat History
            </CustomSubmitButton>
              )}
          {(activeTab === 1  && (userRole===USER_ROLES.doctor||userRole===USER_ROLES.receptionist||userRole===USER_ROLES.wellness_counselor_interim||userRole === USER_ROLES.wellness_nutritionist_interim||userRole === USER_ROLES.wellness_coach)) &&  detailData?.canCancel &&<Button
            className={classes.detailCancelButton}
            onClick={() => {
              if (type !== "FollowupRequest") {
                setConformation("Cancel")
              } else {
                rejectForm()
              }
            }}
          >
            {(type !== "FollowupRequest") ? "Cancel Appointment"
              : "Reject"}
          </Button>}
          &nbsp;&nbsp;
          {(activeTab === 1 && (userRole===USER_ROLES.doctor||userRole===USER_ROLES.receptionist||userRole===USER_ROLES.wellness_counselor_interim||userRole === USER_ROLES.wellness_nutritionist_interim||userRole === USER_ROLES.wellness_coach)) && detailData?.canReschedule &&<CustomSubmitButton
            className={undefined}
            isLoading={request.loading}
            disabled={type !== "FollowupRequest" ? false : fees === "" || fees === undefined ? true : false}
            onClick={() => {
              if (type !== "FollowupRequest") {
                setConformation("Reschedule")
              } else {
                approveForm()
              }

            }}
          > {(type !== "FollowupRequest") ? "Reschedule Appointment"
            : "Approve"}
          </CustomSubmitButton>}
          {(activeTab !== 1 && followupCondition && (userRole===USER_ROLES.doctor ||userRole===USER_ROLES.wellness_counselor_interim||userRole === USER_ROLES.wellness_nutritionist_interim||userRole === USER_ROLES.wellness_coach) && detailData?.consultationType !== 'CHAT') && <CustomSubmitButton
            className={undefined}
            isLoading={request.loading}
            onClick={() => {
              setConformation("")
              setOpenDialog(false)
              setOpenDetilsPopup(false)
              setOpenFixAppointment(true);
              setBookingState("FOLLOW_UP")
              setFixAppointmantPayload({
                facility: detailsData?.facilityId,
                consultationType: detailsData?.consultationType,
                reason: [],
                scheduledAt: "",
                scheduledBy: "",
                policyNumber: "",
                practitionerRoleId: '',
                practitioner: detailsData?.practitioner?._id,
                user: detailsData?.primaryUserId,
                patientType: 'nonPlicyHolder',
                state: "FOLLOWUP",
                policyUser: ''
              })

            }}
          >
            Follow Up
          </CustomSubmitButton>}
        </Grid>
      </Grid> </>)
  }

  const cancelAppointment = async () => {
    setRequest({ loading: true, message: "", severity: "" });
    try {
      let payload = {
        bookedSlotId: detailsData?.bookedSlotId,
        state: "CANCELLED",
        reason: detailsData?.reason?.id,
        comments:detailsData?.otherReason ? detailsData?.otherReason : "",
        reference: user._id,
        role: userRole
      }
      const res = await approveRejectAppointment(payload)
      if (res && res?.status !=400) {
        setRequest({
          title:"Cancelled Appointment",
          message: "You have cancelled the appointment.",
          loading: false,
          severity: "error",
        });
        listRefetch()
        refetchDetail();
        setIsReasonEnable(false);
      } else {
        setRequest({
          message: res?.data?.message || "Something went wrong.",
          loading: false,
          severity: "error",
        });
        setIsReasonEnable(false);
      }
      setConformation("")
      setOpenDialog(false)
      setOpenDetilsPopup(false)
    } catch (e) {
      setRequest({
        message: e?.response?.data?.message || "Something went wrong.",
        loading: false,
        severity: "error",
      });
      setConformation("")
      setOpenDialog(false)
      setOpenDetilsPopup(false)
      setIsReasonEnable(false);
    }
  }
        
    
        

  const rescheduleAppointment = async () => {
    setRequest({ loading: true, message: "", severity: "" });
    try {
      const payload = {
        bookedSlotId: detailsData.bookedSlotId,
        state: "RESCHEDULE_REQUESTED",
        date: fixAppointmentPayload.scheduledAt,
        slotStartTime: fixAppointmentPayload.slotTime,
        slotId: fixAppointmentPayload.slotId,
        reference: user._id,
        role: userRole,
        practitioner:fixAppointmentPayload?.practitioner?._id?fixAppointmentPayload?.practitioner?._id:fixAppointmentPayload?.practitioner,
        isTelemedicine: true,
        reason : fixAppointmentPayload?.reason,
        comments:detailsData?.otherReason ? detailsData?.otherReason : "",
      }
      const res = await approveRejectAppointment(payload)
      if (res) {
        if (res.status === 400) {
          setRequest({
            message: res.data.message,
            loading: false,
            severity: "error",
          });
          setIsReasonEnable(false); 
        } else {
          setRequest({
            title:"Rescheduled Appointment Successfully",
            message: "You have successfully rescheduled the Appointment.",
            loading: false,
            severity: "success",
          });
          listRefetch();
          refetchDetail()
          setOpenFixAppointment(false);
          setBookingState("")
          setFixAppointmantPayload(initialPayload);
          refetchDetail();
          setSelectedRole({})
          setIsReasonEnable(false)
        }
      } else {
        setRequest({
          message: "Something went wrong.",
          loading: false,
          severity: "error",
        });
        setIsReasonEnable(false)
      }
    } catch (e) {
      console.log(e)
      setRequest({
        message: e?.response?.data?.message || "Something went wrong.",
        loading: false,
        severity: "error",
      }); 
    }
  }

  useEffect(() => {
    if (response?.message) {
      setRequest({
        message:response?.message ?? "Consultation completed successfully",
        loading: false,
        severity: response?.severity ?? "success",
      });
      window.history.replaceState({}, document.title)
    }
  }, [])

  return (
    <>
    {showInstant && <InstantChat customizeClass={classes.CustomizeOuterBox} hideChat ={showInstant} sethideChat ={hideChat} chathistory={true} PatientName={detailsData?.primaryUserName?.first + " " +detailsData?.primaryUserName?.last || ''} roomId = {detailData?.roomId} isFollowupChat={detailData?.consultationType === 'CHAT' ? false : !detailData?.isExpired}/>}
      {request.message && <Notify message={request.message} severity={request.severity} title={request.title}/>}
      {!isInstant && <Grid container>
        <Grid item xs={12}>
          <CustomBreadcrumbs aria-label="breadcrumb">
            <RouterLink to="/" className="routerLink">
              Home
            </RouterLink>
            <Typography color="textPrimary">Appointments</Typography>
          </CustomBreadcrumbs>
        </Grid>
        <Grid container className={classes.flexWrapper} xs={12}>
          <Grid item xs={12} md={6}>
            <PageHeader variant="h1">{type === "FollowupRequest" ? "Follow Requests" : "Appointment Management"}</PageHeader>
          </Grid>
          {userRole === USER_ROLES.opd_ops && <Grid item xs={12} md={3}>
            <Controller
              name="facilitylists"
              control={control}
              render={(controlProps) => (
                <CommonSearchMultiselect
                  title=""
                  placeholder="Select Facility"
                  options={FacilityItems || []}
                  className={classes.facilityDropDown}
                  getOptionLabel={(option: any) => option.name}
                  // defaultValue={defaultvalue}
                  value={controlProps.value}
                  onChange={(e: any, value: any) => {
                    if (value === null) {
                      setFacility("")
                      setsearchFacility("");
                      setlistPage(1);
                      setSelFacility(null);
                      controlProps.onChange(value);
                    }
                    else {
                      setFacility(value?._id)
                      setSelFacility(value);
                      controlProps.onChange(value);
                    }
                  }}
                  ListboxProps={{
                    style: { maxHeight: '300px' },
                    onScroll: (event) => {
                      const listboxNode = event.currentTarget;
                      if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1) {
                        setlistPage(listPage + 1)
                      }
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    controlProps.onChange(newInputValue);
                    setsearchFacility(newInputValue);
                    !newInputValue && setlistPage(1);
                  }}
                />)} />
          </Grid>}
        </Grid>
        <LayoutContainer>
          <Grid item xs={12} className={classes.topGrid}>
            <Grid item xs={6} style={{ display: 'inline-flex' }}>
              <Grid style={{ width: '60%', marginRight: '8px' }}>
               <CustomTextFieldSearchWhite
                placeholder={`Search ${process.env.REACT_APP_PATIENT} Name`}
                onSearch={(val: any) => {
                  setSearchTextView(val);
                  setTablePage(1)
                }}
                onClear={() => {
                  setSearchTextView('');
                }}
                resetDefaultOnEmptyChange={true}
              />
              </Grid>
              <FilterAppointment tab={activeTab} DoctorFilterObj={setDoctorFilterObj} setTablePage={setTablePage} />
            </Grid>
            <Grid item xs={6} className={classes.buttonGrid}>
              {/* <Grid>
                <CustomToggleButton view={view} setView={setView} />
              </Grid> */}
              {showFixAppointment && <CustomSubmitButton
                type="button"
                className={classes.submitButton}
                onClick={() => {
                  setOpenFixAppointment(true);
                  setDisabled(true);
                  setChecked(false)
                  setBookingState("NEW")
                }}
              >
                Fix Appointment
              </CustomSubmitButton>}
            </Grid>
          </Grid>
          <Grid>{view === "list" && <ListView setOpenDetilsPopup={setOpenDetilsPopup} isLoading={isLoading} userRole_Static={userRole_Static} activeTab={activeTab} setActiveTab={setActiveTab} tablePage={tablePage} totalRecords={listView?.data?.recordCount} recordCount={listView?.data?.recordCount} tableLength={listView?.data?.data?.length} type={type} tabledata={listView?.data} setSelData={setSelData} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} handlePageChange={handlePageChange}/>}</Grid>
          {/* <Grid>{view === "calendar" && <CalendarView />}</Grid> */}
        </LayoutContainer>
      </Grid>}
      <CustomRightSlideDialog
        open={openFixAppointment}
        title={ bookingState === "FOLLOW_UP"?  "Fix Follow-Up Appointment" : "Fix Appointment"}
        onClose={() => {
          if(isInstant){
            setOpenAppointment(false)
          }
          setOpenFixAppointment(false);
          setFixAppointmantPayload(initialPayload);
          setIsReasonEnable(false); 
          setSelectedRole({})
        }}
        size={"md"}
        autoWidth={true}
        CloseIcon={<CloseIcon />}
        dialogFooter={
          <Grid container justifyContent="space-between" xs={12} direction="row-reverse">
            <Grid item>
              <CustomCancelButton
                onClick={() => {
                  if(isInstant){
                    setOpenAppointment(false)
                  }
                  setOpenFixAppointment(false);
                  setBookingState("")
                  setIsReasonEnable(false); 
                  setFixAppointmantPayload(initialPayload);
                }}
                disabled={request.loading}
              >
                {"Cancel"}
              </CustomCancelButton>
              &nbsp;&nbsp;
              <CustomSubmitButton
                className={undefined}
                disabled={disabled}
                isLoading={request.loading}
                onClick={() => {
                  if (fixAppointmentPayload?.state === "RESCHEDULE") {
                    rescheduleAppointment()
                  } else {
                    bookConsultation();
                  }
                }}
              >
                {fixAppointmentPayload?.state === "RESCHEDULE" ? "Reschedule Appointment" :  "Fix Appointment"}
              </CustomSubmitButton>
            </Grid>
          </Grid>
        }
        className={bookingState==="NEW"? classes.customRightSlideDialog: classes.customFollowRightSlideDialog}
      >
        <BookAppointment
          setDisabled={setDisabled}
          setConsultationFee={setConsultationFee}
          appointmentPayload={fixAppointmentPayload}
          setAppointmentPayload={setFixAppointmantPayload}
          request={request}
          bookingState={bookingState}
          setRequest={setRequest}
          initialPayload={initialPayload}
          detailsData={detailsData}
          facilityId={facilityId}
          Reasons={bookingState === "reschedule" ? rescheduleReasons:followupReason}
          checked={checked}
          setChecked={setChecked}
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          errors={errors}
          reset={reset}
          control={control}
          userData={userData}
          isInstant={isInstant}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          openFixAppointment={openFixAppointment}
        />
      </CustomRightSlideDialog>
      {!isInstant && <div>
      <CustomRightSlideDialog
        open={openDetilsPopup}
        title={detailsPopUpHeader()}
        onClose={() => {
          setOpenDetilsPopup(false)
          setConformation("")
        }}
        size={"medium"}
        autoWidth={false}
        CloseIcon={<CloseIcon />}
        disableEnforceFocus ={showInstant}
        dialogFooter={
          !isDetailFetching ?(selData?.state ==="CANCELLED" ? undefined : ((!reschCondition && !cancelCondition) && !(userRole===USER_ROLES.doctor||userRole===USER_ROLES.receptionist||userRole===USER_ROLES.facility_receptionist ||userRole===USER_ROLES.wellness_counselor_interim||userRole === USER_ROLES.wellness_nutritionist_interim||userRole === USER_ROLES.wellness_coach))  ? undefined: (activeTab !== 1 && ((userRole === USER_ROLES.doctor||userRole===USER_ROLES.wellness_counselor_interim||userRole === USER_ROLES.wellness_nutritionist_interim||userRole === USER_ROLES.wellness_coach) ? !followupCondition ? (!detailData?.isFollowupChat && detailData?.consultationType !== 'CHAT')  : false  :true ))  ? undefined:
          !conformation ? detailsPopUpFooter() :
            <div style={{ width: '100%', textAlign: 'center', fontSize: '14px' }}>
              {conformation === "Cancel" ? 'Are you sure do you want to cancel this appointment?'
                : 'Are you sure do you want to reschedule this appointment?'}
              <CustomIconButton
                icon={<CancelIcon style={{ height: '22px', color: '#F42B3D' }} />}
                label=""
                onClick={() => { setConformation("") }}
                variant="text"
                size="small"
                labelFont={16}
                classes={classes.cancelbtn}
              />
              <CustomIconButton
                icon={<CheckCircleIcon style={{ height: '22px', color: '#07C180' }} />}
                label=""
                onClick={() => { setOpenDialog(true) }}
                variant="text"
                size="small"
                labelFont={16}
                classes={classes.approvebtn}
              />
            </div>) :<></>
        }
        className={classes.customRightSlideDialog}
      >
        {isDetailFetching ? <Box width={'40rem'}><CircularProgress style={{display:"inline-block",position:"absolute",top:"50%",left:"50%"}}/></Box> : <DetailsPopup userRole={userRole_Static} type={type} selData={selData} setDetailsData={setDetailsData} setFees={setFees} followUpDetail={followUpDetail} detailData={detailData} refetchDetail={refetchDetail} activeTab={activeTab} chatNotifyId={chatNotifyId} hideChat ={hideChat}/>}
      </CustomRightSlideDialog>
      </div>}
      {!isInstant && <CustomDialog
        title={conformation === "Cancel" ? "Cancellation Reason" : "Reschedule Reason"}
        open={openDialog}
        autoWidth={true}
        size='md'
        enableFooter
        onClose={() => {
          setConformation("")
          setIsReasonEnable(false);
          setOpenDialog(false)
          setChecked(false)
          setDetailsData({ ...detailsData, reason: "" })
        }}
        CloseIcon={<CloseIcon />}
        className={classes.TagCustomModel}
        classes={{ paperFullWidth: classes.TagcustomDialog }}
        dialogFooter={
          <>
            <Grid item sm={12} className={classes.fullWidthButton}>
              <CustomCancelButton
                type="button"
                className={classes.CustomCancelBtn}
                onClick={() => {
                  setIsReasonEnable(false);
                  setConformation("")
                  setOpenDialog(false)
                  setDetailsData({ ...detailsData, reason: "" })
                }}
              >
                Cancel
              </CustomCancelButton>
              &nbsp;&nbsp;&nbsp;
              <CustomSubmitButton
                className
                //isLoading={request.loading}
                disabled={!isReasonEnable}
                onClick={(e: any) => {
                  if (conformation !== "Cancel") {
                    setConformation("")
                    setOpenDialog(false)
                    setOpenDetilsPopup(false)
                    setOpenFixAppointment(true);
                    setBookingState("reschedule")
                    setFixAppointmantPayload({
                      facility: detailsData?.facilityId,
                      consultationType: detailsData?.consultationType,
                      scheduledAt: "",
                      scheduledBy: "",
                      policyNumber: "",
                      practitionerRoleId: '',
                      practitioner: detailsData?.practitioner,
                      user: detailsData?.primaryUserId,
                      patientType: 'nonPlicyHolder',
                      state: "RESCHEDULE",
                      policyUser: '',
                      otherReason: detailsData?.otherReason,
                      reason: detailsData?.reason?.id
                    })
                  } else {
                    cancelAppointment()
                  }
                }}
              >
                Continue
              </CustomSubmitButton>
            </Grid>
          </>
        }
      >
        <Box>
          <Grid item xs={12}>
            <h4 className={classes.title}>{conformation === "Cancel" ? 'Please add reason for cancelling this appointment' :
              'Please add reason for rescheduling this appointment'}</h4>
            <CommonSearchMultiselect
              className={classes.select}
              label={conformation === "Cancel" ? 'Reason for Cancellation' : 'Reason for Reschedule'}
              variant="filled"
              options={conformation === "Cancel" ? cancellationReason : rescheduleReasons}
              getOptionLabel={(option: any) => option.name}
              popupIcon={false}
              onChange={(e: any, value: any) => {
                if(value!==null){
                  setDetailsData({ ...detailsData, reason: value })
                  if(value.id==="640ed1da1ef04d3bcf283447" || value.id==="640ed0711ef04d3bcf283439" ||
                  value.id==="640ed1361ef04d3bcf28343f" ||value.id === "640ed1741ef04d3bcf283440" ||
                  value.id === "64f062729b60cd28591990c5" ||value.id === "64e74e4257fef31cd24a19dc" ){
                    setIsReasonEnable(false)
                  }else{
                    setIsReasonEnable(true);
                  }
                }else{
                  setIsReasonEnable(false);
                  setDetailsData({ ...detailsData, reason: "" })
                }
              }}
            />
            {(detailsData?.reason?.id === "640ed1da1ef04d3bcf283447" ||detailsData?.reason?.id === "640ed0711ef04d3bcf283439"|| 
              detailsData?.reason?.id === "640ed1361ef04d3bcf28343f"||detailsData?.reason?.id ==="640ed1741ef04d3bcf283440" ||
              detailsData?.reason?.id === "64f062729b60cd28591990c5" || detailsData?.reason?.id === "64e74e4257fef31cd24a19dc") && 
              <CommonTextField 
                  className={classes.textarea}
                  placeholder={"Comments*(Maximum character limit is 300)"}
                  multiline
                  inputProps={{
                    maxLength: 300,
                  }}
                  variant="filled"
                  maxRows={5}
                  onChange={(e: any, value: any) => {
                    if((e.target.value).trim() !== ""){
                      setIsReasonEnable(true);
                      setDetailsData({ ...detailsData, otherReason: e.target.value?.replace( /\s+/g ,' ') })
                    }else{
                      setIsReasonEnable(false);
                    }
                  }}
              />}
          </Grid>
        </Box>
      </CustomDialog>}
    </>
  );
};

export default index;
