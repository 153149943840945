import * as actionTypes from "./actionTypes";

export const updateCareProgramData = data => ({
  type: actionTypes.UPDATE_CARE_PROGRAM_DATA,
  payload: data
});

export const clearCareProgramData = () => ({
  type: actionTypes.CLEAR_CARE_PROGRAM_DATA
});
