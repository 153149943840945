import {CARE_PROGRAM_SERVICE_BASEURL,TAG_BASEURL, URL,CLINIC_CARE_PROGRAM_SERVICE_BASEURL, ROLES_SERVICE_BASEURL,SELECT_USER_SERVICE,CLINIC_CARE_PROGRAM_SERVICE_URL, DEPARTMENT_SERVICE_BASEURL, UPDATE_CARE_PROGRAM_PLAN_SERVICE_BASEURL, CARE_PROGRAM_ORDERS_SERVICE_BASEURL, CARE_PROGRAM_INVOICE_MAIL_BASEURL, CARE_PROGRAM_PLAN_TIER_SERVICE_URL, CARE_PROGRAM_QUESTION_SERVICE_BASEURL, SPECIALITY_SERVICE_BASEURL, QUALIFICATION_SERVICE_BASEURL} from '../../../config/url.config';
import Services from "../../services";

const instance = new Services().getServiceInstance();

export const getCareProgramDetails = id => {
  const data = instance.get(`${CARE_PROGRAM_SERVICE_BASEURL}${id}`);
  return data;
};

export const getTag = (key, page, limit,search) => {
  const params = {
    search,
    limit: limit || 50,
    skip: 0
  }
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  const response = instance.get(TAG_BASEURL, { params })
  return response;
};

export const createCareProgramme = (clinicId, payload) => {
  return instance.post(
    CLINIC_CARE_PROGRAM_SERVICE_URL + clinicId + "/care-programmes",
    payload
  );
};


export const updateCareProgramme = (careProgrammeId, payload) => {
  return instance.patch(
    CARE_PROGRAM_SERVICE_BASEURL + careProgrammeId,
    payload
  );
};

export const getAllCareProgramByClinicApi = async (
  key,
  clinicId,
  order,
  search,
  page,
  limit,
  CareProgramId,
  status,
) => {
  const params = {
    sortBy: order?.orderBy,
    orderBy: order.order,
    limit: limit || 10,
    skip: 0,
    
  };
  if (page && limit) {
    params.skip = (page - 1) * limit;
  }
  if(CareProgramId){
    params['careProgramme']=CareProgramId
  }
  if(search){
    params['search'] = search
  }
  if(status){
    params['status'] = status
  }
  const data = await instance.get(
    `${CLINIC_CARE_PROGRAM_SERVICE_BASEURL}${clinicId}/care-programmes`,
    { params }
  );
  return data;
};

export const getDepartments = async (key, pageNumber) => {
  const { data } = await instance.get(`${DEPARTMENT_SERVICE_BASEURL}?pageNumber=${pageNumber}&pageSize=1000`);
  return data;
};

export const getRoles = async key =>{
  const {data} = await instance.get(ROLES_SERVICE_BASEURL);
  return data;
}

export const getUserByRoles = async (key, roleName,params) => {
  const { data } = await instance.get(`${SELECT_USER_SERVICE}?role=${roleName}`,{params});
  return data
}

export const getSpecialities = async key => {
  const { data } = await instance.get(SPECIALITY_SERVICE_BASEURL);
  return data;
};

export const getQualifications = async (key, name, order, limit) => {
  const params = {
    sortBy: order?.orderBy,
    orderBy: order?.order,
    name: name || "",
    limit: limit || 30,
    skip: 0
  };
  const { data } = await instance.get(QUALIFICATION_SERVICE_BASEURL,{params});
  return data;
};

export const getAllPlansForACareProgramme = async (key, careProgramId) => {
  const data  = await instance.get(
    `${CARE_PROGRAM_SERVICE_BASEURL}${careProgramId}/care-programme-plans`
  );
  return data;
};

export const createaPlanForACareProgramme = (careProgramId, payload) => {
  return instance.post(
    `${CARE_PROGRAM_SERVICE_BASEURL}${careProgramId}/care-programme-plans`,
    payload
  );
};

export const updateCareProgrammePlan = (planId, payload) => {
  return instance.patch(
    `${UPDATE_CARE_PROGRAM_PLAN_SERVICE_BASEURL}${planId}`,
    payload
  );
};

export const getCareProgramPaymentList = async (
  key,
  clinicId,
  order,
  search,
  page,
  limit
) => {
  const params = {
    sortBy: order?.orderBy,
    orderBy: order.order,
    search: search || "",
    limit: limit || 10,
    skip: 0,
    clinicId
  };
  if (page && limit) {
    params.skip = (page - 1) * limit;
  }
  const data = await instance.get(CARE_PROGRAM_ORDERS_SERVICE_BASEURL, {
    params
  });

  return data;
}

export const getCareProgramPaymentDetail = async (key, id) => {
  const { data } = await instance.get(`${CARE_PROGRAM_ORDERS_SERVICE_BASEURL}/${id}`);
  return data
}

export const sendInvoiceMail = async (id) => {
  if (!id) return false
  return instance.post(`${CARE_PROGRAM_INVOICE_MAIL_BASEURL}${id}`)
}

export const getCareProgramPlanTiers = async () => {
  const { data } = await instance.get(
    `${CARE_PROGRAM_PLAN_TIER_SERVICE_URL}`
  );
  return data || [];
};

export const getCareProgramTiers = async (key, careProgramId) => {
  const { data } = await instance.get(
    `${CARE_PROGRAM_SERVICE_BASEURL}${careProgramId}/tier`
  );
  return data
}
export const getCareProgramTierTypes = async (key) => {
  const { data } = await instance.get(
    `${CARE_PROGRAM_QUESTION_SERVICE_BASEURL}/tier`
  );
  return data
}

export const createCareProgramTiers = (careProgramId, data) => {
  return instance.post(`${CARE_PROGRAM_SERVICE_BASEURL}${careProgramId}/tier`, data)
}

export const updateCareProgramTiers = (careProgramId, tierId, data) => {
  return instance.patch(`${CARE_PROGRAM_SERVICE_BASEURL}${careProgramId}/tier/${tierId}`, data)
}

export const getCareProgramAhp = (careProgramId) => {
  const params = {
    role: "ahp",
  };
  return instance.get(`${CARE_PROGRAM_SERVICE_BASEURL}${careProgramId}/ahp`,{params})
}

export const getDashboardCareProgramMembers = (careProgramId,pageNumber,pageSize) => {
  return instance.get(`${CARE_PROGRAM_SERVICE_BASEURL}getAssignedMembers/details?pageSize=${pageSize}&pageNumber=${pageNumber}&careProgramId=${careProgramId}`)
}