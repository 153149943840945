import axios from "axios";
import audio from '../assets/images/audio_icon.svg'
import video from '../assets/images/videoNew_icon.svg'
import pdf from '../assets/images/pdfNew_icon.svg'
import ppt from '../assets/images/pptNew_icon.svg'
import pptx from '../assets/images/pptxNew_icon.svg'
import image from '../assets/images/imageNew_icon.svg'
import pngimage from '../assets/images/pngNew_icon.svg'
import excel from '../assets/images/xlsNew_icon.svg'
import csv from '../assets/images/xlsxNew_icon.svg'
import word from '../assets/images/wordNew_icon.svg'
import heic from "../assets/images/ic_heic.svg"
import moment from "moment";

export const getIdByName = (json, name) => {
  const data = json.filter((v) => v.name === name);
  if (data && data.length) {
    return data[0].id;
  } else {
    return "";
  }
};

export const getNameById = (json, id) => {
  const data = json.filter((v) => v.id === id);
  if (data && data.length) {
    return data[0].name;
  } else {
    return "";
  }
};

export const getRoleName = (role) => {
  switch (role) {
    case "administrators":
      return "Admin";
    case "UNIFIED_APP_FACILITY_ADMIN":
      return "Admin";
    case "UNIFIED_APP_DOCTOR":
      return "UNIFIED_APP_DOCTOR";
    case "dietitians":
      return "Dietician";
    case "diabetic-coaches":
    case "diabetic-coach":
      return "Diabetes Coach";
    default:
      return "Role";
  }
};
export const getRefRoleName = (role) => {
  switch (role) {
    case "UNIFIED_APP_FACILITY_ADMIN":
      return "UNIFIED_APP_FACILITY_ADMIN";
    case "UNIFIED_APP_DOCTOR":
      return "UNIFIED_APP_DOCTOR";
    case "UNIFIED_APP_WELLNESS_SUPPORT":
      return "UNIFIED_APP_WELLNESS_SUPPORT";
    case "UNIFIED_APP_WELLNESS_COUNSELOR":
      return "UNIFIED_APP_WELLNESS_COUNSELOR";
    case "UNIFIED_APP_WELLNESS_NUTRITIONIST":
      return "UNIFIED_APP_WELLNESS_NUTRITIONIST";
    case "UNIFIED_APP_WELLNESS_COACH":
      return "UNIFIED_APP_WELLNESS_COACH";  
    default:
      return "";
  }
};
export const getRoleTypes = (role) => {
  switch (role) {
    case "UNIFIED_APP_FACILITY_ADMIN":
      return "Facility Admin";
    case "UNIFIED_APP_DOCTOR":
      return "Doctor";
    case "UNIFIED_APP_WELLNESS_SUPPORT":
      return "Wellness Support";
    case "UNIFIED_APP_WELLNESS_COUNSELOR":
      return "Wellness Counsellor";
    case "UNIFIED_APP_WELLNESS_NUTRITIONIST":
      return "Wellness Nutritionist";
    case "UNIFIED_APP_WELLNESS_COACH":
      return "Wellness Coach";  
    default:
      return "Role";
  }
};
export const getRefRoleToRole = (role) => {
  switch (role) {
    case "administrator":
      return "administrators";
    case "UNIFIED_APP_DOCTOR":
      return "UNIFIED_APP_DOCTOR";
    case "dietitian":
      return "dietitians";
    case "diabetic-coach":
      return "diabetic-coaches";
    case 'ahp':
      return 'ahp';
    default:
      return "Role";
  }
};

export const getRefRolesToRole = (roles) => {
  switch (roles) {
    case "administrators":
      return "administrator";
    case "UNIFIED_APP_DOCTOR":
      return "UNIFIED_APP_DOCTOR";
    case "dietitians":
      return "dietitian";
    case "diabetic-coaches":
      return "diabetic-coach";
    case 'ahp':
      return 'ahp';
    default:
      return roles;
  }
}

export const flattenArray = (arr) => {
  if (arr && arr.length) {
    const result = arr.map((v) => {
      const { active } = v;
      return {
        active,
        ...v.doctor,
        mobileNum: `+ ${v.mobile.countryCode} ${v.mobile.number}`,
      };
    });
    console.log(result);
    return result;
  }
  return arr;
};

export const getIcon = (filename) => {
  const url = new URL(filename);
  const ext = url.pathname.split(".").pop();
  switch (ext) {
    case 'xls':
      return excel;
    case 'xlsx':
    case 'csv':
      return csv;
    case 'ppt':
      return ppt;
    case 'pptx':
      return pptx;
    case 'mp3':
      return audio;
    case 'mp4':
      case "mov":
      return video;
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return image;
    case 'png':
      return pngimage;
    case "heif":
    case "heic":
      return heic;
    case 'pdf':
      return pdf;
    default:
      return word;
  }
};

export const padNumber = (number, size) => {
  var s = String(number);
  while (s.length < (size || 2)) { s = "0" + s; }
  return s;
}

export const booleanToString = (value) => {
  switch (value) {
    case true:
      return 'yes'
    case false:
      return 'no'
    case null:
    case "null":
      return 'NA'
    default:
      return ''
  }
}
export const stringToBoolean = (value) => {
  switch (value) {
    case 'yes':
      return true
    case 'no':
      return false
    case 'NA':
      return "null"
    default:
      return ''
  }
}

export const downloadFile = async (url, fileName) => {
  const response = await axios({
    url,
    method: 'GET',
    responseType: 'blob',
  })
  const blobbedResponse = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = blobbedResponse;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export const getBasenameFormUrl = (urlStr) => {
  if(urlStr){
    try{
        const url = new URL(urlStr);
        return url.href.split("?")[0].split("/").pop()
    }
    catch(e){
        console.log("Error", e)
        return "file"
    }
  }
};
export const getAppointmentType = (type) => {
  const types = {
    CARE_PROGRAM: "Wellness Program",
    VIRTUAL_CONNECT: "Virtual Connect",
    PHYSICAL_CONSULTATIONS: "Physical Consultation",
  }

  return types[type] || 'NA'
}

export const getAge =(dob) =>{
const age = moment.duration(moment().diff(moment(dob),'milliseconds')).get('years');
return age;
}