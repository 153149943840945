import { Box, Grid, Typography } from "@material-ui/core"
import styles from "./styles"
import moment from 'moment';
import { getRoleTypes } from "../../../utils";

interface IdetailProps{
    followUpDetail: any | undefined;
    detailData:any;
}

const Consultation = ({followUpDetail, detailData}:IdetailProps) => {

    const {practitioner,appointmentCreatedOn,  appointmentCreatedBy,date,slotStartTime,consultationType,facilityPractitioner} = followUpDetail || detailData || {}
    const classes = styles()
    const dateTime = moment(appointmentCreatedOn).format("DD/MM/YYYY");
    const consultationTime=moment(date).format("DD/MM/YYYY")
    const time = moment(appointmentCreatedOn).format('LT');
    let hour = Math.floor(slotStartTime/3600000);
    let minute = (Math.floor(slotStartTime/60000) % 60)
    const slotTime = moment(`${hour}:${minute}`, "HH:mm").format("hh:mm a")
    const TYPES =
    {
      VIRTUAL_CONSULTATION : "Virtual Consult",
      PHYSICAL_CONSULTATION : "Physical Consult",
      INSTANT : "Instant Call",
      CHAT :'Instant Chat'
    }
    return (
        <Grid container xs={12} className={classes.detailsRoot}>
            <Box component="div" className={classes.detailsTitle}>
                <Typography variant="h6" className={classes.details}>Consultation Details</Typography>
            </Box>
            <Box component="div" className={classes.detailsContent}>
                <Grid container xs={12}>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>{facilityPractitioner?.practitionerRole === "UNIFIED_APP_DOCTOR" ? "Doctor Name" : getRoleTypes(facilityPractitioner?.practitionerRole)}</Typography>
                        <Typography variant="h6" className={classes.header}>{facilityPractitioner?.practitionerRole === "UNIFIED_APP_DOCTOR" && "Dr."}{practitioner?.name?.first} {practitioner?.name?.last}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>{'Consultation Date & Time'}</Typography>
                        <Typography variant="h6" className={classes.header}>{consultationTime}, {slotTime}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>{'Consultation Type'}</Typography>
                        <Typography variant="h6" className={classes.header}>{TYPES[consultationType]}</Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12} style={{paddingTop: '16px'}}>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>Booked By</Typography>
                        <Typography variant="h6" className={classes.header}>{detailData?detailData?.appointmentCreatedBy?.first: appointmentCreatedBy?.first} {detailData?detailData?.appointmentCreatedBy?.last:appointmentCreatedBy?.last}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>{'Booked On'}</Typography>
                        <Typography variant="h6" className={classes.header}>{dateTime}, {time}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>{'Mode'}</Typography>
                        <Typography variant="h6" className={classes.header}>{detailData ? detailData?.category: followUpDetail?.category }</Typography>
                    </Grid>
                </Grid>
                    {(detailData?.careProgram || followUpDetail?.careProgram)&&
                <Grid container xs={12} style={{paddingTop: '16px'}}>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>Program Name</Typography>
                        <Typography variant="h6" className={classes.header}>{detailData?detailData?.careProgram?.careProgramName: followUpDetail?.careProgram?.careProgramName}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>Group Name</Typography>
                        <Typography variant="h6" className={classes.header}>{detailData?detailData?.careProgram?.groupName: followUpDetail?.careProgram?.groupName}</Typography>
                    </Grid>
                </Grid>}
            </Box>
        </Grid>
    )
}

export default Consultation