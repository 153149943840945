import { Grid, Chip , Button, CircularProgress} from "@material-ui/core";
import useStyles from "./styles";
import ic_allergies from "../../../../assets/images/appointment/ic_allergies.svg";
import ic_surgery from "../../../../assets/images/appointment/ic_surgery.svg";
import ic_disease from "../../../../assets/images/appointment/ic_disease.svg";
import { useEffect, useState,useRef } from "react";
import TextField from '@mui/material/TextField';
import { useQuery } from "react-query";
import {
  deleteMedicalHistory,
  getMedicalHistoryUser,
  postMedicalHistory,
  getMasterData as getMedicalHistory
} from "../../../../api/consultation/consultation";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { validations } from "../../../../config/validations.config";
import CustomFormEM from "../../../common/CustomFormEM"
import CustomSubmitButton from "../../../../components/common/CustomSubmitButton";
import {
  IMedicalHistoryProps,
  IMasterDataRequiredValue
} from "../../../../models/consultation";
import ic_cancel from "../../../../assets/images/icons/ic_cancel.svg";
import ic_tick from "../../../../assets/images/icons/ic_tick.svg";
import { Close } from "@material-ui/icons";
import Notify from "../../../common/CommonToast";
import { Autocomplete, ListItemButton, Tooltip,ListItem, ListItemText} from "@mui/material";
import { customizeStyle } from "../styles";

interface IPagination
{
  search : string,
  pageNumber : number,
  pageSize : number,
  sortBy: string,
  orderBy: string,
  masterDataKey : string
}


interface IUserDataValue
{
  _id : string,
  text : string
}
const MedicalHistory = ({ userId, DetailScreen }: IMedicalHistoryProps) => {
  const classes = useStyles();
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: "",
    action:"",
    value:"",
    key:""
  });
  const customizeClass = customizeStyle();
  const [allergyShow, setAllergyShow] = useState<boolean>(false);
  const [surgeryShow, setSurgeryShow] = useState<boolean>(false);
  const [diseaseShow, setDiseaseShow] = useState<boolean>(false);
  const allergyRef = useRef<string | null>(null);
  const surgicalRef = useRef<string | null>(null);
  const diseaseRef = useRef<string | null>(null);
  const [allergyMasterdata,setallergyMasterdata] = useState<Array<IMasterDataRequiredValue>>([]);
  const [surgeryMasterdata,setsurgeryMasterdata] = useState<Array<IMasterDataRequiredValue>>([]);
  const [diseaseMasterdata,setdiseaseMasterdata] = useState<Array<IMasterDataRequiredValue>>([]);
  const [stateRefresh,setstateRefresh]=useState<boolean>(false);
  const allergySearch  = useRef<IPagination>({search : '',pageNumber:1,pageSize:20,sortBy:'',orderBy:'',masterDataKey:'allergies'});
  const surgerySearch  = useRef<IPagination>({search : '',pageNumber:1,pageSize:20,sortBy:'',orderBy:'',masterDataKey:'surgery'});
  const diseaseSearch  = useRef<IPagination>({search : '',pageNumber:1,pageSize:20,sortBy:'',orderBy:'',masterDataKey:'disease'});
  const schema = Yup.object().shape({ allergies: validations.commonRequired });
  const {
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      allergies: [],
      surgeries: [],
      disease: [],
    },
  });

  watch(["allergies", "surgeries", "disease"]);
  // const { allergies, surgeries, peds } = getValues();

  const { data: allergyListData, refetch: _fetchRefetch, isLoading: allergyLoading } = useQuery(
    [
      "GET_ALL_ALLERGY_LIST", {...allergySearch.current}
    ],
    getMedicalHistory,
    { enabled: userId, retry: 0 }
  );
useEffect(() => {
  if(allergyListData?.data && Array.isArray(allergyListData?.data))
  {
      if(allergySearch?.current.pageNumber === 1)
      setallergyMasterdata(allergyListData?.data);
      else
      setallergyMasterdata([...allergyMasterdata,...allergyListData?.data]);
  }
},[allergyListData])
  const { data: surgeryListData, refetch: _surgeryRefetch, isLoading: surgeryLoading } = useQuery(
    [
      "GET_ALL_SURGERY_LIST", surgerySearch.current
    ],
    getMedicalHistory,
    { enabled: userId, retry: 0 }
  );
  useEffect(() => {
    if(surgeryListData?.data && Array.isArray(surgeryListData?.data))
    {
        if(surgerySearch?.current.pageNumber === 1)
        setsurgeryMasterdata(surgeryListData?.data);
        else
        setsurgeryMasterdata([...surgeryMasterdata,...surgeryListData?.data]);
    }
  },[surgeryListData])
  const {
    data: existingListData,
    isLoading: existingLoading,
    refetch: _existingRefetch,
  } = useQuery(
    [
      "GET_ALL_EXISTING_LIST", diseaseSearch.current
    ],
    getMedicalHistory,
    { enabled: userId, retry: 0 }
  );

  useEffect(() => {
    if(existingListData?.data && Array.isArray(existingListData?.data))
    {
        if(allergySearch?.current.pageNumber === 1)
        setdiseaseMasterdata(existingListData?.data);
        else
        setdiseaseMasterdata([...diseaseMasterdata,...existingListData?.data]);
    }
  },[existingListData])

  const { data: allergyUserData = [], refetch: allergyUserFetch, isLoading: allergyUserLoading , isFetching : isallergyFetching} = useQuery(
    [
      "GET_ALL_SURGERY_LIST", {
        "userId": userId,
        "code": ["64a66822749cc03c4c6b8f3e"]
      }
    ],
    getMedicalHistoryUser,
    { enabled: userId, retry: 0 }
  );

  const { data: surgeryUserData = [], refetch: surgeryUserFetch, isLoading: surgeryUserLoading , isFetching : issurgeryFecthing} = useQuery(
    [
      "GET_ALL_SURGERY_LIST", {
        "userId": userId,
        "code": ["64a66817749cc03c4c6b8f37"]
      }
    ],
    getMedicalHistoryUser,
    { enabled: userId, retry: 0 }
  );

  const { data: diseaseUserData = [], refetch: diseaseUserFetch, isLoading: diseaseUserLoading , isFetching : isdiseaseFetching} = useQuery(
    [
      "GET_ALL_SURGERY_LIST", {
        "userId": userId,
        "code": ["64a667d5749cc03c4c6b8f30"]
      }
    ],
    getMedicalHistoryUser,
    { enabled: userId, retry: 0 }
  );


  const deleteApi = async (payload,key) => {
    const { _id } = payload;
    if (_id) {
      setRequest({ loading: true, message: '', severity: '' ,action:"delete",value:payload,key:key})
      try {
        const response = await deleteMedicalHistory('delete-Medical-History', {code : _id, userId : userId});
        if (response && response?.data && response.status < 350) {
          setRequest({ loading: false, message: '', severity: '' ,action:"",value:"",key:""})
          key === 'allergies' ? allergyUserFetch() : key === 'surgeries' ? surgeryUserFetch() : diseaseUserFetch();
        }
      }
      catch (err) {
        setRequest({ loading: false, message: err?.response?.data?.message || 'Something Went wrong', severity: 'error' ,action:"",value:"",key:""})
      }
    }
  }
  const updateAPi = async (key: 'allergies' | 'surgeries' | 'disease') => {
    const updateValue = getValues(key);
    const masterCode = { allergies : "64a66822749cc03c4c6b8f3e" , surgeries : "64a66817749cc03c4c6b8f37" , disease : "64a667d5749cc03c4c6b8f30"}
    const customizePayload = updateValue?.reduce((accumulator,initial : any) => {
        let value =  initial ;
        if(initial?._id === initial?.masterDataValue)
        value = {text : initial?.masterDataValue} ;
        else
        value = {_id : initial?._id,text : initial?.masterDataValue}
        return accumulator.concat(value)      
    },[]);
    if (updateValue) {
      setRequest({ loading: true, message: '', severity: '' ,action:"Add",value:"",key:key})
      try {
        const response = await postMedicalHistory('Update-Medical-History', {
          "userId": userId,
          "code": masterCode[`${key}`],
          "value": customizePayload
        });
        if (response && response.data && response.status < 350) {
          setRequest({ loading: false, message: '', severity: '' ,action:"",value:"",key:""})
          key === 'allergies' ? allergyUserFetch() : key === 'surgeries' ? surgeryUserFetch() : diseaseUserFetch();
        }
      }
      catch (err) {
        setRequest({ loading: false, message: err?.response?.data?.message || 'Something Went wrong', severity: 'error' ,action:"",value:"",key:""})
      }
    }

  }

  const renderHistory = (selectedValue: IUserDataValue, key: string) => <Tooltip classes={{popper : classes.chipTooltip}}  title={selectedValue?.text.length > 45 ? selectedValue?.text : ''}><Chip className ={classes.chipLabel} classes={{ root: classes.blueContainer }} label={selectedValue?.text} onDelete={deleteApi.bind(null, selectedValue,key)} deleteIcon={<Close fontSize="small" />} /></Tooltip>

  return (
    <>
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <div className={classes.mainContainer}>
        <h4 className={classes.mainHeading}>Medical History</h4>
        <Grid container xs={12} className={classes.gridContainer}>
          <Grid item xs={4} className={classes.wrapper}>
            <div>
              <div className={classes.wrapperContainer}>
                <img src={ic_allergies} alt="allergy" />
                <h4 className={classes.allery}>Allergies </h4>
              </div>
              <div className={classes.historyContainer}>
                {allergyUserLoading && (
                  <CircularProgress className={classes.progressBar} />
                )}
                {Array.isArray(allergyUserData) && allergyUserData[0]?.data && allergyUserData[0]?.data?.value?.map((value) => renderHistory(value, 'allergies'))}
                {(isallergyFetching || (request.action === 'Add' && request.loading && request.key === 'allergies')) && <CircularProgress color={'primary'} size={'small'} style={{width : '1.25rem'}}/>}
              </div>
            </div>

            {!DetailScreen ? allergyShow ? (
              <>
                {(
                  <Controller
                    name="allergies"
                    control={control}
                    render={(
                      controlProps: ControllerRenderProps<Record<string, any>>
                    ) => (
                      <Autocomplete
                      multiple
                      key={'allergies-select'}
                      loading={allergyLoading}
                      noOptionsText = {<Button style={allergySearch.current.search ? {color : 'blue'} : {}} onClick={() => 
                      {
                          const value = [...controlProps.value]?.find((element : any) => element?._id?.toLowerCase() === allergyRef.current?.trim()?.toLowerCase()) ;
                          if(!value && allergyRef.current?.trim())
                          {
                            let controlValue = controlProps.value ?? [] ;
                            controlProps.onChange([...controlValue,...[{_id : allergyRef.current.trim() , masterDataValue : allergyRef.current.trim()}]]);
                          }
                        }
                      }>+Add New</Button>}
                      id="allergies-select-check"
                      options={allergyMasterdata}
                      value={controlProps.value}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option : IMasterDataRequiredValue, index) => (
                          <Chip {...getTagProps({ index })} label={option?.masterDataValue} className={classes.blueContainer} style={{maxWidth :'320px'}}/>
                        ));
                      }}
                      ListboxProps={{
                        style: { maxHeight: "310px" },
                        onScroll: (event: { currentTarget: any }) => {
                          const listboxNode = event.currentTarget;
                          if (
                           ( listboxNode.scrollHeight -
                            listboxNode.scrollTop -
                            listboxNode.clientHeight <
                            1 ) && allergyListData?.hasNextPage
                          ) {
                            allergySearch.current.pageNumber = allergySearch.current.pageNumber + 1 ;
                            setstateRefresh(!stateRefresh)
                          }
                        },
                      }}
                      onChange={(_event,value) => 
                        {
                          if(value?.length > 0)
                          {
                            const updateValue = value[value.length - 1];
                            const repeated = [...value ?? [],...allergyUserData[0]?.data?.value ?? []].filter((check) => check?._id === updateValue._id)
                            repeated.length === 1  && controlProps.onChange(value)
                          }
                          else
                          controlProps.onChange(value)
                        }
                      }
                      renderOption={(props,option,_state) => 
                      <ListItem style={{margin : 0 ,padding : 0 , width : '100%'}} {...props}>
                        <ListItemButton alignItems = {'flex-start'} style={([...controlProps?.value ?? [],...allergyUserData[0]?.data?.value ?? []]?.find((element) => element?._id === option?._id)) ? {background : '#cfdbfa78' , padding : '0.5rem',width : '100%'} : {padding : '0.5rem',width : '100%'}}>
                           <ListItemText primary={option?.masterDataValue}/>
                        </ListItemButton>
                      </ListItem>}
                    //   filterOptions={(options, params) => {
                    //     let filtered = options.filter((ele) => {
                    //       return (
                    //         !Boolean(allergyUserData[0]?.data?.value?.find((userData) => userData?._id === ele?._id))
                    //       );
                    //     });
                    //     const { inputValue } = params;
                    //     if (inputValue && inputValue !== "" && inputValue.trim()) {
                    //       let array: Array<IMAsterDataValue> = [];
                    //       filtered.map((value) => {
                    //         if (value?.masterDataValue?.toLowerCase()?.includes(inputValue.trim().toLowerCase())) {
                    //           array.push(value);
                    //         }
                    //       });
                    //       filtered = array;
                    //     }
                    //     return filtered;
                    // }}
                      getOptionLabel={(option : IMasterDataRequiredValue) => option?.masterDataValue}
                      style={{ width: '100%' }}
                      onInputChange={(_event ,value) => (allergyRef.current = value,allergySearch.current.search = value,allergySearch.current.pageNumber = 1,setstateRefresh(!stateRefresh))}
                      renderInput={(params) => (
                        <TextField {...params} label={'Search Allergies'} className={customizeClass.root}/>
                      )}
                    />
                    )}
                  />
                )}
                <CustomFormEM
                  errors={errors}
                  name="allergies"
                  render={({ message }) => <p>{message}</p>}
                />
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button onClick={() => (updateAPi('allergies'),setAllergyShow(false))}><img src={ic_tick} /></Button>
                  <Button onClick={() => (setValue('allergies',[]) , setAllergyShow(false))}><img src={ic_cancel} /></Button>
                </Grid>
              </>
            ) : (
              <CustomSubmitButton
                type="button"
                className={classes.addBtn}
                onClick={() => setAllergyShow(true)}
              >
                + Add New
              </CustomSubmitButton>
            ) : <></>}
          </Grid>
          <Grid item xs={4} className={classes.wrapper}>
            <div>
              <div className={classes.wrapperContainer}>
                <img src={ic_surgery} alt="surgery" />
                <h4 className={classes.surgery}>Surgical History</h4>
              </div>
              <div className={classes.historyContainer}>
                {surgeryUserLoading && (
                  <CircularProgress className={classes.progressBar} />
                )}
                {Array.isArray(surgeryUserData) && surgeryUserData[0]?.data && surgeryUserData[0]?.data?.value?.map((value) => renderHistory(value, 'surgeries'))}
                {(issurgeryFecthing || (request.action === 'Add' && request.loading && request.key === 'surgeries')) && <CircularProgress color={'primary'} size={'small'} style={{width : '1.25rem'}}/>}
              </div>
            </div>
            {!DetailScreen ? surgeryShow ? (
              <>
                {!DetailScreen && (
                  <Controller
                    name="surgeries"
                    control={control}
                    render={(
                      controlProps: ControllerRenderProps<Record<string, any>>
                    ) => (
                      <Autocomplete
                      multiple
                      key={'surgeries-select'}
                      loading={surgeryLoading}
                      noOptionsText = {<Button  style={surgerySearch.current.search ? {color : 'blue'} : {}} onClick={() => 
                      {

                        const value = [...controlProps.value ?? []]?.find((element : IMasterDataRequiredValue) => element?._id?.toLowerCase() === surgicalRef.current?.trim()?.toLowerCase()) ;
                        if(!value && surgicalRef.current?.trim())
                        {
                          let controlValue = controlProps.value ?? [] ;
                          controlProps.onChange([...controlValue ?? [],...[{_id : surgicalRef.current.trim() ,  masterDataValue : surgicalRef.current.trim()}]]);
                        }
                      }
                      }>+Add New</Button>}
                      id="surgeries-select-check"
                      options={surgeryMasterdata}
                      value={controlProps.value}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option : IMasterDataRequiredValue, index) => (
                          <Chip {...getTagProps({ index })} label={option?.masterDataValue} className={classes.blueContainer} style={{maxWidth :'320px'}}/>
                        ));
                      }}
                      ListboxProps={{
                        style: { maxHeight: "310px" },
                        onScroll: (event: { currentTarget: any }) => {
                          const listboxNode = event.currentTarget;
                          if (
                            (listboxNode.scrollHeight -
                            listboxNode.scrollTop -
                            listboxNode.clientHeight <
                            1) && surgeryListData?.hasNextPage
                          ) {
                            surgerySearch.current.pageNumber = surgerySearch.current.pageNumber + 1 ;
                            setstateRefresh(!stateRefresh);
                          }
                        },
                      }}
                      onChange={(_event,value) => 
                        {
                          if(value?.length > 0)
                          {
                            const updateValue = value[value.length - 1];
                            const repeated = [...value ?? [],...surgeryUserData[0]?.data?.value ?? []].filter((check) => check?._id === updateValue._id)
                            repeated.length === 1  && controlProps.onChange(value)
                          }
                          else
                          controlProps.onChange(value)
                        }
                      }
                      renderOption={(props,option,_state) => 
                        <ListItem style={{margin : 0 ,padding : 0 , width : '100%'}} {...props}>
                          <ListItemButton alignItems = {'flex-start'} style={([...controlProps?.value ?? [],...surgeryUserData[0]?.data?.value ?? []]?.find((element) => element?._id === option?._id)) ? {background : '#cfdbfa78' , padding : '0.5rem' ,width : '100%'} : {padding : '0.5rem' , width : '100%'}}>
                          <ListItemText primary={option?.masterDataValue}/>
                          </ListItemButton>
                        </ListItem>}
                    //   filterOptions={(options, params) => {
                    //     let filtered = options.filter((ele) => {
                    //       return (
                    //         !Boolean(surgeryUserData[0]?.data?.value?.find((userData) => userData?._id === ele?._id))
                    //       );
                    //     });
                    //     const { inputValue } = params;
                    //     if (inputValue && inputValue !== "" && inputValue.trim()) {
                    //       let array: Array<IMAsterDataValue> = [];
                    //       filtered.map((value) => {
                    //         if (value?.masterDataValue?.toLowerCase()?.includes(inputValue.trim().toLowerCase())) {
                    //           array.push(value);
                    //         }
                    //       });
                    //       filtered = array;
                    //     }
                    //     return filtered;
                    // }}
                      getOptionLabel={(option : IMasterDataRequiredValue) => option?.masterDataValue}
                      style={{ width: '100%' }}
                      onInputChange={(_event ,value) => (surgicalRef.current = value,surgerySearch.current.search = value ,surgerySearch.current.pageNumber = 1,setstateRefresh(!stateRefresh))}
                      renderInput={(params) => (
                        <TextField {...params} label={'Search Surgeries'} className={customizeClass.root}/>
                      )}
                    />
                    )}
                  />
                )}
                <CustomFormEM
                  errors={errors}
                  name="surgeries"
                  render={({ message }) => <p>{message}</p>}
                />
                 <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button onClick={() => (updateAPi('surgeries'),setSurgeryShow(false))}><img src={ic_tick} /></Button>
                  <Button  onClick={() => (setValue('surgeries',[]),setSurgeryShow(false))} ><img src={ic_cancel}/></Button>
                </Grid>
              </>
            ) : (
              <CustomSubmitButton
                type="button"
                className={classes.addBtn}
                onClick={() => setSurgeryShow(true)}
              >
                + Add New
              </CustomSubmitButton>
            ) : <></>}
          </Grid>
          <Grid item xs={4} className={classes.wrapper}>
            <div>
              <div className={classes.wrapperContainer}>
                <img src={ic_disease} alt="disease" />
                <h4 className={classes.disease}>Pre-Existing Disease</h4>
              </div>
              <div className={classes.historyContainer}>
                {diseaseUserLoading && (
                  <CircularProgress className={classes.progressBar} />
                )}
                {Array.isArray(diseaseUserData)  && diseaseUserData[0]?.data && diseaseUserData[0]?.data?.value?.map((value) => renderHistory(value, 'disease'))}
               { (isdiseaseFetching || (request.action === 'Add' && request.loading &&  request.key === 'disease' )) && <CircularProgress color={'primary'} size={'small'} style={{width : '1.25rem'}}/>}
              </div>
            </div>
            {!DetailScreen ? diseaseShow ? (
              <>
                <Controller
                  name="disease"
                  control={control}
                  render={(
                    controlProps: ControllerRenderProps<Record<string, any>>
                  ) => (
                    <Autocomplete
                    multiple
                    loading ={existingLoading}
                    key={"disease-select"}
                    noOptionsText = {<Button style={diseaseSearch.current.search ? {color : 'blue'} : {}} onClick={() => 
                    {

                      const value = [...controlProps.value ?? []]?.find((element : IMasterDataRequiredValue) => element?._id?.toLowerCase() === diseaseRef.current?.trim()?.toLowerCase()) ;
                      if(!value && diseaseRef.current?.trim())
                      {
                        let controlValue = controlProps.value ?? [] ;
                        controlProps.onChange([...controlValue ?? [],...[{_id : diseaseRef.current.trim() , masterDataValue : diseaseRef.current.trim()}]]);
                      }
                    }
                    }>+Add New</Button>}
                    id="disease-select-check"
                    options={diseaseMasterdata}
                    value={controlProps.value}
                  //   filterOptions={(options, params) => {
                  //     let filtered = options.filter((ele) => {
                  //       return (
                  //         !Boolean(diseaseUserData[0]?.data?.value?.find((userData) => userData?._id === ele?._id))
                  //       );
                  //     });
                  //     const { inputValue } = params;
                  //     if (inputValue && inputValue !== "" && inputValue.trim()) {
                  //       let array: Array<IMasterDataRequiredValue> = [];
                  //       filtered.map((value) => {
                  //         if (value?.masterDataValue?.toLowerCase()?.includes(inputValue.trim().toLowerCase())) {
                  //           array.push(value);
                  //         }
                  //       });
                  //       filtered = array;
                  //     }
                  //     return filtered;
                  // }}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option : IMasterDataRequiredValue, index) => (
                        <Chip {...getTagProps({ index })} label={option?.masterDataValue} className={classes.blueContainer} style={{maxWidth :'320px'}}/>
                      ));
                    }}
                    ListboxProps={{
                      style: { maxHeight: "310px" },
                      onScroll: (event: { currentTarget: any }) => {
                        const listboxNode = event.currentTarget;
                        if (
                          (listboxNode.scrollHeight -
                          listboxNode.scrollTop -
                          listboxNode.clientHeight <
                          1) && existingListData?.hasNextPage
                        ) {
                          diseaseSearch.current.pageNumber = diseaseSearch.current.pageNumber + 1 ;
                          setstateRefresh(!stateRefresh)
                        }
                      },
                    }}
                    onChange={(_event,value) => 
                      {
                        if(value?.length > 0)
                        {
                          const updateValue = value[value.length - 1];
                          const repeated = [...value ?? [],...diseaseUserData[0]?.data?.value ?? []].filter((check) => check?._id === updateValue._id)
                          repeated.length === 1  && controlProps.onChange(value)
                        }
                        else
                        controlProps.onChange(value)
                      }
                    }
                    renderOption={(props,option,_state) => 
                      <ListItem style={{margin : 0 ,padding : 0 , width : '100%'}} {...props}>
                        <ListItemButton alignItems = {'flex-start'} style={([...controlProps?.value ?? [],...diseaseUserData[0]?.data?.value ?? []]?.find((element) => element?._id === option?._id)) ? {background : '#cfdbfa78' , padding : '0.5rem',width : '100%'} : {padding : '0.5rem',width : '100%'}}>
                        <ListItemText primary={option?.masterDataValue}/>
                        </ListItemButton>
                      </ListItem>}

                    getOptionLabel={(option : IMasterDataRequiredValue) => option?.masterDataValue}
                    style={{ width: '100%' }}
                    onInputChange={(_event ,value) => (diseaseRef.current = value,diseaseSearch.current.search = value , diseaseSearch.current.pageNumber = 1,setstateRefresh(!stateRefresh))}
                    renderInput={(params) => (
                      <TextField {...params} label={'Search Diseases'} className={customizeClass.root} />
                    )}
                  />
                  )}
                />
                <CustomFormEM
                  errors={errors}
                  name="disease"
                  render={({ message }) => <p>{message}</p>}
                />
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button onClick={() => (updateAPi('disease'),setDiseaseShow(false))}><img src={ic_tick} /></Button>
                  <Button onClick={() => (setValue('disease',[]),setDiseaseShow(false))}><img src={ic_cancel} /></Button>
                </Grid>
              </>
            ) : (
              <CustomSubmitButton
                type="button"
                className={classes.addBtn}
                onClick={() => setDiseaseShow(true)}
              >
                + Add New
              </CustomSubmitButton>
            ) : <></>}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MedicalHistory;
