import { ChangeEvent, useState, useEffect } from "react";
import { Grid, Typography, Box, CircularProgress, Tooltip } from "@material-ui/core";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import clsx from "clsx";
import useStyles from "./styles";
import { useQuery } from "react-query";
import { validations } from "../../../../config/validations.config";
import ic_prescription_grey from "../../../../assets/images/consultation/ic_prescription_grey.png";
import CustomCheckboxLabel from "../../../common/CustomCheckbox";
import CommonTextField from "../../../../components/common/CommonTextField";
import CustomFormEM from "../../../common/CustomFormEM";
import CustomSearchSelect from "../../../common/CustomSearchSelect";
import ThreeDotsMenu from "../../../../components/CustomThreeDotsMenu";
import CustomCancelButton from "../../../../components/common/CustomCancelButton";
import { RootStateOrAny, useSelector } from "react-redux";
import CustomSubmitButton from "../../../common/CustomSubmitButton";
import PrescriptionList from "./PrescriptionList";
import CustomDialog from "../../../common/CustomDialog";
import CloseIcon from "@material-ui/icons/Close";
// import { getMedicine } from "../../../../api/patient";
import {
  createPrescription,
  generatePrescription,
  getAllPrescription,
} from "../../../../api/consultation/consultation";
import {
  EmptyPrescriptionProps,
  IdrugList,
  IcreatePayload,
  IdrugDetail,
  Iprescription,
  IprescriptionForm,
  IMedicinelist,
} from "../../../../models/consultation";
import { genericObj } from "../../../../models/common";
import { USER_ROLES } from "../../../../utils/constants";

const Prescription = ({ data, EditPres, element }: Iprescription) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const { details } = useSelector((state: RootStateOrAny) => state.clinic);
  const [editValue, setEditValue] = useState<genericObj>({});
  const [drugsList, setDrugsList] = useState<any>([]);
  const [buttonCondition, setButtonCondition] = useState<boolean>(false);
  const medicineHeight = 90;
  const prescritionHeight = 190;
  const getAllPrescriptionPayload = {
    facilityId: details?.id,
    consultationId: data?.consultationId,
    patientId: data?.user?._id,
    pageNumber: 1,
    pageSize: 10,
    idList: [],
  };

  const { data: getUserPrescription = {}, refetch: getAllPrescriptionRefeth } =
    useQuery(
      ["GET_ADDED_DRUGS", getAllPrescriptionPayload],
      getAllPrescription,
      { enabled: data?.consultationId }
    );

  useEffect(() => {
    if (drugsList.length === 0) {
      setShowForm(false)
      EditPres(false)
    }
  }, [drugsList])

  useEffect(() => {
    if (getUserPrescription?.data?.data.length > 0 && showForm && drugsList.length > 0) {
      element.scrollTo({
        top: medicineHeight * drugsList.length + prescritionHeight * getUserPrescription?.data?.data.length + medicineHeight,
        behavior: "smooth",
      });
    } else if (showForm && drugsList.length >= 3) {
      element.scrollTo({
        top: medicineHeight * drugsList.length + medicineHeight,
        behavior: "smooth",
      });
    } else if (getUserPrescription?.data?.data.length > 0 && showForm) {
      element.scrollTo({
        top: prescritionHeight * getUserPrescription?.data?.data.length,
        behavior: "smooth",
      });
    }
  }, [showForm])


  return (
    <div>
      {!showForm &&
        drugsList.length === 0 &&
        getUserPrescription?.data?.data?.length === 0 && (
          <EmptyPrescription onClick={() => setShowForm(true)} />
        )}
      {getUserPrescription?.data?.data.length > 0 && (
        <PrescriptionList
          drugsList={drugsList}
          presList={getUserPrescription && getUserPrescription?.data?.data}
          onClick={() => setShowForm(true)}
          showForm={showForm}
        />
      )}
      {drugsList.length > 0 && (
        <PrescriptionItem
          setDrugsList={setDrugsList}
          item={drugsList}
          onClick={() => setShowForm(true)}
          showForm={showForm}
          getEditValue={(value: IdrugList) => setEditValue(value)}
          setShowForm={setShowForm}
          setButtonCondition={setButtonCondition}
          buttonCondition={buttonCondition}
          getAllPrescriptionRefeth={getAllPrescriptionRefeth}
          data={data}
          setLoad={setLoad}
          setEditValue={setEditValue}
          load={load}
          EditPres={EditPres}
        />
      )}
      {showForm && (
        <PrescriptionForm
          setShowForm={setShowForm}
          drugsListed={drugsList}
          setDrugsList={setDrugsList}
          editValue={editValue}
          setLoad={setLoad}
          setEditValue={setEditValue}
          load={load}
          data={data}
          EditPres={EditPres}
        />
      )}
    </div>
  );
};

export default Prescription;

const PrescriptionForm = ({
  setShowForm,
  editValue,
  setEditValue,
  load,
  setDrugsList,
  drugsListed,
  EditPres,
}: IprescriptionForm) => {

  const classes = useStyles();
  const [drugData, setDrugData] = useState<IdrugDetail>({
    dose: "",
    drugName: "",
    uoM: "nos",
    drugId: "",
    genricName: "",
    description: "",
    dosageForm: "",
    route: "",
    quantity: "",
    sourceSystem: "",
  });
  // const [names, setNames] = useState<string[]>([]);
  // const [medData, setMedData] = useState<any[]>([]);
  // const [drugnames, setDrugNames] = useState<string>("");
  // const [medicineInput, setMedicineInput] = useState<string>("");
  // const [pageNumber, setPageNumber] = useState<number>(1)
  // const {
  //   data: medList = [],
  //   isLoading: isMedicineLoading,
  // } = useQuery(["searchMedcine", medicineInput.trim(), pageNumber, 10], getMedicine);

  // useEffect(() => {
  //   if (!isMedicineLoading) {
  //     const medicinesNames = medList?.data?.filter(t => t.genricName !== undefined).map(t => t.genricName)
  //     setMedData([...medData, ...medList.data]);
  //     setNames([...names, ...medicinesNames]);
  //   }
  // }, [medList]);

  const schema = Yup.object().shape({
    medicineName: Yup.string().trim().required("Required field cannot be left blank").max(100, 'Please enter valid medicine name'),
    medicationForm: validations.commonRequired,
    medicationFrequency: validations.commonRequired,
    medicationUsage: validations.commonRequired,
    medicationDuration: Yup.string().trim().required('Required field cannot be left blank').typeError('Please enter valid duration').max(10, 'Please enter valid duration'),
    medicationQuantity: Yup.string().trim().required('Required field cannot be left blank').typeError('Please enter valid duration').max(3, 'Please enter valid quantity'),
    comment: validations.commonRequired,
  });

  const {
    handleSubmit,
    control,
    errors,
    setValue,
    getValues,
    watch,
    setError
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      medicineName: "",
      medicationForm: "",
      medicationFrequency: Array<string>(),
      medicationUsage: Array<string>(),
      medicationDuration: "",
      medicationQuantity: "",
      comment: "",
    },
  });

  watch([
    "medicineName",
    "medicationForm",
    "medicationFrequency",
    "medicationUsage",
    "medicationDuration",
    "medicationQuantity",
    "comment",
  ]);

  const {
    medicationFrequency,
    medicationUsage,
    medicationDuration,
    medicationQuantity,
    comment,
    medicationForm,
    medicineName
  } = getValues();

  useEffect(() => {
    if (Object.keys(editValue)) {
      setValue('medicineName', editValue?.drugDetail?.drugName);
      setValue("medicationForm", editValue?.drugDetail?.dosageForm);
      setValue("medicationFrequency", editValue?.intake);
      setValue("medicationUsage", editValue?.intakeTime);
      setValue("medicationDuration", editValue?.intakeDuration);
      setValue("medicationQuantity", editValue?.intakeQuantity);
      setValue("comment", editValue?.instructions);
      setDrugData({
        ...drugData,
        drugName: editValue?.drugDetail?.drugName,
        dosageForm: editValue?.drugDetail?.dosageForm
      });
    }
  }, [editValue]);

  const onSubmit = async () => {  
    const drugList = [
      {
        drugDetail: drugData,
        intake: medicationFrequency,
        intakeDuration: medicationDuration,
        intakeQuantity: medicationQuantity,
        intakeTime: medicationUsage,
        instructions: comment ? comment : "",
      },
    ];
    if (Object.keys(editValue)?.length > 0) {
      drugsListed[drugsListed.findIndex((a: any) => a?.drugDetail?.drugName === editValue?.drugDetail?.drugName)] = drugList[0]
      setDrugsList([...drugsListed]);      
    } else {
      setDrugsList([...drugsListed, ...drugList]);
    }
    setShowForm(false);
    if (editValue) {
      setEditValue({})
    }
    EditPres(true);
  };

  const renderFrequency = ({ label, value }: genericObj) => (
    <CustomCheckboxLabel
      label={label}
      checked={medicationFrequency?.includes(value)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const consTypes = [value];
        setValue("medicationFrequency", consTypes);
        setError("medicationFrequency", {
          type: "custom",
          message: !consTypes.length
            ? "Required field cannot be left blank"
            : "",
        });
      }}
    />
  );

  const renderUsage = ({ label, value }: genericObj) => (
    <CustomCheckboxLabel
      label={label}
      checked={medicationUsage?.includes(value)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const consTypes = !e.target.checked
          ? medicationUsage?.filter((type: string) => type !== value)
          : [...(medicationUsage || []), value];
        setValue("medicationUsage", consTypes);
        setError("medicationUsage", {
          type: "custom",
          message: !consTypes.length
            ? "Required field cannot be left blank"
            : "",
        });
      }}
    />
  );

  const cancelButton = () => {
    setShowForm(false);
    setEditValue({});
    drugsListed?.length>0? EditPres(true): EditPres(false);
  };

  // const onBlurMedicine = () => {
  //   if (drugData.drugName === "") {
  //     setValue("medicineName", "")
  //   }
  // }

  useEffect(()=>{
    if(medicineName?.length>0||medicationForm?.length>0||medicationUsage?.length>0||medicationFrequency?.length>0||medicationDuration?.length>0||medicationQuantity?.length>0||comment?.length>0 || drugsListed?.length>0){
      EditPres(true)
    }else{
      EditPres(false)
    }
  },[medicationFrequency,medicationUsage,medicineName,medicationForm,medicationDuration,medicationQuantity,comment])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.prescriptionForm}
    >
      {load && <CircularProgress className={classes.progressBar} />}
      <Grid container spacing={5} className={"firstWrapper"}>
        <Grid item md={6} xs={12}>
          <Controller
            name="medicineName"
            control={control}
            render={(
              controlProps: ControllerRenderProps<Record<string, any>>
            ) => (
              <CommonTextField
                className={clsx(classes.root)}
                type="text"
                variant="filled"
                label="Medicine Name"
                value={controlProps.value || ""}
                onChange={(e: { target: { value: string } }) => {
                  setDrugData({
                    ...drugData,
                    drugName: e.target.value
                  });
                  controlProps.onChange(e.target.value);
                }}
              />
              // <CustomSearchSelect
              //   className={""}
              //   label={"Medicine name"}
              //   options={names?.length > 0 ? names : []}
              //   getOptionLabel={(option: any) => option != '' ? option.split(',')[0] : option}
              //   value={controlProps.value || ""}
              //   loading={isMedicineLoading}
              //   onBlur={() => {onBlurMedicine()}}
              //   onChange={(_: any, value: any) => {
              //     controlProps.onChange(value);
              //     if (value) {
              //       const medName = medData?.filter(
              //         (x: any) => x?.genricName !== undefined && x?.genricName === value
              //       )[0];
              //       setDrugData({
              //         dose: medName?.dose || "0",
              //         drugName: medName?.drugName,
              //         uoM: "nos",
              //         drugId: medName?.drugId,
              //         genricName: medName?.genricName,
              //         description: medName?.description,
              //         dosageForm: medName?.drug_form || "",
              //         route: medName?.route,
              //         quantity: medName?.drug_unit || "",
              //         sourceSystem: medName?.sourceSystem,
              //       });
              //     }
              //   }}
              //   onInputChange={(event: any, newInputValue: any) => {
              //     setMedicineInput(newInputValue);
              //     controlProps.onChange(newInputValue);
              //     setMedData([])
              //     setNames([])
              //     setPageNumber(1)
              //   }}
              //   ListboxProps={{
              //     style: { maxHeight: '200px' },
              //     onScroll: (event) => {
              //       const listboxNode = event.currentTarget;
              //       if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1) {
              //         setPageNumber(pageNumber + 1)
              //       }
              //     }
              //   }}
              //   disableClearable
              // />
            )}
          />
          <CustomFormEM
            errors={errors}
            name="medicineName"
            render={({ message }) => <p>{message}</p>}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="medicationForm"
            control={control}
            render={(
              controlProps: ControllerRenderProps<Record<string, any>>
            ) => (
              <CustomSearchSelect
                className={""}
                label={"Medication form"}
                options={SAMPLE_FORMS}
                getOptionLabel={(option: string) => option}
                value={controlProps.value || ""}
                onChange={(_: any, value: string) => {
                  setDrugData({
                    ...drugData,
                    dosageForm: value
                  });
                  controlProps.onChange(value);
                }}
                disableClearable
              />
            )}
          />
          <CustomFormEM
            errors={errors}
            name="medicationForm"
            render={({ message }) => <p>{message}</p>}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} className={"wrapper"}>
        <Grid item md={6} xs={12}>
          <Typography variant="h6" className={"subtitle"}>
            Medication Frequency
          </Typography>
          <Controller
            name="medicationFrequency"
            control={control}
            render={() => (
              <Grid container className="checkboxWrapper">
                {MEDICATION_FREQUENCY.map(renderFrequency)}
              </Grid>
            )}
          />
          <CustomFormEM
            errors={errors}
            name="medicationFrequency"
            render={({ message }) => <p>{message}</p>}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="h6" className={"subtitle"}>
            Medication Consumption
          </Typography>
          <Controller
            name="medicationUsage"
            control={control}
            render={() => (
              <Grid container className="checkboxWrapper">
                {MEDICATION_USAGE.map(renderUsage)}
              </Grid>
            )}
          />
          <CustomFormEM
            errors={errors}
            name="medicationUsage"
            render={({ message }) => <p>{message}</p>}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} className={"wrapper"}>
        <Grid item md={6} xs={12}>
          <Controller
            name="medicationDuration"
            control={control}
            render={(
              controlProps: ControllerRenderProps<Record<string, any>>
            ) => (
              <CommonTextField
                className={clsx(classes.root)}
                type="text"
                variant="filled"
                label="Medication Duration"
                value={controlProps.value || ""}
                onChange={(e: { target: { value: string } }) => {
                  controlProps.onChange(e.target.value);
                }}
              />
            )}
          />
          <CustomFormEM
            errors={errors}
            name="medicationDuration"
            render={({ message }) => <p>{message}</p>}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            name="medicationQuantity"
            control={control}
            render={(
              controlProps: ControllerRenderProps<Record<string, any>>
            ) => (
              <CommonTextField
                className={clsx(classes.root)}
                type="text"
                variant="filled"
                label="Medication Quantity"
                value={controlProps.value || ""}
                onChange={(e: { target: { value: string } }) => {
                  controlProps.onChange(e.target.value);
                }}
              />
            )}
          />
          <CustomFormEM
            errors={errors}
            name="medicationQuantity"
            render={({ message }) => <p>{message}</p>}
          />
        </Grid>
      </Grid>

      <Grid container spacing={5} className={"textWrapper"}>
        <Grid item md={6} xs={12}>
          <Controller
            name="comment"
            control={control}
            render={(
              controlProps: ControllerRenderProps<Record<string, any>>
            ) => (
              <CommonTextField
                className={clsx(classes.root)}
                multiline
                rows={3}
                type="text"
                variant="filled"
                label="Other Comments"
                value={controlProps.value || ""}
                onChange={(e: { target: { value: string } }) => {
                  controlProps.onChange(e.target.value);
                }}
              />
            )}
          />
          <CustomFormEM
            errors={errors}
            name="comment"
            render={({ message }) => <p>{message}</p>}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={"buttonWrapper"}>
        <CustomCancelButton
          type="button"
          onClick={cancelButton}
          style={{ marginRight: "20px" }}
        >
          Cancel
        </CustomCancelButton>
        <CustomSubmitButton
          type="button"
          className=""
          style={{ marginRight: "40px" }}
          onClick={handleSubmit(onSubmit)}
          disabled={undefined}
        >
          {Object.keys(editValue).length > 0 ? "Save" : "Add"}
        </CustomSubmitButton>
      </Grid>
    </form>
  );
};

const PrescriptionItem = ({
  onClick,
  item,
  showForm,
  getEditValue,
  setShowForm,
  buttonCondition,
  getAllPrescriptionRefeth,
  setButtonCondition,
  setLoad,
  load,
  data,
  setDrugsList,
  setEditValue,
  EditPres,
}: IMedicinelist) => {
  const { details } = useSelector((state: RootStateOrAny) => state.clinic);
  const { user, userRole } = useSelector((state: RootStateOrAny) => state.auth);
  const classes = useStyles();
  const [comments, setComments] = useState<string>("");
  const [ShowDelete, setShowDelete] = useState<boolean>(false);
  const [dataDelete, setDataDelete] = useState<any>({});
  const [buttonContent, setButtonContent] = useState<boolean>(true);

  const schema = Yup.object().shape({
    prescriptionNotes: validations.commonRequired,
  });
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      prescriptionNotes: "",
    },
  });

  const handleMenuEdit = (data: IdrugList) => {
    getEditValue(data);
    setShowForm(true);
  };
  const addMedicinePayload: IcreatePayload = {
    consultationId: data ? data?.consultationId : "",
    createdBy: {
      userId: user?.userId,
      roleName: userRole,
    },
    drugList: item,
    encountedId: data?.encounterID.toString(),
    facilityId: details.id,
    patientId: data ? data?.user?._id : "",
    prescribedBy: {
      practitionerId: user?.userId,
      practitionerRoleId: userRole,
    },
  };

  const clickToGenerate = async () => {
    setLoad(true);
    try {
      const res = await createPrescription(
        "createPrescription",
        addMedicinePayload
      );
      if (res?.statusCode === 200 && res) {
        const generatePayload = {
          consultationId: data ? data?.consultationId : "",
          facilityId: details.id,
          patientId: data ? data?.user?._id : "",
          prescriptionId: res?.data?.id,
          prescriptionNotes: comments ? comments : "",
          status: "GeneratePrescription",
          updatedBy: {
            userId: user?.userId,
            roleName: "UNIFIED_APP_DOCTOR",
          },
        };
        try {
          const res = await generatePrescription("generate", generatePayload);
          if (res?.statusCode === 200) setDrugsList(() => []);
          getAllPrescriptionRefeth();
          setLoad(false);
          setButtonCondition(false);
          EditPres(false);
        } catch (error) {
          setLoad(false);
        }
      }
    } catch (error) {
      setLoad(false);
    }
  };

  const buttonHandle = () => {
    setButtonContent(() => false);
  };
  const handleMenuDelete = (value: any) => {
    setDataDelete(value)
    setShowDelete(true);
    setShowForm(false);
    setEditValue({});
  };
  const clickToCancel = async () => {
    setDrugsList(() => [])
    EditPres(false);
  };
  const clickToCancelAll = async () => {
    setButtonContent(() => true);
    setButtonCondition(false);
  };
  const handleDelete = () => {
    item.splice(item.findIndex((a: any) => a?.drugDetail?.drugName === dataDelete?.drugDetail?.drugName), 1)
    setShowDelete(false);
    if (item.length === 0) {
      EditPres(false)
    }
  };
  const renderContainer = (props: IdrugList) => {
    const inTake = props?.intake.map((x: string) => " " + x);
    const inTakeTime = props?.intakeTime.map((x: string) => " " + x);

    return (
      <Grid item xs={12} className={classes.tableData}>
        <Grid item xs={3} className={classes.medicineName}>
          <Typography className={classes.tabArr}>Medicine Name</Typography>
           <Tooltip title={props?.drugDetail.drugName}>
            <Typography className={classes.tabData}>{props?.drugDetail.drugName}
            </Typography>
            </Tooltip>
        </Grid>
        <Grid item xs={2} spacing={3}>
          <Typography className={classes.tabArr}>Frequency</Typography>
          <Typography className={classes.tabData}>
            {props.intake.length === 1 ? props.intake : inTake + ""}
          </Typography>
        </Grid>
        <Grid item xs={2} spacing={3}>
          <Typography className={classes.tabArr}>Usage</Typography>
          <Typography className={classes.tabData}>
            {props.intakeTime.length === 1 ? props.intakeTime : inTakeTime + ""}
          </Typography>
        </Grid>
        <Grid item xs={1} spacing={3}>
          <Typography className={classes.tabArr}>Duration</Typography>
          <Typography className={classes.tabData}>
            {props.intakeDuration}
          </Typography>
        </Grid>
        <Grid item xs={1} spacing={3}>
          <Typography className={classes.tabArr}>Quantity</Typography>
          <Typography className={classes.tabData}>
            {props.intakeQuantity}
          </Typography>
        </Grid>
        <Grid item xs={1} spacing={3} className={!buttonContent ? classes.disableContainer : ""}>
          <ThreeDotsMenu
            handleMenuEdit={handleMenuEdit.bind(null, props)}
            handleMenuDelete={handleMenuDelete.bind(null, props)}
          ></ThreeDotsMenu>
        </Grid>
        <CustomDialog
          title={"Delete"}
          open={ShowDelete}
          onClose={() => setShowDelete(false)}
          size={"xs"}
          autoWidth={undefined}
          classes={{ paperFullWidth: classes.customDialogReject }}
          enableFooter={false}
          className={classes.CustomModel}
          CloseIcon={<CloseIcon />}
        >
          <Box>
            <Grid sm={12} className={classes.gridSpace}>
              <Typography variant="h6" component="h6">
                Are you sure, do you want to delete?
              </Typography>
            </Grid>
            <Grid
              item
              sm={12}
              className={clsx(classes.RightAlign, classes.fullWidthButton)}
            >
              <CustomCancelButton
                type="button"
                className={classes.CustomCancelBtn}
                onClick={() => setShowDelete(false)}
              >
                No
              </CustomCancelButton>
              &nbsp;&nbsp;&nbsp;
              <CustomSubmitButton
                onClick={handleDelete}
                className=""
              >
                Yes
              </CustomSubmitButton>
            </Grid>
          </Box>
        </CustomDialog>
      </Grid>
    );
  };
  return (
    <>
      {load && <CircularProgress className={classes.progressBar} />}
      <Grid container className={classes.prescriptionBox}>
        <Grid item xs={12} className={classes.headerContainer}>
          <Typography
            className={classes.bold}
          >{`Added medicines`}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.prescribeData}>
          {item?.map(renderContainer)}
        </Grid>
        {showForm === false && buttonContent === true && (
          <h4 onClick={onClick} className={classes.addNewButton}>
            + Add Medicine
          </h4>
        )}
        {!buttonContent && (
          <>
            <Grid container xs={12} className={classes.notesContainer}>
              <Controller
                name="prescriptionNotes"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <CommonTextField
                    className={clsx(classes.root)}
                    multiline
                    rows={3}
                    type="text"
                    label="Prescription Notes"
                    onChange={(e: { target: { value: string } }) => {
                      setComments(e.target.value);
                      controlProps.onChange(e.target.value);
                    }}
                  />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="prescriptionNotes"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            <Grid container xs={12}>
              <CustomCheckboxLabel
                label={
                  "I hereby authorise the generation of an e-prescription with the above medication details for this patient."
                }
                checked={buttonCondition}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setButtonCondition(!buttonCondition);
                }}
              />
            </Grid>
          </>
        )}

        {showForm === false && (
          <Grid container spacing={2} className={"buttonWrapper"}>
            <CustomCancelButton
              type="button"
              onClick={buttonContent ? clickToCancel : clickToCancelAll}
              style={{ marginRight: "20px" }}
            >
              Cancel
            </CustomCancelButton>
            <CustomSubmitButton
              type="button"
              className=""
              isLoading={load}
              disabled={!buttonContent && !buttonCondition || load}
              style={{ marginRight: "20px" }}
              onClick={
                buttonContent ? buttonHandle : handleSubmit(clickToGenerate)
              }
            >
              {buttonContent ? "Finish Adding" : "Generate Prescription"}
            </CustomSubmitButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const EmptyPrescription = ({ onClick }: EmptyPrescriptionProps) => {
  const classes = useStyles();
  const { userRole } = useSelector((state: RootStateOrAny) => state?.auth);
  return (
    <div className={classes.emptyPrescription}>
      <div className="iconWrapper">
        <img src={ic_prescription_grey} alt="placeholder" />
      </div>
      <span className="desc">No added prescription to display</span>
      <CustomSubmitButton
        className={classes.button}
        onClick={onClick}
        disabled={
          userRole &&
          (userRole === USER_ROLES.receptionist ||
            userRole === USER_ROLES.opd_ops ||
            userRole === USER_ROLES.facility_receptionist)
        }
      >
        Add Prescription
      </CustomSubmitButton>
    </div>
  );
};

const MEDICATION_FREQUENCY = [
  { label: "Before food", value: "Beforefood" },
  { label: "After food", value: "Afterfood" },
  { label: "Other", value: "Other" },
];

const MEDICATION_USAGE = [
  { label: "Morning", value: "Morning" },
  { label: "Afternoon", value: "Afternoon" },
  { label: "Evening", value: "Evening" },
  { label: "Night", value: "Night" },
  { label: "Others", value: "Others" },
];

const SAMPLE_FORMS = ["Tablet", "Capsule", "Injection", "Syrup", "Ointment"];
