import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "0.5rem",
  },
  paragraph: {
    fontSize: "0.65rem",
    margin: "0",
    color: "#333333",
  },
  heading: {
    margin: "0",
    color: "#000000",
    fontSize: "0.8rem",
    textTransform:"capitalize",
    wordBreak:"break-word"
  },
  sectionTwo: {
    background: "white",
    borderRadius: "4px",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0.625rem 1rem",
    borderBottom: "1px solid #E4E4E4",
    "& button": {
      padding: "1px 0.5rem",
      background: "#24B648",
      borderRadius: "0.25rem",
      fontSize: "0.75rem",
      color: "#FFFFFF",
      border: "2px solid #24B648",
      height: "fit-content",
      "&:hover": {
        backgroundColor: "#24B648",
        color: "#FFFFFF",
      },
    }
  },
  idContainer: {
    margin: "0",
  },
  bottomContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    padding: "0.5rem 1rem",
    [theme.breakpoints.down('md')]: {
      padding: "1rem 0rem 1rem",

    },
    "& button": {
      background: "#DB3500",
      color: "#FFFFFF",
      fontWeight: "700",
      padding: "0.5rem 1rem",
      borderRadius: "0.25rem",
      margin: "0",
      fontSize: "0.75rem",
      border: "2px solid #DB3500",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#DB3500",
      },
    },

    "& h4": {
      fontSize: "0.875rem",
    },
  },
  countdownNumber: {
    fontWeight: 800,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: "0.5rem",

    },
  },
  AddDoctor: {
    background: "#FFFFFF !important",
    color: '#34519C !important',
    border: '1px solid #FFFFFF !important',
    alignItems: 'center',
    padding: '10px !important',

  },
  EndCall: {
    background: "#DB3500 !important",
    color: '#FFFFFF !important',
    border: '1px solid #DB3500 !important',
    "&:hover": {
      background: "#FFFFFF !important",
      color: '#DB3500 !important',
      border: '1px solid #DB3500 !important'
    }
  },
  paymentDiv: {
    fontWeight: 600,
    margin: 0,
  },
  buttonClass: {
    display: 'flex', flexDirection: 'row', padding: '0.5rem', alignItems: 'center', width: '100%'
  },
  buttonClassEnd: {
    display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center', padding: '0.5rem', paddingLeft: '5%', borderTop: '1px solid #E4E4E4', width: '100%', justifyContent: "space-between"
  },
  status: {
    borderRadius: '2rem', background: '#24B648', height: '10px', width: '10px', marginRight: '2px'
  },
  Activestatus: {
    color: '#24B648', fontWeight: 600, display: 'flex', alignItems: 'center'
  },
  unsavedTab: {
    padding: '16px',
    display: "flex !important",
    justifyContent: "space-between !important",
  },
  submitBtn: {
    color: " #FFFFFF !important",
    border: "2px solid #35C5EA !important",
    backgroundColor: "#35C5EA !important",
    width: "200px",
    textTransform: "none",
    "&:hover": {
      color: "#35C5EA !important",
      backgroundColor: "transparent !important",

    }
  },
  cancleBtn: {
    color: " #FFFFFF !important",
    border: "2px solid #B7BDCE !important",
    width: "200px",
    backgroundColor: "#B7BDCE !important",
    height: "40px",
    "&  .MuiButton-label": {
      fontSize: "14px",
    },
    "&:hover": {
      color: "#B7BDCE !important",
      backgroundColor: "transparent !important",

    }
  },
  patientInfo: {
    background: "#ffffff",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  patientImg: {
    width: "5rem",
    height: "5rem",
    borderRadius: "50%",
    overflow: "hidden",
    display: "inline-flex",
    margin: "1rem 0 1rem 1rem"
  },
  patientContentWrap: {
    display: 'flex',
    width: "calc(100% - 5rem)",
    flexDirection: "column"
  },
  patientTextWrap: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "0.8rem",
    marginBottom: "0.4rem",
    "& >  div": {
      width: "calc(100%/8)",
      padding: "0 0.4rem",
      borderRight: "1px solid #e4e4e4",
      "&:last-of-type":{
        borderRight: "0"
      }
    }
  },
  editPatientWrap: {
    display: 'flex',
    justifyContent: "flex-end",
    padding: "0.4rem 0.8rem 0 0",
    margin: "0.5rem 0 0 1.6rem",
    borderTop: "1px solid #e4e4e4"
  },
  editPatientbtn: {
    color: "#34519C !important",
    border: "0px !important",
    background: "transparent !important",
    padding: "4px 8px !important",
    "&:hover": {
      color: "#34519C !important",
    }
  },
  dates: {
    background: '#f4f4f4 !important',
    borderRadius: "4px",
    "& .MuiOutlinedInput-root": {
      background: 'transparent !important',
    },
    "& fieldset": {
      border: "0px solid transparent !important",
    },
  "&:hover fieldset": {
      borderRadius: "4px",
      border: '0px solid #717171 !important'
  },
  "& input":{
    color:"#808080a8 !important"
  }
  },
  patientNameTooltip:{
    width:"90px", 
    overflow:"hidden", 
    whiteSpace:"nowrap",
    textOverflow:"ellipsis"
  }
}));

export default useStyles;
