import React, {useEffect , useState} from "react";
import { useHistory } from "react-router-dom";
import Notify from "../components/common/CommonToast";
import { clearUser } from "../api/StarLogin";
import { useSelector } from "react-redux";
const events = [
  "onload",
  "onmousemove",
  "onmousedown",
  "onclick",
  "onscroll",
  "onkeypress",
];
const SessionLogout = ({ children }) => {
  const history = useHistory();
  const [request, setRequest] = useState({
    message: "",
    loading: false,
    severity: "",
  });
  const {user} = useSelector((state) => state?.auth);
  const clearLogout = async () => {
   history.push("/logout");
  }

useEffect(() => {
  var timer;
  Object.values(events).forEach((item) => {
   window[item] = function handleLogoutTimer ()
   {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      clearTimeout(timer);
      Object.values(events).forEach((item) => {
       window[item] = null;
      });
      clearLogout();
    }, 3600000);
   }
  });

  return () => {
    clearTimeout(timer);
    Object.values(events).forEach((item) => {
     window[item] = null;
    });
  }
}, []);



  return <>
   {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
  {children}
  </>;
};

export default React.memo(SessionLogout);