import { ChangeEvent, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import CustomDialog from "../../../common/CustomDialog";
import CustomFormEM from "../../../common/CustomFormEM";
import { Controller, useForm, ControllerRenderProps } from "react-hook-form";
import useStyles from "./styles";
import CommonTextField from "../../../common/CommonTextField";
import CloseIcon from "@material-ui/icons/Close";
import CustomSubmitButton from "../../../common/CustomSubmitButton";
import { IrejectProps } from "../../../../models/consultation";
import { RootStateOrAny, useSelector } from "react-redux";
import { USER_ROLES } from "../../../../utils/constants";
import CommonDropDown from "../../../common/CommonDropDown";
import CommonMenuItem from "../../../common/CommonDropDown/CommonMenuItem";
import { endCallReasons } from '../../../../api/consultation/consultation'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validations } from "../../../../config/validations.config";

interface Ireason {
  id: 'string',
  reason: 'string'
}

const RejectPopup = ({ modalVisible, closeModal, clickToEndConsultation, setEndReason, consultationType, isLoading }: IrejectProps) => {
  const classes = useStyles();
  const [endreason, setEndreason] = useState<any>();
  const { userRole } = useSelector((state: RootStateOrAny) => state.auth);
  const receptionist = userRole === USER_ROLES.facility_receptionist || userRole === USER_ROLES.opd_ops || userRole === USER_ROLES.receptionist;
  const [selectedReason, setSelectedReason] = useState("");
  const [showButton, setShowbutton] = useState<boolean>(true);

  // const receptionist = true;
  const schema = yup.object().shape({
    selectReason: validations.commonRequired,
    rejectComment: selectedReason === "640833d4b07f451d51fd1383" ? validations.commonRequired : yup.string(),
  })

  // const receptionist = true;
  const {
    getValues,
    watch,
    handleSubmit,
    errors,
    control,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectComment: "",
      selectReason: "",
    },
  });

  watch(["selectReason", "rejectComment"]);

  const { rejectComment, selectReason } = getValues();
  useEffect(() => {
    if (consultationType) {
      (async () => {
        try {
          const res = await endCallReasons('Call Reasons', { role: userRole, type: consultationType });
          if (res && res?.data)
            setEndreason(res?.data);
        }
        catch (e) { }
      })()
    }
  }, [consultationType])

  useEffect(() => {
    const selectedval = endreason?.filter((x: any) => x.id === selectReason)
    if (selectedval?.map((x: any) => x?.reason)[0] === "Others") {
      if (rejectComment?.length > 0) {
        setShowbutton(false)
      } else {
        setShowbutton(true)
      }
    } else if (selectedval?.length > 0) {
      setShowbutton(false)
    } else {
      setShowbutton(true)
    }
  }, [rejectComment, selectReason])

  return (
    <CustomDialog
      size={"md"}
      open={modalVisible}
      title={receptionist ? 'End Call' : window.location.pathname === '/chat-consulation' ? "End Chat " : "End Consultation"}
      onClose={() => {
        !isLoading && closeModal(false);
      }}
      classes={{ paper: classes.RejectDoctor }}
      statu
      children={
        <form onSubmit={handleSubmit(clickToEndConsultation)}>
          <div className={classes.root}>
            <Controller
              name="selectReason"
              control={control}
              render={(
                controlProps: ControllerRenderProps<Record<string, any>>
              ) => (
                <div style={{ marginTop: '5%' }}>
                  <CommonDropDown
                    variant="filled"
                    multiple={false}
                    onChange={(e: any) => {
                      controlProps.onChange(e.target.value);
                      setSelectedReason(e.target.value)
                      clearErrors()
                    }}
                    value={controlProps.value}
                    placeholder={'Select Reason'}
                  >
                    {endreason && endreason.map((data: Ireason) => {
                      return (<CommonMenuItem value={data?.id}>{data?.reason}</CommonMenuItem>)
                    })}
                  </CommonDropDown>
                </div>
              )}
            />
            <CustomFormEM
              errors={errors}
              name="selectReason"
              render={({ message }: any) => <p>{message}</p>}
            />
            <Controller
              name="rejectComment"
              control={control}
              render={(
                controlProps: ControllerRenderProps<Record<string, any>>
              ) => (
                <div style={{ marginTop: '5%' }}>
                  <CommonTextField
                    rows={4}
                    variant="filled"
                    placeholder={"Comments"}
                    multiline
                    value={rejectComment || ""}
                    className={classes.CommentSection}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      controlProps.onChange(e.target.value.trimStart())
                    }
                  />
                </div>
              )}
            />
            <CustomFormEM
              errors={errors}
              name="rejectComment"
              render={({ message }: any) => <p>{message}</p>}
            />
            <Stack
              spacing={4}
              direction={"row"}
              justifyContent={"space-between"}
              style={{ position: "absolute", top: "14rem" }}
            >
              <CustomSubmitButton
                className={classes.RejectDoctorClxBtn}
                disabled={isLoading}
                onClick={() => {
                  closeModal(false);
                }}
              >
                Cancel
              </CustomSubmitButton>
              <CustomSubmitButton
                type="submit"
                isLoading={isLoading}
                disabled={showButton || isLoading}
                className={showButton ? classes.ReceptionistEndBtn : classes.ReceptionistRejBtn}
                onClick={() => { setEndReason({ rejectComment: rejectComment, selectReason: selectReason }); }}
              >
                {isLoading ? '' : receptionist ? 'End Call' : 'End Consultation'}
              </CustomSubmitButton>
            </Stack>
          </div>
        </form>
      }
      enableFooter={false}
      CloseIcon={<CloseIcon />}
      autoWidth={undefined}
    />
  );
};

export default RejectPopup;
