import { CircularProgress, Grid, IconButton, Radio, RadioGroup, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Controller, ControllerRenderProps, Control } from "react-hook-form";
import { useQuery } from "react-query";
import Wallet from "../../../assets/images/icons/wallet_inactive.svg";
import { ReactComponent as VirtualConsult } from "../../../assets/images/icons/video_consult_inactive.svg";
import styles from "./styles";
import CommonDropDown from "../../../components/common/CommonDropDown";
import CommonMenuItem from "../../../components/common/CommonDropDown/CommonMenuItem";
import CommonTextFieldSearch from "../../../components/common/CommonTextFieldSearch";
import PatientListing from "./patientListing";
import { RootStateOrAny, useSelector } from "react-redux";
import CommonDatePicker from "../../../components/common/CommonDatePicker";
import { consultationType } from "./consultation.json";
import {genericObj, IAppointmentPayload, Irequest } from "../../../models/common";
import PolicyListing from "./policyListing";
import CommonTextField from "../../../components/common/CommonTextField";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import moment from "moment";
import CustomFormEM from "../../../components/common/CustomFormEM";
import { consultationTypeByFacility, getSymptoms, getSlotList, getUserDetailBySearch, getpolicydetails } from '../../../api/AppointmentManagement/Appointment'
import CommonSearchMultiselect from "../../../components/common/CommonSearchMultiselect";
import { getProviderPractitionerNames } from "../../../api/scheduler/slot-management";
import { getAllCareProgramByClinicApi } from "../../../api/BarosAPI/careProgram/careProgram";
import { GetGroupByCareProgramId } from "../../../api/BarosAPI/careProgram/groups";
import { USER_ROLES } from "../../../utils/constants";

interface IBookAppointment {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setConsultationFee: React.Dispatch<React.SetStateAction<string>>;
  appointmentPayload: IAppointmentPayload;
  setAppointmentPayload: React.Dispatch<React.SetStateAction<IAppointmentPayload>>;
  request: Irequest;
  bookingState: string;
  setRequest: React.Dispatch<React.SetStateAction<Irequest>>;
  initialPayload: IAppointmentPayload;
  detailsData: any;
  facilityId: string;
  Reasons: any;
  checked:any;
  setChecked:any;
  reset:(value:any)=>void;
  setValue:(name: any, value: unknown, config?: any) => void;
  getValues:(payload?: string | string[]) => any;
  trigger:()=>void;
  errors:genericObj;
  control:Control<Record<string, any>>;
  userData:any;
  isInstant:boolean;
  setSelectedRole:(value:any)=>void;
  selectedRole:any;
  openFixAppointment:boolean;
}

export interface IPatientDetail {
  _id: string;
  countryCode: string;
  gender: string;
  secondaryMobile?: null[] | null;
  profileStatus: string;
  loginAttemptFailedAt: string;
  totalFailedLoginAttempt: number;
  loginRetryDelay: number;
  mobile: string;
  name: IPatientName;
  addresses?: null[] | null;
  mobileDevices?: null[] | null;
  insurances?: null[] | null;
  onboardingQuestions?: null[] | null;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
export interface IPatientName {
  _id: string;
  first: string;
  last: string;
}

const BookAppointment = ({
  setConsultationFee,
  appointmentPayload,
  setAppointmentPayload,
  bookingState,
  initialPayload,
  detailsData,
  facilityId,
  Reasons,
  setValue,
  errors,
  control,
  userData,
  isInstant,
  selectedRole, 
  setSelectedRole,
  openFixAppointment
}: IBookAppointment) => {
  const classes = styles();
  const [search, setSearch] = useState<string>("");
  const [tablePage, setTablePage] = useState<number>(1);
  const rowsPerPage = 10;
  const { userRole, user } = useSelector((state: RootStateOrAny) => state?.auth);
  const clinic = useSelector((state: RootStateOrAny) => state?.clinic?.details);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [patientsData, setPatientsData] = useState<IPatientDetail[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLElement | null>(null);
  const [selectedDoctor, setSelectedDoctor] = useState<any>("");
  const [consultType, setConsultType] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [timeSlotList, setTimeSlotList] = useState<any>([]);
  const [specialityId, setSpecialityId] = useState<string>("");
  const [selectedPatient, setSelectedPatient] = useState<IPatientDetail | null>(null);
  const [searchdoctor, setsearchdoctor] = useState<any>('');
  const [DoctorPage, setDoctorPage] = useState<number>(1);
  const [practitionersList, setPractitionersList] = useState<any>([]);
  const [doctorData, setDoctorData] = useState<any>(null);
  const [searchCareProgram, setSearchCareProgram] = useState<any>("");
  const [careProgramPage, setCareProgramPage] = useState<any>(1);
  const [CareprogramList, setCareprogramList] = useState<any>([])
  const [GroupsList, setGroupsList] = useState<any>([]);
  const[clinicianList,setClinicianList] =useState<any>("")
  const [CareProgramSelected, setCareProgramSelected] = useState<any>({})
  const [groupPage, setGroupPage] = useState<any>(1);
  const [selectedGroup, setSelectedGroup] = useState<any>({});  
  //AS PER TASK-28769 SPECIALITY COMMENTED
  // const [specialitieslist, setspecialitieslist] = useState<any>([]);
  // const [specialitiesPage, setSpecialitiesPage] = useState<number>(1);
  // const [searchSpeciality, setsearchSpeciality] = useState('');

  const appointmentAvailableRoles =['UNIFIED_APP_DOCTOR', 'UNIFIED_APP_WELLNESS_NUTRITIONIST', 'UNIFIED_APP_WELLNESS_COUNSELOR','UNIFIED_APP_WELLNESS_COACH']
  const ROLES =[{id:"UNIFIED_APP_DOCTOR", name:"Doctor"}, {id:"UNIFIED_APP_WELLNESS_NUTRITIONIST", name:"Wellness Nutritionist"}, {id:"UNIFIED_APP_WELLNESS_COACH", name:"Wellness Coach"}, {id:"UNIFIED_APP_WELLNESS_COUNSELOR", name:"Wellness Counsellor"}]

  const { data: facilityUsersList, isFetching: facilityUsersListFetching,refetch: refetchDetail } = useQuery(
    [search, rowsPerPage, 0, rowsPerPage, tablePage, CareProgramSelected?._id?CareProgramSelected?._id:"", selectedGroup?._id?selectedGroup?._id:""],
    getUserDetailBySearch,
    { enabled: search !== "" }
  );

  const { data: careProgramList ,isLoading:careProgramLoading} = useQuery(
    ["care-programs", clinic.id, '', searchCareProgram, careProgramPage, 10, '', "active"],
    getAllCareProgramByClinicApi, {enabled:userRole !== USER_ROLES?.receptionist}
  );
useEffect(()=>{
  if(careProgramList?.data?.length>0){
    setCareprogramList(careProgramList?.data)
  }
},[careProgramList, careProgramPage])

  const SearchCarePrograms = async (vel:any) =>{
    setSearchCareProgram(vel)
    if(vel == '') setGroupsList([])
  }
  const { data: Groups, isLoading:groupLoading} = useQuery(
    ['GetGroupByCareProgramId',
    {
      id:CareProgramSelected?._id,
      userId: "",
      page: groupPage,
      limit:rowsPerPage
    }
  ], 
  GetGroupByCareProgramId);

  useEffect(()=>{
    if (Groups && Groups.status < 350) {
      let grpList =[...GroupsList, ...Groups?.data]
    const unique = [...new Map(grpList.map((m:any) => [m._id, m])).values()];
      setGroupsList(unique)
    }
  },[Groups, groupPage])

  const { data: consultationTypeList = [] } = useQuery(
    ["consultationTypeByFacility",
      (appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP") ? detailsData?.facilityId : facilityId,
      selectedPatient],
    consultationTypeByFacility,
    { enabled: selectedPatient !== null || appointmentPayload.state === "RESCHEDULE" }
  );

  //AS PER TASK-28769 SPECIALITY COMMENTED
  // const { data: specialities = [] } = useQuery(
  //   [10, specialitiesPage, searchSpeciality],
  //   getAllSpeciality
  // );
  // useEffect(() => {
  //   if (specialities.length > 0) {
  //     specialities.map((data:any)=>{
  //       data.name = data?.providerSpeciality
  //      })
  //     if (specialitiesPage === 1) {
  //       setspecialitieslist([...specialities]);
  //     }
  //     else {
  //       setspecialitieslist([...specialitieslist, ...specialities]);
  //     }
  //   }
  // }, [specialities])

  useEffect(() => {
    if (facilityUsersList) {
      if (tablePage === 1) {
        setPatientsData([...facilityUsersList.data]);
      } else {
        setPatientsData([...patientsData, ...facilityUsersList.data]);
      }
    }
  }, [facilityUsersList]);

  const { data: consultationReason = [], isFetching: consultationReasonFetching } = useQuery(
    [25, 1, 0],
    getSymptoms
  );

  const { data: policydetail, isFetching: policydetailfetching } = useQuery(
    [selectedPatient?.mobile],
    getpolicydetails,
    { enabled: (selectedPatient !== null && appointmentPayload.patientType === "policyHolder") }
  );

  useEffect(() => {
    setSelectedDate(null);
    setValue("appointmentTime", "");
    setValue("searchPatient", "")
    setSelectedPatient(null);
    if(isInstant){
      setAppointmentPayload({ ...appointmentPayload, patientType: "nonPolicyHolder", user: userData?._id });
      setSelectedPatient({ ...userData });
      setValue("patientSearch", userData?.userName)
    } else {
      setAppointmentPayload({ ...appointmentPayload, patientType: "nonPolicyHolder" });
    }
  }, [])

  const { data: newPractitioner = [], isFetching: fetchingPractitioner } = useQuery(["GET-ALL-PRACTITIONERS",
  { search: searchdoctor, pageSize: 10, pageNumber: DoctorPage, active: "true",rolesGroup:[], rolesArray:[appointmentPayload?.roleId], facilityId: clinic.id, consultationType:consultType, sortBy:"name", orderBy:"asc", careProgramId:CareProgramSelected?._id?CareProgramSelected?._id:"", groupId:selectedGroup?._id?selectedGroup?._id:""}],
  getProviderPractitionerNames, {enabled:selectedRole?.id && selectedDoctor==="" });

  const { data: timeSlots, isFetching: timeSlotFetching } = useQuery(
    ["getSlotList",
      {
        facilityId: facilityId,
        practitionerId: (appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP")?appointmentPayload?.practitioner?._id?appointmentPayload?.practitioner?._id:appointmentPayload?.practitioner :selectedDoctor?.id,
        roleId:appointmentPayload?.roleId? appointmentPayload?.roleId:detailsData?.facilityPractitioner?.practitionerRole,
        consultType: consultType,
        primaryUserId: selectedPatient?._id ?? appointmentPayload.user,
        date: moment(selectedDate).format("YYYY/MM/DD"),
        "isTelemedicine":true,
      }
    ],
    getSlotList,
    { enabled: selectedDoctor && selectedDate && openFixAppointment }
  );

  useEffect(() => {
    if (newPractitioner && newPractitioner.length > 0) {
      let list: any = []
      newPractitioner.forEach((data: any) => {
        list.push({ id: data?.Practitioners?._id, name: `${data?.Practitioners?.name?.prefix} ${data?.Practitioners?.name?.first} ${data?.Practitioners?.name?.last}` })
      });
      if (appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP") {
        if (list && userRole !== "UNIFIED_APP_DOCTOR") {
          list?.forEach((data: any) => {
            if (data?.id === detailsData?.practitioner?._id) {
              setSelectedDoctor(data);
            }
          })
        } else if (userRole === "UNIFIED_APP_DOCTOR") {
          setSelectedDoctor(user);
        }
      }
      if(searchdoctor || DoctorPage === 1){
        setPractitionersList(list)
      }else{
      setPractitionersList([...practitionersList, ...list])
      }
    }
  }, [newPractitioner])

  useEffect(() => {
    if (appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP") {
      setValue('appointmentType', detailsData?.consultationType)
      setConsultType(appointmentPayload.state === "FOLLOWUP" ? "VIRTUAL_CONSULTATION" :detailsData?.consultationType)
      setSpecialityId(detailsData?.specialityId?._id);
      if (practitionersList && userRole !== "UNIFIED_APP_DOCTOR") {
        const data = {id: detailsData?.practitioner._id ,name: `Dr.${detailsData?.practitioner?.name?.first} ${detailsData?.practitioner?.name?.last}`}
        setDoctorData(data);
        setSelectedDoctor(data);
      } else if (userRole === "UNIFIED_APP_DOCTOR") {
        setSelectedDoctor(user);
      }

    }
    if (consultationTypeList && consultationTypeList?.consultationType?.length === 1) {
      setValue("appointmentType", consultationTypeList.consultationType[0])
      setConsultType(consultationTypeList.consultationType[0]);
      setAppointmentPayload({ ...appointmentPayload, consultationType: consultationTypeList.consultationType[0] });
    }
   
  }, [consultationTypeList])

  useEffect(()=>{
    const uniqueClinicianNames = [...new Map(practitionersList?.map((m) => [m.id, m])).values()];
    setClinicianList(uniqueClinicianNames)
  },[ practitionersList])

  useEffect(() => {
    if (specialityId) {
      setAppointmentPayload({
        ...appointmentPayload,
        specialityId: specialityId,
      });
    }
  }, [specialityId])

  useEffect(() => {
    if (timeSlots) {
      const{Morning, AfterNoon, Evening, Night}=timeSlots || {}
      let slotMap: any = [...Morning, ...AfterNoon, ...Evening, ...Night];

      const timeConvertion =slotMap?.map((x:any)=>({
          slotId: x?.slotId,
          booked: x?.isBooked,
          isExpired : x?.isExpired, 
          time: moment(x?.time, "HH:mm").format("hh:mm a")
      }))

      setAppointmentPayload({ ...appointmentPayload, consultationFee: timeSlots?.Amount });
      setTimeSlotList(timeConvertion)
      setConsultationFee(timeSlots?.Amount)
    }
  }, [timeSlots]);

  const handleClose = () => {
    anchorEl && setAnchorEl(null);
  };

  const handlePolicyClose = () => {
    anchorEl2 && setAnchorEl2(null);
  }

  const renderPatients = (patients: IPatientDetail[], controlProps: ControllerRenderProps<Record<string, any>>) => {
    let _patients = patients.map((patient: IPatientDetail) => {
      let user: any = {
        patientDetail: patient,
        firstName: patient?.name?.first,
        lastName: patient?.name?.last,
        gender: patient?.gender || "male",
        mobileNumber: patient?.mobile || "",
        patientId: patient?._id,
      };
      return user;
    });

    return (
      <PatientListing
        id={"participant-List"}
        anchorEl={anchorEl}
        handleClose={handleClose}
        patientList={_patients || []}
        facilityUsersListFetching={facilityUsersListFetching}
        controlProps={controlProps}
        setSelectedPatient={setSelectedPatient}
        setIsDisabled={setIsDisabled}
        appointmentPayload={appointmentPayload}
        setAppointmentPayload={setAppointmentPayload}
        tablePage={tablePage}
        setTablePage={setTablePage}
        hasNext={facilityUsersList?.hasNextPage}
      />
    );
  };

  //THIS FEATURE WILL BE ENNABLED IN THE FUTURE
  // const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(e.target.checked);
  //   delete appointmentPayload.isFamily;
  //   delete appointmentPayload.familyMember;
  // };

  //THIS FEATURE WILL BE ENNABLED IN THE FUTURE
  // const addNewPatient = async () => {
  //   const values = getValues(["firstName", "lastName", "gender", "phone"]);
  //   if (Object.values(values).every((value) => value)) {
  //     // setLoading(true);
  //     setRequest({ message: "", loading: false, severity: "" });
  //     if (values.phone) {
  //        const { countryCallingCode, nationalNumber }: any = parsePhoneNumber(values.phone);
  //       // const mobile = `${nationalNumber}`
  //       // const patient = await getPatientByPhoneApi(countryCallingCode, mobile)
  //       //   .then((res: any) => res)
  //       //   .catch((e: { response: any }) => e.response);
  //       // if (patient && patient?.status === 200) {
  //       //   setError("phone", { message: "This mobile number already exists" });
  //       //   setLoading(false);
  //       // } else {
  //         const payload = {
  //           countryCode: countryCallingCode,
  //           mobile: nationalNumber,
  //           name: {
  //             first: values.firstName,
  //             last: values.lastName,
  //           },
  //           gender: values.gender,
  //         };
  //         try {
  //           const createdPatient = await createPatientApi(payload);
  //           if (createdPatient && createdPatient.status === 201) {
  //             setRequest({ message: "Patient added successfully", loading: false, severity: "success" });
  //             // setLoading(false);
  //             setSelectedPatient(createdPatient.data);
  //             setValue("searchPatient", createdPatient.data.name.first + " " + createdPatient.data.name.last);
  //             setAppointmentPayload({ ...appointmentPayload, user: createdPatient.data._id, mobile: createdPatient.data?.mobile });
  //             setIsDisabled(false);
  //             // setIsAddPatient(false);
  //             if(createdPatient?.data?._id && createdPatient?.data?.mobile){
  //             try{await getPolicy(createdPatient?.data?._id,createdPatient?.data?.mobile)}
  //             catch(e){}
  //             }
  //           }
  //         } catch (error) {
  //           setRequest({
  //             message: error.response.data.message || "Something went wrong",
  //             loading: false,
  //             severity: "error",
  //           });
  //           // setLoading(false);
  //         }
  //      // }
  //     }
  //   } else {
  //     trigger();
  //   }
  // };
  // will be enabled in next phase
  // const getBalence = (consultationFee:any, walletBalance:any) => {
  //   return consultationFee > walletBalance ? consultationFee - walletBalance : 0;
  // } 

  // const getWalletAmount = (consultationFee:any, walletBalance:any) => {
  //   return walletBalance > consultationFee ? consultationFee : walletBalance
  // }

  return (
    <Grid container xs={12} className={classes.root} direction={"column"}>
      {bookingState === "NEW" && (
        <Grid
          item
          container
          xs={12}
          direction={"column"}
          className={classes.patientType}
        >
          {/* THIS FEATURE WILL BE ENNABLED IN THE FUTURE */}
          {/* <Typography variant={"body2"} className={classes.title}>
            {"Patient Type"}
          </Typography> */}
          {/* <Grid item xs={12}>
            <Controller
              name={"patientType"}
              control={control}
              render={(controlProps) => {
                return (
                  <RadioGroup
                    row
                    name={"patientTypeChooser"}
                    onChange={(e) => {
                      controlProps.onChange(e.target.value);
                      setAppointmentPayload({ ...initialPayload, patientType: e.target.value });
                      setSelectedDate(null);
                      setValue("appointmentTime", "");
                      setValue("searchPatient", "")
                      setSelectedPatient(null);
                      setIsAddPatient(false);
                      setConsultType("")
                    }}
                  >
                    <Radio value={"policyHolder"} hidden id={"policyHolder"} />
                    <label
                      htmlFor={"policyHolder"}
                      className={clsx(classes.patientTypeChoice, appointmentPayload.patientType === "policyHolder" ? "active" : "")}
                    >
                      <Typography variant={"body2"} style={{fontSize: "13px"}}>{"Policy Holder"}</Typography>
                    </label>
                    <Radio value={"nonPolicyHolder"} hidden id={"nonPolicyHolder"} />
                    <label
                      htmlFor={"nonPolicyHolder"}
                      className={clsx(
                        classes.patientTypeChoice,
                        appointmentPayload.patientType === "nonPolicyHolder" ? "active" : ""
                      )}
                    >
                      <Typography variant={"body2"} style={{fontSize: "13px"}}>{"Non Policy Holder"}</Typography>
                    </label>
                  </RadioGroup>
                );
              }}
            />
          </Grid> */}
        </Grid>
      )}
      {/* THIS FEATURE WILL BE ENNABLED IN THE FUTURE */}
      {/* {isAddPatient && (
        <Grid item container xs={12} className={clsx(classes.addPatientInformation, !isAddPatient ? "hidden" : "")}>
          <Grid item container xs={12} justifyContent="space-between">
            <Typography variant="body2" className={classes.title}>
              {"Add Patient"}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" className={classes.createPatient}>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonTextField
                      label="First Name"
                      value={controlProps.value}
                      onChange={(e: { target: { value: any } }) => {
                        controlProps.onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
              <CustomFormEM name={"firstName"} errors={errors} render={(message: string) => <p>{message}</p>} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonTextField
                      label="Last Name"
                      value={controlProps.value}
                      onChange={(e: { target: { value: any } }) => {
                        controlProps.onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
              <CustomFormEM name={"lastName"} errors={errors} render={(message: string) => <p>{message}</p>} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="phone"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonMobileInput
                      placeholder={"Mobile Number"}
                      displayInitialValueAsLocalNumber={true}
                      defaultCountry="IN"
                      value={controlProps.value}
                      onChange={(e: any) => {
                        controlProps.onChange(e);
                      }}
                    />
                  );
                }}
              />
              <CustomFormEM name={"phone"} errors={errors} render={(message: string) => <p>{message}</p>} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="gender"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonDropDown
                      placeholder="Gender"
                      className={classes.dropDown}
                      onChange={(e: any) => {
                        controlProps.onChange(e.target.value);
                      }}
                      value={controlProps.value}
                    >
                      <CommonMenuItem value="male">Male</CommonMenuItem>
                      <CommonMenuItem value="female">Female</CommonMenuItem>
                      <CommonMenuItem value="other">Other</CommonMenuItem>
                    </CommonDropDown>
                  );
                }}
              />
              <CustomFormEM name={"gender"} errors={errors} render={(message: string) => <p>{message}</p>} />
            </Grid>
          </Grid>
          <Grid item container xs={12} direction={"row-reverse"}>
            <CustomSubmitButton className={"undefined"} onClick={addNewPatient} isLoading={loading}>
              {"Register New Patient"}
            </CustomSubmitButton>
            &nbsp;&nbsp;
            <CustomCancelButton
              onClick={() => {
                setIsAddPatient(false);
                reset({ firstName: "", lastName: "", gender: "", phone: "" });
              }}
            >
              {"Cancel"}
            </CustomCancelButton>
          </Grid>
        </Grid>
      )} */}
      {
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          style={{ marginBottom: "12px" }}
          className={
            bookingState !==
            "NEW"
              ? "hidden"
              : ""
          }
        >
          <Grid item container xs={12} className={classes.searchPatient}>
            <Grid item xs={6}>
              <Grid container xs={12} justifyContent="space-between">
                <Typography variant={"body2"} className={classes.title}>
                  {appointmentPayload.patientType === "policyHolder"
                    ? "Search Policy"
                    : "Customer Information"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {appointmentAvailableRoles.includes(userRole) && 
          <Grid item container xl={12} className={classes.consultInformation}>
          <Grid item container xs={12} style={{ gap: "12px", marginBottom:"0.5rem"}}>
            <Grid item xs={6}>
              <Controller
                name="careProgram" 
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonSearchMultiselect
                      className={classes.specialitiesTypes}
                      options={CareprogramList || []}
                      loading={careProgramLoading}
                      getOptionLabel={(option: any) => option.name}
                      value={controlProps?.value}
                      variant="filled"
                      label={"Select Program"}
                      onChange={(e: any, value: any) => {
                        if (value === null) {
                          setCareProgramSelected('') 
                          controlProps.onChange("");
                            setAppointmentPayload({
                              ...appointmentPayload,
                              careProgramId: "",
                              consultationType: "",
                              scheduledAt: "",
                              roleId: "",
                            })
                            setGroupsList([]);
                            setCareProgramPage(1); 
                            setAnchorEl(null)
                            setValue("searchPatient", undefined);
                            setSelectedPatient(null);
                            setSelectedRole({})
                            setSelectedGroup({})
                            setConsultType("")
                            setGroupPage(1)
                            setValue("groups", null)
                        } else if(JSON.stringify(CareProgramSelected) !== JSON.stringify(value)) {
                          setCareProgramSelected(value)
                          setAnchorEl(null)
                          setSelectedRole({})
                          setSelectedGroup({})
                          setGroupsList([]);
                          setGroupPage(1)
                          setValue("groups", null)
                          setValue("searchPatient", undefined);
                            setSelectedPatient(null);
                            setAppointmentPayload({
                              ...appointmentPayload,
                              consultationType: "",
                              careProgramId: value?._id,
                            })
                          controlProps.onChange(value);
                        }
                      }}
                      onInputChange={(e:any, vel:any) => {
                       SearchCarePrograms(vel)
                      }}
                      ListboxProps={{
                        onScroll: (event:any) => {
                          const listboxNode = event.currentTarget;
                          if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1) {
                            setCareProgramPage(careProgramPage + 1)
                          }
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>
            {CareProgramSelected?._id && 
            <Grid item xs={6}>
              <Controller
                name="groups"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonSearchMultiselect
                      className={classes.specialitiesTypes}
                      options={GroupsList || []}
                      loading={groupLoading}
                      getOptionLabel={(option: any) => option.name}
                      value={controlProps?.value}
                      variant="filled"
                      label={"Select Group"}
                      onChange={(e: any, value: any) => {
                        if (value === null) {
                          setSelectedGroup({})
                          controlProps.onChange(value);
                          setAppointmentPayload({
                            ...appointmentPayload,
                            consultationType: "",
                            groupId: "",
                          })
                          setConsultType("");
                          setSelectedRole({})
                          setSelectedDoctor({})
                          setSelectedPatient(null);
                          setGroupPage(1)
                        } else {
                          setValue("searchPatient", "")
                          setSearch("")
                          setSelectedPatient(null);
                          setSelectedRole({})
                          controlProps.onChange(value);
                          setSelectedGroup(value)
                          setAppointmentPayload({
                            ...appointmentPayload,
                            consultationType: "",
                            groupId: value?._id,
                          })
                        }   
                        setConsultType("");                   
                      }}
                      ListboxProps={{
                        onScroll: (event:any) => {
                          const listboxNode = event.currentTarget;
                          if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1) {
                            setGroupPage(groupPage + 1)
                          }
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>}
          </Grid>
          </Grid>}
          {(selectedGroup?._id || userRole === USER_ROLES.receptionist) && 
          <Grid item container xs={12} className={classes.searchPatient}>
            <Grid item xs={6}>
              <Controller
                name="searchPatient"
                control={control}
                render={(controlProps) => {
                  return (
                    <>
                      <CommonTextFieldSearch
                        name={"patientSearch"}
                        placeholder={
                          appointmentPayload.patientType === "policyHolder"
                            ? "Search by policy or phone number"
                            : "Search by name or phone number"
                        }
                        disabled={isInstant}
                        inputProps={{
                          autoComplete: "off",
                        }}
                        label={
                          appointmentPayload.patientType === "policyHolder"
                            ? "Search Policy User"
                            : "Search Member"
                        }
                        variant="filled"
                        value={
                          isInstant ? userData?.userName : controlProps.value
                        }
                        onClick={() => {
                          if (search !== "") {
                            setAnchorEl(
                              document.getElementsByName("patientSearch")[0]
                                .parentElement
                            );
                            refetchDetail();
                            setTablePage(1);
                          }
                        }}
                        onChange={(e: any) => {
                          controlProps.onChange(e);
                          setAnchorEl(
                            document.getElementsByName("patientSearch")[0]
                              .parentElement
                          );
                          setSearch(e.trim());
                          setTablePage(1);
                          if (e === "") {
                            setIsDisabled(true);
                            setSelectedPatient(null);
                            setsearchdoctor("");
                            setValue("specialities", undefined);
                            setValue("doctor", undefined);
                            setValue("reasonForVisit", undefined);
                            setValue("appointmentDate", undefined);
                            setSelectedRole({})
                            setSelectedDoctor({})
                          }
                          else
                          {
                            if(CareProgramSelected?._id && selectedGroup?._id){
                              setAppointmentPayload({
                                ...initialPayload,
                                patientType: appointmentPayload.patientType,
                                groupId: selectedGroup?._id,
                                careProgramId: CareProgramSelected?._id,
                              });
                            }else{
                              setAppointmentPayload({
                                ...initialPayload,
                                patientType: appointmentPayload.patientType,
                              });
                            }
                          }
                            setConsultType("");
                        }}
                      />
                      {search !== "" &&
                        !selectedPatient &&
                        renderPatients(patientsData, controlProps)}
                    </>
                  );
                }}
              />
            </Grid>
            {selectedPatient &&
              appointmentPayload.patientType === "policyHolder" && (
                <Grid item xs={6}>
                  <Typography variant={"body2"} className={classes.title}>
                    {"Policy & Patient Information"}
                  </Typography>
                  <Controller
                    name={"policyInfo"}
                    control={control}
                    render={(controlProps) => {
                      return (
                        <>
                          <CommonTextField
                            name={"policyInfo"}
                            label={"Select Policy & Patient"}
                            placeholder={"Select Policy & Patient"}
                            onClick={(e: any) => {
                              setAnchorEl2(
                                document.getElementsByName("policyInfo")[0]
                                  .parentElement
                              );
                            }}
                            InputProps={{
                              readOnly: true,
                              endAdornment: (
                                <IconButton
                                  onClick={(e: any) =>
                                    setAnchorEl2(
                                      document.getElementsByName(
                                        "policyInfo"
                                      )[0].parentElement
                                    )
                                  }
                                >
                                  {!anchorEl2 ? <ExpandMore /> : <ExpandLess />}
                                </IconButton>
                              ),
                            }}
                            value={controlProps.value || ""}
                          />
                          {selectedPatient && (
                            <PolicyListing
                              patientId={selectedPatient._id || ""}
                              policyLists={policydetail?.data || []}
                              policydetailfetching={policydetailfetching}
                              anchorEl={anchorEl2}
                              controlProps={controlProps}
                              handleClose={handleClose}
                              handlePolicyClose={handlePolicyClose}
                              appointmentPayload={appointmentPayload}
                              setAppointmentPayload={setAppointmentPayload}
                            />
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>
              )}
            {appointmentPayload.policyNumber !== "" &&
              appointmentPayload.walletBalence && (
                <Grid
                  item
                  container
                  xs={12}
                  className={classes.walletInformation}
                  wrap="nowrap"
                  alignItems="center"
                >
                  <Grid item>
                    <img src={Wallet} alt="" height={"24px"} />
                  </Grid>
                  <Grid
                    item
                    container
                    wrap="nowrap"
                    xs="auto"
                    alignItems="center"
                    justifyContent="space-between"
                    className="walletInfo"
                  >
                    <Grid>
                      <Typography variant="body2">
                        {"OPD Cashless Wallet Balance"}
                      </Typography>
                      <Typography className="walletNote">
                        {
                          "Note : Above wallet balance can be used for both proposer and dependents"
                        }
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="h6" className="walletBalance">
                        {`${appointmentPayload.walletBalence} ₹`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>}
        </Grid>
      }

      {(appointmentPayload.policyNumber !== "" ||
        (appointmentPayload.patientType !== "policyHolder" &&
          selectedPatient &&
          consultationTypeList?.consultationType?.length > 0) ||
        (bookingState !== "NEW" && bookingState !== "FOLLOW_UP")) && (
        <Grid item container xl={12} className={classes.appointmentType}>
          <Typography className={classes.title}>
            {"Consultation Type"}
          </Typography>
          <Grid item xs={12}>
            <Controller
              name="appointmentType"
              control={control}
              render={(controlProps) => {
                return (
                  <RadioGroup
                    row
                    name="ConsultTypeChoose"
                    onChange={(e) => {
                      controlProps.onChange(e.target.value);
                      setConsultType(e.target.value);
                      setSelectedDate(null);
                      setValue("appointmentTime", "");
                      setValue("specialities", "");
                      setValue("doctor", "");
                      setValue("reasonForVisit", undefined);
                      setValue("appointmentDate", undefined);
                      appointmentAvailableRoles.includes(userRole)
                        ? setAppointmentPayload({
                            ...appointmentPayload,
                            consultationType: e.target.value,
                            scheduledAt: "",
                          })
                        : setAppointmentPayload({
                            ...appointmentPayload,
                            consultationType: e.target.value,
                            scheduledAt: "",
                            practitioner: undefined,
                          });
                    }}
                  >
                    {consultationTypeList?.consultationType?.indexOf(
                      "VIRTUAL_CONSULTATION"
                    ) !== -1 && (
                      <Radio
                        value={consultationType.virtualConsultation}
                        hidden
                        disabled={
                          appointmentPayload.state === "RESCHEDULE" ||
                          appointmentPayload.state === "FOLLOWUP"
                        }
                        id={consultationType.virtualConsultation}
                        //disabled={isDisabled}
                      />
                    )}
                    {consultationTypeList?.consultationType?.indexOf(
                      "VIRTUAL_CONSULTATION"
                    ) !== -1 && (
                      <label
                        hidden={isDisabled}
                        htmlFor={consultationType.virtualConsultation}
                        className={clsx(
                          classes.appointmentTypeChoice,
                          consultType === consultationType.virtualConsultation
                            ? "active"
                            : ""
                        )}
                      >
                        <VirtualConsult />
                        <Typography variant="body2">
                          {"Virtual Consult"}
                        </Typography>
                      </label>
                    )}
                    {/* THIS FEATURE WILL BE ENNABLED IN THE FUTURE */}
                    {/* {consultationTypeList?.consultationType?.indexOf('PHYSICAL_CONSULTATION') !== -1 && <Radio
                        value={consultationType.physicalConsultation}
                        hidden
                        id={consultationType.physicalConsultation}
                        disabled={appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP"}
                      />}
                      {consultationTypeList?.consultationType?.indexOf('PHYSICAL_CONSULTATION') !== -1 && <label
                        hidden={isDisabled}
                        htmlFor={consultationType.physicalConsultation}
                        className={clsx(
                          classes.appointmentTypeChoice,
                          consultType === consultationType.physicalConsultation ? "active" : ""
                        )}
                      >
                        <PhysicalConsult />
                        <Typography variant="body2">{"Physical Consultation"}</Typography>
                      </label>} */}
                  </RadioGroup>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      {appointmentPayload.consultationType !== "" && (
        <Grid item container xl={12} className={classes.consultInformation}>
          <Grid xs={12}>
            {bookingState !== "FOLLOW_UP" && (
              <Typography className={classes.title}>
                {"Consult Information"}
              </Typography>
            )}
          </Grid>
          <Grid item container xs={12} style={{ gap: "12px" }}>
            {appointmentPayload.state !== "RESCHEDULE" &&
              appointmentPayload.state !== "FOLLOWUP" && (
                <Grid item xs={6}>
                  <Controller
                    name="roles"
                    control={control}
                    render={(controlProps) => {
                      return (
                        <CommonSearchMultiselect
                          key={appointmentPayload.consultationType}
                          className={classes.specialitiesTypes}
                          options={ROLES || []}
                          loading={false}
                          getOptionLabel={(option: any) => option.name}
                          value={controlProps?.value}
                          variant="filled"
                          label={"Select Role"}
                          onChange={(e: any, value: any) => {
                            if (value === null) {
                              controlProps.onChange("");
                              setSelectedRole({});
                              setAppointmentPayload({
                                ...appointmentPayload,
                                roleId: "",
                              });
                              setPractitionersList([]);
                              setsearchdoctor("");
                              setSelectedDoctor({})
                              setValue("doctor", null)
                              setTimeSlotList([]);
                              setSelectedDate(null);
                            } else {
                              controlProps.onChange(value);
                              if(value?.id!==selectedRole?.id){
                                setValue("doctor", null)
                                setsearchdoctor("");
                                setPractitionersList([]);
                                setSelectedDoctor("")
                                setTimeSlotList([]);
                              }                        
                              setSelectedRole(value);
                              setAppointmentPayload({
                                ...appointmentPayload,
                                roleId: value?.id,
                              });
                            }
                            setDoctorPage(1);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              )}
            {/* AS PER TASK-28769 SPECIALITY COMMENTED */}
            {/* {(appointmentPayload.state !== "RESCHEDULE" && appointmentPayload.state !== "FOLLOWUP") && <Grid item xs={6}>
                <Controller
                  name="specialities"
                  control={control}
                  render={(controlProps) => (
                    <CommonSearchMultiselect
                      key={appointmentPayload.consultationType}
                      disabled={isDisabled}
                      options={newSpeciality || []}
                      className={classes.specialitiesTypes}
                      variant="filled"
                      getOptionLabel={(option: any) => option.providerSpeciality}
                      label={"Select Specialty"}
                      value={controlProps.value}
                      onChange={(e: any, value: any) => {
                        if (value === null) {
                          controlProps.onChange(value);
                          setSpecialityId('');
                          setAppointmentPayload({
                            ...appointmentPayload,
                            specialityId: '',
                          });
                          setSpecialitiesPage(1)
                        }
                        else {
                          controlProps.onChange(value);
                          setSpecialityId(value?.id);
                          setAppointmentPayload({
                            ...appointmentPayload,
                            specialityId: value?.id,
                          });
                          setSpecialitiesPage(1)
                        }
                      }}
                      ListboxProps={{
                        style: { maxHeight: '300px' },
                        onScroll: (event) => {
                          const listboxNode = event.currentTarget;
                          if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1) {
                            setSpecialitiesPage(specialitiesPage + 1)
                          }
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        controlProps.onChange(newInputValue);
                        setsearchSpeciality(newInputValue);
                        !newInputValue && setSpecialitiesPage(1);
                      }}
                    />
                  )}
                />
              </Grid>} */}
            {appointmentPayload?.roleId !== "" && userRole !== USER_ROLES.doctor && (
              <Grid item xs={6}>
                <Controller
                  name={"doctor"}
                  control={control}
                  render={(controlProps) => {
                    return appointmentPayload?.state === "RESCHEDULE" ||
                      !selectedPatient ? (
                      <CommonTextField
                        value={doctorData?.name}
                        disabled={true}
                      />
                    ) : (
                      <CommonSearchMultiselect
                        key={appointmentPayload.consultationType}
                        className={classes.specialitiesTypes}
                        options={clinicianList || []}
                        loading={fetchingPractitioner}
                        getOptionLabel={(option: any) => option.name}
                        value={controlProps?.value}
                        variant="filled"
                        label={`Select Clinician`}
                        onChange={(e: any, value: any) => {
                          if (value === null) {
                            controlProps.onChange("");
                            setSelectedDoctor("");
                            setAppointmentPayload({
                              ...appointmentPayload,
                              practitioner: "",
                            });
                            setTimeSlotList([]);
                            setDoctorPage(1);
                          } else {
                            controlProps.onChange(value);
                            setSelectedDoctor(value);
                            setAppointmentPayload({
                              ...appointmentPayload,
                              practitioner: value?.id,
                            });
                          }
                        }}
                        ListboxProps={{
                          style: { maxHeight: "300px" },
                          onScroll: (event) => {
                            const listboxNode = event.currentTarget;
                            if (
                              listboxNode.scrollHeight -
                                listboxNode.scrollTop -
                                listboxNode.clientHeight <
                              1
                            ) {
                              setDoctorPage(DoctorPage + 1);
                            }
                          },
                        }}
                        onInputChange={(event, newInputValue) => {
                          setsearchdoctor(newInputValue);
                          setDoctorPage(1);
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            )}
          </Grid>

          {appointmentPayload?.roleId !== "" && (
            <>
              {bookingState !== "FOLLOW_UP" && (
                <Grid item xs={6}>
                  <Controller
                    name={
                      bookingState === "NEW"
                        ? "reasonForVisit"
                        : bookingState === "FOLLOW_UP"
                        ? "followupReason"
                        : "rescheduleReason"
                    }
                    control={control}
                    render={(controlProps) => {
                      if (
                        appointmentPayload.state === "RESCHEDULE" &&
                        !controlProps.value
                      ) {
                        controlProps.onChange(detailsData?.reason?.id);
                      }
                      return (
                        <CommonDropDown
                          className={
                            controlProps.value
                              ? classes.dropDownvalue
                              : classes.dropDown
                          }
                          label={
                            bookingState === "NEW"
                              ? "Reason for Visit"
                              : bookingState === "FOLLOW_UP"
                              ? "Reason for Followup"
                              : "Reason for Reschedule"
                          }
                          value={controlProps.value}
                          variant="filled"
                          disabled={isDisabled}
                          onChange={(e: { target: { value: any } }) => {
                            controlProps.onChange(e?.target?.value);
                            delete appointmentPayload.otherReason;
                            appointmentPayload?.state === "RESCHEDULE"
                              ? setAppointmentPayload({
                                  ...appointmentPayload,
                                  reason: e?.target?.value,
                                })
                              : setAppointmentPayload({
                                  ...appointmentPayload,
                                  reason: [e?.target?.value],
                                });
                          }}
                        >
                          {bookingState === "NEW" ? (
                            consultationReason?.data?.length > 0 ? (
                              consultationReason?.data?.map((reason: any) => {
                                return (
                                  <CommonMenuItem value={reason.id}>
                                    {reason.name}
                                  </CommonMenuItem>
                                );
                              })
                            ) : (
                              <CommonMenuItem disabled value={"na"}>
                                {consultationReasonFetching
                                  ? "Loading..."
                                  : "No Data Available"}
                              </CommonMenuItem>
                            )
                          ) : (
                            Reasons?.map((reason: any) => {
                              return (
                                <CommonMenuItem value={reason.id}>
                                  {reason.name}
                                </CommonMenuItem>
                              );
                            })
                          )}
                        </CommonDropDown>
                      );
                    }}
                  />
                  <CustomFormEM
                    name={
                      bookingState === "NEW"
                        ? "reasonForVisit"
                        : bookingState === "FOLLOW_UP"
                        ? "followupReason"
                        : "rescheduleReason"
                    }
                    errors={errors}
                    render={(message: string) => <p>{message}</p>}
                  />
                </Grid>
              )}
              {(appointmentPayload?.reason === "640ed1da1ef04d3bcf283447" ||
                appointmentPayload?.reason === "640ed0711ef04d3bcf283439" ||
                appointmentPayload?.reason === "640ed1361ef04d3bcf28343f" ||
                appointmentPayload?.reason === "640ed1741ef04d3bcf283440") &&
                bookingState !== "FOLLOW_UP" && (
                  <Grid item xs={6}>
                    <Controller
                      name="otherReason"
                      control={control}
                      render={(controlProps) => {
                        if (detailsData?.otherReason && !controlProps.value) {
                          controlProps.onChange(detailsData?.otherReason);
                        }
                        return (
                          <CommonTextField
                            label={"Other Reason"}
                            placeholder="Other Reason"
                            value={controlProps.value}
                            onChange={(e: any) => {
                              setAppointmentPayload({
                                ...appointmentPayload,
                                otherReason: e.target.value,
                              });
                              controlProps.onChange(e.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                )}
              <Grid item xs={6}>
                <Controller
                  name="appointmentDate"
                  control={control}
                  render={(controlProps) => {
                    return (
                      <CommonDatePicker
                        label={"Select Date"}
                        format="DD/MM/YYYY"
                        placeholder="Select Date"
                        className={classes.dates}
                        value={controlProps.value || null}
                        disablePast
                        maxDate={moment(
                          moment(new Date())
                            .add(30, "d")
                            .format("YYYY/MM/DD HH:mm:ss")
                        )}
                        disabled={isDisabled}
                        onChange={(e: any) => {
                          setSelectedDate(e);
                          setAppointmentPayload({
                            ...appointmentPayload,
                            scheduledAt: "",
                          });
                          setValue("appointmentTime", "");
                          controlProps.onChange(e);
                        }}
                        inputProps={{ readOnly: true }}
                      />
                    );
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
      {selectedDate &&
        appointmentPayload.consultationType !== "" &&
        appointmentPayload?.roleId !== "" && (
          <Grid item container xs={12} className={classes.availableTime}>
            <Typography className={classes.title}>
              {"Available Time Slots"}
            </Typography>
            <Grid item container xs={12}>
              <Controller
                name={"appointmentTime"}
                control={control}
                render={(controlProps) => {
                  return (
                    <RadioGroup
                      row
                      name="appointmentTime"
                      onChange={(e) => {
                        controlProps.onChange(e.target.value);
                        const splitValue = moment(e.target.value, "hh:mm a")
                          .format("HH:mm")
                          .split(":");
                        const { Morning, AfterNoon, Evening, Night } =
                          timeSlots || {};
                        let slotMap: any = [
                          ...Morning,
                          ...AfterNoon,
                          ...Evening,
                          ...Night,
                        ];
                        const slotTime = slotMap?.map(
                          (time: any) => time?.time
                        );
                        Object.entries(slotTime).forEach(
                          ([key, value]: any, index: any) => {
                            if (slotTime.indexOf( moment(e.target.value, "hh:mm a").format("HH:mm")) !== -1) {
                              const selectedTime =slotMap[slotTime.indexOf(moment(e.target.value,"hh:mm:a").format("HH:mm"))]
                              setAppointmentPayload({
                                ...appointmentPayload,
                                slotId: selectedTime?.slotId,
                                slotTime:
                                  +splitValue[0] * (60000 * 60) +
                                  +splitValue[1] * 60000,
                                scheduledAt:
                                  moment(selectedDate).format("YYYY/MM/DD"),
                              });
                            }
                          }
                        );
                      }}
                      value={controlProps.value}
                    >
                      {!timeSlotList && timeSlotFetching && (
                        <CircularProgress />
                      )}
                      {timeSlotList &&
                        !timeSlotFetching &&
                        timeSlotList?.map((key: any) => {
                          return (
                            <>
                              <Radio
                                value={key?.time}
                                hidden
                                id={key?.time}
                                disabled={
                                  isDisabled || key?.isExpired || key?.booked
                                }
                              />
                              <label
                                htmlFor={key?.time}
                                className={
                                  key?.isExpired || key?.booked
                                    ? clsx(classes.timeSlots, classes.TimeHide)
                                    : clsx(
                                        classes.timeSlots,
                                        controlProps.value === key?.time
                                          ? "active"
                                          : ""
                                      )
                                }
                              >
                                <Typography variant="body2">
                                  {key?.time}
                                </Typography>
                              </label>
                            </>
                          );
                        })}
                    </RadioGroup>
                  );
                }}
              />
              {timeSlotList?.filter((x: any) => x?.booked === false).length ===
                0 && (
                <Typography className={classes.noSlots}>
                  No slots available
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
        
      {/* THIS FEATURE WILL BE ENNABLED IN THE FUTURE */}
      {/* {appointmentPayload.scheduledAt !== "" && bookingState !== "reschedule" && (
        <>
          <Grid item container xs={12} className={classes.paymentInformation}>
            <Typography className={classes.title}>{"Payment Information"}</Typography>
            <Grid item container xs={12} className={"infoGrid"}>
              {appointmentPayload.patientType === "policyHolder" && (
                <Grid item xs={12}>
                  <Controller
                    name={"opdWalletBalance"}
                    control={control}
                    render={(controlProps) => {
                      return (
                        <>
                          <Checkbox
                            id="opdWalletBalance"
                            disableRipple
                            checked={checked}
                            onChange={(e:any)=>{
                              handleCheck(e)
                            }}
                            color="primary"
                          />
                          <label htmlFor="opdWalletBalance">{"Use OPD Wallet Balance"}</label>
                        </>
                      );
                    }}
                  />
                </Grid>
              )}
              <Grid item container xs={12} justifyContent={"space-between"} wrap={"nowrap"}>
                <Typography variant={"body2"}>{"Consultation Fee"}</Typography>
                <Typography variant={"body2"} align={"right"}>
                  {`${timeSlots?.Amount} ₹`}
                </Typography>
              </Grid>
              <Grid item container xs={12} justifyContent={"space-between"} wrap={"nowrap"}>
                <Typography variant={"body2"}>{appointmentPayload.patientType === "nonPolicyHolder" ? "Other Charges" : "Paid from Star Cashless OPD"}</Typography>
                <Typography variant={"body2"} align={"right"}>
                  {`-  ${appointmentPayload.patientType === "nonPolicyHolder" ? 0 : 
                          checked && appointmentPayload?.walletBalence ? getWalletAmount(timeSlots?.Amount, appointmentPayload?.walletBalence) : 0}  ₹`}
                </Typography>
              </Grid>
              <Grid item container xs={12} justifyContent={"space-between"} wrap={"nowrap"}>
                <Typography variant={"body2"} style={{ fontWeight: 600 }}>
                  {"Total Amount to Pay"}
                </Typography>
                <Typography variant={"body2"} align={"right"} style={{ fontWeight: 600 }}>
                {`${ appointmentPayload.patientType === "nonPolicyHolder" ? timeSlots?.Amount : 
                       checked && appointmentPayload?.walletBalence ? getBalence(timeSlots?.Amount, appointmentPayload?.walletBalence) : timeSlots?.Amount} ₹`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems={"center"}
            style={{ padding: "12px 16px", backgroundColor: "#F3F7FF", borderRadius: "6px", marginTop: '12px' }}
          >
            <Info color="primary" />
            &nbsp;&nbsp;
            <Typography variant={"body2"} style={{ fontSize: "11px", color: "#64707D" }}>
              {"Note : Consultation fee have to be paid at hospital after consultation"}
            </Typography>
          </Grid>
        </>
      )} */}
    </Grid>
  );
};

export default BookAppointment;
