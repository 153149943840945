import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: any) => ({
    root: {
        width: "100%",
        "& .MuiInputBase-root": {
            borderRadius: "4px",
            backgroundColor: '#fff',
            "&.Mui-disabled": {
                backgroundColor: '#fafafa',
            }
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: "unset",
            borderRadius: "4px"
        },
        "& fieldset":{
            borderRadius: "4px",
            border: '1px solid rgba(0, 0, 0, 0.23) !important',
            top: 0
        },
        " &:hover fieldset": {
            borderRadius: "4px",
            border: '1px solid #717171 !important',
            top: 0
        },
        '&.Mui-focused fieldset': {
            border: 'none !important'
        },
        "& .MuiOutlinedInput-root": {
            border: '1px solid transparent !important',
            '&.Mui-focused fieldset': {
                border: '1px solid #717171 !important',
            }
        },
        "& fieldset legend": {
            display: "none"
        },
        "& .MuiFilledInput-root": {
            backgroundColor: '#f5f5f5 !important',
            border: '1px solid transparent !important'
        },
        "& .MuiFilledInput-underline:before":{
            borderBottom: 'none !important'
        },
        "& .MuiFilledInput-underline:after":{
            borderBottom: 'none !important'
        },
        "&:hover .MuiFilledInput-underline": {
            border: '1px solid #717171 !important'
        },
        "& .MuiFilledInput-underline.Mui-focused": {
            border: '1px solid #717171 !important'
        },
        "& input": {
            borderRadius: "4px",
            background: '#f4f4f4'
        },
        "& .Mui-disabled":{
            opacity: '1'
        }
    },
    menuItem: {
        color: theme.palette.input.textMain,
        fontSize: 15,
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'space-between',
		
        "& .MuiSvgIcon-root":{
            color: '#35C5EA'
        },
        "&.Mui-selected": {
            backgroundColor: "#FFFFFF !important",
            "&.Mui-focusVisible": { background: "#FFFFFF !important" }
        }
    },
    placeholder: {
        fontWeight: 400,
        fontSize: 16,
        color: `${theme.palette.input.labelMain} !important`,
    },
    content:{
        width:'100%',
        display:'flex',
        alignItems:'center',
        gap:'10px'
    },
    withLabel: {
        "& .MuiSelect-select": {
            padding: "22px 15px 8px 15px",
            fontSize: "14px",
            fontWeight: "600",

        },
    },
    withoutLabel:{
        "& .MuiSelect-select": {
            padding: "15px",
            fontSize: "14px",
            fontWeight: "600",
            color:"#717171",

        },
    }
}))

export default styles;
