import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: any) => ({
    root: {
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "4px",
        "& label": {
            color: `${theme.palette.input.labelMain} !important`,
            transform: "translate(14px, 18px) scale(1)",
            "&.MuiInputLabel-shrink": {
                transform: "translate(14px, 6px) scale(0.75)"
            }
        },
        "& input": {
            padding: "18px !important",
            color: theme.palette.input.textMain,
            fontSize: "13px",
            fontWeight: "400",
            "&::placeholder": {
                ['@media(max-width:1366px)']: {
                    fontSize: 13
                }
            }
        },
        "& fieldset, &:hover fieldset": {
            borderRadius: "4px",
            borderColor: `#F4F4F4 !important`
        }
    }
}));

export default styles;