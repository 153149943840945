import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import Person from '../../../assets/images/Personlogo.png'
import AddUser from '../../../assets/images/wpf_add-user.png';
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
    buttonWrapper: {
        color: 'white',
        background: 'blue',
        width: '200px',
    },
    imageWrapper:{
        marginBottom: '5%',
        width:"7rem",
        height:"7rem",
        '& svg': {
            transform: 'scale(1)',
          },
    }
}));

const ReConnect = ({OpenDialog}: any) => {
    const { connecting } = useSelector((state : any)=> state?.instantVideo)
    const classes = useStyles();
    return (
    <Grid container spacing={0} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' ,height:'100%'}}>       
        {/* <CloseOutlinedIcon style={{position:'absolute',top:'50%',left:'50%'}}/>         */}
        <img src={Person}  className={classes.imageWrapper}/>
       {!connecting.show &&  <>
       <span style={{ color: 'white', marginBottom: '20px' }}>
            Unable to connect with the doctor
        </span>
        <span style={{ color: 'white', marginBottom: '5%' }}>
            Try adding another doctor
        </span>
        <span>
            <Button onClick={()=>{OpenDialog();}}startIcon={<img src={AddUser} height={'20px'} />} className={classes.buttonWrapper}>Add doctor</Button>
        </span> 
        </> }
        {connecting.show && <span style={{ color: 'white' }}>
            Connecting with {`Dr.${connecting?.name}`}
        </span>}
    </Grid>
);

}

export default ReConnect;