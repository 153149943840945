import { CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { uploadToS3 } from "../../../../utils/uploadToS3";
import { styles } from "./styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";
import {fileUploadCount} from "../../../../api/InstantVideo/Instant"

const useStyles = makeStyles(styles);
const FileUploadButton = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

 
  const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);
   
  const triggerFileSelect = () => {
    document.getElementById('file-input').click();
  };

  const handleFileUpload = async (e) => {
    if ( e.target.files.length < 6) {
      props.popMessage({
        loading: true,
        message: "",
        severity: "",
      });
      setLoading(true);
      const regex = /[~#%\&_{}+\|]|\\.\\.|^\\.|\\.$/;
      {
        const type = [
          "application/pdf",
          "image/jpeg",
          "image/png",
          ".csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];

        let validType = 1;
        let invalidCharlength = false ;
        const filesList =  e.target.files ;
        for ( let i =0 ; i < filesList.length ;i++)
        {
          if(!type.includes(filesList[i].type ))
          {
            validType = 0 ;
            break ;
          }
          else
          {
            const nameCharlength = filesList[i].name.split('.').slice(0,-1).join('.');
            if(nameCharlength.length > 100)
            {
              validType = 0 ;
              invalidCharlength = true ;
              break ;
            }
          }
        }

        if (validType === 1) {
          let isValidCount = 0;

          for (let i=0 ;i < filesList.length ;i++)
          {
            const fileSize = bytesToMegaBytes(filesList[i].size);
            if (fileSize < 5) {
              isValidCount++;
            } else {
              props.popMessage({
                loading: false,
                message: "File size more than 5 MB is not supported",
                severity: "",
              });
            }
          }
          const urlPath ={url:[],keypath:[]}
          if (e.target.files.length === isValidCount) {
                if(filesList.length + props.fileCount.current < 6){
                 let array = [] ;
                  for (let i=0 ;i < filesList.length ;i++)
                  {
                    try
                    {
                      const {url , keyPath} =  await uploadFilesToSErver(e.target.files[i]);
                      urlPath.url[i] = url;
                      urlPath.keypath[i] = keyPath;
                    }
                    catch (error)
                    {
                      props.popMessage({
                        loading: false,
                        message:error?.message || "Error Occured while uploading!",
                        severity: "error",
                      });
                      setLoading(false);
                      break;
                    }
                   
                  }
                }else{
                  props.popMessage({
                    loading: false,
                    message:`Already sent ${props.fileCount.current} files. Maximum file limit is 5.`,
                    severity: "",
                  });
              }
            props?.setUrlpath(urlPath);
          }
        }
        else
        {
          props.popMessage({
            loading: false,
            message: invalidCharlength ? "File name is too long" :"Upload a valid File type",
            severity: "error",
          });
        }
      }
    } else {
      props.popMessage({
        loading: false,
        message: "Upload Maximum only 5 files",
        severity: "success",
      });
    }

    setLoading(false);
  };
  const uploadFilesToSErver = async (files) => {
    const file = files;
    const { url, err, keyPath } = await uploadToS3(file, "CHAT", {
      key: "user_id",
      value:props?.user_id,
    });
    if (url) {
      props.popMessage({
        loading: false,
        message: "Uploaded successfuly",
        severity: "success",
      });
      return {url , keyPath}
    } else {
     const error = new Error();
     error.message = err?.response?.data?.message;
     throw error;
    }
  };
  const handleFileClick = (event) => {
  
    event.target.value = "";
    props.popMessage({
      loading: true,
      message: "",
      severity: "",
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      document.getElementById("attach-file").blur();
      props.handleKeyDown(e);
    }
  };
  return (
    <>
    <IconButton id="attach-file" disabled = { props?.fileCount?.current >= 5} className={classes.root} onClick={triggerFileSelect} onKeyDown={handleKeyDown}>     
      {loading &&
        <CircularProgress style={{ height: "24px", width: "24px" }} />
      }
       {!loading &&
        props.chatParticipantInfo.isGroup && !props.chatParticipantInfo.isActive 
        ? null :  <AddCircleIcon style={{ marginRight: "5px", marginTop: "5px", color: "deepskyblue" }} />
      } 
      
      <input disabled ={props.connected}
        type="file" id="file-input" accept="application/pdf,image/jpeg,image/png,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={handleFileUpload} onClick={handleFileClick} multiple/>
    </IconButton>
    </>
  );
};

export default FileUploadButton;