import Services from "../services";
import { AxiosInstance } from "axios";
import { CREATE_MEDICAL_HISTORY, GET_PAST_CONSULTATION, CREATE_CASESHEET ,MASTER_DATA_SERVICE, CALL_REASONS, MASTER_DATA_URL} from "../../config/url.config";
import { apiGetInvestigations, apiGetSymptoms, apiUpdateCaseSheet, CREATE_CONSULTATION, GET_JOIN_CONSULTATION, apiGetMedicalRecords } from "../../config/url.config";
import { CaseSheetPayload } from "../../models/consultation";
import { genericObj } from "../../models/common";

const instance: AxiosInstance = new Services().getServiceInstance();

export const createPrescription = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}`, payload);
  return data;
};
export const addDrug = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/Drug`, payload);
  return data;
};

export const getDataParameterList = async (key:string, payload:any) =>{
  const params= payload;
  const {data} = await instance.get(`${CREATE_MEDICAL_HISTORY}/dataparameter/GetDataParameterMasterValueByCode?`, {params});
  return data;
};

/* Insert User DataParameter*/
export const addMedicines = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_MEDICAL_HISTORY}/userdataparameter/InsertUserDataParameter`, payload);
  return data;
}; 

/*Get All User DataParameter */
export const getAllUserDataParameter = async (key: string, payload: any) =>{
  const {data} = await instance.post(`${CREATE_MEDICAL_HISTORY}/userdataparameter/GetUserDataParameterSelectedValue`, payload);
  return data;
}
export const deleteDrug = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/Drug/Delete`, payload);
  return data;
};
export const editDrug = async (key: string, payload: any) => {
  const { data } = await instance.patch(`${CREATE_CONSULTATION}/Drug`, payload);
  return data;
};
export const getAllPrescription = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/GetAllPrescription`, payload);
  return data;
};
export const generatePrescription = async (key: string, payload: any) => {
  const { data } = await instance.patch(`${CREATE_CONSULTATION}`, payload);
  return data;
};
export const getAddedDrugs = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/GetAddedDrugs`, payload);
  return data;
};

export const getPrescriptionById = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/GetPrescriptionById?data=${payload.id}`);
  return data;
};
export const endConsultation = async (key: string, payload: any) => {
  const { data } = await instance.patch(`${GET_PAST_CONSULTATION}/consultation/updateStateConsultation`, payload);
  return data;
};

export const endConsultationUpdate = async (key: string, payload: any) =>{
  const { data } = await instance.post(`${GET_PAST_CONSULTATION}/updateStatusForEndCall`, payload);
  return data;
}

export const endCallReasons = async (key: string, params: any) =>{
  const { data } = await instance.get(`${CALL_REASONS}/callStatus?`,{params});
  return data;
}

export const joinConsultation = async (bookedSlotId: string) => await instance.post(GET_JOIN_CONSULTATION, {bookedSlotId})

export const getPastConsultation = async (userId: string, PageNumber:number) => await instance.get(`${GET_PAST_CONSULTATION}/pastConsultations?userId=${userId}&pageNumber=${PageNumber}`)

export const fetchSymptoms = async (_: string, pageNumber: number, pageSize: number = 25, limit: number = 25, skip: number = 0) => await instance.get(apiGetSymptoms(pageNumber, pageSize, limit, skip))

export const fetchInvestigation = async (_: string, search: string = "") => await instance.get(apiGetInvestigations(search))

export const autoUpdateCaseSheet = async (caseSheetId: string, payload: CaseSheetPayload) => await instance.patch(apiUpdateCaseSheet(caseSheetId), payload)

export const fetchMedicalRecords = async (_: string, userId: string) => await instance.get(apiGetMedicalRecords(userId))

export const createCaseSheet = async (_: string, consultationId: string) => await instance.post(`${CREATE_CASESHEET}CreateCaseSheet?consultationId=${consultationId}`)

export const chatHistoryList = async (key:string,payload:genericObj) =>
{
  const {data} = await instance.get(`${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_CONTEXT_PATH}instantchat-service/api/v1/${process.env.REACT_APP_KEY_WORD}/getChatHistory?roomId=${payload?.roomId}&pageSize=${payload?.pageSize}&pageNumber=${payload?.pageNumber}&skip=${payload?.skip}&roleId=${payload?.roleId}`);
  return data;
}

export const chatRoomStatus = async (roomId : string) =>{
  const data = await instance.get(`${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_CONTEXT_PATH}instantchat-service/api/v1/${process.env.REACT_APP_KEY_WORD}/getConnectionStatus?roomId=${roomId}`);
  return data;
}

export const linkChatID = async (chatLogId:string,bookedId:string,) =>{
  const data = await instance.post(`${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_CONTEXT_PATH}video-service/api/v1/${process.env.REACT_APP_KEY_WORD}/connectionManager/linkChatId`,{chatLogId:chatLogId,bookedId:bookedId});
  return data;
}

export const linkCallID = async (callLogId:string,bookedId:string,) =>{
  const data = await instance.post(`${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_CONTEXT_PATH}video-service/api/v1/${process.env.REACT_APP_KEY_WORD}/connectionManager/linkCallId`,{callLogId:callLogId,bookedId:bookedId});
  return data;
}
export const getMedicalHistoryUser = async (key,payload) => {
  const {data} = await instance.post(`${MASTER_DATA_URL}/getUserMasterData`,payload);
  return data;
}

export const deleteMedicalHistory = async (key,payload) => {
  const data = await instance.delete(`${MASTER_DATA_URL}/deleteUserMasterData?userId=${payload?.userId}&code=${payload?.code}`);
  return data;
}
export const getMasterData = async (key,payload) => {
  const {data} = await instance.post(`${MASTER_DATA_SERVICE}/getMasterData`,payload);
  return data;
}
export const postMedicalHistory = async (key,payload) => {
  const data = await instance.post(`${MASTER_DATA_URL}/addUserMasterData`,payload);
  return data;
}
export const getDBSettings = async (key: string, payload: any) =>{
  const { data } = await instance.post(`${GET_PAST_CONSULTATION}/getDBSettings`, payload);
  return data;

}