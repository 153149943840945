import HomeInactiveIcon from "../assets/images/icons/home_inactive.svg";
import SlotMangement from "..//assets/images/icons/appointment_inactive.svg";
import AppointmentIcon from "..//assets/images/icons/ic_menu_appointment.svg";
import ic_doctor from "../assets/images/menu-drawer/ic_menu_doctor.svg"
import ic_admin from "../assets/images/menu-drawer/ic_menu_admin.svg"
import ic_home from "../assets/images/menu-drawer/ic_menu_home.svg"
import ic_others from "../assets/images/menu-drawer/ic_menu_others.svg"
import ic_facility from "../assets/images/menu-drawer/ic_menu_facility.svg"
import CareProgramInactiveIcon from "../assets/images/icons/cp_inactive.svg";
import EnrolmentRequestIcon from "../assets/images/menu-drawer/ic_enroll_active.svg";
import ic_receptionist from "../assets/images/menu-drawer/ic_menu_receptionist.svg"
import ic_profile from "../assets/images/menu-drawer/ic_menu_profile.svg"
import ic_users from "../assets/images/menu-drawer/ic_menu_users.svg"
import ic_appointments from "../assets/images/menu-drawer/ic_menu_appoinments.svg"
import ic_ePharma from "../assets/images/menu-drawer/ic_menu_e-pharma.svg"
import ic_diagonosis from "../assets/images/menu-drawer/ic_menu_diagonosis.svg"
import ic_consultation from "../assets/images/menu-drawer/ic_menu_consultation.svg"
import ic_scheduler from "../assets/images/menu-drawer/ic_menu_scheduler.svg"
import ic_dashboardActiveIcon from "../assets/images/icons/DashboardActive_BlueIcon.svg"
import ic_doctorActiveIcon from "../assets/images/icons/DoctorActive_BlueIcon.svg"
import ic_receptionistActiveIcon from  "../assets/images/icons/ReceptionistActive_BlueIcon.svg"
import ic_appointmentsActiveIcon from "../assets/images/icons/AppointmentActive_BlueIcon.svg"
import ic_slotActiveIcon from "../assets/images/icons/slotActive_BlueIcon.svg"
import ic_reportActiveIcon from "../assets/images/menu-drawer/ic_selected_report.svg"
import ic_reports from "../assets/images/menu-drawer/ic_menu_reports.svg"
import ic_hp_inactive from "../assets/images/menu-drawer/ic_healthprofiler_inactive.svg"
import ic_questions_inactive from "../assets/images/menu-drawer/ic_questions_inactive.svg"
import associationsIcon from "../assets/images/healthProfiler/associations_inactive.svg";
import questionLibIcon from "../assets/images/healthProfiler/question_library_inactive.svg";
import questionLibSetIcon from "../assets/images/healthProfiler/question_set_library_inactive.svg";
import tempLibIcon from "../assets/images/healthProfiler/template_library_inactive.svg";
import dataParamLibIcon from "../assets/images/healthProfiler/data_parameter_library_inactive.svg";
import HealthProfiler from "../assets/images/healthProfiler/ic_health_profiler_logo.svg";
import ic_questionset_inactive from "../assets/images/menu-drawer/ic_questioset_inactive.svg"
import ic_templates_inactive from "../assets/images/menu-drawer/ic_template_inactive.svg"
import ic_tags_inactive from "../assets/images/menu-drawer/ic_tags_inactive.svg"
import ic_dataparameter_inactive from "../assets/images/menu-drawer/ic_dataparameter_inactive.svg"
import ic_association_inactive from "../assets/images/menu-drawer/ic_association_inactive.svg"
import ic_careprogram_inactive from "../assets/images/menu-drawer/ic_cp_inactive.svg"
import ic_wp_inactive from "../assets/images/menu-drawer/ic_wp_inactive.svg"
import ic_groups_inactive from "../assets/images/menu-drawer/ic_groups_inactive.svg"
import ic_enrollment_inactive from "../assets/images/menu-drawer/ic_enrollment_inactive.svg"
import ic_groups_active from "../assets/images/menu-drawer/ic_groups_active.svg"
import ic_wp_active from "../assets/images/menu-drawer/ic_wp_active.svg"
import ic_chat_active from "../assets/images/menu-drawer/ic_chat_active.svg"
import ic_chat_inactive from "../assets/images/menu-drawer/ic_chat_inactive.svg"
import ic_lab_active from "../assets/images/menu-drawer/ic_lab_active.svg"
import ic_lab_inactive from "../assets/images/menu-drawer/ic_lab_inactive.svg"
import ic_users_active from "../assets/images/menu-drawer/ic_users_active.svg"
import ic_admin_active from "../assets/images/menu-drawer/ic_admin_active.svg"
import ic_admin_inactive from "../assets/images/menu-drawer/ic_admin_inactive.svg"
import ic_patinet_active from "../assets/images/menu-drawer/ic_patient_active.svg"
import ic_patient_inactive from "../assets/images/menu-drawer/ic_patient_inactive.svg"
import ic_users_inactive from "../assets/images/menu-drawer/ic_fuser.inactive.svg"
import ChatMessage from "../assets/images/icons/chat-message.svg";
import VectorInactiveIcon from "../assets/images/icons/BarosIcons/tag_icon_inactive.svg";
import ic_settings from "../assets/images/menu-drawer/ic_menu_settings.svg";
import ic_setting_active from "../assets/images/menu-drawer/ic-setting-active.svg";
import ic_patient from "../assets/images/ic_patient.svg";
import ic_patient_active from "../assets/images/ic_patient_active.svg";
import ic_ahp from "../assets/images/icons/ic_ahp.svg";
import ic_ahp_active from "../assets/images/icons/ic_ahp_active.svg";
const Member=process.env.REACT_APP_PATIENT

export const MENU_EMPANELMENT_ADMIN = [

  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img
          src={ic_home}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  // {
  //   label: "Prescription",
  //   url: "/Prescription",
  //   icon: (
  //     <>
  //       <img
  //         src={HomeInactiveIcon}
  //         className="inactive"
  //         style={{ width: "19px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // },
  // {
  //   label: "Lab Transcriber",
  //   url: "/labTranscriber",
  //   icon: (
  //     <>
  //       <img
  //         src={HomeInactiveIcon}
  //         className="inactive"
  //         style={{ width: "19px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // }, /* STAR FACILITY  IS COMMON * /
  // {
  //   label: "Facility Empanelment",
  //   url: "/Empanelment/facilityEmpanelment",
  //   icon: (
  //     <>
  //       <img
  //         src={ic_facility_emp}
  //         className="inactive"
  //         style={{ width: "19px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // }, 
  // {
  //   label: "Doctor Empanelment",
  //   url: "/Empanelment/DoctorEmpanelment",
  //   icon: (
  //     <>
  //       <img
  //         src={ic_doctor_emp}
  //         className="inactive"
  //         style={{ width: "15px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // }
  //Except dashboard all side menu items are temporarily removed for Star release
  /* {
    label: "Facility Empanelment",
    url: "/empanelment/facility",
    icon: (
      <>
        <img
          src={ClinicInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  }, {
    label: "Doctor Empanelment",
    url: "/empanelment/doctor",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Dashboard - Admin",
    url: "/admin/facility-detail",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Manage users",
    url: "/admin",
    disabled: true,
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: [
      {
        label: "Admins",
        url: "/admin/admins-list",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Doctors",
        url: "/admin/doctors-list",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "AHPs",
        url: "/admin/ahps-list",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
    ]
  }, */
 

  /*
  {
    label: "Dashboard",
    url: "/Empanelment/index",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
        </>
        ),
        submenu:[]
  },
  {
    label: "Facility Empanelment",
    url: "/Empanelment/facilityEmpanelment",
    icon: (
      <>
        <img src={FacilityIcon} className="inactive" />
      </>
    ),
    submenu: []
    },
  {
    label: "Doctor Empanelment",
    url: "/Empanelment/DoctorEmpanelment",
    icon: (
      <>
        <img src={DoctorIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  */
];

export const Operational_Receptionist_Login = [
  {
    label: "Dashboard",
    url: "/OperationalReceptionist/dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={ic_scheduler} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={ic_appointments} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "My Profile",
    url: "/newprofile/operational-recepptionist",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
]

export const Receptionist_Login = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="inactive" />
      </>
    ),
    submenu: [],
  },
  // {
  //   label: "Enrollment",
  //   url: "/enrollment/patients",
  //   icon: (
  //     <>
  //       <img src={ic_patient} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={ic_patient_active} className="active" />
  //     </>
  //   ),
  //   submenu: [],
  // },
  // {
  //   label: "Patients",
  //   url: "/ManageUsers/Patients",
  //   icon: (
  //     <>
  //       <img src={ic_patient_inactive} className="inactive" />
  //     </>
  //   ), 
  //   iconActive: (
  //     <>
  //       <img src={ic_patinet_active} className="active" />
  //     </>
  //   ),  
  //   submenu:[]
  // },
  // {
  //   label: "Groups",
  //   url: "/care-program/groups",
  //   icon: (
  //     <>
  //       <img src={ic_groups_inactive} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={ic_groups_active} className="active" />
  //     </>
  //   ),
  //   submenu:[]
  // },
  // {
  //   label: "Health Profiler",
  //   url: "/health-profiler",
  //   icon: (
  //     <>
  //       <img src={ic_hp_inactive} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={ic_groups_active} className="active" />
  //     </>
  //   ),
  //   submenu:[]
  // },
  //Commented as per role-base access
  // {
  //   label: "Health Profiler",
  //   url: "/health-profiler",
  //   icon: (
  //     <>
  //       <img src={ic_hp_inactive} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={HealthProfiler} className="active" />
  //     </>
  //   ),
  //   submenu: [
  //     {
  //       label: "Question Library",
  //       url: "/health-profiler/hp-questions",
  //       icon: (
  //         <>
  //           <img src={ic_questions_inactive} className="inactive"/>
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={questionLibIcon} className="active" />
  //         </>
  //       ),
  //     },
  //     {
  //       label: "Question Set Library",
  //       url: "/health-profiler/hp-question-set",
  //       icon: (
  //         <>
  //           <img src={ic_questionset_inactive} className="inactive" />
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={questionLibSetIcon} className="active" />
  //         </>
  //       ),
  //     },
  //     {
  //       label: "Templates Library",
  //       url: "/health-profiler/template",
  //       icon: (
  //         <>
  //           <img src={ic_templates_inactive} className="inactive" />
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={tempLibIcon} className="active" />
  //         </>
  //       ),
  //     },
  //     {
  //       label: "Data Parameter Library",
  //       url: "/health-profiler/data-master",
  //       icon: (
  //         <>
  //           <img src={ic_dataparameter_inactive} className="inactive" />
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={dataParamLibIcon} className="active" />
  //         </>
  //       ),
  //     },
  //     {
  //       label: "Associations",
  //       url: "/health-profiler/Associations",
  //       icon: (
  //         <>
  //           <img src={ic_association_inactive} className="inactive" />
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={associationsIcon} className="active" />
  //         </>
  //       ),
  //     } 
  //   ]
  // },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_appointmentsActiveIcon}  className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  // {
  //   label: "Chat",
  //   url: "/chats",
  //   icon: (
  //     <>
  //       <img src={ic_chat_inactive} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={ic_chat_active} className="active" />
  //     </>
  //   ),
  //   submenu: []
  // },
  // {
  //   label: "My Profile",
  //   url: "/newprofile/receptionist",
  //   icon: (
  //     <>
  //       <img src={ic_profile} className="inactive" />
  //     </>
  //   ),
  //   submenu: [],
  // },
]
export const MENU_SUPER_ADMIN = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Manage users",
    url: "/ManageUsers/",
    disabled:true,
    icon: (
      <>
        <img src={ic_users} className="inactive" />
      </>
    ),
    submenu: [
      {
        label: "Admins",
        url: "/ManageUsers/Admin",
        icon: (
          <>
            <img src={ic_admin} className="inactive" />
          </>
        ),  
      },
      {
        label: "Doctors",
        url: "/ManageUsers/Doctor",
        icon: (
          <>
            <img src={ic_doctor} className="inactive" />
          </>
        ),  
      },
      // commenting as per bug 19743
      // {
      //   label: "AHPs",
      //   url: "/ManageUsers/AHP",
      //   icon: (
      //     <>
      //       <img src={ic_ahp} className="inactive" />
      //     </>
      //   ),  
      // },
  //***--  Commenting this and will use in next phase --**//
      // {
      //   label: "Patients",
      //   url: "/ManageUsers/Patients",
      //   icon: (
      //     <>
      //       <img src={ic_patient} className="inactive" />
      //     </>
      //   ),  
      // },
      {
        label: "Others",
        url: "/ManageUsers/Others",
        icon: (
          <>
            <img src={ic_others} className="inactive" />
          </>
        ),  
      },
      
    ],
  }, 
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={ic_scheduler} className="inactive" />
      </>
    ),
    submenu: [],
  },/* STAR FACILITY  IS COMMON */
      // {
      //   label: "Facility Settings",
      //   url: "/newprofile/facility",
      //   icon: (
      //     <>
      //       <img src={ic_settings} className="inactive" />
      //     </>
      //   ),
      //   submenu: [],
      // },
  {
    label: "My Profile",
    url: "/newprofile/admin",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
];

export const MENU_WELLNESS_SUPPORT = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: `${Member}s`,
    url: "/ManageUsers/Patients",
    icon: (
      <>
        <img src={ic_patient_inactive} className="inactive" />
      </>
    ), 
    iconActive: (
      <>
        <img src={ic_patinet_active} className="active" />
      </>
    ),  
    submenu: [],
  },
  {
    label: "Program Management",
    url: "/care-program",  
    disabled:true,
    icon: (
      <>
        <img
          src={ic_careprogram_inactive}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    iconActive: (
      <>
        <img src={CareProgramInactiveIcon} className="active" />
      </>
    ),
    submenu: [
      // {
      //   label: "Programs",
      //     url: "/care-program/wellness-program",
      //     disabled:false,
      //     icon: (
      //     <>
      //       <img src={ic_wp_inactive} className="inactive" />
      //     </>
      //   ),
      //   iconActive: (
      //     <>
      //       <img src={ic_wp_active} className="active" />
      //     </>
      //   ),
      // },
      
      {
        label: "Groups",
        url: "/care-program/groups",
        icon: (
          <>
            <img src={ic_groups_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={ic_groups_active} className="active" />
          </>
        ),
      },
      {
        label: "Enrollment Requests",
        url: "/care-program/enrollment-request",
        icon: (
          <>
            <img src={ic_enrollment_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={EnrolmentRequestIcon} className="active" />
          </>
        ),
      },
    ]
  },
  {
    label: "Chat",
    url: "/chats",
    icon: (
      <>
        <img src={ic_chat_inactive} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_chat_active} className="active" />
      </>
    ),
    submenu: []
  },
]
export const MENU_FACILITY_ADMIN = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
//   {
//   label: "Enrollment",
//   url: "/enrollment/patients",
//   icon: (
//     <>
//       <img src={ic_patient} className="inactive" />
//     </>
//   ),
//   iconActive: (
//     <>
//       <img src={ic_patient_active} className="active" />
//     </>
//   ),
//   submenu: [],
// },
  {
    label: "Tags",
    url: "/tags",
    icon: (
      <>
        <img
          src={ic_tags_inactive}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    iconActive: (
      <>
        <img src={VectorInactiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Facility Users",
    url: "/ManageUsers/",
    disabled:true,
    icon: (
      <>
        <img src={ic_users_inactive} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_users_active} className="active" />
      </>
    ),
    
    submenu: [
      {
        label: "Admins",
        url: "/ManageUsers/Admin",
        icon: (
          <>
            <img src={ic_admin_inactive} className="inactive" />
          </>
        ), 
        iconActive: (
          <>
            <img src={ic_admin_active} className="active" />
          </>
        )  
      },
      {
        label: "Doctors",
        url: "/ManageUsers/Doctor",
        icon: (
          <>
            <img src={ic_doctor} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={ic_doctorActiveIcon} className="active" />
          </>
        )  
      },
      // commenting as per bug 19743
      {
        label: "AHPs",
        url: "/ManageUsers/AHP",
        icon: (
          <>
            <img src={ic_ahp} className="inactive" />
          </>
        ), 
        iconActive: (
          <>
            <img src={ic_ahp_active} className="active" />
          </>
        ),  
      },
   //***--  Commenting this and will use in next phase --**//
      {
        label: `${Member}s`,
        url: "/ManageUsers/Patients",
        icon: (
          <>
            <img src={ic_patient_inactive} className="inactive" />
          </>
        ), 
        iconActive: (
          <>
            <img src={ic_patinet_active} className="active" />
          </>
        ),  
      },
      {
        label: "Receptionist",
        url: "/ManageUsers/Others",
        icon: (
          <>
            <img src={ic_receptionist} className="inactive" />
          </>
        ), 
        iconActive: (
          <>
            <img src={ic_receptionistActiveIcon} className="active" />
          </>
        ), 
      },
    ],
  }, 
  {
    label: "Health Profiler",
    url: "/health-profiler",
    icon: (
      <>
        <img src={ic_hp_inactive} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={HealthProfiler} className="active" />
      </>
    ),
    submenu: [
      {
        label: "Question Library",
        url: "/health-profiler/hp-questions",
        icon: (
          <>
            <img src={ic_questions_inactive} className="inactive"/>
          </>
        ),
        iconActive: (
          <>
            <img src={questionLibIcon} className="active" />
          </>
        ),
      },
      {
        label: "Question Set Library",
        url: "/health-profiler/hp-question-set",
        icon: (
          <>
            <img src={ic_questionset_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={questionLibSetIcon} className="active" />
          </>
        ),
      },
      {
        label: "Templates Library",
        url: "/health-profiler/template",
        icon: (
          <>
            <img src={ic_templates_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={tempLibIcon} className="active" />
          </>
        ),
      },
      {
        label: "Data Parameter Library",
        url: "/health-profiler/data-master",
        icon: (
          <>
            <img src={ic_dataparameter_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={dataParamLibIcon} className="active" />
          </>
        ),
      },
      {
        label: "Associations",
        url: "/health-profiler/Associations",
        icon: (
          <>
            <img src={ic_association_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={associationsIcon} className="active" />
          </>
        ),
      } 
    ]
  },
  {
    label: "Program Management",
    url: "/care-program",  
    disabled:true,
    icon: (
      <>
        <img
          src={ic_careprogram_inactive}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    iconActive: (
      <>
        <img src={CareProgramInactiveIcon} className="active" />
      </>
    ),
    submenu: [
      {
        label: "Programs",
          url: "/care-program/wellness-program",
          disabled:false,
          icon: (
          <>
            <img src={ic_wp_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={ic_wp_active} className="active" />
          </>
        ),
      },
      
      {
        label: "Groups",
        url: "/care-program/groups",
        icon: (
          <>
            <img src={ic_groups_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={ic_groups_active} className="active" />
          </>
        ),
      },
      {
        label: "Enrollment Requests",
        url: "/care-program/enrollment-request",
        icon: (
          <>
            <img src={ic_enrollment_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={EnrolmentRequestIcon} className="active" />
          </>
        ),
      },
    ]
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon}  className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_appointmentsActiveIcon}  className="active" />
      </>
    ),
  submenu: []
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
   {
    label: "Facility Settings",
    url: "/newprofile/facility",
    icon: (
      <>
        <img src={ic_settings} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_setting_active} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Chat",
    url: "/chats",
    icon: (
      <>
        <img src={ic_chat_inactive} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_chat_active} className="active" />
      </>
    ),
    submenu: []
  },
  // {
  //   label: "Reports",
  //   url: "/Reports",
  //   icon: (
  //     <>
  //       <img src={ic_reports} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={ic_reportActiveIcon} className="active" />
  //     </>
  //   ),
  //   submenu: [],
  // },
  // {
  //   label: "Lab orders",
  //   url: "/lab-orders",
  //   icon: (
  //     <>
  //       <img src={ic_lab_inactive} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={ic_lab_active} className="active" />
  //     </>
  //   ),
  //   submenu: [],
  // },
   /* STAR FACILITY  IS COMMON */
  // {
  //   label: "Facility Settings",
  //   url: "/newprofile/facility",
  //   icon: (
  //     <>
  //       <img src={ic_settings} className="inactive" />
  //     </>
  //   ),
  //   submenu: [],
  // },
  // {
  //   label: "My Profile",
  //   url: "/newprofile/admin",
  //   icon: (
  //     <>
  //       <img src={ic_profile} className="inactive" />
  //     </>
  //   ),
  //   submenu: [],
  // },
];

export const MENU_FIELD_AGENT = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "My Profile",
    url: "/newprofile/admin",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
];

export const MENU_DOCTOR = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>     
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  // {
  //   label: "Enrollment",
  //   url: "/enrollment/patients",
  //   icon: (
  //     <>
  //       <img src={ic_patient} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={ic_patient_active} className="active" />
  //     </>
  //   ),
  //   submenu: [],
  // },
  // {
    // label: "Facility Users",
    // url: "/ManageUsers/",
    // disabled:true,
    // icon: (
    //   <>
    //     <img src={ic_users_inactive} className="inactive" />
    //   </>
    // ),
    // iconActive: (
    //   <>
    //     <img src={ic_users_active} className="active" />
    //   </>
    // ),
    
    // submenu: [
      // {
      //   label: "Admins",
      //   url: "/ManageUsers/Admin",
      //   icon: (
      //     <>
      //       <img src={ic_admin_inactive} className="inactive" />
      //     </>
      //   ), 
      //   iconActive: (
      //     <>
      //       <img src={ic_admin_active} className="active" />
      //     </>
      //   )  
      // },
      // {
      //   label: "Doctors",
      //   url: "/ManageUsers/Doctor",
      //   icon: (
      //     <>
      //       <img src={ic_doctor} className="inactive" />
      //     </>
      //   ),
      //   iconActive: (
      //     <>
      //       <img src={ic_doctorActiveIcon} className="active" />
      //     </>
      //   )  
      // },
      // commenting as per bug 19743
      // {
      //   label: "AHPs",
      //   url: "/ManageUsers/AHP",
      //   icon: (
      //     <>
      //       <img src={ic_ahp} className="inactive" />
      //     </>
      //   ),  
      // },
   //***--  Commenting this and will use in next phase --**//
      {
        label: `${Member}s`,
        url: "/ManageUsers/Patients",
        icon: (
          <>
            <img src={ic_patient_inactive} className="inactive" />
          </>
        ), 
        iconActive: (
          <>
            <img src={ic_patinet_active} className="active" />
          </>
        ), 
        submenu: [],
      },
      // {
      //   label: "Receptionist",
      //   url: "/ManageUsers/Others",
      //   icon: (
      //     <>
      //       <img src={ic_receptionist} className="inactive" />
      //     </>
      //   ), 
      //   iconActive: (
      //     <>
      //       <img src={ic_receptionistActiveIcon} className="active" />
      //     </>
      //   ), 
      // },
    // ],
  // }, 
  {
    label: "Program Management",
    url: "/care-program",  
    disabled:true,
    icon: (
      <>
        <img
          src={ic_careprogram_inactive}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    iconActive: (
      <>
        <img src={CareProgramInactiveIcon} className="active" />
      </>
    ),
    submenu: [
      // {
      //   label: "Programs",
      //     url: "/care-program/wellness-program",
      //     disabled:false,
      //     icon: (
      //     <>
      //       <img src={ic_wp_inactive} className="inactive" />
      //     </>
      //   ),
      //   iconActive: (
      //     <>
      //       <img src={ic_wp_active} className="active" />
      //     </>
      //   ),
      // },
      
      {
        label: "Groups",
        url: "/care-program/groups",
        icon: (
          <>
            <img src={ic_groups_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={ic_groups_active} className="active" />
          </>
        ),
      },
      {
        label: "Enrollment Requests",
        url: "/care-program/enrollment-request",
        icon: (
          <>
            <img src={ic_enrollment_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={EnrolmentRequestIcon} className="active" />
          </>
        ),
      },
    ]
  },
  //Commented as per role-base access
  // {
  //   label: "Health Profiler",
  //   url: "/health-profiler",
  //   icon: (
  //     <>
  //       <img src={ic_hp_inactive} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={HealthProfiler} className="active" />
  //     </>
  //   ),
  //   submenu: [
  //     {
  //       label: "Question Library",
  //       url: "/health-profiler/hp-questions",
  //       icon: (
  //         <>
  //           <img src={ic_questions_inactive} className="inactive"/>
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={questionLibIcon} className="active" />
  //         </>
  //       ),
  //     },
  //     {
  //       label: "Question Set Library",
  //       url: "/health-profiler/hp-question-set",
  //       icon: (
  //         <>
  //           <img src={ic_questionset_inactive} className="inactive" />
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={questionLibSetIcon} className="active" />
  //         </>
  //       ),
  //     },
  //     {
  //       label: "Templates Library",
  //       url: "/health-profiler/template",
  //       icon: (
  //         <>
  //           <img src={ic_templates_inactive} className="inactive" />
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={tempLibIcon} className="active" />
  //         </>
  //       ),
  //     },
  //     {
  //       label: "Data Parameter Library",
  //       url: "/health-profiler/data-master",
  //       icon: (
  //         <>
  //           <img src={ic_dataparameter_inactive} className="inactive" />
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={dataParamLibIcon} className="active" />
  //         </>
  //       ),
  //     },
  //     {
  //       label: "Associations",
  //       url: "/health-profiler/Associations",
  //       icon: (
  //         <>
  //           <img src={ic_association_inactive} className="inactive" />
  //         </>
  //       ),
  //       iconActive: (
  //         <>
  //           <img src={associationsIcon} className="active" />
  //         </>
  //       ),
  //     } 
  //   ]
  // },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_appointmentsActiveIcon}  className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Chat",
    url: "/chats",
    icon: (
      <>
        <img src={ic_chat_inactive} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_chat_active} className="active" />
      </>
    ),
    submenu: []
  },
  // {
  //   label: "My Profile",
  //   url: "/newprofile/doctor",
  //   icon: (
  //     <>
  //       <img src={ic_profile} className="inactive" />
  //     </>
  //   ),
  //   submenu: [],
  // },
];

export const MENU_AHP = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>     
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  // {
  //   label: "Enrollment",
  //   url: "/enrollment/patients",
  //   icon: (
  //     <>
  //       <img src={ic_patient} className="inactive" />
  //     </>
  //   ),
  //   iconActive: (
  //     <>
  //       <img src={ic_patient_active} className="active" />
  //     </>
  //   ),
  //   submenu: [],
  // },
  // {
    // label: "Facility Users",
    // url: "/ManageUsers/",
    // disabled:true,
    // icon: (
    //   <>
    //     <img src={ic_users_inactive} className="inactive" />
    //   </>
    // ),
    // iconActive: (
    //   <>
    //     <img src={ic_users_active} className="active" />
    //   </>
    // ),
    
    // submenu: [
      // {
      //   label: "Admins",
      //   url: "/ManageUsers/Admin",
      //   icon: (
      //     <>
      //       <img src={ic_admin_inactive} className="inactive" />
      //     </>
      //   ), 
      //   iconActive: (
      //     <>
      //       <img src={ic_admin_active} className="active" />
      //     </>
      //   )  
      // },
      // {
      //   label: "Doctors",
      //   url: "/ManageUsers/Doctor",
      //   icon: (
      //     <>
      //       <img src={ic_doctor} className="inactive" />
      //     </>
      //   ),
      //   iconActive: (
      //     <>
      //       <img src={ic_doctorActiveIcon} className="active" />
      //     </>
      //   )  
      // },
      // commenting as per bug 19743
      // {
      //   label: "AHPs",
      //   url: "/ManageUsers/AHP",
      //   icon: (
      //     <>
      //       <img src={ic_ahp} className="inactive" />
      //     </>
      //   ),  
      // },
   //***--  Commenting this and will use in next phase --**//
      {
        label: `${Member}s`,
        url: "/ManageUsers/Patients",
        icon: (
          <>
            <img src={ic_patient_inactive} className="inactive" />
          </>
        ), 
        iconActive: (
          <>
            <img src={ic_patinet_active} className="active" />
          </>
        ),  
        submenu: [],
      },
      // {
      //   label: "Receptionist",
      //   url: "/ManageUsers/Others",
      //   icon: (
      //     <>
      //       <img src={ic_receptionist} className="inactive" />
      //     </>
      //   ), 
      //   iconActive: (
      //     <>
      //       <img src={ic_receptionistActiveIcon} className="active" />
      //     </>
      //   ), 
      // },
    // ],
  // }, 
  {
    label: "Program Management",
    url: "/care-program",  
    disabled:true,
    icon: (
      <>
        <img
          src={ic_careprogram_inactive}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    iconActive: (
      <>
        <img src={CareProgramInactiveIcon} className="active" />
      </>
    ),
    submenu: [
      // {
      //   label: "Programs",
      //     url: "/care-program/wellness-program",
      //     disabled:false,
      //     icon: (
      //     <>
      //       <img src={ic_wp_inactive} className="inactive" />
      //     </>
      //   ),
      //   iconActive: (
      //     <>
      //       <img src={ic_wp_active} className="active" />
      //     </>
      //   ),
      // },
      
      {
        label: "Groups",
        url: "/care-program/groups",
        icon: (
          <>
            <img src={ic_groups_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={ic_groups_active} className="active" />
          </>
        ),
      },
      {
        label: "Enrollment Requests",
        url: "/care-program/enrollment-request",
        icon: (
          <>
            <img src={ic_enrollment_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={EnrolmentRequestIcon} className="active" />
          </>
        ),
      },
    ]
  },
  {
    label: "Health Profiler",
    url: "/health-profiler",
    icon: (
      <>
        <img src={ic_hp_inactive} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={HealthProfiler} className="active" />
      </>
    ),
    submenu: [
      {
        label: "Question Library",
        url: "/health-profiler/hp-questions",
        icon: (
          <>
            <img src={ic_questions_inactive} className="inactive"/>
          </>
        ),
        iconActive: (
          <>
            <img src={questionLibIcon} className="active" />
          </>
        ),
      },
      {
        label: "Question Set Library",
        url: "/health-profiler/hp-question-set",
        icon: (
          <>
            <img src={ic_questionset_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={questionLibSetIcon} className="active" />
          </>
        ),
      },
      {
        label: "Templates Library",
        url: "/health-profiler/template",
        icon: (
          <>
            <img src={ic_templates_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={tempLibIcon} className="active" />
          </>
        ),
      },
      {
        label: "Data Parameter Library",
        url: "/health-profiler/data-master",
        icon: (
          <>
            <img src={ic_dataparameter_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={dataParamLibIcon} className="active" />
          </>
        ),
      },
      {
        label: "Associations",
        url: "/health-profiler/Associations",
        icon: (
          <>
            <img src={ic_association_inactive} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={associationsIcon} className="active" />
          </>
        ),
      } 
    ]
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_appointmentsActiveIcon}  className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Chat",
    url: "/chats",
    icon: (
      <>
        <img src={ic_chat_inactive} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_chat_active} className="active" />
      </>
    ),
    submenu: []
  },
  // {
  //   label: "My Profile",
  //   url: "/newprofile/doctor",
  //   icon: (
  //     <>
  //       <img src={ic_profile} className="inactive" />
  //     </>
  //   ),
  //   submenu: [],
  // },
];

export const MENU_OPERATOR = [

  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Followup Requests",
    url: "/Followup_requests",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "My Profile",
    url: "/newprofile/doctor",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
]

/* Commenting this code and this will be used in next Phase */
export const TRANSCRIPTION_AGENT = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Lab Tests",
    url: "/labTranscriber",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  }
];

/* Commenting this code and this will be used in next Phase */
export const CLAIMS_PROCESS = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Consultation",
    url: "/claims/consultation",
    icon: (
      <>
        <img src={ic_consultation} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "E - Pharmacy",
    url: "/claims/epharmacylist",
    icon: (
      <>
        <img src={ic_ePharma} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Diagnostics",
    url: "/claims/diagnostics",
    icon: (
      <>
        <img src={ic_diagonosis} className="inactive" />
      </>
    ),
    submenu: []
  }
];
