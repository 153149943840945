import { ClickAwayListener, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CommonMenuItem from "../../../components/common/CommonDropDown/CommonMenuItem";
import Wallet from "../../../assets/images/icons/wallet_inactive.svg";
import styles from "./styles";
import { ControllerRenderProps } from "react-hook-form";
import CustomPopover from "../../../components/common/CustomPopover";
import { IAppointmentPayload, Irequest } from "../../../models/common";
import { getPolicyWalletBalance } from "../../../api/AppointmentManagement/Appointment";
import Notify from "../../../components/common/CommonToast";

export interface IPolicyListing {
  policyLists: IPolicyList[];
  controlProps: ControllerRenderProps<Record<string, any>>;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handlePolicyClose: () => void;
  appointmentPayload: IAppointmentPayload;
  setAppointmentPayload: React.Dispatch<React.SetStateAction<IAppointmentPayload>>;
  policydetailfetching: any,
  patientId: string;
}

export interface IPolicyList {
  policyNo: string;
  policyName: string;
  insuredDetails: IPolicyUser[];
  userPolicyId: string;
}

export interface IPolicyUser {
  insuredName: string;
  relation: string;
  healthCardNo: string;
}

const PolicyListing = ({
  policyLists,
  anchorEl,
  controlProps,
  handleClose,
  setAppointmentPayload,
  appointmentPayload,
  handlePolicyClose,
  policydetailfetching,
  patientId
}: IPolicyListing) => {
  const classes = styles();

  const [request, setRequest] = useState<Irequest>({
    message: "",
    loading: false,
    severity: "",
  });

  const getWallet = async (walletPayload, name) => {
    handlePolicyClose();
    try {
      const res = await getPolicyWalletBalance(walletPayload);
      if (res.statusCode) {
        setAppointmentPayload({
          ...appointmentPayload,
          policyNumber: walletPayload.policyNumber,
          policyUser: name,
          healthCardNo: walletPayload.healthId,
          walletBalence: Math.floor(res.data.availableBalance * 100) / 100
        });
      }
    } catch (e) {
      setRequest({ message: e?.response?.data?.message || "Something went wrong!", loading: false, severity: "error" });
    }
  }
  return (
    <>
      {request.message && <Notify message={request.message} severity={request.severity} />}

      <CustomPopover name={"PolicyListing"} anchorEl={anchorEl} className={""} handleClose={handleClose}>
        <ClickAwayListener onClickAway={handlePolicyClose}>
          <div>
        {policyLists.length > 0 && <Typography style={{padding:'2% 4%'}}>{policyLists.length} policies found</Typography>}
          <Grid container xs={12} style={{ maxHeight: '250px', overflow: 'auto' }}>
            {policyLists && policyLists.length > 0 ? policyLists.map((policy) => {
              return (
                <>
                  <Grid
                    style={{ backgroundColor: "#F4F8FF", padding: "10px" }}
                    xs={12}
                    item
                    container
                    className={classes.policyDetail}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item container direction={"column"}>
                      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                        {policy.policyNo}
                      </Typography>
                      <Typography style={{ fontSize: "12px", color: "#707070" }}>{policy.policyName}</Typography>
                    </Grid>
                    <Grid item container alignItems={"center"} direction={"row"}>
                      <img src={Wallet} alt="" height={"18px"} />
                    </Grid>
                  </Grid>
                  <div style={{width:'100%'}}>
                  {policy.insuredDetails.map((users) => {
                    return (
                      <CommonMenuItem
                        value={policy}
                        onClick={() => {
                          controlProps?.onChange(
                            users.insuredName +
                            " - " +
                            policy.policyNo.slice(0, 3) +
                            "..." +
                            policy.policyNo.slice(-4)
                          );
                          getWallet({
                            userPolicyId: policy.userPolicyId,
                            userId: patientId,
                            policyNumber: policy.policyNo,
                            healthId: users.healthCardNo,
                            isOPD: true
                          }, users.insuredName)
                        }}
                      >
                        <Grid item container direction="row" justifyContent="space-between" alignItems="center" >
                          <Typography>{users.insuredName}</Typography>
                          <Typography style={{color:'grey',fontSize:'0.638rem'}}>{users.relation}</Typography>
                        </Grid>
                      </CommonMenuItem>
                    ); 
                  })}
                  </div>
                </>
              );
            }) : !policydetailfetching && <Grid item style={{ padding: '8px', color: 'grey' }} xs={12}>No Data Available</Grid>}
            {policydetailfetching && <Grid item style={{ padding: '8px' }} xs={12}>Loading</Grid>}
          </Grid>
          </div>
        </ClickAwayListener>
      </CustomPopover>
    </>
  );
};

export default PolicyListing;
