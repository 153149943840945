import { CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import { AttachFileOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import Notify from "../../../../components/Notify";
import { uploadToS3 } from "../../../../utils/uploadToS3";
import { styles } from "./styles";
import {fileFormat} from "../../../../utils/constants";

const useStyles = makeStyles(styles);
const FileUploadButton = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: "",
  });
  const triggerFileSelect = () => {
    document.getElementById(`file-input-${props.chatParticipantInfo.room}`).click()
  }
  const handleFileUpload = async (e) => {    
    setRequest({ loading: true, message: "", severity: "" });
    setLoading(true)
    if (e.target.files && e.target.files[0]) {
      const file=e.target.files[0];
      const replacedName = file.name.replace(/[#()\$^[\]%]/g, '_')
      const originalContentType = file.type;
      const newFile = new File([file], replacedName,{ type: originalContentType })
      const { name, size } = newFile; 
      const type  = name.split(".").pop();
      let Filetype  = fileFormat.includes(type?.toLowerCase());
    if(Filetype){
      const payload={
        user_id: props.chatParticipantInfo?.isGroup 
                ? props.chatParticipantInfo.room 
                : props.chatParticipantInfo.chatUserId
      }      
      const s3Prefix="chat";
      const {url,err,keyPath} = await uploadToS3(newFile, s3Prefix, name);
      if (props.onFileUpload) {
        props.onFileUpload(url,keyPath)
      }     
      if (url && name) {        
        setRequest({
          loading: false,
          message: "Uploaded successfuly",
        });
      } else {
         setRequest({
          loading: false,
          message: err?.response?.data?.message || "Error Occured while uploading!",
          severity: "",
        });
      }
    }else{
      setRequest({
        loading: false,
        message: "File Type Not Supported",
        severity: "",
      });
      }
    }
    setLoading(false)
  }

  const handleFileClick = (event)=>{
    event.target.value = ''
  }

  return (
    <>
    {request.message && (
      <Notify message={request.message} severity={request.severity} onClose={()=> setRequest({ message: "", loading: false, severity: "" })} />
    )}
    <IconButton className={classes.root} onClick={triggerFileSelect} size="small">     
      {loading &&
        <CircularProgress style={{ height: "24px", width: "24px" }} />
      }
      {!loading &&
        <AttachFileOutlined fontSize="small"/>
      }
      <input
        type="file" id={`file-input-${props.chatParticipantInfo.room}`} onChange={handleFileUpload} onClick={handleFileClick}/>
    </IconButton>
    </>
  );
};

export default FileUploadButton;
