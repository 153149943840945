import { authStorage } from "../../utils/login";
export var sendInstant = (value?) => {
try
{
    value && sendMsg(value);
}
catch(err)
{console.log(err);}
}, ws, closeInstant = () => {},sendMsg=(value?)=>{};
export var onMessageCallback, calling = false, SocketConnection,webWorker;

var intervalData;
export const UpdateCalling = (value) => {
    calling = value;
}

export const RepeatCall = (id: string, role: any, string: string) => {
    calling = true
    webWorker({method:'START',repeat:"INTERVAL",message:JSON.stringify({ 'userId': id, 'role': role, "method": string })})
}
export const stopRepeatCall = () => {
    webWorker({method:'STOP',repeat:"INTERVAL",message:''})
}
export function InstantVideo(NewNotifyUpd: any, fn: () => void, Socket: () => void, setonlineDoctorlist: (value?: any) => void, logoutUser: (value:any) => void, initialCall1?: any, initialCall2?: any, initialCall3?: any) {
    onMessageCallback = fn.bind(fn);
    var aliveCall;
    SocketConnection = Socket.bind(Socket);

    ws = new WebSocket(`${process.env.REACT_APP_INSTANT_WS}`, `${authStorage.authToken}`);
    const socketWebWorker = new Worker("/socketWorker.js");
    webWorker = socketWebWorker.postMessage.bind(socketWebWorker);
    ws.onopen = function () {
        SocketConnection(true);
        ws.readyState === 1;
        initialCall1 && ws.send(JSON.stringify(initialCall1));
        
        initialCall2 && setTimeout(() => {
            ws.send(JSON.stringify(initialCall2))
        }, 1000);
        initialCall3 && socketWebWorker.postMessage({method:'START',repeat:"NOTIFY",message:JSON.stringify(initialCall3)});
        socketWebWorker.postMessage({method:'START',repeat:"ALIVE",message:JSON.stringify({ "method": "keepAlive" })});
    }

    ws.onmessage = function (event) {   
        if (JSON.parse(event.data).status === 401) {
            if(JSON.parse(event.data)?.body?.code === 1001){
                logoutUser && logoutUser("multi");
            }
            else if(JSON.parse(event.data)?.body?.code === 1002){
                logoutUser && logoutUser("single");
            }
            else
            logoutUser;
        } else {
            if (event.data && JSON.parse(event.data)?.type === 'status')
                setonlineDoctorlist(event.data);
            else if (event.data && JSON.parse(event.data)?.type === 'CHAT_NOTIFICATION') {
                let payload = {};
                let data = JSON.parse(event.data);
                payload[data.bookedSlotId] = data;
                NewNotifyUpd(payload);
            } else {
                onMessageCallback(JSON.parse(event.data));
                calling = false;
               socketWebWorker.postMessage({method:'STOP',repeat:"INTERVAL",message:""});
            }
        }
    };

    ws.onclose = function (event) {
        calling = false;
        socketWebWorker.postMessage({method:'STOP-ALL',repeat:"",message:""});
        SocketConnection(false);
        socketWebWorker.terminate();
    };

    ws.onerror = function (error) {
        calling = false;
        clearInterval(intervalData);
        clearInterval(aliveCall);
        SocketConnection(false);
        socketWebWorker.postMessage({method:'STOP-ALL',repeat:"",message:""});
        socketWebWorker.terminate();
    };

    socketWebWorker.onmessage = (res) =>{
        sendInstant(res.data);
    }

    sendMsg = ws.send.bind(ws);
    closeInstant = ws.close.bind(ws);
}
