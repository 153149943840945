import {
  AppBar,
  CssBaseline,
  Drawer,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  Box,
  Grid
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ic_notify from "../../assets/images/admin/icons/ic_notify.svg";
import SessionLogout from "../../utils/sessionLogout";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink as RouterLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Avatar } from "@material-ui/core";
import LogoWhite from "../../assets/images/logo.svg";
import MenuUIIcon from "../../assets/images/menu-icon.svg";
import {
  MENU_EMPANELMENT_ADMIN,
  MENU_SUPER_ADMIN,
  MENU_DOCTOR,
  MENU_FIELD_AGENT,
  Receptionist_Login,
  MENU_AHP,
  MENU_FACILITY_ADMIN,
  TRANSCRIPTION_AGENT,
  Operational_Receptionist_Login,
  CLAIMS_PROCESS,
  MENU_WELLNESS_SUPPORT,
} from "../../config/menu.config";
import { USER_ROLES } from "../../utils/constants";
import {
  setChatRoomList,
  setChatRoomInfo,
  instantChatSlot,
  setChatParticipantInfo,
  setEnableWidget,
  setClinicDetails,
  OnchatBusy,
  NewMsgReceived,
  EmptyChatState,
  setMessageList,
  setOpenChatDetails,
  messageSendError,
} from "../../store/actions";
import EnhancedMenu from "./EnhancedMenu";
import { styles } from "./styles";
import Notify from "../common/CommonToast";
import Push from "push.js";
import menulogo from "../../assets/images/poweredBySecurraWhite.svg";
import tick from "../../assets/images/charm_tick.png";
import SecurraLogoBlue from "../../assets/images/securraLogoBlue.svg";
import { switchUserRole } from "../../store/actions";
import ChatWidget from "../../components/ChatWidget";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import CallPopUp from "../../components/callPopup";
import {
  InstantVideo,
  closeInstant,
  RepeatCall,
  stopRepeatCall,
  ws,
  calling,
  sendInstant,
  UpdateCalling,
} from "../Websocket/InstantVideo";
import {
  openWebSocket,
  setPickUp,
  setOtherInfo,
  setChatNow,
  UpdateInstInfo,
  UpdateCallInfo,
  UpdateDoctorStatus,
  UpdateConnectingInfo,
  NewNotifyUpd,
  UpdateInstantType,
  UpdateCallbackInfo,
  UpdateRejoinStatus,
  ChatConnected,
  ChatDisConnected,
  ChatDetails,
  UpdateMessagelist,
  EmptyMessagelist,
  ChatRoomReconnect,
  setShowConsultation,
  DisconnectMsg
} from "../../store/actions";
import {
  instantChatIO,
  closeSocket,
  sendMsg,
  getcurrentRoomID
} from "../../components/Websocket/InstantChatIO";
import CustomSubmitButton from "../../components/common/CustomSubmitButton";
import Notification from "./Notification/index";
import MyProfile from "../../assets/images/myprofile.svg";
import Logout from "../../assets/images/logout.svg";
import CustomDialog from "../common/CustomDialog";
import CloseIcon from "@material-ui/icons/Close";
import {clearUser} from "../../api/StarLogin";
import { authStorage } from "../../utils/login";
import {getChatRoomList} from "../../api/BarosAPI/chat/chat"
import {startWebsocketConnection,registerOnMessageCallback,recieve} from "../../utils/wss"
import moment from "moment";
import EllipsisTooltip from "../BarosComponent/EllipsisTooltip";
import { store } from "../../store";

const useStyles = makeStyles(styles);

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function scrollToBottom(roomId = "") {
  const container =
    roomId != ""
      ? document.getElementById(`message_container_${roomId}`)
      : document.getElementById(`message_container`);
  if (container) {
    let firstTime = true;
    if (firstTime) {
      container.scrollTop = container.scrollHeight;
      firstTime = false;
    } else if (
      container.scrollTop + container.clientHeight ===
      container.scrollHeight
    ) {
      container.scrollTop = container.scrollHeight;
    }
  }
}

export default function Layout({ children }) {
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(false);
  const [isMultiUser, setIsMultiUser] = useState("");
  const clinic =useSelector((state) => state?.clinic?.details) ;
  const [showSwitch, setShowSwitch] = useState(false);
  const [showfacility, setshowfacility] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [facilityList, setfacilityList] = useState([]);
  const { userRole, user, facility,chatUserId } = useSelector((state) => state?.auth);
  const FirstName =
    user?.name?.first && user?.name?.first.length > 0
      ? user?.name?.first
      : "";
  const LastName =
    user?.name?.last && user?.name?.last.length > 0
      ? user?.name?.last
      : "";
  const fullname = FirstName + " " + LastName;
  const clinicData = undefined; /* Temporary for dev-star-release*/
  const [rolesVisible, setrolesVisible] = useState(false);
  const [facilityVisible, setfacilityVisible] = useState(false);
  const [MENU, setMenu] = useState([]);
  const { details } = useSelector((state) => state?.clinic);
  const { pickup, chatnow } = useSelector((state) => state?.call);
  const { instantdata, onCall, connecting, callBackData } = useSelector(
    (state) => state?.instantVideo
  );
  const [instantResponse, setinstantResponse] = useState({});
  const [socketConnected, setsocketConnected] = useState(false);
  const [onlineDoctorlist, setonlineDoctorlist] = useState({});
  const [headerDisable, setheaderDisable] = useState(false);
  const [NewArraivalMsg, setNewArraivalMsg] = useState({});
  const [ReconnectConsult, setReconnectConsult] = useState({
    show: false,
    path: "",
  });
  const [Online, setOnline] = useState(true);
  const [request, setRequest] = useState({
    message: "",
    loading: false,
    severity: "",
  });
  const {
    connected,
    chatdetails,
    Chatmessages,
    reconnecting,
    onchatBusy,
    skipCount,
  } = useSelector((state) => state?.instantChat);
  const [chatRoomDetail, setchatRoomDetail] = useState({
    action: "",
    data: "",
  });
  const {  
    chatRoomList,
    chatParticipantInfo,
    openChatDetails,
    openChatRooms,
    chatRoomInfo,
    msgList,
    loadingHistory,
    hasMore,
    fetchingNewMessage,
    isChatWidgetEnabled,
   } = useSelector((state) => state?.chat);

   useEffect(() => {
    // Set ChatParticipantInfo to an empty
    dispatch(setChatParticipantInfo({}));
  }, []);

  useEffect(() => {
    if (user && clinic.id && [USER_ROLES.facility_admin,USER_ROLES.Wellness_support_interim,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.wellness_coach,USER_ROLES.doctor].includes(userRole)) {
      getChatRooms();
    }
  }, [user,clinic.id]);

  /* Instant chat Method Handling */
  const ChatInfo = (chatRoomDetail) => {
    if (chatRoomDetail?.action === "CONNECTED") {
      dispatch(ChatConnected());
      dispatch(ChatRoomReconnect(false));
      sendMsg('join',{userId: user?._id,type:"join"});
    } else if (chatRoomDetail?.action === "DISCONNECTED") {
      dispatch(ChatDisConnected());
      dispatch(ChatRoomReconnect(false));
    } else if (chatRoomDetail?.action === "RECONNECTING") {
      dispatch(ChatRoomReconnect(chatRoomDetail?.data));
    } else if (chatRoomDetail?.action === "CHAT_DETAILS") {
      dispatch(ChatDetails(chatRoomDetail?.data));
    } else if (chatRoomDetail?.action === "UPDATE_MESSAGE_LIST") {
      const roomId = getcurrentRoomID();
      if(roomId === chatRoomDetail?.data?.channelId)
      {
        dispatch(UpdateMessagelist([chatRoomDetail?.data]));
        dispatch(NewMsgReceived(true));
      }
     else{
      let payload = {};
      payload[chatRoomDetail?.data?.channelId] = {bookedSlotId:chatRoomDetail?.data?.channelId,date:chatRoomDetail?.data?.createdAt,message:chatRoomDetail?.data?.text,type:'CHAT_NOTIFICATION'};
      dispatch(NewNotifyUpd(payload));
     }
                
    } else if (chatRoomDetail?.action === "EMPTY_MESSAGE_LIST") {
      dispatch(EmptyMessagelist());
    } else if (chatRoomDetail?.action === "PATIENT_DISCONNECT") {
      if (window.location.search.includes(chatRoomDetail?.data?.room) && ['/chat-consulation'].includes(window.location.pathname))
        dispatch(DisconnectMsg(`${process.env.REACT_APP_PATIENT} has ended the chat`));
      else
        dispatch(DisconnectMsg(''));
    } else if (chatRoomDetail?.action === "CALL_BACK_TOKEN"){
      dispatch(UpdateCallbackInfo({
        type: 'CALLBACK',
        status: chatRoomDetail?.action,
        data: chatRoomDetail?.data
      }));
    } else if (chatRoomDetail?.action === "BUSY_ON_ANOTHER_CALL" || chatRoomDetail?.action === "ANOTHER_CALL"
                || chatRoomDetail?.action === "BUSY_ON_ANOTHER_CHAT"){
      dispatch(UpdateCallbackInfo({
        type: 'CALLBACK',
        status: 'BUSY'
      }));
      setRequest({ message: "", loading: true, severity: "" });
      setRequest({
        message: chatRoomDetail?.data?.message,
        loading: false,
        severity: "info",
      });
    } else if(chatRoomDetail?.action === "USER_ACCEPTED" ){
      dispatch(UpdateCallbackInfo({
        ...callBackData,
        type: 'CALLBACK',
        status: "USER_ACCEPTED"
      }));
    } else if(chatRoomDetail?.action === "USER_REJECTED"){
      dispatch(UpdateCallbackInfo({...callBackData,...{type: 'CALLBACK', status: chatRoomDetail?.action}}));
      setRequest({ message: "", loading: true, severity: "" });
      setRequest({
        message: chatRoomDetail?.data?.message,
        loading: false,
        severity: "info",
      });
    } else if(chatRoomDetail?.action === "ACCEPTED") {
      dispatch(UpdateCallbackInfo({...callBackData,...{type: 'CALLBACK', status: chatRoomDetail?.action}}));
    }
  };

  /* Remove Socket Connection */
  const removeCallNotify = () => {
    if (ws && (ws?.readyState === 0 || ws?.readyState === 1)) {
      closeInstant();
      closeSocket();
    }
  };

  useEffect(() => {
    SetUserRole(userRole);
  }, [userRole]);

  useEffect(() => {
    if (
      !location.pathname.includes("/facility-list") &&
      !location.pathname.includes("/Admin/Slider") &&
      !userRole.includes("UNIFIED_APP_EMPANELMENT_ADMIN") &&
      !location.pathname.includes("/createfacility") &&
      location.pathname.includes("/chats") 
    ) {
      JSON.stringify(details) != "{}" && setRolesList(details?.roles);
      facility && setfacilityList(facility);
      user?.practitionerProfile?.service && setShowSwitch(true);
      JSON.stringify(details) != "{}" && setshowfacility(true);
      dispatch(setEnableWidget(false));
    } else {
      setShowSwitch(false);
      setshowfacility(false);
      const AHP_ROLES = [USER_ROLES.wellness_coach,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim];
      const WELLNESS_SUPPORT = [USER_ROLES.Wellness_support_interim];
      [USER_ROLES.facility_admin,USER_ROLES.doctor,...AHP_ROLES,...WELLNESS_SUPPORT].includes(userRole) ? (!isChatWidgetEnabled && dispatch(setEnableWidget(true))) : dispatch(setEnableWidget(false));
    }

    if (
      !location.pathname.includes("/Appointments/room") &&
      !location.pathname.includes("/InstantCall/room")
    ) {
      dispatch(setShowConsultation({ isShowConsultation: false }));
    }else{
      dispatch(setEnableWidget(false))
    }
  }, [location]);

  const submenuView = (type, check) => {
    if (type === "role") setrolesVisible(check);
    else setfacilityVisible(check);
  };

  useEffect(() => {
    !Push.Permission.has() &&
      !localStorage.getItem("notifyRequest") &&
      Push.Permission.request(onGranted, onDenied);
  }, []);

  const onGranted = () => {};

  const onDenied = () => {
    localStorage.setItem("notifyRequest", "true");
  };

  const getChatRooms = async () => {
    setRequest({ message: "", loading: true, severity: "" });
    if (chatUserId && clinic?.id) {
      try {
        const res = await getChatRoomList(chatUserId, clinic?.id);
        if (res && res.status < 350 && res.data) {
          dispatch(setChatRoomList([...res.data]));
        } else {
          setRequest({
            message: res.data.message,
            loading: false,
            severity: "",
          });
        }
      } catch (error) {
        setRequest({
          message: error?.response?.data?.message || "Something went wrong",
          loading: false,
          severity: "error",
        });
      }
    }
  };

  const updateIsOnlineOrOffline = (event,value) =>{
    const {chat} = store.getState()
    const updateChatInfo = chat?.chatRoomList?.map((ele) => {
      if (event?.chatUser?.id === ele?.formattedRoom?.id) {
        ele.formattedRoom.isOnline = value;
      }
      return ele;
    });
    if(event?.chatUser?.id === chatParticipantInfo?.id){
      dispatch(setChatParticipantInfo({ ...chatParticipantInfo, isOnline: value }))
    }
    return updateChatInfo
  }

  const updateActiveStatus = (event) => {
    const updateChatInfo = updateIsOnlineOrOffline(event,true)
    dispatch(setChatRoomList([...updateChatInfo]));
  };

  const updateInActiveStatus = (event) => {
    const updateChatInfo = updateIsOnlineOrOffline(event,false)
    dispatch(setChatRoomList([...updateChatInfo]));
  };

  const registerCallback = (data) => {
    const msg = JSON.parse(data);
    if(!Object.keys(msg).includes("action") && msg.message){  
      dispatch(messageSendError({ requesting: false, success: false, error: msg }))
      return 
    }

    switch (msg.action) {
      case "message-sent":
        if (isChatWidgetEnabled) {
          const roomId = msg.chat.room ? msg.chat.room._id : msg.chat._id;
          if (Object.keys(openChatRooms).length > 0) {
            dispatch(
              setOtherInfo({
                fetchingNewMessage: true,
                hasMore: true,
                loadingHistory: false,
              })
            );
            if (
              openChatDetails[roomId] &&
              openChatDetails[roomId].length === 0
            ) {
              recieve(roomId, 10, openChatDetails[roomId].length);
            } else {
              recieve(roomId, 1, 0);
            }
            scrollToBottom(roomId);
          } else if (msg.chat) {
            recieve(roomId, 10, 0);
          }
          msg?.chat?.payload?.message && getChatRooms();
        } else {
          dispatch(messageSendError( { requesting: false, success: true, error: null }))
          if (Object.keys(chatRoomInfo).length > 0) {
            dispatch(
              setOtherInfo({
                fetchingNewMessage: true,
                hasMore: true,
                loadingHistory: false,
              })
            );
            if (msgList.length === 0) {
              recieve(
                chatRoomInfo?._id || msg.chat.room,
                10,
                msgList.length
              );
            } else {
              recieve(chatRoomInfo?._id || msg.chat.room, 1, 0);
            }
            getChatRooms();
            scrollToBottom();
          } else if (msg.chat && !msg.chat.payload) {
            if (!msg.chat.room) {
              dispatch(setChatRoomInfo(msg.chat));
              recieve(msg.chat.id || msg.chat.room, 10, 0);
            } else {
              dispatch(setChatRoomInfo(msg.chat.room));
              recieve(msg.chat.room._id || msg.chat.room, 10, 0);
            }
          }
        }
        break;
      case "get-messages":
        if (msg.chats?.length) {
          if (isChatWidgetEnabled) {
            if (loadingHistory) {
              dispatch(
                setOpenChatDetails({
                  ...openChatDetails,
                  [msg.chats[0].room]: [
                    ...openChatDetails[msg.chats[0].room],
                    ...msg.chats,
                  ],
                })
              );
            } else {
              if (fetchingNewMessage) {
                dispatch(
                  setOpenChatDetails({
                    ...openChatDetails,
                    [msg.chats[0].room]: [
                      ...msg.chats,
                      ...(openChatDetails[msg.chats[0].room]
                        ? openChatDetails[msg.chats[0].room]
                        : []),
                    ],
                  })
                );
              } else {
                dispatch(
                  setOpenChatDetails({
                    ...openChatDetails,
                    [msg.chats[0].room]: [...msg.chats],
                  })
                );
              }
            }
          } else {
            if (loadingHistory) {
              const tst = [...msgList, ...msg.chats];
              dispatch(setMessageList([...msgList, ...msg.chats]));
            } else {
              if (fetchingNewMessage) {
                dispatch(setMessageList([...msg.chats, ...msgList]));
              } else {
                dispatch(setMessageList([...msg.chats]));
              }
            }
          }
        } else {
          dispatch(
            setOtherInfo({ hasMore: false, loadingHistory, fetchingNewMessage })
          );
        }
        dispatch(
          setOtherInfo({
            fetchingNewMessage: false,
            loadingHistory: false,
            hasMore,
          })
        );
        if (loadingHistory == false && msg.chats.length > 0) {
          // send(
          //   JSON.stringify({
          //     action: "message-read",
          //     body: { room: msg.chats[0]?.room }
          //   })
          // );
          // getChatRooms()
        }
        // getChatRooms()
        break;
      case "message-received":
        dispatch(
          setOtherInfo({
            fetchingNewMessage: true,
            hasMore: true,
            loadingHistory: false,
          })
        );
        if (isChatWidgetEnabled) {
          if (openChatRooms.indexOf(msg?.payload?.chat?.room?._id) !== -1) {
            recieve(msg?.payload?.chat?.room?._id, 1, 0);
          }
        } else {
          if (msg?.payload?.chat?.room?.id == chatRoomInfo?.id) {
            recieve(chatRoomInfo?._id || msg?.payload?.chat?.room, 1, 0);
          }
        }
        scrollToBottom();
        Push.create(`Message from ${msg?.payload?.chat?.room?.isGroup ? msg?.payload?.chat?.room?.name : msg?.payload?.chat?.sender?.participant?.displayName}`,{
          body:
            msg.payload.chat.payload.media.length === 0
              ? msg.payload.chat.payload.message
              : "Media Content",
          // timeout: 10000,
          // link:'https://google.com',
          onClick: function () {
            window.focus();
            this.close();
            history.push({
              pathname: `/chats/${msg.payload.chat.room._id}`,
              state: msg.payload,
            });
          },
        });
        getChatRooms();
        break;
      case "kept-alive":
        break;
      case "get-rooms":
        dispatch(
          setOtherInfo({
            loadingHistory: false,
            hasMore,
            fetchingNewMessage,
          })
        );
        break;
      case "message-read":
        getChatRooms();
        break;
      case "user-checked-in":
        updateActiveStatus(msg.payload)
        break;
      /**
       * Get the payload from the websocket and Check if the chat room id is availalbe in the chat room array.
       * if matched change the status true
       * chatRoomList
       *  */
      case "user-checked-out":
        updateInActiveStatus(msg.payload);
        break;
      /**
       * Get the payload from the websocket and Check if the chat room id is availalbe in the chat room array.
       * if matched change the status false
       *
       *  */
      default:
        break;
    }
  };
  React.useEffect(() => {
  if (user && [USER_ROLES.facility_admin,USER_ROLES.Wellness_support_interim,USER_ROLES.wellness_counselor_interim,USER_ROLES.wellness_nutritionist_interim,USER_ROLES.wellness_coach,USER_ROLES.doctor].includes(userRole)) {
    registerOnMessageCallback(registerCallback);
  }
}, [
  user,
  msgList,
  loadingHistory,
  fetchingNewMessage,
  hasMore,
  openChatDetails,
]);

React.useEffect(() => {
  if (user?.chatUserId) {
    dispatch(openWebSocket({ _id: user?.chatUserId }));
    startWebsocketConnection(user?.chatUserId, undefined, undefined);
  }
}, [user?.chatUserId]);

  const MENU_ITEMS = [
    {
      key: "1",
      caption: "Calender",
      onClick: () => {},
    },
    {
      key: "2",
      caption: "Notifications",
      onClick: () => {},
    },
    {
      key: "3",
      caption: "My Account",
      onClick: () => {
        history.push("/my-account");
      },
    },
    {
      key: "switch_role",
      caption: "Switch Role",
      subMenuItems: [
        {
          key: "4",
          caption: "Administrator",
          onClick: () => switchRole("administrators"),
        },
        {
          key: "5",
          caption: "Doctor",
          onClick: () => switchRole("doctors"),
        },
      ],
    },
    {
      key: "6",
      caption: "Signout",
      onClick: () => {},
    },
  ];
  const FACILITY_VIEW_ROLE =
    userRole === USER_ROLES.facility_admin ||
    userRole === USER_ROLES.super_admin ||
    userRole === USER_ROLES.doctor ||
    userRole === USER_ROLES.field_agent ||
    userRole === USER_ROLES.receptionist ||
    userRole === USER_ROLES.facility_receptionist;
  const DRAWER_EXCLUDED_PATHS = [
    "/Admin/Slider",
    "/admin/facility-list",
    "/createfacility",
    "/404/error",
  ];
  const WEB_SOCKET_BLOCK_PATHS = [
    "/Admin/Slider",
    "/admin/facility-list",
    "/createfacility",
  ];
  const FACILITY_LIST_DRAWER = ["/admin/facility-list"];

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuanchorEl, setmenuAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const isMenuOpen = Boolean(anchorEl) || Boolean(menuanchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [logo, setLogo] = useState('')
  import(`${process.env.REACT_APP_LOGO_IMAGE}`).then((module) => {
    setLogo(module.default);
  });
  const time =
    new Date(localStorage.getItem("cookie-expires")).getTime() -
    new Date().getTime();

 
  // React.useEffect(() => {
  //   if (user.chatUserId) {
  //     dispatch(openWebSocket({ _id: user?.chatUserId }));
  //     startWebsocketConnection(user?.chatUserId, undefined, undefined);
  //   }
  // }, [chatUserId]);
  React.useEffect(() => {
    setInterval(() => {
      window.alert("Your session has been expired please login again");
      window.location.href = "/";
    }, time);
  }, []);

  /* Delete Remember Key after TimeExpires*/
  React.useEffect(() => {
    if(localStorage.getItem("rememberKey-expires"))
    {
      const remeberKeytime = new Date(localStorage.getItem("rememberKey-expires")).getTime() - moment().utc();
      var remember =  setInterval(() => {
         authStorage.deleteRememberKey();
         localStorage.removeItem('rememberKey-expires');
         clearInterval(remember);
       }, remeberKeytime);
    }
  }, [localStorage.getItem("rememberKey-expires")]);


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleProfileMenuClose = (event) => {
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <EnhancedMenu
      id={mobileMenuId}
      open={Boolean(isMobileMenuOpen)}
      menuItems={MENU_ITEMS}
      anchorElement={mobileMoreAnchorEl}
      onClose={handleMenuClose}
    />
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const findSelected = (currentUrl) => {
    return location.pathname.includes(currentUrl);
  };

  const switchRole = (role) => {
    history.push("/logout");
  };
  const handleMenuClick = () => {
    dispatch(setChatParticipantInfo({}));
    dispatch(setChatRoomInfo({}));
    if (!getCookie("SAID")) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (location && isExpand !== "") {
      setIsExpand(location.pathname);
    }
  }, [location]);

  const hideToolbar =
    location.pathname.includes("/Admin/Slider") ||
    location.pathname.includes("/404/error");
  const handleExpand = (text) => {
    setIsExpand((preval) => {
      if (preval !== text.url) {
        return text.url;
      } else {
        return "";
      }
    });
  };

  function SetUserRole(userRole) {
    switch (userRole) {
      case USER_ROLES.super_admin:
        setMenu(MENU_SUPER_ADMIN);
        break;
      case USER_ROLES.facility_admin:
        setMenu(MENU_FACILITY_ADMIN);
        break;
      case USER_ROLES.empanelment_admin:
        setMenu(MENU_EMPANELMENT_ADMIN);
        break;
      case USER_ROLES.doctor:
        setMenu(MENU_DOCTOR);
        break;
      case USER_ROLES.receptionist:
        setMenu(Receptionist_Login);
        break;
      case USER_ROLES.facility_receptionist:
        setMenu(Receptionist_Login);
        break;
      case USER_ROLES.field_agent:
        setMenu(MENU_FIELD_AGENT);
        break;
      case USER_ROLES.transcription_agent:
        setMenu(TRANSCRIPTION_AGENT);
        break;
      case USER_ROLES.opd_ops:
        setMenu(Operational_Receptionist_Login);
        break;
      case USER_ROLES.claims_admin:
        setMenu(CLAIMS_PROCESS);
        break;
      case USER_ROLES.claims_processor:
        setMenu(CLAIMS_PROCESS);
        break;
      case USER_ROLES.wellness_coach:
        setMenu(MENU_AHP);
        break;
      case USER_ROLES.wellness_counselor_interim:
        setMenu(MENU_AHP);
        break;
      case USER_ROLES.wellness_nutritionist_interim:
        setMenu(MENU_AHP);
        break;
      case USER_ROLES.Wellness_support_interim:
        setMenu(MENU_WELLNESS_SUPPORT);
        break;
      default:
        setMenu([]);
        break;
    }
  }
  /* New Notification Arraival */

  useEffect(() => {
    if (NewArraivalMsg && JSON.stringify(NewArraivalMsg) !== "{}")
      dispatch(NewNotifyUpd(NewArraivalMsg));
  }, [NewArraivalMsg]);

  /* Socket Connection Initializatioin For Reception and Doctor */
  useEffect(() => {
    if (
      JSON.stringify(details) !== "{}" &&
      !WEB_SOCKET_BLOCK_PATHS.includes(window.location.pathname) && !isLogin
    ) {
      if (ws ? ws?.readyState === 3 : true) {
        let doctor = userRole === USER_ROLES.doctor;
        let ahp =  userRole ===   USER_ROLES.wellness_coach ||
        userRole === USER_ROLES.wellness_nutritionist_interim ||
        userRole === USER_ROLES.wellness_counselor_interim;
        let receptionist =
          userRole === USER_ROLES.receptionist ||
          userRole === USER_ROLES.opd_ops ||
          userRole === USER_ROLES.facility_receptionist;
        let initialCall1 = (doctor || ahp)
          ? { userId: user?._id, role: userRole, method: "connect" }
          : receptionist
          ? { userId: user?._id, role: userRole, method: "connect" }
          : ahp
          ? { userId: user?._id, role: userRole, method: "connect" }
          :null;
        let initialCall2 = (doctor || ahp)
          ? { userId: user?._id, role: userRole, method: "doctorFirstCheck" }
          : receptionist
          ? {
              userId: user?._id,
              role: userRole,
              method: "receptionistFirstCheck",
            }
          : ahp
          ? {
            userId: user?._id,
            role: userRole,
            method: "ahpFirstCheck",
          }
          :null;
        let initialCall3 = doctor
          ? {
              userId: user?._id,
              role: userRole,
              method: "doctorChatNotification",
            }
          : null;
        InstantVideo(
          setNewArraivalMsg,
          setinstantResponse,
          setsocketConnected,
          setonlineDoctorlist,
          logoutUser,
          initialCall1,
          initialCall2,
          initialCall3
        );
      }
      /* Repeat Call for Receptionist Instant Call  Check */
      if (
        receptionist &&
        !pickup &&
        ws?.readyState === 1 &&
        !calling &&
        !onCall
      ) {
        RepeatCall(user?._id, "receptionist", "receptionistCheck");
      }
      /* Repeat Call for Receptionist Instant Chat  Check */
      if (
        userRole === USER_ROLES.doctor &&
        !chatnow &&
        !pickup &&
        ws?.readyState === 1 &&
        !calling &&
        !onCall &&
        !onchatBusy
      ) {
        RepeatCall(user?._id, userRole, "doctorChatRequest");
      }
    }
  }, [
    userRole,
    socketConnected,
    details,
    pickup,
    onCall,
    chatnow,
    onchatBusy,
    Online,
  ]);

  /* Update Redux state For Instant Call & chat*/
  useEffect(() => {
    let role =
      userRole === USER_ROLES.receptionist ||
      userRole === USER_ROLES.opd_ops ||
      userRole === USER_ROLES.facility_receptionist ||
      userRole === USER_ROLES.doctor;
    if (role) {
      if (location.pathname.includes("/InstantCall/room") && !onCall) {
        dispatch(UpdateCallInfo(true));
        stopRepeatCall();
        UpdateCalling(false);
      } else if (onCall && !location.pathname.includes("/InstantCall/room")) {
        dispatch(UpdateCallInfo(false));
      }
    }
    if (
      userRole === USER_ROLES.doctor &&
      onchatBusy &&
      !location.pathname.includes("/chat-consulation")
    ) {
      dispatch(OnchatBusy(false));
      UpdateCalling(false);
    }
  }, [location]);

  useEffect(() => {
    const searchVal = location.search.split('?')[1];
    let isCallBack = searchVal?.split('/').length > 1
    if (
      ws?.readyState === 1 &&
      sessionStorage.getItem("INSTANT_CHAT_INITIATED") &&
      !location.pathname.includes("/chat-consulation") && !isCallBack
    ) {
      sendInstant &&
        (userRole === USER_ROLES.receptionist ||
        userRole === USER_ROLES.opd_ops ||
        userRole === USER_ROLES.facility_receptionist
          ? sendInstant(
              JSON.stringify({
                userId: user?._id,
                role: userRole,
                method: "receptionistFirstCheck",
              })
            )
          : sendInstant(
              JSON.stringify({
                userId: user?._id,
                role: userRole,
                method: "doctorFirstCheck",
              })
            ));
      sessionStorage.removeItem("INSTANT_CHAT_INITIATED");
    }
    if (
      ws?.readyState === 1 &&
      sessionStorage.getItem("INSTANT_CALL_INITIATED") &&
      !location.pathname.includes("/InstantCall/room") && !isCallBack
    ) {
      /*Commented the code because the method doctorFirstCheck calling two times while reloading after rejoin*/
      // sendInstant &&
      //   (userRole === USER_ROLES.receptionist ||
      //   userRole === USER_ROLES.opd_ops ||
      //   userRole === USER_ROLES.facility_receptionist
      //     ? sendInstant(
      //         JSON.stringify({
      //           userId: user?._id,
      //           role: userRole,
      //           method: "receptionistFirstCheck",
      //         })
      //       )
      //     : sendInstant(
      //         JSON.stringify({
      //           userId: user?._id,
      //           role: userRole,
      //           method: "doctorFirstCheck",
      //         })
      //       ));
      sessionStorage.removeItem("INSTANT_CALL_INITIATED");
    }
    if (
      location.pathname.includes("/InstantCall/room") ||
      location.pathname.includes("/chat-consulation") ||
      location.pathname.includes("/chat-consulation") ||
      location.pathname.includes("/Appointments/room")
    )
      ReconnectConsult?.show && setReconnectConsult({ show: false, path: "" });
  }, [ws, socketConnected, location, ReconnectConsult?.show]);

  /* Trigger Websocket On Internet Connect and Disconnect */

  const userOnline = () => setOnline(true);
  const userOffline = () => setOnline(false);

  useEffect(() => {
    function Online() {
      userOnline();
    }
    function Offline() {
      userOffline();
    }
    window.addEventListener("online", Online, true);
    window.addEventListener("offline", Offline, true);
    return () => {
      window.removeEventListener("online", Online, true);
      window.removeEventListener("offline", Offline, true);
    };
  }, []);

  /* Websocket CallBack Message Type */
  useEffect(() => {
    if (JSON.stringify(instantResponse) !== "{}") {
      if (
        instantResponse?.type === "INSTANT" &&
        instantResponse?.method === "Rejected"
      ) {
        dispatch(
          UpdateConnectingInfo({
            ...connecting,
            split: true,
            show: false,
            name: "",
          })
        );
      } else if (instantResponse?.type === "INSTANT" && instantResponse?.method !== "Accepted") {
        dispatch(UpdateInstInfo(instantResponse));
        dispatch(setPickUp({ pickup: true }));
        chatnow && dispatch(setChatNow({ chatnow: false }));
      } else if (instantResponse?.type === "CHAT") {
        dispatch(ChatDetails(instantResponse));
        dispatch(setChatNow({ chatnow: true }));
        pickup && dispatch(setPickUp({ pickup: false }));
      } else if (instantResponse?.type === "RECONNECT") {
        if (instantResponse?.consulationType === "INSTANT") {
          dispatch(UpdateInstInfo(instantResponse));
          if (location.pathname.includes("/InstantCall/room")) {
            setinstantResponse({});
            dispatch(UpdateCallInfo(true));
            history.push({
              pathname: "/InstantCall/room",
              search: `${instantResponse?.roomId ? instantResponse?.roomId : instantdata?.roomId}`,
              state: { row: { row: { _id: instantResponse?.bookedId || instantdata?.bookedId } } },
            });
          } else setReconnectConsult({ show: true, path: "/InstantCall/room" });
        } else if (instantResponse?.consulationType === "CHAT") {
          dispatch(ChatDetails(instantResponse));
          dispatch(instantChatSlot(instantResponse?.bookedId));
          if (location.pathname.includes("/chat-consulation")) {
            instantResponse.chatbookedID = instantResponse?.bookedId;
            instantResponse.userId = instantResponse?.user;
            const roomID = new URLSearchParams(location.search).get("roomID")            
            const {name ,roomId,userId,chatLogId,chatbookedID,user,bookedId : BookedId} = instantResponse ;
            roomID !== roomId && dispatch(EmptyMessagelist());
            sendMsg('join',{ name, room:roomId, userId : userId || user, "type": "web",  chatLogId,bookSlotId: BookedId});
            setinstantResponse({});
            dispatch(OnchatBusy(true));
            history.push({
              pathname: "/chat-consulation",
              search: `?roomID=${chatdetails?.roomId}`,
              state: { row: { row: { _id: chatdetails?.bookedId } } },
            });
          } else setReconnectConsult({ show: true, path: "/chat-consulation" });
        }
        else if(instantResponse?.consulationType === "VIRTUAL_CONSULTATION")
        {
          dispatch(UpdateInstInfo(instantResponse));
          const stateValue = instantResponse?.isCallBack ? { row: { row: { _id: instantResponse?.bookedId } }, consultType : instantResponse?.consulationType  } : { row: { row: { _id: instantResponse?.bookedId ,user:{fullname:instantResponse?.name}} } }
          if (location.pathname.includes("/Appointments/room")) {
            setinstantResponse({});
            dispatch(UpdateCallInfo(true));
            history.push({
              pathname: "/Appointments/room",
              search: `${instantResponse?.roomId ? instantResponse?.roomId : instantdata?.roomId}`,
              state: stateValue,
            });
          } else setReconnectConsult({ show: true, path: "/Appointments/room" });
        }
      }
    }
  }, [instantResponse]);

  useEffect(()=>{
    if(ReconnectConsult.show){
      dispatch(UpdateRejoinStatus(true))
    } else {
      dispatch(UpdateRejoinStatus(false))
    }
  },[ReconnectConsult])

  /* Update Doctor Online list*/
  useEffect(() => {
    if (JSON.stringify(onlineDoctorlist) !== "{}") {
      dispatch(UpdateDoctorStatus(JSON.parse(onlineDoctorlist)));
    }
  }, [onlineDoctorlist]);
  // /* Remove Notification on tab close*/
  useEffect(() => {
    pickup && dispatch(setPickUp({ pickup: false }));
    chatnow && dispatch(setChatNow({ chatnow: false }));
    closeSocket();
    dispatch(ChatDisConnected());
    dispatch(OnchatBusy(false));
    dispatch(EmptyChatState());
    sessionStorage.removeItem("INSTANT_CHAT_INITIATED");
    sessionStorage.removeItem("INSTANT_CALL_INITIATED");
    if (
      userRole === USER_ROLES.doctor &&
      location.pathname.includes("/chat-consulation")
    )
      dispatch(EmptyMessagelist());
  }, []);
  const receptionist =
    userRole === USER_ROLES.receptionist ||
    userRole === USER_ROLES.opd_ops ||
    userRole === USER_ROLES.facility_receptionist;

  /* close Websocket connection for header excluded pages */
  useEffect(() => {
    if (DRAWER_EXCLUDED_PATHS.includes(window.location.pathname)) {
      removeCallNotify();
    }
    if (window.location.pathname.includes("/chat-consulation")) {
      setheaderDisable(true);
      handleDrawerClose();
    }
    if (
      !window.location.pathname.includes("/Appointments/room") &&
      !window.location.pathname.includes("/InstantCall/room") &&
      !window.location.pathname.includes("/chat-consulation") &&
      headerDisable
    ) {
      setheaderDisable(false);
      handleDrawerOpen();
    }
    if (
      (window.location.pathname.includes("/Appointments/room") ||
        window.location.pathname.includes("/InstantCall/room")) &&
      !headerDisable
    ) {
      setheaderDisable(true);
      handleDrawerClose();
    }
  }, [location]);

  const logoutUser = (value) => {
    setIsMultiUser(value);
    setIsLogin(true);
    closeInstant();
    dispatch(setPickUp({ pickup: false }));
    dispatch(setChatNow({ chatnow: false }));
  };
  useEffect(()=>{
    if(isLogin){
      window.onbeforeunload = function() {
        localStorage.clear();
        authStorage.deleteAuthDetails()
     }
    }
    return () => {
      window.onbeforeunload = null;
    }
  },[isLogin])
  
  /* SOCKET IO OPEN */
  useEffect(() => {
   if(!connected && user?._id)
   {
       closeSocket();
       instantChatIO(ChatInfo,user?._id);
  }
  },[connected, user?._id])

  const clearLogout = async () => {
   history.push("/logout");
  }
  const onClickProfile = () => {
    if (userRole === USER_ROLES.facility_admin) {
      history.push("/Dashboard/newprofile/admin");
    } else if (userRole === USER_ROLES.doctor) {
      history.push("/Dashboard/newprofile/doctor");
    } 
    else if (userRole === USER_ROLES.wellness_coach) {
      history.push("/Dashboard/newprofile/wellnessCoach");
    } 
    else if (userRole === USER_ROLES.wellness_counselor_interim) {
      history.push("/Dashboard/newprofile/wellnessCounselor");
    } 
    else if (userRole === USER_ROLES.wellness_nutritionist_interim) {
      history.push("/Dashboard/newprofile/wellnessNutritionist");
    } 
    else if (userRole === USER_ROLES.Wellness_support_interim) {
      history.push("/Dashboard/newprofile/wellnessSupport");
    }
     else {
      history.push("/Dashboard/newprofile/receptionist");
    }
  };
  const INSTANT_CALL_NOTIFY =
    pickup &&
    !onCall &&
    !DRAWER_EXCLUDED_PATHS.includes(window.location.pathname) &&
    JSON.stringify(instantResponse) !== "{}";
  const INSTANT_CHAT_NOTIFY =
    chatnow &&
    !onchatBusy &&
    !DRAWER_EXCLUDED_PATHS.includes(window.location.pathname) &&
    JSON.stringify(instantResponse) !== "{}";
  const NotifyPopEnable = INSTANT_CALL_NOTIFY || INSTANT_CHAT_NOTIFY;
  return (
    <div className={clsx(classes.root)}>
      {NotifyPopEnable && (
        <CallPopUp
          Notifydata={instantResponse}
          setinstantResponse={setinstantResponse}
          ChatInfo={ChatInfo}
        />
      )}
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <CssBaseline />
      {hideToolbar ? null : (
        <AppBar
          color="default"
          position="fixed"
          style={headerDisable ? { cursor: "not-allowed" } : {}}
          className={clsx(
            classes.appBar,
            window.location.pathname !== "/createfacility"
              ? {
                  [classes.appBarShift]: open,
                }
              : classes.appBarFull
          )}
        >
          <Toolbar
            style={
              headerDisable
                ? {
                    pointerEvents: "none",
                    maxHeight: "58px",
                    minHeight: "58px",
                  }
                : { maxHeight: "58px", minHeight: "58px" }
            }
          >
            {window.location.pathname === "/createfacility" && (
              <div>
                <img src={SecurraLogoBlue} alt="securra logo" />
              </div>
            )}
            <div
              className={classes.sectionDesktop}
              style={
                headerDisable
                  ? { cursor: "not-allowed", pointerEvents: "none" }
                  : {}
              }
            >
               {/* * Temperorily Instant chat is disabled. */}
              {/* {userRole === USER_ROLES.doctor && (
                <Notification
                  setOpenNotification={setOpenNotification}
                  openNotification={openNotification}
                />
              )} */}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                disableRipple={true}
                disableFocusRipple={true}
                className={classes.profileButton}
                onMouseEnter={handleProfileMenuOpen}
                onMouseLeave={handleProfileMenuClose}
              >
                {user?.profilePic ? (
                  <div>
                    <img
                      className={classes.profileIcon}
                      src={user?.profilePic}
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                ) : (
                  <Avatar className={classes.avatarIcons}>
                    {user?.name?.first?.toUpperCase()?.charAt(0) +
                      user?.name?.last?.toUpperCase()?.charAt(0)}
                  </Avatar>
                )}
                <div className={classes.profileTextContainer}>
                  {/* <span className={classes.profileName}>{clinic?.name}</span> */}
                  <span className={classes.profileRole}>
                    <Typography style={{ fontSize: "0.85rem" }}>
                     <EllipsisTooltip title={fullname} placement="left"/>
                    </Typography>
                    {!FACILITY_LIST_DRAWER.includes(window.location.pathname) &&
                      user?.practitionerProfile?.service?.map((data) => {
                        if (userRole === data?.practitionerRole?.id) {
                          return data?.practitionerRole?.name;
                        }
                      })}
                  </span>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {!isMenuOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </div>
                <div
                  className={clsx(
                    classes.profileMenu,
                    isMenuOpen
                      ? "openProfileMenuDropdown"
                      : "profileMenuDropdown"
                  )}
                >
                  {/* {showfacility && <div style={{ cursor: 'default', borderBottom: '1px solid #E6E6E6' }}>
                  <span style={{ color: '#828282', paddingTop: '10%' }}>Facility</span>
                  <div style={{ display: 'flex', flexDirection: "row", padding: '5%', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{
                      height: "40px", width: "40px", backgroundImage: `url(${details?.logo})`, borderRadius: '2rem', marginRight: '10px', backgroundSize: "cover",
                      backgroundPosition: "center"
                    }}></div>
                    <div style={{ color: '#333333', wordWrap: 'break-word' }}>{details?.name}</div>
                  </div>
                </div>} */}
                  {/* {showSwitch && rolesList.length >= 2 && <div style={{ borderBottom: '1px solid #E6E6E6' }} onMouseEnter={() => { submenuView('role', true) }}
                  onMouseLeave={() => { submenuView('role', false) }}>
                  <div style={{ color: '#828282', padding: '16px', paddingLeft: "8px" }}
                  >Roles
                    <div className={clsx(classes.subMenuRoles, rolesVisible ? "openProfileMenuDropdown" : "profileMenuDropdown")}>
                      {rolesList?.map((data, i) => {
                        return (
                          <div style={{ listStyleType: 'none', marginLeft: '0', color: '#000000', padding: '8px', display: 'flex', justifyContent: "space-between" }} onClick={() => {
                            removeCallNotify()
                            dispatch(switchUserRole(data?._id));
                            history.replace('/');

                          }}>
                            {data?.name}
                            {userRole && userRole.includes(data?._id) ? <img src={tick} /> : ''}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>} */}
                  <div
                    className="profileDropdownMenuItem"
                    onClick={onClickProfile}
                  >
                    <img src={MyProfile} width={16} height={16} />
                    <div>My Profile</div>
                  </div>
                  {showfacility && facilityList.length >= 2 && (
                    <div
                      style={{
                        color: "#828282",
                        padding: "16px",
                        borderBottom: "1px solid #E6E6E6",
                      }}
                      onMouseEnter={() => {
                        submenuView("facility", true);
                      }}
                      onMouseLeave={() => {
                        submenuView("facility", false);
                      }}
                    >
                      Change Facility
                      <div
                        className={clsx(
                          classes.subMenufacility,
                          facilityVisible
                            ? "openProfileMenuDropdown"
                            : "profileMenuDropdown"
                        )}
                      >
                        {facilityList?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className={
                                details?.id.includes(data?.id)
                                  ? classes.activeback
                                  : facilityList.length - 1 === i
                                  ? classes.grey
                                  : clsx(classes.borderbottom, classes.grey)
                              }
                              style={{
                                listStyleType: "none",
                                marginLeft: "0",
                                color: "#000000",
                                padding: "8px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                removeCallNotify();
                                dispatch(switchUserRole(data?.roles[0]?._id));
                                dispatch(setClinicDetails(data));
                                history.replace("/");
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={
                                    data?.logo
                                      ? {
                                          height: "40px",
                                          width: "40px",
                                          backgroundImage: `url(${data?.logo})`,
                                          borderRadius: "2rem",
                                          marginRight: "10px",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                        }
                                      : {}
                                  }
                                ></div>
                                {!data?.logo && (
                                  <span style={{ marginRight: "25px" }}>
                                    <LocalHospitalIcon />
                                  </span>
                                )}
                                <div>{data?.name}</div>
                              </div>
                              <div>
                                {details && details?.id.includes(data?.id) ? (
                                  <img src={tick} height={"20px"} />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div
                    className="profileDropdownMenuItem"
                    onClick={clearLogout}
                  >
                    <img src={Logout} />
                    <div>Logout</div>
                  </div>
                </div>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      )}
      {renderMobileMenu}
      <div className={clsx(classes.root)}>
        {!DRAWER_EXCLUDED_PATHS.includes(window.location.pathname) && (
          <div style={headerDisable ? { cursor: "not-allowed" } : {}}>
            <Drawer
              style={headerDisable ? { pointerEvents: "none" } : {}}
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx(classes.ScrollDrawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={open ? classes.toolbar : classes.toolbarClose}>
                {open && (
                  <Link component={RouterLink} to="/Dashboard">
                    <img
                      src={logo}
                      alt="securra logo"
                      className={classes.logo}
                    />
                  </Link>
                )}
                <IconButton
                  onClick={open ? handleDrawerClose : handleDrawerOpen}
                >
                  <img src={MenuUIIcon} />
                </IconButton>
              </div>

              <List>
                {!open &&
                  MENU.map((text, index) => {
                    return (
                      <List
                        key={index}
                        title={text.label}
                        className={clsx(
                          classes.MobileMenuLink,
                          classes.menuLink,
                          open === false ? classes.customhoverlink : "",
                          text.disabled ? "disabled" : ""
                        )}
                      >
                        <ListItem
                          button
                          disableRipple={text.disabled}
                          disableTouchRipple={text.disabled}
                          disableFocusRibble={text.disabled}
                          selected={findSelected(text.url)}
                          className={clsx(classes.menuItem, "menu-item-mobile")}
                          onClick={() => {
                            if (
                              !text.submenu.length > 0 &&
                              text?.url &&
                              !location.pathname.includes(text.url)
                            ) {
                              history.push(text.url);
                            }
                          }}
                        >
                          <ListItemIcon>
                            <Icon>
                              {findSelected(text.url)
                                ? text.iconActive
                                : text.icon}
                            </Icon>
                          </ListItemIcon>
                          <List
                            component="ul"
                            className={clsx(
                              classes.nested,
                              classes.MobileMenuDropdown,
                              "onhover-open"
                            )}
                          >
                            <Link
                              to={text.url}
                              disablePadding
                              component={
                                !text.disabled ? RouterLink : undefined
                              }
                              onClick={() => handleMenuClick()}
                            >
                              <ListItemText
                                className={classes.MenuTopPrimaryWrap}
                                classes={{ primary: classes.MenuTopPrimary }}
                                primary={text.label}
                              />
                            </Link>
                            {text.submenu.map((subMenuItem, submenuIndex) => (
                              <List
                                component="li"
                                disablePadding
                                className={classes.nestedOnMobile}
                              >
                                <Link
                                  component={RouterLink}
                                  to={subMenuItem.url}
                                  key={submenuIndex}
                                  title={subMenuItem.label}
                                  className={classes.menuLink}
                                  onClick={() => handleMenuClick()}
                                >
                                  <ListItemText primary={subMenuItem.label} />
                                </Link>
                              </List>
                            ))}
                          </List>
                        </ListItem>
                      </List>
                    );
                  })}
                {open &&
                  MENU.map((text, index) => {
                    return (
                      <Link
                        component={!text.disabled ? RouterLink : undefined}
                        to={text.url}
                        key={index}
                        title={text.label}
                        className={`${classes.menuLink} ${
                          open === false ? classes.customhoverlink : ""
                        } ${text.disabled ? "disabled" : ""}`}
                        onClick={() => handleMenuClick()}
                      >
                        <ListItem
                          button
                          disableRipple={text.disabled}
                          disableTouchRipple={text.disabled}
                          disableFocusRibble={text.disabled}
                          selected={findSelected(text.url)}
                          className={`${classes.menuItem} ${
                            text.disabled ? "disabled" : ""
                          }`}
                          onClick={() => handleExpand(text)}
                        >
                          <ListItemIcon>
                            <Icon>
                              {findSelected(text.url)
                                ? text.iconActive
                                : text.icon}
                            </Icon>
                          </ListItemIcon>
                          <ListItemText primary={text.label} />
                        </ListItem>
                        <Collapse
                          in={text.submenu.length > 0 && open}
                          timeout="auto"
                          className="collaps-menu"
                        >
                          <Collapse
                            in={isExpand ? isExpand.includes(text.url) : false}
                            timeout="auto"
                          >
                            <List className={clsx(classes.nested)}>
                              {text.submenu.map((subMenuItem, submenuIndex) => (
                                <Link
                                  component={RouterLink}
                                  to={subMenuItem.url}
                                  key={submenuIndex}
                                  title={subMenuItem.label}
                                  className={classes.menuLink}
                                  onClick={() => handleMenuClick()}
                                >
                                  <ListItem
                                    button
                                    selected={findSelected(subMenuItem.url)}
                                    className={classes.menuItem}
                                  >
                                    <ListItemIcon>
                                      <Icon>
                                        {findSelected(subMenuItem.url)
                                          ? subMenuItem.iconActive
                                          : subMenuItem.icon}
                                      </Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={subMenuItem.label} />
                                  </ListItem>
                                </Link>
                              ))}
                            </List>
                          </Collapse>
                        </Collapse>
                      </Link>
                    );
                  })}
              </List>
              {open && (
                <div className={classes.menuBottom}>
                  <img
                    src={menulogo}
                    alt="securra"
                    style={{ width: "140px" }}
                  />
                  <span className={classes.version}>
                    v {process.env.REACT_APP_DEPLOYED_VERSION}
                  </span>
                </div>
              )}
            </Drawer>{" "}
          </div>
        )}
        {FACILITY_LIST_DRAWER.includes(window.location.pathname) &&
          FACILITY_VIEW_ROLE && (
            <Drawer
              style={headerDisable ? { pointerEvents: "none" } : {}}
              variant="permanent"
              className={clsx(classes.Sliderdrawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx(classes.ScrollDrawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.root}>
                <div className={""}>
                  <div className={classes.baseContainer}>
                    <img
                      src={logo}
                      alt="star-health"
                      className={classes.logo}
                    ></img>
                  </div>
                </div>
              </div>
            </Drawer>
          )}
        <main
          className={
            hideToolbar
              ? clsx(
                  window.location.pathname !== "/createfacility"
                    ? classes.content
                    : classes.contentWhite,
                  classes.listedContent
                )
              : window.location.pathname !== "/createfacility"
              ? classes.content
              : classes.contentWhite
          }
          style={{
            padding:
              headerDisable  && !window.location.pathname.includes('/chat-consulation') || hideToolbar ? "0px" : "80px 30px 24px 30px",
          }}
        >
          <div className={classes.mainRoot} />
          <div className={hideToolbar ? null : classes.toolbar} />
          {ReconnectConsult.show && (
            <div className={classes.messagePendingWrapper}>
              <div className={classes.messagePendingContainer}>
                <div style={{ display: "flex" }}>
                  <div className={classes.notifyPendingIcon}>
                    <img src={ic_notify} alt="notify icon" />
                  </div>
                  <div className={classes.facilityContent}>
                    Your consultation is still in progress, you can join now!
                  </div>
                </div>
                <CustomSubmitButton
                  className={clsx(classes.ViewForm)}
                  id="rejoinButton"
                  bookedSlotId={instantdata?.bookedId}
                  onClick={() => {
                    dispatch(UpdateCallbackInfo({}));
                    if (ReconnectConsult.path.includes("/InstantCall/room")|| ReconnectConsult.path.includes("/Appointments/room")) {
                      const stateValue = instantdata?.isCallBack ? { row: { row: { _id: instantdata?.bookedId } }, consultType : instantdata?.consulationType  } : { row: { row: { _id: instantdata?.bookedId , user:{fullname:instantResponse?.name}} } }
                      dispatch(UpdateInstantType(''))
                      dispatch(UpdateCallInfo(true));
                      setReconnectConsult({ show: false, path: "" });
                      setinstantResponse({});
                      history.push({
                        pathname:  ReconnectConsult.path ?? "/InstantCall/room",
                        search: `${instantdata?.roomId}`,
                        state: stateValue,
                      });
                    } else if (
                      ReconnectConsult.path.includes("/chat-consulation")
                    ) {
                      dispatch(OnchatBusy(true));
                      setReconnectConsult({ show: false, path: "" });
                      setinstantResponse({});
                      const roomID = new URLSearchParams(location.search).get("roomID")   
                      const {name ,roomId,userId,chatLogId,chatbookedID,user,bookedId : BookedId} = chatdetails ;
                      roomID !== roomId && dispatch(EmptyMessagelist());
                      sendMsg('join',{ name, room:roomId, userId : userId || user, "type": "web",  chatLogId,bookSlotId: BookedId});
                      history.push({
                        pathname: "/chat-consulation",
                        search: `?roomID=${chatdetails?.roomId}`,
                        state: { row: { row: { _id: chatdetails?.bookedId } } },
                      });
                    }
                    
                  }}
                >
                  Re-join
                </CustomSubmitButton>
              </div>
            </div>
          )}
          {children}
        </main>
      </div>
      <SessionLogout />
      <CustomDialog
        title={"Logged Out"}
        open={isLogin}
        onClose={() => history.push("/logout")}
        size={"xs"}
        autoWidth={undefined}
        classes={{ paperFullWidth: classes.customDialogReject }}
        enableFooter={false}
        className={classes.CustomModel}
        CloseIcon={<CloseIcon />}
        style={{zIndex:3000}}
      >
        <Box>
          <Grid sm={12} className={classes.gridSpace}>
            <Typography variant="body1" component="p">
             {isMultiUser === "multi" ? "You have been logged out from the browser because we detected you tried to login in another browser. If it was not you then please change your password." : "You have multiple active sessions. We are logging out all your sessions. Kindly make sure you have only one session."}
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            className={clsx(classes.RightAlign, classes.fullWidthButton)}
          >
            <CustomSubmitButton type="button" onClick={() => history.push("/logout")}>
              Back to Login
            </CustomSubmitButton>
          </Grid>
        </Box>
      </CustomDialog>
      {isChatWidgetEnabled && <ChatWidget />}
    </div>
  );
}
