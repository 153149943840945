import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Grid, IconButton } from "@material-ui/core";
import useStyles from "./styles";
import { useHistory, useLocation } from "react-router";
import PatientInfo from "../../../components/Appointment/Consultation/PatientInfo";
import MedicalHistory from "../../../components/Appointment/Consultation/MedicalHistory";
import CaseSheet from "../../../components/Appointment/Consultation/CaseSheet";
import TabUi from "../../../components/Appointment/Consultation/TabConsultation";
import { joinConsultation, fetchInvestigation, fetchSymptoms, getPastConsultation, fetchMedicalRecords, chatRoomStatus, linkChatID, linkCallID } from "../../../api/consultation/consultation";
import { JoinConsultationData, Ilocation } from "../../../models/consultation";
import { genericObj } from "../../../models/common";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { USER_ROLES } from "../../../utils/constants";
import InstantChat from "./InstantChat";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomSubmitButton from '../../../components/common/CustomSubmitButton';
import Button from '@material-ui/core/Button';
import Notify from "../../../components/common/CommonToast";
import { DisconnectMsg } from "../../../store/actions";
import {setfollowUp}from "../Consultation/FollowUp"
const Consultation = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [unSavedChanges, setUnSavedChanges] = useState<any>(false)
  const location = useLocation<Ilocation>()
  const [data, setData] = useState<JoinConsultationData | null | undefined | any>(null)
  const [symptom, setSymptom] = useState<genericObj>({})
  const { bookedId, consultationId, consultationType, user, encounterID } = data ?? {}
  const { _id: userId } = user || {}
  const { data: investigationRes } = useQuery(["INVESTIGATIONS", ""], fetchInvestigation)
  const { data: medicalRecords } = useQuery(["MEDICAL_RECORDS", userId], fetchMedicalRecords, { enabled: !!userId })
  const investigationsArr = investigationRes?.data?.data || []
  const row: genericObj = location?.state?.row || {}
  const { connected, chatdetails ,disconnectMsg} = useSelector((state: any) => state?.instantChat);
  const { instantdata, callID } = useSelector((state: any) => state?.instantVideo)
  const Chat_Consulattion = location.pathname.includes('/chat-consulation');
  const { userRole } = useSelector((state: RootStateOrAny) => state?.auth);
  const roomID = new URLSearchParams(location.search).get("roomID")
  const [isInvalidRoom, setInvalidRoom] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [patientLeftChat,setpatientLeftChat] =useState({message:'',severity:''});
  const searchVal = location.search.split('?')[1];
  let isCallBack = searchVal?.split('/').length > 1

   useEffect(() => {
    if (row?.row?._id || instantdata?.bookedId && (isCallBack ? callID : true))
      (async () => {
        
        const response = await joinConsultation(isCallBack ? callID?.split('/')[0] : row?.row?._id ? row?.row?._id:instantdata?.bookedId);
        if (response) {
          setData(response.data);
        }
      })();
  }, [row?.row?._id, callID])

const setUnSaved = (value : boolean) =>{
  setUnSavedChanges(value);
}
  useEffect(() => {
    if (sessionStorage.getItem("prescriptionId") && row?._id !== data?.bookedId) {
      sessionStorage.removeItem("prescriptionId")
    }
  }, [data])

  useEffect(() => {
    (async () => {
      const pageNoSymptoms: number = 1
      const response = await fetchSymptoms("SYMPTOMS", pageNoSymptoms);
      if (response) {
        setSymptom(response)
      }
    })();
  }, [])

  useEffect(() =>{
    setfollowUp ({
      isFollowUp:false,
      appointmentType:"FOLLOW_UP",
      modeOfBooking:"TELEBOOKING",
      appointmentCreatedBy:"",
      appointmentCreatedPersonRole: "",
      state:"APPOINTMENT_REQUESTED",
      consultationFee:0,
      claimAmount:0,
      slot:"",
      dayOfTheWeek:0,
      date:"",
      slotStartTime: "",
      isTelemedicine:true,
      parentAppointmentId:""
  });
  },[])
/* Toaster Message for Patient Disconnect in Instant Chat*/
useEffect(()=>{
  if(disconnectMsg)
  {
    setpatientLeftChat({message : '' ,severity:''})
    setTimeout(() => {
      if(window.location.pathname === '/chat-consulation')
    {
      setpatientLeftChat({message :disconnectMsg ,severity:'error'})
      dispatch(DisconnectMsg(''));
    }
    },500)
  }
},[disconnectMsg])

  const { data: pastConsultationArray , isLoading:isPastLoading} = useQuery([userId, pageNumber], getPastConsultation, { enabled: userId });
  /* Room ID status for INSTANT CHAT */
  const RoomIdValidation = async (roomID) =>
  {
    try {
      const res = await chatRoomStatus(roomID);
      if(res && res?.status < 350 && res?.data?.status === 'AVAILABLE')
      setInvalidRoom(false)
      else
      setInvalidRoom(true)
    }
    catch (e) {
      setInvalidRoom(true)
    }
  }
  useEffect(() => {
    if (window.location.pathname === '/chat-consulation') {
      if (roomID)
      RoomIdValidation(roomID);
    }
  }, [roomID])
  useEffect(() => {
    if (window.location.pathname === '/chat-consulation' && sessionStorage.getItem('LinkChatID')){
      try {
        (async () => {
          await linkChatID(chatdetails?.chatLogId, row?.row?._id)
        })()
      } catch (e) { }
      sessionStorage.removeItem('LinkChatID');
    }
      
    if (window.location.pathname === '/InstantCall/room' && sessionStorage.getItem('LinkCallID')){
      try {
        (async () => {
          const call_Id = isCallBack ? callID?.split('/')[1] : instantdata?.callId;
          const book_ID = isCallBack ? callID?.split('/')[0] : row?.row?._id
          await linkCallID(call_Id, book_ID)
        })()
      } catch (e) { }
      sessionStorage.removeItem('LinkCallID');
    }
    window.onfocus = function () 
    {
      if (window.location.pathname === '/chat-consulation')
      roomID && RoomIdValidation(roomID);
    }
    return () => {
      window.onfocus = null;
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname === '/chat-consulation') {
      sessionStorage.setItem('INSTANT_CHAT_INITIATED', 'true');
    }
    else if (window.location.pathname === '/InstantCall/room') {
      sessionStorage.setItem('INSTANT_CALL_INITIATED', 'true');
    }
  }, [])

  return (
    <>
    {patientLeftChat.message && <Notify severity={patientLeftChat?.severity} message={patientLeftChat?.message} />}
      {Chat_Consulattion && connected && <InstantChat roomId={roomID} />}
      <PatientInfo userData={user && user} bookedId={bookedId} encounterID={encounterID} consultationType={consultationType} consultationDetails={data} unSavedChanges={unSavedChanges}/>
      <MedicalHistory userId={userId} />
      <div className={classes.SecondaryContainer}>
        {(userRole !== USER_ROLES.receptionist && userRole !== USER_ROLES.facility_receptionist) && <Grid container xs={6}>
          <CaseSheet
            investigationsArray={investigationsArr}
            symptomsArr={symptom?.data?.data}
            caseSheetId={consultationId ? consultationId : ""}
            data={data ? data : {}}
            patient={user ? user : {}}
          />
        </Grid>}
        <Grid container xs={userRole !== USER_ROLES.receptionist && userRole !== USER_ROLES.facility_receptionist ? 12 : 8}>
          <TabUi  pastConsultationArray={pastConsultationArray ? pastConsultationArray : []} data={data && data} medicalRecords={medicalRecords ? medicalRecords?.data : []} notAReceptionist={userRole !== USER_ROLES.receptionist && userRole !== USER_ROLES.facility_receptionist} setUnSavedChanges={setUnSaved} setPageNumber={setPageNumber} pageNumber={pageNumber} isPastLoading={isPastLoading}/>
        </Grid>
      </div>
      <Dialog open={isInvalidRoom === true} fullWidth={true} maxWidth="xs">
        <DialogTitle className={classes.titleRoot}>
          Permission denied
          <IconButton className="close-btn" onClick={() => {setInvalidRoom(false) }}>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{ fontWeight: 500, color: "#000000" }}>
              <div style={{ paddingTop: "8px" }}>
                Invalid Meeting Id
              </div>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '16px' }}>
          <Button onClick={() => {
            history.push("/Dashboard")
          }} className={classes.cancelBtn}>
            Close
          </Button>
          <CustomSubmitButton
            className={undefined}
            isLoading={false}
            onClick={() => { window.location.reload(); }}
          >
            Try Again
          </CustomSubmitButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Consultation;