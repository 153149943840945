
import { Tooltip } from "@material-ui/core"
import styles from './style';

interface ItemType{
    title:string
    placement: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined
}
const EllipsisTooltip=({ title,placement}:ItemType)=>{
    const classes = styles();
    
        return(
        <>
           <Tooltip title={title} placement={placement} classes={{popper:classes.toolTip}}>
            <span className={classes.ellipsis}>
                {title}
            </span>
            </Tooltip>
        </>
    )
}
export default EllipsisTooltip