import { Grid, Tooltip } from "@material-ui/core";
import useStyles from "./styles";
import { useEffect, useState } from "react"
import CustomCancelButton from "../../../../components/common/CustomCancelButton";
import { endConsultationUpdate, getAllUserDataParameter, addMedicines, getDataParameterList } from "../../../../api/consultation/consultation";
import { RootStateOrAny, useSelector } from "react-redux";
import RejectPopup from "../TabConsultation/endConsultationPopup";
import { Dialog, DialogTitle, IconButton, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";
import PersonIcon from '@mui/icons-material/Person';
import { Consultation, HeaderValue } from "../../../../models/consultation";
import { USER_ROLES } from "../../../../utils/constants";
import { removeUser, IRemoveUser, IendVideoCall, roomDeleteApi } from "../../../../api/InstantVideo/Instant";
import CustomSubmitButton from "../../../common/CustomSubmitButton";
import Add_Doctor from "../../../../assets/images/Add_Doctor.png";
import Schedule from "../../../../assets/images/Schedule.png";
import OnlineDoctorlist from "../../../OnlineDoctorlist";
import { useHistory, useLocation } from "react-router-dom";
import { sendMsg } from "../../../Websocket/InstantChatIO";
import AppointmentManagement from '../../../../pages/AppointmentManagement/index'
import moment from "moment";
import Notify from "../../../common/CommonToast";
import { getfollowUp } from "../../../../pages/AppointmentManagement/Consultation/FollowUp";
import { fixFollowUpAppointment } from "../../../../api/AppointmentManagement/Appointment";
import { sendInstant } from "../../../Websocket/InstantVideo";
import CommonDropDown from "../../../common/CommonDropDown";
import CommonMenuItem from "../../../common/CommonDropDown/CommonMenuItem";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { validations } from "../../../../config/validations.config";
import CommonTextField from "../../../common/CommonTextField";
import CustomFormEM from "../../../common/CustomFormEM";
import CommonDatePicker from "../../../common/CommonDatePicker";
import { useQuery } from "react-query";
import UnsavedChangesWarning from "../unsavedWarning";

var currentTime: any;

const PatientInfo = ({ userData, bookedId, encounterID, consultationType, patientDetail, consultationDetails, unSavedChanges }: Consultation) => {
  const classes = useStyles();
  const {
    userName = "-",
    dob = "",
    height = "",
    weight = "",
    bloodGroup = "",
    gender = "",
    name,
    imageUrl = "",
    profilePic = "",
    photo = "",
    profile = {},
  } = userData || patientDetail || {};
  const history = useHistory();
  const { user, userRole, dbData } = useSelector((state: RootStateOrAny) => state.auth);
  const clinic = useSelector((state: RootStateOrAny) => state?.clinic?.details);
  const { instantdata, connecting, roomStatus } = useSelector((state: any) => state?.instantVideo);
  const location = useLocation();
  const { consultType }: any = location?.state || {}
  const { chatdetails } = useSelector((state: any) => state?.instantChat);
  const receptionist = userRole === USER_ROLES.facility_receptionist || userRole === USER_ROLES.opd_ops || userRole === USER_ROLES.receptionist;
  const CallType = location.pathname.includes('/InstantCall/room')
  const [modalVisible, setmodalvisible] = useState<boolean>(false);
  const [warningDlg, setwarningDlg] = useState(false);
  const [EndReason, setEndReason] = useState({ rejectComment: '', selectReason: '' })
  const [open, setOpen] = useState(false);
  const [patientModal, setPatientModal] = useState(false);
  const [openFixAppointment, setOpenFixAppointment] = useState<boolean>(false);
  const headers = new window.Headers();
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: "",
  });
  const [displayInfo, setDisplayInfo] = useState<any>(null)
  const { data: allPatientInfo, refetch: refetchPatientInfo } = useQuery(
    [
      "GET_ALL_ALLERGY_LIST",
      {
        UserId: userData?._id,
        DataparameterCode: ["SCL110L212", "SCL110L900", "SCL110L23", "SCL110L216", "SCL110L235", "SCL110L26"],
      },
    ],
    getAllUserDataParameter,
    { enabled: userData?._id, retry: 0 }
  );

  const { data: allGender } = useQuery(
    [
      "GET_ALL_GENDER_LIST",
      {
        code: "SCL110L212",
      },
    ],
    getDataParameterList, { retry: 0 }
  );

  const { data: allBloodGroup } = useQuery(
    [
      "GET_ALL_BLOOD_GROUP_LIST",
      {
        code: "SCL110L900",
      },
    ],
    getDataParameterList, { retry: 0 }
  );

  useEffect(() => {
    if (consultationType === "VIRTUAL_CONSULTATION" || location.pathname.includes('/InstantCall/room') || location.pathname.includes('/chat-consulation')) {
      let timerValue;
      currentTime = new Date();
      clearInterval(timerValue);
      timerValue = undefined;
      const countdownNumberEl = document.getElementById("countdown-number");
      if (countdownNumberEl !== null) {
        countdownNumberEl.textContent = null;
      }
      timerValue = setInterval(() => {
        const countdownNumberEl = document.getElementById("countdown-number");
        const systemdate: any = new Date();
        let difference: any = Math.floor((systemdate - currentTime) / 1000).toLocaleString().replace(',', '');
        const minutes: number = Math.floor(difference / 60);
        const seconds: number = Math.floor(difference % 60);
        const hours: number = Math.floor(minutes / 60);
        if (countdownNumberEl !== null) {
          countdownNumberEl.textContent = `${hours < 10 ? "0" + hours : hours} : ${minutes < 10 ? "0" + minutes : minutes} : ${seconds < 10 ? "0" + seconds : seconds}`;
        }
      }, 1000);
    }
  }, [consultationType])

  const schema = Yup.object().shape({
    height: validations.validHeightRequired,
    weight: validations.validWeightRequired,
    temperature:  Yup.number().integer().nullable().transform((_, val) => (val !== "" ? Number(val) : null)).moreThan(dbData?.MINIMUM_TEMP_FARHENHEIT-1,`Minimum value should be ${dbData?.MINIMUM_TEMP_FARHENHEIT}`).lessThan(dbData?.MAXIMUM_TEMP_FARHENHEIT+1,`Maximum value should be ${dbData?.MAXIMUM_TEMP_FARHENHEIT}`),
  });

  const {
    control,
    errors,
    handleSubmit,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (allPatientInfo && allPatientInfo.data && allGender && allGender.data && allBloodGroup && allBloodGroup.data && patientModal) {
      setTimeout(() => {
        allPatientInfo.data.forEach(item => {
          switch (item.dataparameterCode) {
            case "SCL110L212":
              setValue("gender", item.selectedValues[0].dataParameterMasterValueId)
              break;
            case "SCL110L23":
              setValue("dob", moment(item.value,["DD/MM/YYYY","YYYY/MM/DD","MM/DD/YYYY"]))
              break;
            case "SCL110L900":
              setValue("bloodGroup", item.selectedValues[0].dataParameterMasterValueId)
              break;
            case "SCL110L216":
              setValue("height", item.value)
              break;
            case "SCL110L235":
              setValue("weight", item.value)
              break;
            case "SCL110L26":
              setValue("temperature", item.value)
              break;
          }
        })
      }, 100)
    }
    if (allPatientInfo && allPatientInfo.data) {
      const userInfo: any = {}
      allPatientInfo.data.forEach(item => {
        switch (item.dataparameterCode) {
          case "SCL110L212":
            userInfo.gender = item.selectedValues[0].dataParameterMasterValueId;
            break;
          case "SCL110L23":
            userInfo.dob = item.value;
            break;
          case "SCL110L900":
            userInfo.bloodGroup = item.selectedValues[0].dataParameterMasterValueId;
            break;
          case "SCL110L216":
            userInfo.height = item.value;
            break;
          case "SCL110L235":
            userInfo.weight = item.value;
            break;
        }
      })
      !userInfo.dob && (userInfo.dob = dob);
      !userInfo.height && (userInfo.height = height);
      !userInfo.weight && (userInfo.weight = weight);
      setDisplayInfo(userInfo)
    }
  }, [allPatientInfo, allBloodGroup, allGender, patientModal])

  const closeModal = (value: boolean) => {
    setmodalvisible(value);
  };
  const UpdateFeedback = (value: any) => {
    setEndReason({ ...EndReason, ...value });
  }
  const endConsulPayload = {
    bookedId: bookedId || '',
    role: userRole,
    consultType: consultationType || '',
    reason: EndReason?.selectReason || '',
    comments: EndReason?.rejectComment || '',
    reference: user?._id,
    duration: 0
  };
  const UserRoomPayload: IRemoveUser =
  {
    callId: instantdata?.callId || '',
    role: userRole,
    room_name: instantdata?.roomId || '',
    user_identity: user?._id,
  }
  const EndVideoCall: IendVideoCall =
  {
    callId: instantdata?.callId || '',
    comment: EndReason?.rejectComment || '',
    room_name: instantdata?.roomId || '',
    user_identity: user?._id,
    consultationType: 'INSTANT',
    role: userRole,
    duration: 0,
    reason: EndReason?.selectReason || '',
    bookedId: bookedId || '',
  }
  const getDuration = () => {
    const count = document.getElementById("countdown-number")?.textContent;
    let array = count && count.split(':')
    let Duration = 0;
    if (array) {
      let hour = Number(array[0]);
      let minutes = Number(array[1]);
      let seconds = Number(array[2]) > 0 ? Number(array[2]) / 100 : Number(array[2]);
      Duration = hour > 0 ? hour * 60 + minutes + seconds : minutes + seconds
    }
    EndVideoCall.duration = Duration || 0;
    endConsulPayload.duration = Duration || 0;
  }

  const clickToEndConsultation = async () => {
    try {
      getDuration();
      if (CallType) {
        if (receptionist && instantdata?.doctorConnected) {
          /*check for Receptionist leave on doctor Arrive*/
          await removeUser('RemoveUser', UserRoomPayload).then((res: any) => {
            if (res?.message) {
              closeCall(res)
            }
          })
        } else {
          endVideoCallForReceptionist()
        }
      } else {
        /* End call for scheduled call*/
        await endConsultationUpdate("endConsultation", endConsulPayload).then((res: any) => {
          if (res?.message) {
            closeCall(res)
          }
        })
      }
    }
    catch (e) {
      if(e?.response?.data?.code === 1005){
      history.push({ pathname: "/Appointments", state: { response: {message: e?.response?.data?.message} } });
      }
      else{
        setRequest({
          loading: false,
          message: e?.response?.data?.message || "Something went wrong",
          severity: 'error',
        })
      }
      
     }
  };

  const closeCall = async (res: any) => {
    setmodalvisible(false);
    window.location.pathname.includes('/chat-consulation') && sendMsg('doctorEndCall', { room: chatdetails?.roomId });
    sendInstant(JSON.stringify({ 'userId': user?._id, 'status': 'online', "method": "updateStatus" }));
    history.push({ pathname: "/Appointments", state: { response: res} });
  }

  const endVideoCallForReceptionist = async () => {
        if (receptionist && !instantdata?.doctorConnected) {
      /* End call for receptionist when doctor not joined the call*/
      // await endVideoCall('endVideoCall', EndVideoCall).then((res:any)=>{
      //   if(res?.message){
      //     closeCall(res)
      //   }
      // })
              const endResponse = await endConsultationUpdate("endConsultation", endConsulPayload)
        if (endResponse?.message) {
          closeCall(endResponse)   
            }
    } else {
      /* End call for doctor in instant call*/
      /* const res = await endVideoCall('endVideoCall', EndVideoCall)
      if(res?.message){
        const endResponse = await endConsultationUpdate("endConsultation", endConsulPayload)
        if(endResponse?.message){
          closeCall(endResponse)
        }
      } */
      const endResponse = await endConsultationUpdate("endConsultation", endConsulPayload)
      if (endResponse?.message) {
        closeCall(endResponse)
              }
    }
  }

  const setOpenDialog = () => {
    setOpen(true);
  }
  const closeDialog = () => {
    setOpen(false);
  }
  const clickToEnd = () => {
    if (unSavedChanges) {
      setwarningDlg(true)
    } else {
      setmodalvisible(true)
    }
  }
const endFlow = async () => {
  try {
    let room_name = ''
    if (location.pathname === '/Appointments/room' && consultType)
      room_name = consultType?.row?._id;
    else {
      const searchVal = location.search.split('?')[1];
      if (searchVal?.split('/').length > 1) {
        room_name = searchVal?.split('/')[1];
      } else {
        room_name = searchVal;
      }
    }
    const response = await roomDeleteApi('Room_Deletion_Api', room_name);
    if (response?.status === 200) {
      headers.set('content-type', 'application/json');
      clickToEndConsultation()
    }
    else
      setRequest({
        loading: false,
        message: response?.data?.message || "Something went wrong",
        severity: "",
      });
  }
  catch (err) {
    setRequest({
      loading: false,
      message: err?.response?.data?.message || "Something went wrong",
      severity: 'error',
    })
  }
}
  const endCall = async () => {
    setRequest({
      loading: true,
      message: "",
      severity: "",
    });
    const value = getfollowUp();
    value.appointmentCreatedBy = user?._id;
    value.appointmentCreatedPersonRole = USER_ROLES.doctor;
    value.parentAppointmentId = bookedId || '';
    if(value?.isFollowUp)
    {
      if(value?.date && value?.slot)
      await fixFollowUpAppointment(value).then(() => endFlow()).catch((err) => {
        setRequest({
          loading: false,
          message: err?.response?.data?.message || "Something went wrong",
          severity: 'error',
        })
    });
      else
      setRequest({
        loading: false,
        message: `Make sure the date and slot has been selected for followup`,
        severity: "error",
      });
    }
    else
    endFlow();
  };
  const onSubmit = async (values) => {
    try {
      setRequest({
        loading: true,
        message: "",
        severity: "",
      });
      let successCount: number = 0;
      const obj = Object.fromEntries(Object.entries(values).filter(([_, v]) => v))
      if (Object.keys(obj).length > 0) {
        for (const val in obj) {
          const payLoad: any = {
            type: "HP",
            clinicId: clinic.id,
            UserId: userData?._id,
            createdBy: user?._id,
            createdByRoleName: userRole
          }
          switch (val) {
            case "temperature":
              payLoad.dataparameterCode = "SCL110L26",
                payLoad.value = `${values[val]}`
              break;
            case "weight":
              payLoad.dataparameterCode = "SCL110L235",
                payLoad.value = `${values[val]}`
              break;
            case "height":
              payLoad.dataparameterCode = "SCL110L216",
                payLoad.value = `${values[val]}`
              break;
            case "dob":
              payLoad.dataparameterCode = "SCL110L23",
                payLoad.value = moment(values[val]._d).format("DD/MM/YYYY")
              break;
            case "bloodGroup":
              payLoad.dataparameterCode = "SCL110L900",
                payLoad.selectedValues = allBloodGroup.data.filter(item => item.id === values[val])
              break;
            case "gender":
              payLoad.dataparameterCode = "SCL110L212",
                payLoad.selectedValues = allGender.data.filter(item => item.id === values[val])
              break;
          }
          const res = await addMedicines("", payLoad)
          if (res.statusCode === 200) {
            successCount += 1;
          }
        }
        if (successCount === Object.keys(obj).length) {
          setRequest({
            loading: false,
            message: `${process.env.REACT_APP_PATIENT}s vitals updated successfully`,
            severity: "",
          });
          refetchPatientInfo();
          setPatientModal(false);
        } else {
          setRequest({
            loading: false,
            message: "Something went wrong",
            severity: "",
          });
        }
      }
    } catch (err) {
      setRequest({
        loading: false,
        message: err?.response?.data?.message || "Something went wrong",
        severity: "",
      });
    }
  }
  return (
    <>
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <div className={classes.mainContainer}>
        {CallType && <OnlineDoctorlist open={open} closeDialog={closeDialog} />}
        <div className={classes.patientInfo}>
          <div className={classes.patientImg}>
            {profilePic || imageUrl || photo || profile?.picture ? <img src={profilePic || imageUrl || photo || profile?.picture} alt="profile" className='photoSize' /> : <PersonIcon fontSize="large" />}
          </div>
          <div className={classes.patientContentWrap}>
            <div className={classes.patientTextWrap}>
              <HeaderData heading="Name" content={userName !== '-' ? (userName?.length > 20 ? <Tooltip title={userName}><p className={classes.patientNameTooltip}>{userName}</p></Tooltip>  :  userName) :( `${name?.first} ${name?.last}`?.length > 20 ? <Tooltip title={userName}><p className={classes.patientNameTooltip}>{`${name?.first} ${name?.last}`}</p></Tooltip> : `${name?.first} ${name?.last}`)} isBorder={true} />
              <HeaderData heading="Age" content={`${displayInfo?.dob ? moment.duration(moment().diff(moment(displayInfo?.dob,["DD/MM/YYYY","YYYY/MM/DD","MM/DD/YYYY"]),'milliseconds')).get('years') : '-'} Yrs`} isBorder={true} />
              <HeaderData heading="Gender" content={displayInfo?.gender ? allGender?.data.filter(item => item.id === displayInfo?.gender)[0].value : (gender || "-")} isBorder={true} />
              <HeaderData heading="Date of Birth" content={displayInfo?.dob ? moment(displayInfo?.dob,["DD/MM/YYYY","YYYY/MM/DD","MM/DD/YYYY"]).format("DD/MM/YYYY") : "-"} isBorder={true} />
              <HeaderData
                heading="Blood Group"
                content={displayInfo?.bloodGroup ? allBloodGroup?.data.filter(item => item.id === displayInfo?.bloodGroup)[0].value : (bloodGroup || "-")}
                isBorder={true}
              />
              <HeaderData heading="Height" content={`${displayInfo?.height || "-"}cm`}isBorder={true} />
              <HeaderData heading="Weight" content={`${displayInfo?.weight || "-"} kg`}isBorder={true} />
              <HeaderData heading="BMI" content={`${(displayInfo?.weight && displayInfo?.height) ? ((displayInfo.weight / (displayInfo.height * displayInfo.height)) * 10000).toFixed(2) : "-"}kg/m2`}isBorder={false} />
            </div>
            <div className={classes.editPatientWrap}>
              <CustomSubmitButton type="button" className={classes.editPatientbtn} onClick={() => setPatientModal(!patientModal)}>
             Edit {process.env.REACT_APP_PATIENT} Vitals
              </CustomSubmitButton>
            </div>
          </div>
        </div>

        {!patientDetail && !receptionist && <Grid container xs={4} className={classes.sectionTwo}>
          <div className={classes.topContainer}>
            <div>
              <h4 className={classes.idContainer}>Consultation ID</h4>
              <span>{encounterID}</span>
            </div>
            <CustomCancelButton type="button" onClick={() => {/* console.log("click") */ }}>
              Active
            </CustomCancelButton>
          </div>
          <div className={classes.bottomContainer}>
            <div style={{ display: "flex", gap: "1rem" }}>
              {(consultationType === "VIRTUAL_CONSULTATION" || CallType || location.pathname.includes('/chat-consulation')) && <div id="countdown-number" className={classes.countdownNumber}>
              </div>}
              {(consultationType === "PHYSICAL_CONSULTATION") &&
                <div>
                  <h4 className={classes.paymentDiv}>Payment</h4>
                  <span>{consultationDetails?.toPay?.amount?.toPay?.amount?.amount ? consultationDetails?.toPay?.amount?.toPay?.amount?.amount : 0} ₹</span>
                </div>}
            </div>
            <CustomCancelButton type="button" onClick={clickToEnd}>
              {location.pathname.includes('/chat-consulation') ? "End Chat" : CallType ? 'End Call' : 'End Consultation'}
            </CustomCancelButton>
          </div >
        </Grid >}
        {CallType && receptionist &&
          <Grid container xs={4} className={classes.sectionTwo}>
            {!instantdata?.doctorConnected && !connecting.show && roomStatus && <div className={classes.buttonClass}>
              {consultType !== 'VIRTUAL_CONSULTATION' && <CustomSubmitButton className={classes.AddDoctor} onClick={() => { setOpenDialog() }}><img src={Schedule} style={{ margin: ' 0px 10px' }} />Add Doctor </CustomSubmitButton>}
              <CustomSubmitButton className={classes.AddDoctor} onClick={() => {
                setOpenFixAppointment(true)
              }}> <img src={Add_Doctor} style={{ margin: '0px 10px' }} />Schedule</CustomSubmitButton>
            </div>}
            <div className={classes.buttonClassEnd}>
              <div style={{ display: "flex", gap: "1rem" }}>
                <div className={classes.Activestatus}>
                  <span className={classes.status} />
                  Active
                </div>
                {(consultationType === "VIRTUAL_CONSULTATION" || location.pathname.includes('/InstantCall/room')) && <div id="countdown-number" style={{ fontWeight: 800, fontSize: '14px' }}>
                </div>}
              </div>
              <CustomSubmitButton className={classes.EndCall} onClick={() => setmodalvisible(true)}>
                {CallType ? 'End Call' : 'End Consultation'}
              </CustomSubmitButton>
            </div>
          </Grid>
        }
        <RejectPopup modalVisible={modalVisible} closeModal={closeModal} clickToEndConsultation={endCall} setEndReason={UpdateFeedback} consultationType={consultationType} isLoading={request.loading} />
        {/* openFixAppointment &&  */<AppointmentManagement type="Appointment" isInstant={true} setOpenAppointment={setOpenFixAppointment} openAppointment={openFixAppointment} userData={userData} />}
        <UnsavedChangesWarning open={warningDlg} onClose={() => { setwarningDlg(false)}} onSubmit={() => {setwarningDlg(false),setmodalvisible(true)}}/>
      </div>
      <form onSubmit={() => handleSubmit(onSubmit)}>
        <Dialog open={patientModal} fullWidth={true} maxWidth="xs">
          <DialogTitle className={classes.titleRoot}>
            Edit {process.env.REACT_APP_PATIENT} Vitals
            <IconButton className="close-btn" onClick={() => { }}>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span style={{ fontWeight: 500, color: "#000000" }}>
                <div style={{ paddingTop: "8px" }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs>
                      <Controller
                        name="gender"
                        control={control}
                        render={(
                          controlProps: ControllerRenderProps<Record<string, any>>
                        ) => (
                          <CommonDropDown
                            multiple={false}
                            variant="filled"
                            className={classes.dates}
                            onChange={(e: any) => {
                              controlProps.onChange(e.target.value);
                            }}
                            value={controlProps.value || ""}
                            label={'Gender'}
                            disabled={true}
                          >
                            {
                              allGender && allGender.data && allGender.data.map(gender => (
                                <CommonMenuItem value={gender.id}>{gender.value}</CommonMenuItem>
                              ))
                            }
                          </CommonDropDown>
                        )}
                      />
                    </Grid>
                    <Grid item xs>
                      <Controller
                        name="dob"
                        control={control}
                        render={(controlProps) => {
                          return (
                            <CommonDatePicker
                              label={"Date Of Birth"}
                              format="DD MMM YYYY"
                              className={classes.dates}
                              inputProps={{ readOnly: true }}
                              placeholder="Select Date Of Birth"
                              maxDate={moment(new Date()).subtract(2, 'years').format("YYYY/MM/DD")}                              
                              value={controlProps.value || null}
                              onChange={(e: any) => {
                                controlProps.onChange(e)
                              }}
                              disabled={true}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Controller
                        name="bloodGroup"
                        control={control}
                        render={(
                          controlProps: ControllerRenderProps<Record<string, any>>
                        ) => (
                          <CommonDropDown
                            multiple={false}
                            variant="filled"
                            className={classes.Consult}
                            onChange={(e: any) => {
                              controlProps.onChange(e.target.value);
                            }}
                            value={controlProps.value || ""}
                            label={'Blood Group'}
                          >
                            {
                              allBloodGroup && allBloodGroup.data && allBloodGroup.data.map(blood => (
                                <CommonMenuItem value={blood.id}>{blood.value}</CommonMenuItem>
                              ))
                            }
                          </CommonDropDown>
                        )}
                      />
                    </Grid>
                    <Grid item xs>
                      <Controller
                        name="height"
                        control={control}
                        render={(
                          controlProps: ControllerRenderProps<Record<string, any>>
                        ) => (
                          <CommonTextField
                            type="number"
                            variant="filled"
                            label="Height (in cms)"
                            placeholder="Height (in cms)"
                            value={controlProps.value}
                            onChange={(e: { target: { value: string } }) => {
                              controlProps.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <CustomFormEM
                        errors={errors}
                        name="height"
                        render={({ message }) => <p>{message}</p>}
                      />
                    </Grid>
                    <Grid item xs>
                      <Controller
                        name="weight"
                        control={control}
                        render={(
                          controlProps: ControllerRenderProps<Record<string, any>>
                        ) => (
                          <CommonTextField
                            type="number"
                            variant="filled"
                            label="Weight (in kgs)"
                            placeholder="Weight (in kgs)"
                            value={controlProps.value}
                            onChange={(e: { target: { value: string } }) => {
                              controlProps.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <CustomFormEM
                        errors={errors}
                        name="weight"
                        render={({ message }) => <p>{message}</p>}
                      />
                    </Grid>
                    <Grid item xs>
                      <Controller
                        name="temperature"
                        control={control}
                        render={(
                          controlProps: ControllerRenderProps<Record<string, any>>
                        ) => (
                          <CommonTextField
                            type="number"
                            variant="filled"
                            label="Temperature (in Fahrenheit)"
                            placeholder="Temperature (in Fahrenheit)"
                            value={controlProps.value}
                            onChange={(e: { target: { value: string } }) => {
                              controlProps.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <CustomFormEM
                        errors={errors}
                        name="temperature"
                        render={({ message }) => <p>{message}</p>}
                      />
                    </Grid>
                  </Grid>
                </div>
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.unsavedTab}>
            <CustomSubmitButton
              className={classes.cancleBtn}
              isLoading={false}
              disabled={request?.loading}
              onClick={() => setPatientModal(!patientModal)}
            >
              Cancel
            </CustomSubmitButton>
            <CustomSubmitButton
              type="submit"
              isLoading={request?.loading}
              disabled={request?.loading}
              onClick={handleSubmit(onSubmit)}
              className={classes.submitBtn}>
              Save
            </CustomSubmitButton>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default PatientInfo;

const HeaderData = ({ heading, content, isBorder }: HeaderValue) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={
          isBorder ? classes.textBorderWrapper : classes.textWrapper
        }
      >
        <p className={classes.paragraph}>{heading}</p>
        <h3 className={classes.heading}>{content}</h3>
      </div>
    </>
  );
};
