import { useEffect, useState, ChangeEvent } from "react";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { validations } from "../../../../config/validations.config";
import useStyles from "./styles";
import clsx from "clsx";
import { CircularProgress, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import CommonTextField from "../../../../components/common/CommonTextField";
import CommonSearchMultiselect from "../../../common/CommonSearchMultiselect";
import CustomFormEM from "../../../common/CustomFormEM";
import { autoUpdateCaseSheet } from "../../../../api/consultation/consultation";
import { IInvestigationValue, ICommonDropDown, IcaseSheet } from "../../../../models/consultation";
import { RootStateOrAny, useSelector } from "react-redux";
import { USER_ROLES } from "../../../../utils/constants";
import CommonDropDown from "../../../common/CommonDropDown";
import CommonMenuItem from "../../../common/CommonDropDown/CommonMenuItem";
import CommonDatePicker from "../../../common/CommonDatePicker";
import moment from "moment";
import { useQuery } from "react-query";
import { getSlotList } from "../../../../api/AppointmentManagement/Appointment";
import { setfollowUp, followUp } from "../../../../pages/AppointmentManagement/Consultation/FollowUp";
import Notify from "../../../Notify";
import { getTotalBookedAppointment } from "../../../../api/dashboardDetails";
let debounceTimer: NodeJS.Timeout | null = null;
type AutoSaveType = "onchange" | "onblur";

const CaseSheet = ({
  investigationsArray = [],
  caseSheetId = "",
  symptomsArr,
  data,
  patient
}: IcaseSheet) => {
  const [tablePage, setTablePage] = useState(1);
  const [filteredArr, setFilteredArr] = useState<any>([])
  const splitArry = investigationsArray.map((item: IInvestigationValue) => item.testName.map((value: { _id: string, value: string }) => ({ _id: value?._id, name: value?.value, id: value?._id }))).flat(1);
  const classes = useStyles();
  const [timeSlotList, setTimeSlotList] = useState<any>([]);
  const { userRole, user } = useSelector((state: RootStateOrAny) => state?.auth);
  useEffect(() => {
    return () => {
      if (debounceTimer) clearTimeout(debounceTimer);
    };
  }, []);

  const schema = Yup.object().shape({
    complaints: validations.commonRequired,
    diagnosis: validations.commonRequired,
    notes: validations.commonRequired,
    investigation: validations.commonRequired,
    symptoms: validations.commonRequired,
  });
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: "",
  });

  const {
    control,
    errors,
    getValues,
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      complaints: "",
      diagnosis: "",
      symptoms: Array<string>(),
      investigation: Array<string>(),
      notes: "",
      appointmentDate: '',
      FollowUp: '',
    },
  });
  const { details } = useSelector((state: RootStateOrAny) => state.clinic);
  const { appointmentDate, FollowUp } = getValues();
  const { data: timeSlots, isFetching: timeSlotFetching } = useQuery(
    ["getSlotList",
      {
        facilityId: details?.id,
        practitionerId: user?._id,
        roleId: userRole,
        primaryUserId: patient?._id,
        consultType: 'VIRTUAL_CONSULTATION',
        date: moment(appointmentDate).format("YYYY/MM/DD"),
        "isTelemedicine": true,
      }
    ],
    getSlotList,
    { enabled: FollowUp === 'YES' && appointmentDate }
  );

  useEffect(() => {
    if (data) {
      if (symptomsArr && data?.symptoms?.length > 0) {
        let symptomsVal: { id: string, name: string }[] = [];
        symptomsArr?.forEach((val) => {
          if (data?.symptoms?.indexOf(val?.id) !== -1) {
            symptomsVal.push(val)
          }
        });
        control.setValue("symptoms", symptomsVal)
      }

      if (splitArry && data?.investigation?.length > 0) {
        let investigationVal: { id: string, name: string, _id: string }[] = []
        splitArry?.forEach((val) => {
          if (data?.investigation?.indexOf(val?.id) !== -1) {
            investigationVal.push(val)
          }
        });
        control.setValue("investigation", investigationVal)
      }
      control.setValue("complaints", data?.complaints)
      control.setValue("diagnosis", data?.diagnosis)
      control.setValue("notes", data?.notes)
    }
  }, [data, symptomsArr]);

  watch(["complaints", "diagnosis", "symptoms", "investigation", "notes", "appointmentDate", "FollowUp"]);

  const autoSave = async (actionType: AutoSaveType) => {
    if (debounceTimer) clearTimeout(debounceTimer);
    debounceTimer = setTimeout(
      () => {
        debounceTimer = null;
        if (caseSheetId) autoUpdateCaseSheet(caseSheetId, getValuePayload());
      },
      actionType === "onblur" ? 0 : 3000
    );
  };

  const getcountAppoinment = async () => {
    try {
      const response: any = await getTotalBookedAppointment(data.user._id);
      !response?.data.isAllowed &&
        setRequest({
          loading: false,
          message: response?.data?.message || 'Something went wrong',
          severity: response?.data?.severity,
        });
      return response?.data.isAllowed;
    } catch (err) {
      setRequest({
        loading: false,
        message: err?.response?.data?.message || 'Something went wrong',
        severity: err?.response?.data?.severity,
      });
      return false
    }

  }


  const { investigation } = getValues();
  useEffect(() => {
    setFilteredArr(
      splitArry?.filter((ad: any) =>
        investigation?.every((fd: any) => fd.id !== ad.id)
      ));
  }, [investigation]);

  const getValuePayload = () => {
    const value = getValues()
    let payload = {
      symptoms: value.symptoms.map((val: any) => { return val.id }),
      diagnosis: value.diagnosis,
      complaints: value.complaints,
      notes: value.notes,
      investigation: value.investigation.map((val: any) => { return val.id }),
    }
    return payload
  }

  useEffect(() => {
    if (timeSlots) {
      const { Morning, AfterNoon, Evening, Night } = timeSlots || {}
      let slotMap: any = [...Morning, ...AfterNoon, ...Evening, ...Night];

      const timeConvertion = slotMap?.map((x: any) => ({
        slotId: x?.slotId,
        booked: x?.isBooked,
        isExpired: x?.isExpired,
        time: moment(x?.time, "HH:mm").format("hh:mm a")
      }))
      setTimeSlotList(timeConvertion)
    }
  }, [timeSlots]);

  return (
    <>
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <form className={classes.container}>
        <div className={classes.headerContainer}>
          <Typography className={classes.caseSheet}>Case Sheet</Typography>
        </div>
        <div className={classes.inputsContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="complaints"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <CommonTextField
                    className={clsx(classes.root)}
                    type="text"
                    label="Chief Complaints"
                    placeholder="Eg. Peanut Allergy, Penicillin Allergy.."
                    value={controlProps.value}
                    onChange={(e: { target: { value: string } }) => {
                      controlProps.onChange(e.target.value);
                      autoSave("onchange");
                    }}
                    onBlur={() => autoSave("onblur")}
                  />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="complaints"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="symptoms"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <CommonSearchMultiselect
                    label={"Symptoms"}
                    options={symptomsArr || []}
                    className={classes.select}
                    multiple
                    getOptionLabel={(option: { name: string }) => option.name}
                    value={controlProps.value || ""}
                    popupIcon={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>, value: ICommonDropDown) => {
                      controlProps.onChange(value);
                      autoSave("onchange")
                    }}
                    onBlur={() => autoSave("onblur")}
                    ListboxProps={{
                      style: { maxHeight: "300px" },
                      onScroll: (event: { currentTarget: any }) => {
                        const listboxNode = event.currentTarget;
                        if (
                          listboxNode.scrollHeight -
                          listboxNode.scrollTop -
                          listboxNode.clientHeight <
                          1
                        ) {
                          setTablePage(tablePage + 1);
                        }
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="diagnosis"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <CommonTextField
                    className={clsx(classes.root)}
                    multiline
                    rows={3}
                    type="text"
                    label="Diagnosis"
                    placeholder="Eg. Fever, Headache"
                    value={controlProps.value}
                    disabled={userRole && (userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops ||
                      userRole === USER_ROLES.facility_receptionist)}
                    onChange={(e: { target: { value: string } }) => {
                      controlProps.onChange(e.target.value);
                      autoSave("onchange");
                    }}
                    onBlur={() => autoSave("onblur")}
                  />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="diagnosis"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="notes"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <CommonTextField
                    className={clsx(classes.root)}
                    multiline
                    rows={3}
                    type="text"
                    label="Clinical Notes"
                    placeholder="Enter your notes here..."
                    value={controlProps.value}
                    disabled={userRole && (userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops ||
                      userRole === USER_ROLES.facility_receptionist)}
                    onChange={(e: { target: { value: string } }) => {
                      controlProps.onChange(e.target.value);
                      autoSave("onchange");
                    }}
                    onBlur={() => autoSave("onblur")}
                  />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="notes"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="investigation"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <CommonSearchMultiselect
                    label={"Investigations Recommended"}
                    options={investigation?.length > 0 ? filteredArr : splitArry}
                    className={classes.select}
                    multiple
                    getOptionLabel={(option: ICommonDropDown) => option.name}
                    value={controlProps.value || ""}
                    popupIcon={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>, value: ICommonDropDown) => {
                      controlProps.onChange(value);
                      autoSave("onchange")
                    }}
                    disabled={userRole && (userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops ||
                      userRole === USER_ROLES.facility_receptionist)}
                    onBlur={() => autoSave("onblur")}
                    ListboxProps={{
                      style: { maxHeight: "300px" },
                      onScroll: (event: { currentTarget: any }) => {
                        const listboxNode = event.currentTarget;
                        if (
                          listboxNode.scrollHeight -
                          listboxNode.scrollTop -
                          listboxNode.clientHeight <
                          1
                        ) {
                          setTablePage(tablePage + 1);
                        }
                      },
                    }}
                  />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="investigation"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            {data?.consultationType !== "CHAT" &&
              <Grid item xs={12}>
                <Controller
                  name="FollowUp"
                  control={control}
                  render={(
                    controlProps: ControllerRenderProps<Record<string, any>>
                  ) => (
                    <CommonDropDown
                      multiple={false}
                      //variant="filled"
                      className={classes.Consult}
                      onChange={async (e: any) => {
                        setRequest({
                          loading: false,
                          message: '',
                          severity: '',

                        });
                        const _t = await getcountAppoinment();
                        controlProps.onChange(e.target.value);
                        if (!_t || e.target.value === 'NO') {
                          setfollowUp && setfollowUp({ ...followUp, ...{ date: "", slot: "", slotStartTime: "",isFollowUp : false } })
                          setValue('appointmentDate', undefined);
                          setValue('appointmentTime', undefined);
                          setValue('FollowUp', "NO");
                          setTimeSlotList([]);
                        }
                        else {
                          setfollowUp && setfollowUp({ ...followUp, isFollowUp: e.target.value === 'YES' ? true : false })
                        }
                      }}
                      value={controlProps.value}
                      label={'Consultation Need Followup?'}
                    >
                      <CommonMenuItem value={'YES'}>Yes</CommonMenuItem>
                      <CommonMenuItem value={'NO'}>No</CommonMenuItem>
                    </CommonDropDown>
                  )}
                />
                <CustomFormEM
                  errors={errors}
                  name="FollowUp"
                  render={({ message }) => <p>{message}</p>}
                />
              </Grid>
            }
            {FollowUp === 'YES' && <Grid item xs={12}>
              <Controller
                name="appointmentDate"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonDatePicker
                      label={"Select Date for Followup"}
                      format="DD/MM/YYYY"
                      inputProps={{ readOnly: true }}
                      placeholder="Select Date"
                      className={classes.dates}
                      value={controlProps.value || null}
                      disablePast
                      maxDate={moment(moment(new Date()).add(30, 'd').format('YYYY/MM/DD HH:mm:ss'))}
                      onChange={(e: any) => {
                        controlProps.onChange(e);
                        setfollowUp && setfollowUp({ ...followUp, date: moment(e).format('YYYY/MM/DD') })
                      }}
                    />
                  );
                }}
              />
            </Grid>}
            {FollowUp === 'YES' && appointmentDate && appointmentDate !== '' && <Grid item container xs={12} className={classes.availableTime}>
              <Typography className={classes.title}>{`Available Slots on ${moment(appointmentDate).format('DD/MM/YYYY')}`}</Typography>
              <Grid item container xs={12}>
                <Controller
                  name={"appointmentTime"}
                  control={control}
                  render={(controlProps) => {
                    return (
                      <RadioGroup
                        key={'Followup+Time'}
                        row
                        name="appointmentTime"
                        className={classes.IconHide}
                        onChange={(e) => {
                          controlProps.onChange(e.target.value);
                          const splitValue = moment(e.target.value, "hh:mm a").format("HH:mm").split(":")
                          const { Morning, AfterNoon, Evening, Night } = timeSlots || {}
                          let slotMap: any = [...Morning, ...AfterNoon, ...Evening, ...Night];
                          const slotTime = slotMap?.map((time: any) => (time?.time))
                          Object.entries(slotTime).forEach(([key, value]: any, index: any) => {
                            if (slotTime.indexOf(moment(e.target.value, "hh:mm a").format("HH:mm")) !== -1) {
                              const selectedTime = slotMap[slotTime.indexOf(moment(e.target.value, "hh:mm a").format("HH:mm"))]
                              setfollowUp && setfollowUp({ ...followUp, ...{ slot: selectedTime?.slotId, slotStartTime: (+splitValue[0] * (60000 * 60)) + (+splitValue[1] * 60000) } })
                            }
                          })
                        }}
                      >
                        {!timeSlotList && timeSlotFetching && <CircularProgress />}
                        {timeSlotList &&
                          !timeSlotFetching &&
                          timeSlotList?.map((key: any) => {
                            return (
                              <>
                                <Radio className={classes.IconHide} value={key?.time} id={key?.time} disabled={key?.isExpired || key?.booked} icon={<></>} />
                                <label
                                  htmlFor={key?.time}
                                  className={key?.isExpired || key?.booked ? clsx(classes.FollowupTime, classes.TimeHidden) : clsx(classes.FollowupTime, controlProps.value === key?.time ? "active" : "")}
                                >
                                  <Typography variant="body2">{key?.time}</Typography>
                                </label>
                              </>
                            );
                          })}
                      </RadioGroup>
                    );
                  }}
                />
                {timeSlotList?.filter((x: any) => x?.booked === false).length === 0 &&
                  <Typography className={classes.slotNotAvailable}>No slots available</Typography>
                }
              </Grid>
            </Grid>}
          </Grid>
        </div>
      </form>
    </>
  );
};

export default CaseSheet;
