import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
    outerBox:{
        position:"fixed",
        bottom:0,
        right:0,
        zIndex:1,
     },
     mainNormalAccordion:{
       width:"330px",
       borderRadius: "15px 15px 0px 0px",
       "& .MuiAccordionSummary-root.Mui-expanded":{
        borderBottom: "2px solid #F1F1F1"
       }
     },
     userPhoto: {
        height: "35px",
        width: "35px",
        backgroundSize: "cover",
        borderRadius: "50%",
        backgroundColor: "lightgrey",
        marginRight:"20px"
     },
     chatUserName:{
        lineHeight:"40px"
     },
     chatDetails:{
        height:"450px",
        overflow:"scroll",
        "& .MuiAccordionDetails-root":{
           borderTop: "2px solid #f1f1f1"
        }
     },
     chatText:{
        width:"100%"
     },
     sendermessage:{
        backgroundColor:"#34519C",
        padding:" 10px",
        color: "white",
        borderRadius: "12px 0px 12px 12px",
     },
     sendername:{
        fontWeight:600,
        textAlign: "end",
        fontSize:"12px",
        
     },
     receivermessage:{
        backgroundColor:"#EBEFF4",
        padding:"10px",
        color: "black",
        borderRadius: "0px 12px 12px 12px",
     },
     receivername:{
        fontWeight:600,
        textAlign: "start",
        fontSize:"12px"
     },
     senderStyle:{
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
     },
     receiverStyle:{
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
     },
     
     //call popup
     popupbox:{
        padding:"20px",
        marginTop:"70px",
        backgroundColor:"#1A284E",
        borderRadius:"10px",
        left: "50%",
        position: "absolute",
        zIndex:999,
        translate:"-50%"
     },
     callUserPhoto: {
        height: "50px",
        width: "50px",
        backgroundSize: "cover",
        // backgroundPosition: "center",
        borderRadius: "50%",
        backgroundColor: "lightgrey",
        marginRight:"20px"
     },
     svg:{
        position: "absolute",
        top: 0,
        right: 0,
        width: "40px",
        height: "40px",
        transform: "rotateY(-180deg) rotateZ(-90deg)"
     },
     circle:{
        strokeDasharray: "113px",
        strokeDashoffset: "0px",
        strokeLinecap: "round",
        strokeWidth: "2px",
        stroke: "#D4AC1D",
        fill: "none",
     },
     countdown:{
        position: "relative",
        margin: "auto",
        height: "40px",
        width: "40px",
        textAlign: "center",
     },
     countdownumber:{
        color: "white",
        display:"inline-block",
        lineHeight: "40px"
     },
     acceptButton:{
      backgroundColor:"#44BA42 !important"

     },
     rejectButton:{
      backgroundColor:"#FF4242 !important"
      
     },
}));

export default useStyles