import { IconButton, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import React, { useState } from "react";
import clsx from "clsx";
import { styles } from "./styles";
import { SearchOutlined } from "@material-ui/icons";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
const useStyles = makeStyles(styles);
const CommonTextFieldSearch = React.forwardRef(({ className, ...props }:any, ref) => {
  const classes = useStyles();
  const [search, setSearch] = useState(props.value || '')
  const onSearch = () => {
    if (props.onSearch) {
      props.onSearch(search)
    }
  }

  const onClear =()=>{
   if(props.onClear){
      props.onClear("")
      setSearch("")
   }
  }

  return (
    <TextField
      className={clsx(classes.root, className)}
      placeholder={props.placeHolder}
      {...props}
      variant = {props?.variant ? props?.variant : 'outlined'}
      ref={ref}
      onChange={(e) => {
        setSearch(e.target.value)
        if(props?.onChange) props?.onChange(e.target.value);
        if(props.resetDefaultOnEmptyChange && e.target.value =='') props.onSearch('');
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSearch()
        }
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">
                        <IconButton style={{position: 'absolute', left: '8px'}} onClick={onSearch } size='small'>{<SearchOutlined/>}</IconButton>
                        <IconButton style={{position: 'absolute', right: '8px'}} onClick={onClear} size='small'>{props.isSearched && <CloseOutlinedIcon />}</IconButton>
                      </InputAdornment>
      }}
    />
  );
});

export default CommonTextFieldSearch;
