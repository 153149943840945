import { useEffect, useState } from "react";
import useStyles from "./styles";
import notificationIcon from "../../../assets/images/ic_bell.svg";
import chatNotification from "../../../assets/images/ChatIcon.svg";
import Tick from "../../../assets/images/outline.svg";
import { CircularProgress, ClickAwayListener, Typography } from "@material-ui/core";
import ovelRed from "../../../assets/images/newRed.svg";
import ovelBlue from "../../../assets/images/newBlue.svg";
import { useHistory } from "react-router-dom";
import { getNotificationDetails, markAllNotification } from '../../../api/Notifications/Notification';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { EmptyMessagelist, UpdNotifyList } from "../../../store/actions";
const notification = ({ setOpenNotification, openNotification}: any) => {
  const classes = useStyles();
  const { user } = useSelector((state: any) => state?.auth)
  const [tablePage, settablePage] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const { Notifylist } = useSelector((state: any) => state?.NotifyList) ;
  const [NotifyData,setNotifyData]=useState<any>();
  const [NotifyLoading,setNotifyLoading]=useState(false);
  const [NotifyRefetch,setNotifyRefetch]=useState(false);

  const NotificationList = async() =>{
    try 
    {
      setNotifyLoading(true);
        const response = await getNotificationDetails('GET_NOTIFICATION_DETAILS',{
          practitionerId: user?._id,
          pageSize: 3,
          pageNumber: tablePage,
          skip:Notifylist?.length || 0
        })
        if(response && response?.status < 350 )
        {
          setNotifyData(response);
          setNotifyLoading(false);
        }
        else
        setNotifyLoading(false);
    }
    catch (e)
    {setNotifyLoading(false);}
  }

  useEffect(() => {
    NotificationList();
  } ,[NotifyRefetch,tablePage])

  const markAllRead = async () => {
    try {
      const payload: any = {
        practitionerId: user?._id
      };
      const res = await markAllNotification(payload);
      if (res && res?.status < 350) {
        console.log(res, 'res')
        setNotifyRefetch(!NotifyRefetch);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (openNotification) {
      settablePage(1);
      setNotifyRefetch(!NotifyRefetch);
    }
  }, [openNotification])

  useEffect(() => {
    if (NotifyData && NotifyData?.data?.data?.length > 0 ) {
      const payload = {};
      NotifyData?.data?.data?.map((value) => {
        payload[value?.bookedSlotId] = value;
      })
      dispatch(UpdNotifyList({...Notifylist,...payload}));
    }
    else if (tablePage === 1 && NotifyData?.data?.data?.length === 0)
    {
      dispatch(UpdNotifyList({}));
    }
  }, [NotifyData])

  const setNotification = (value : [string,any],index:number) => {
    const {bookedSlotId, date, message, type } = value[1];
    return (
      <div
        key={bookedSlotId}
        onClick={() => {
          if (type.includes('CHAT')) {
            dispatch(EmptyMessagelist());
            const list = Notifylist;
            delete list[bookedSlotId] ;
            dispatch(UpdNotifyList(list));
            history.push({
              pathname: '/Appointments',
              state: { chatNotifyId: { appointmentID: bookedSlotId } }
            }); setOpenNotification(!openNotification);
          }
        }}
        style={{
          background: "rgba(243, 247, 255, 1)",
        }}
      >
        <img
          src={ovelBlue}
          alt="new-Notify"
          style={{
            height: "9px",
            width: "9px",
            position: "relative",
            top: "8px",
            left: "8px",
          }}
        />
        <div
          className={
            index !== Object.keys(Notifylist).length - 1
              ? classes.setNotify
              : classes.noBorder
          }
        >
          <div style={{ paddingTop: "5px", paddingLeft: "16px" }}>
            <img src={type === "CHAT" ? chatNotification : ""} alt="Notify-Icon" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography component={"h6"} className={classes.Type}>
              {type.includes('CHAT') ? 'Chat Request' : type}
            </Typography>
            <Typography component={"h6"} className={classes.Time}>
              {date && `${moment(date).day() === moment().day() ? 'Today' : moment(date).format('DD/MM/YYYY')} at ${moment(date).format('LT')}`}
            </Typography>
            <Typography component={"h6"} className={classes.Content}>
              {message}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => { setOpenNotification(false); }}>

      <div>
        <div
          onClick={() => setOpenNotification(!openNotification)}
          className={openNotification ? classes.openNotify : classes.noOpenNotify}
        >
          <img
            className={classes.notifyIcon}
            src={notificationIcon}
            loading="lazy"
            decoding="async"
          />
          {Notifylist && Object.keys(Notifylist).length > 0 && <img
            src={ovelRed}
            alt="new notify"
            style={{
              height: "9px",
              width: "9px",
              position: "absolute",
              marginLeft: "10px",
            }}
          />}
        </div>
        {openNotification && (
          <div className={classes.profileMenu}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "12px 16px",
                height: "45px",
              }}
            >
              <div>
                <Typography className={classes.notify}>Notifications</Typography>
              </div>
              {Notifylist && Object.keys(Notifylist).length > 0 && <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography className={classes.markAll} onClick={markAllRead}>
                    Mark all as read
                  </Typography>
                </div>
                <div style={{ paddingTop: "3px", paddingLeft: "6px" }}>
                  <img src={Tick} />
                </div>
              </div>}
            </div>
            <div className='PageNavigation' onScroll={(event) => {
              const listboxNode = event.currentTarget;
              if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1 && NotifyData?.data?.hasNextPage)
                settablePage(tablePage + 1)
            }}>
              {Notifylist && Object.keys(Notifylist).length > 0 && <div>{Object.entries(Notifylist).map(setNotification)}</div> }
              {(!NotifyLoading && Notifylist && Object.keys(Notifylist).length  === 0 ) && <div className="EmptyMessage"><Typography className={"NoMessage"}>No new message to read</Typography></div>}
              {NotifyLoading && <div className="loading"><CircularProgress color="primary" size={30} className="Circular"/></div>}
            </div>
          </div>
        )}

      </div>
    </ClickAwayListener>
  );
};

export default notification;
