import { useHistory } from "react-router-dom";
import { Tab, Tabs, AppBar, Grid, Box, TablePagination, Tooltip} from "@material-ui/core";
import styles from "./styles";
import moment from "moment";
import Table from "../../components/Table";
import CustomIconButton from "../../components/common/CustomIconButton";
import Complete from "../../assets/images/icons/Group.svg";
import videoIcon from "../../assets/images/icons/ph_video-camera-fill.svg";
import { useEffect, useState, useRef } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { USER_ROLES } from "../../utils/constants";
import { UpdateCallbackInfo } from "../../store/actions/instantVideo";
import ThreeDotsMenu from "../../components/CustomThreeDotsMenu";
import { Irequest } from '../../models/common';
import Notify from '../../components/common/CommonToast';
const TYPES = {
  VIRTUAL_CONSULTATION: "Virtual Consult",
  PHYSICAL_CONSULTATION: "Physical Consult",
  INSTANT: "Instant Call",
  CHAT: "Instant Chat",
};
const ListView = ({
  setOpenDetilsPopup,
  userRole_Static,
  activeTab,
  isLoading,
  setActiveTab,
  tablePage,
  type,
  tabledata,
  totalRecords,
  rowsPerPage,
  setSelData,
  handlePageChange,
  setRowsPerPage
}: any) => {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  let worker = useRef(new Worker("/joinCallWorker.js")).current;
  const [aptData, setAptData] = useState<any>([]);
  const [request, setRequest] = useState<Irequest>({
    title:"",
    message: "",
    loading: false,
    severity: "",
  });
  const Enable = useRef<boolean>(false);
  const { userRole } = useSelector((state: RootStateOrAny) => state.auth);
  const Member=process.env.REACT_APP_PATIENT
  const rowsPerPageOptions = process.env.REACT_APP_LIST_ROWS_PER_PAGE_ARRAY?.split(',')?.map(i=>Number(i)) ?? [10];
  const commonHeader = [
    {
      id: "user",
      numeric: false,
      disablePadding: false,
      label: `${Member}`,
      width: "20%",
      render: (text, row, index) =>  {
        let fullName = `${text?.name?.first} ${text?.name?.last}`
       return fullName?.length > 45 ?
         <Tooltip title={fullName}><div className={classes.ellipsisText}>{fullName}</div></Tooltip> 
         : 
         fullName
      },
    },
    {
      id: "practitioner",
      numeric: false,
      disablePadding: false,
      label: "Clinician",
      render: (text:any) => `${text?.name?.prefix} ` + text?.name?.first + " " + text?.name?.last + ` ${text?.name?.suffix}`,
    },
    {
      id: "consultationType",
      numeric: false,
      disablePadding: false,
      label: "Consultation Type",
      render: (text: any, row: any) => {
        return <>{TYPES[row?.consultationType]}</>;
      },
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
      render: (text: any, row: any) => text
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      width:"18%",
      render: (text: any) => moment(text).format("DD/MM/YYYY"),
    },
    {
      id: "Time",
      numeric: false,
      disablePadding: false,
      label: "Time",
      width:"10%",
      render: (text: any, row: any) => {
        let hour = Math.floor(row?.slotStartTime / 3600000);
        let minute = Math.floor(row?.slotStartTime / 60000) % 60;
        return moment(`${hour}:${minute}`, "HH:mm").format("hh:mm a");
      },
    },
    {
      id: "slotStartTime",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "5%",
      render: (text: any, row: any, index: any, data: any) => {
        var current = new Date();
        var currentTime = `${current.getHours()}:${current.getMinutes()}`;
        const splitValue = currentTime.split(":");
        const splitted = +splitValue[0] * (60000 * 60) + +splitValue[1] * 60000;
        return (
          <>
            {splitted / 1000 - text / 1000 >= -300 &&
              splitted / 1000 - text / 1000 <= 900 &&
              moment(row?.date).format("DD/MM/YYYY") ===
                moment().format("DD/MM/YYYY") &&
              row?.isCallEnable &&
              !Enable.current && (
                <CustomIconButton
                  icon={
                    row.consultationType === "VIRTUAL_CONSULTATION" ? (
                      <img src={videoIcon} style={{ paddingRight: "4px" }} />
                    ) : (
                      <img src={Complete} style={{ paddingRight: "4px" }} />
                    )
                  }
                  label={
                    row.consultationType === "VIRTUAL_CONSULTATION"
                      ? "Join"
                      : "Complete"
                  }
                  classes={classes.submitButton}
                  variant="contained"
                  color="primary"
                  onClick={(e: any) => {
                    setRequest({ message: "", loading: true, severity: "" });
                    const currentRejoin = (!document.getElementById("rejoinButton") || document.getElementById("rejoinButton")?.getAttribute("bookedslotid") === row?._id )
                    if(currentRejoin)
                    {
                      dispatch(UpdateCallbackInfo({}));
                      if (row.consultationType === "VIRTUAL_CONSULTATION") {
                        history.push({
                          pathname: `/Appointments/room`,
                          search: `${row?._id}`,
                          state: {
                            row: { row },
                          },
                        });
                      } else {
                        history.push({
                          pathname: "/Appointments/consultation",
                          state: {
                            row: { row },
                          },
                        });
                      }
                      e.stopPropagation();
                    }
                    else{
                      setTimeout(() => {
                        setRequest({
                          message: "Please end the ongoing call to join a new consultation.",
                          loading: false,
                          severity: "error",
                        });
                      },10)
                    }
                  }}
                  size="small"
                />
             )}
          </>
        );
      },
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      width: "5%",
      render: (text: any, row: any) => {
        return (
          <>
              <ThreeDotsMenu handleMenuView={handleRowOnclick.bind(null, row)} data={row} />
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if (
      worker &&
      !isLoading &&
      tabledata?.data &&
     (userRole === USER_ROLES.doctor ||
      userRole === USER_ROLES.wellness_nutritionist_interim ||
      userRole === USER_ROLES.wellness_counselor_interim  ||
      userRole === USER_ROLES.wellness_coach )
    ) {
      Enable.current = true;
      worker.postMessage(tabledata.data);
      worker.onmessage = (res) => {
        const _ndata = tabledata?.data.map((item: any) => {
          res.data[0]._id === item._id
            ? (item.isCallEnable = res.data[1])
            : (item.isCallable = false);
          return item;
        });
        setAptData(_ndata);
        Enable.current = false;
      };
    }
    setAptData(tabledata?.data);
  }, [tabledata]);

  useEffect(() => {
    return () => {
      worker.terminate();
    };
  }, []);

  const [tableHeaderUpcoming_Admin ,tableHeaderUpcoming_Operator] = [commonHeader.filter((item) => item.id !== "slotStartTime"),commonHeader.filter((item) => item.id !== "slotStartTime")] ;
	const tableHeaderUpcoming_Doctor = commonHeader.filter((item) => item.id !== "practitioner") ;
	const tableHeaderAhp   = commonHeader

  const getTableHeaderUpcoming = () => {
    if (
      userRole_Static === "Admin" ||
      userRole_Static === "Receptionist" ||
      userRole_Static === "Operatornal_Receptionist" ||
      userRole_Static === "Facility_Receptionist"
    ) {
      return tableHeaderUpcoming_Admin;
    } else if (userRole_Static === "Operator" || type === "FollowupRequest") {
      return tableHeaderUpcoming_Operator;
    } else if (userRole_Static === "Doctor") {
      return tableHeaderUpcoming_Doctor;
    } else if (userRole_Static === 'Nutritionist' || userRole_Static === 'coach' ||userRole_Static === 'counselor' ) {
      return tableHeaderAhp;
    }
  };

  const tableHeaderHistory = [
    {
      id: "user",
      numeric: false,
      disablePadding: false,
      label: `${Member}`,
      width: "15%",
      render: (text, row, index) => {
        let fullName = `${text?.name?.first} ${text?.name?.last}`
       return fullName?.length > 45 ?
         <Tooltip title={fullName}><div className={classes.ellipsisText}>{fullName}</div></Tooltip> 
         : 
         fullName
      },
    },
    userRole_Static !== "Doctor" && {
      id: "practitioner",
      numeric: false,
      disablePadding: false,
      label: "Clinician",
      width: "18%",
      render: (text:any) => `${text?.name?.prefix} ` + text?.name?.first + " " + text?.name?.last + ` ${text?.name?.suffix}`,
    },

    {
      id: "consultationType",
      numeric: false,
      disablePadding: false,
      width: "16%",
      label: "Consultation Type",
      render: (text: any, row: any) => {
        return <>{TYPES[row?.consultationType]}</>;
      },
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
      width: "8%",
      render: (text: any, row: any) => text
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      width: "18%",
      render: (text: any) => moment(text).format("DD/MM/YYYY"),
    },
    {
      id: "Time",
      numeric: false,
      disablePadding: false,
      label: "Time",
      width: "10%",
      render: (text: any, row: any) => {
        let hour = Math.floor(row?.slotStartTime / 3600000);
        let minute = Math.floor(row?.slotStartTime / 60000) % 60;
        return moment(`${hour}:${minute}`, "HH:mm").format("hh:mm A");
      },
    },
    {
      id: "state",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: userRole !== USER_ROLES?.doctor ? "20%" : "15%",
      render: (text: any, row: any) => {
        return <>{row?.gridStatus}</>;
      },
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      width: "3%",
      render: (text: any, row: any) => {
        return (
          <>
              <ThreeDotsMenu handleMenuView={handleRowOnclick.bind(null, row)} data={row} />
          </>
        );
      },
    },
  ];

  const handleRowOnclick = (row: any) => {
    setOpenDetilsPopup(true);
    setSelData(() => row);
  };

  const onPageChange = (event: any) => {
    handlePageChange(parseInt(event.target.value))
  }

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value)
    handlePageChange(1)
  }
  return (
    <>
    {request.message && <Notify message={request.message} severity={request.severity} title={request.title}/>}
    <Grid item xs={12}>
      <Box mt={3} component="div" className={classes.customLayoutWrap}>
        <Grid container>
          <Grid item xs={12} style={{ position: "relative" }}>
            <AppBar position="static" className={classes.tabBarNew}>
              <Tabs
                value={activeTab}
                onChange={(e, value) => {
                  setActiveTab(value);
                  handlePageChange(1);
                }}
                scrollButtons="auto"
                variant="scrollable"
              >
                 <Tab style={{fontSize: "14px"}} label = {type === "FollowupRequest" ? "Followup Requests" : "Upcoming"} value={1}/>
            <Tab style={{fontSize: "14px"}} label="History" value={2}/>
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        <Table
          tableHeader={
            activeTab === 1 ? getTableHeaderUpcoming() : tableHeaderHistory
          }
          tableData={aptData}
          paginationServerSide={true}
          totalRecords={totalRecords}
          page={tablePage}
          rowsPerPage={rowsPerPage}
          sortingServerSide={true}
          onPageChange={handlePageChange}
          rowclickable={false}
          isLoading={isLoading}
          extra={
            <>
              <TablePagination
                component={"div"}
                ActionsComponent={() => {
                  return <></>;
                }}
                page={tablePage - 1}
                onPageChange={onPageChange}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                count={totalRecords ? totalRecords : 0}
                onRowsPerPageChange={handleRowsPerPageChange}
                SelectProps={{
                  disabled: totalRecords < 5 ? true : false,
                  className: classes.selectDropdown,
                }}
                labelRowsPerPage='Showing'
              />
            </>
          }
        />
      </Box>
    </Grid>
    </>
  );
};

export default ListView;
