import {
    Typography,
} from "@material-ui/core";
import { useState } from "react";
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import useStyles from "./styles";
import CloseIcon from '@material-ui/icons/Close';

interface ICommonToast {
    severity: string,
    title?: string,
    message: string,
}

const Notify = ({ severity, title = "", message }: ICommonToast) => {
    const [open, setOpen] = useState(true);
    const classes = useStyles()
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <SnackbarContent className={classes.root}
                message={
                    <div className={classes.wrapper}>
                        <div className={severity === "error" ? classes.mainContainer : severity === "success" ? classes.successContainer : classes.infoContainer}>
                            <div className={severity === "error" ? classes.colorContainer : severity === "success" ? classes.successMessageContainer : classes.infoMessageContainer}></div>
                            <div className={classes.messageContainer}>
                                <div className={severity === "error" ? classes.notifyIcon : severity === "success" ? classes.notifySuccessIcon : classes.notifyInfoIcon}>
                                    <Typography>i</Typography>
                                </div>
                                <div className={classes.facilityContent}>
                                    {title && <Typography className={classes.title}>{title}</Typography>}
                                    <Typography className={classes.reason}>{message}</Typography>
                                </div>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    className=""
                                    onClick={handleClose}>
                                    {severity === "success" && <CloseIcon
                                        style={{
                                            color: "green",
                                        }} />}
                                    {severity === "error" && <CloseIcon
                                        style={{
                                            color: "red",
                                        }} />}
                                    {severity === "info" || severity === "" && <CloseIcon
                                        style={{
                                            color: "blue",
                                        }} />}
                                </IconButton>
                            </div>
                        </div>
                    </div>
                } />
        </Snackbar>
    )
}

export default Notify