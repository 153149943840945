
const CONTEXT_PATH = process.env.REACT_APP_CONTEXT_PATH;
export const PRESCRIPTION_SERVICE_BASEURL =
process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}consultation-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/prescriptions/autocomplete/medicines/brands`;
// Facility URLs
export const FACILTY_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `dev/${CONTEXT_PATH}facility-service/api/v1/`+process.env.REACT_APP_KEY_WORD;
export const CONSULTATION_BASEURL = process.env.REACT_APP_API_BASE_URL + `dev/${CONTEXT_PATH}consultation-service/api/v1/`+process.env.REACT_APP_KEY_WORD;
export const AVAILABLE_TIMESLOT_BASEURL = process.env.REACT_APP_API_BASE_URL + `dev/${CONTEXT_PATH}slot-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/availableTimeSlot`;
//country,state,city
export const COUNTRY_STATE_CITY_BASEURL = `${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}master-data-service/api/v1/${process.env.REACT_APP_KEY_WORD}`;
// patient create
export const BASE_URL_OPD = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}user-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/users`
export const MASTER_DATA_URL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}user-service/api/v1/`+process.env.REACT_APP_KEY_WORD


export const TERMS_OF_USE_URL =
`https://www.starhealth.in/blog/wp-content/uploads/2021/10/Wellnessprogram-Terms-of-use.pdf`;
export const PRIVACY_POLICY_URL = `https://securra.com/privacy-policy`;
// Dashboard
export const DASHBOARD_SERVICE = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}dashboard-service/api/v1/`+process.env.REACT_APP_KEY_WORD
//Consultation
export const CREATE_CONSULTATION = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}prescription/v1/`+process.env.REACT_APP_KEY_WORD+`/Prescription`;
export const CREATE_MEDICAL_HISTORY = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}dataparameter/v1/`+process.env.REACT_APP_KEY_WORD;
export const CREATE_CASESHEET=process.env.REACT_APP_API_BASE_URL +`prescription/v1/`+process.env.REACT_APP_KEY_WORD;
//Transcriber 
export const TRANSCRIBER_URL = process.env.REACT_APP_API_BASE_URL+`labpartner/v1/`+process.env.REACT_APP_KEY_WORD+`/OPD`;
// Slot Management
const SLOT_MANAGEMENT_BASEURL = process.env.REACT_APP_API_BASE_URL
const SLOT_FACILITIES_BASEURL = `${SLOT_MANAGEMENT_BASEURL}${CONTEXT_PATH}facility-service/api/v1/${process.env.REACT_APP_KEY_WORD}/all/`
const SLOT_SERVICE_API = `${SLOT_MANAGEMENT_BASEURL}${CONTEXT_PATH}slot-service/api/v1/${process.env.REACT_APP_KEY_WORD}`

export const CREATE_SLOT = `${SLOT_SERVICE_API}/creator`
export const GET_ALL_FACILITIES = `${SLOT_FACILITIES_BASEURL}facilities/names`
export const apiRolesByFacility = (facilityId) => `${SLOT_FACILITIES_BASEURL}practitioners/roles/facility/${facilityId}`
export const apiPractitionersByRoleAndFacility = (roleId, facilityId) => `${SLOT_FACILITIES_BASEURL}practitioners/names/role/${roleId}/facility/${facilityId}`
export const GET_DOCTORS_SLOT_BY_FACILITY = `${SLOT_SERVICE_API}/slots/all/doctors`
export const getRolesForConsultation = (settingId)=>`${SLOT_MANAGEMENT_BASEURL}${CONTEXT_PATH}master-data-service/api/v1/${process.env.REACT_APP_KEY_WORD}/applicable/roles/${settingId}`
export const GET_SLOTS_BY_PRACTITIONER = `${SLOT_SERVICE_API}/slot/practitioner`
export const DELETE_SLOT = `${SLOT_SERVICE_API}/delete/appointments/slot`
export const CALL_REASONS = `${SLOT_MANAGEMENT_BASEURL}${CONTEXT_PATH}master-data-service/api/v1/${process.env.REACT_APP_KEY_WORD}` 
//FacilityEmpanelment
const BASE_EMPANEL_FACILITY_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}facility-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/facility/`;
export const FACILITY_EMPANELMENT_LIST =`${BASE_EMPANEL_FACILITY_URL}empanelment/`
export const FACILITY_EMPANELMENT_DETAIL_PAGE= process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}facility-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/facilities/`
//Doctorempanelment
export const BASE_EMPANEL_URL=process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}practitioner-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/practitioners/`
export const DOCTOR_EMPANELMENT_LIST= `${BASE_EMPANEL_URL}empanelment/doctor/`
export const DOCTOR_EMPANELMENT_DETAIL_PAGE= `${BASE_EMPANEL_URL}id/`
export const PRACTITIONER_INFO = `${BASE_EMPANEL_URL}/mobile`
export const AUTH_SERVICE =  process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}auth-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/practitioners/loginWithIdentityToken`;
export const INVITE_USER = `${BASE_EMPANEL_URL}quick/add`;
//Signup
export const FACILITY_SIGNUP_URL=process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}facility-service/api/v1/`+process.env.REACT_APP_KEY_WORD;
export const PRACTITIONER_SERVICE = process.env.REACT_APP_API_BASE_URL +`${CONTEXT_PATH}practitioner-service/api/v1/`+process.env.REACT_APP_KEY_WORD
export const MASTER_DATA_SERVICE = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}master-data-service/api/v1/`+process.env.REACT_APP_KEY_WORD
// Consultation
export const GET_JOIN_CONSULTATION = `${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}consultation-service/api/v1/${process.env.REACT_APP_KEY_WORD}/joinConsultation`
export const GET_PAST_CONSULTATION = `${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}consultation-service/api/v1/${process.env.REACT_APP_KEY_WORD}`
export const GET_CONSULT_ARRAY=`${GET_PAST_CONSULTATION}/consultation-type`
export const apiGetSymptoms = (pageNumber, pageSize, limit = 25, skip = 0) => `${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}master-data-service/api/v1/${process.env.REACT_APP_KEY_WORD}/symptom?pageSize=${pageSize}&pageNumber=${pageNumber}&limit=${limit}&skip=${skip}`
export const apiGetInvestigations = (search) => `${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}master-data-service/api/v1/${process.env.REACT_APP_KEY_WORD}/lab-investigation/?search=${search}`
export const apiUpdateCaseSheet = (caseSheetId) => `${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}consultation-service/api/v1/${process.env.REACT_APP_KEY_WORD}/consultation/update-case-sheet/${caseSheetId}`
export const apiGetMedicalRecords = (userId) => `${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}user-locker-service/api/v1/${process.env.REACT_APP_KEY_WORD}/users/${userId}/locker/files?sortBy=createdAt&tags=prescription,caseSheet`
export const APPOINTMENT_SERVICE = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}consultation-service/api/v1/`+process.env.REACT_APP_KEY_WORD
export const GET_SLOT_LIST_SERVICE = `${SLOT_SERVICE_API}/availableTimeSlot`
export const GET_USER_SERVICE = `${SLOT_MANAGEMENT_BASEURL}${CONTEXT_PATH}user-service/api/v1/${process.env.REACT_APP_KEY_WORD}`
export const POLICY_DETAILS=process.env.REACT_APP_API_BASE_URL + `policy/api/v1/`+process.env.REACT_APP_KEY_WORD
export const CLAIMS_CONSULTATION_BASE_URL=`${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}consultation-service/api/v1/${process.env.REACT_APP_KEY_WORD}`
export const UPCOMING_APPOINTMENTS=`${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}consultation-service/api/v1/${process.env.REACT_APP_KEY_WORD}`
export const VIDEO_SERVICE = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}video-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/video`
export const STAR_AUTH_SERVICE = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}auth-service/api/v1/`+process.env.REACT_APP_KEY_WORD
//Receptionist Dashboard
export const DASHBOARD_CALL_COUNT = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}dashboard-service/api/v1/`+process.env.REACT_APP_KEY_WORD
export const NOTIFICATION_API = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}video-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/video`
//Upload file count Api

export const FILE_COUNT = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}instantchat-service/api/v1/`+process.env.REACT_APP_KEY_WORD

//PatientService from BAROS.

export const PATIENT_USER_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}user-service/api/v2/`+process.env.REACT_APP_KEY_WORD;
export const PATIENT_ENROLLMENT_BASEURL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}facility-service/api/v1/`+process.env.REACT_APP_KEY_WORD;
export const CHAT_USERS_SERVICE_URL =
process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}chat-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/chat-users/`;
export const URL = {
  patients: {
    BASE_URL: process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}user-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/users`,
    BASE_URL_V2: process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}user-service/api/v2/`+process.env.REACT_APP_KEY_WORD+`/users`
  },
};

export const CARE_PROGRAM_PLAN_SERVICE_BASEURL = process.env.REACT_APP_USER_CARE_PROGRAM_PLAN_SERVICE_BASEURL + `/api/v1/`+process.env.REACT_APP_KEY_WORD+`/user-care-programme-plans/`
export const DATA_PLATFORM_QUERY_SERVICE_BASEURL = process.env.REACT_APP_DATA_PLATFORM_SERVICE + `/api/v1/`+process.env.REACT_APP_KEY_WORD+`/generic-vitals/query`
export const DATA_PLATFORM_QUERY_SERVICE_CHARTS_BASEURL = process.env.REACT_APP_DATA_PLATFORM_SERVICE + `/api/v1/`+process.env.REACT_APP_KEY_WORD+`/generic-vitals/query/chart`
export const HEALTH_RISK_SCORE = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}user-health-score-service/api/v1/`+process.env.REACT_APP_KEY_WORD;
export const PATIENT_CARETAKER_BASEURL = process.env.REACT_APP_CARETAKER_BASEURL + `/api/v1/`+process.env.REACT_APP_KEY_WORD+`/users/`
export const PATIENT_ONBOARDING_BASEURL = process.env.REACT_APP_ONBOARDING_SERVICE + `/api/v1/`+process.env.REACT_APP_KEY_WORD
export const VERIFY_USER_BASEURL = process.env.REACT_APP_CLINIC_BASEURL + `/api/v1/`+process.env.REACT_APP_KEY_WORD+`/clinic-users/`
export const LOCKER_FILES_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}user-locker-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/locker/files`;
export const USER_LOCKER_FILES_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}user-locker-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/users`;
export const CLINIC_AHP_BASEURL = `${process.env.REACT_APP_CLINIC_AHP_BASEURL}/api/v1/${process.env.REACT_APP_KEY_WORD}/clinics/`
export const CLINIC_SERVICE_URL =
process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}facility-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/facilities/`;
//Lab Orders
export const LAB_ORDERS = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}labpartner/v1/`+process.env.REACT_APP_KEY_WORD+`/Lab`;

//Behaviour Logger
export const BEHAVIOUR_LOGGER = process.env.REACT_APP_ACTIVITY_LOGGER+`/api/v1/`+process.env.REACT_APP_KEY_WORD;
//Physical-Activity-Logger
export const ACTIVITY_LOGGER=process.env.REACT_APP_ACTIVITY_LOGGER+`/api/v1/`+process.env.REACT_APP_KEY_WORD;
// TwentyfourHourRecall
export const MEAL_LOGGER=process.env.REACT_APP_MEAL_LOGGER+`/api/v1/`+process.env.REACT_APP_KEY_WORD;
//Devices Synced
export const DEVICES_SYNCED= process.env.REACT_APP_DEVICES_SYNCED + `/api/v1/`+process.env.REACT_APP_KEY_WORD+`/device-vitals/user-vitals`;
//Vitals
export const Recent_Vitals = process.env.REACT_APP_RECENT_VITALS+`/api/v2/`+process.env.REACT_APP_KEY_WORD+`/generic-vitals/recent-vitals`
export const History_Vitals = process.env.REACT_APP_RECENT_VITALS+`/api/v2/`+process.env.REACT_APP_KEY_WORD+`/generic-vitals/query`
export const Chart_Vitals = process.env.REACT_APP_RECENT_VITALS+`/api/v2/`+process.env.REACT_APP_KEY_WORD+`/generic-vitals/query/chart`
//Meal and Water Loggers
export const LOGGER_URL = process.env.REACT_APP_LOGGERS+`/api/v1/`+process.env.REACT_APP_KEY_WORD;
//BAROS API
export const ASSOCIATIONS_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}healthprofiler/v1/`+process.env.REACT_APP_KEY_WORD+`/association`;
export const CLINIC_CARE_PROGRAM_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}care-programme-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/facility/`
export const CLINIC_CARE_PROGRAM_SERVICE_URL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}care-programme-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/facilities/`
export const TAG_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}tagmanager/v1/`+process.env.REACT_APP_KEY_WORD+`/tag`;
export const DATA_PARAMETER_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}dataparameter/v1/`+process.env.REACT_APP_KEY_WORD+`/dataparameter`;
export const HEALTH_PROFILER_CONSOLE_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}healthprofiler/v1/`+process.env.REACT_APP_KEY_WORD+`/console`;
export const UOM_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}dataparameter/v1/`+process.env.REACT_APP_KEY_WORD+`/uom`;
export const QUESTIONS_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}healthprofiler/v1/`+process.env.REACT_APP_KEY_WORD;
export const QUESTION_SET_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}healthprofiler/v1/`+process.env.REACT_APP_KEY_WORD;
export const CHAT_ROOM_SERVICE_URL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}chat-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/chat-rooms`;
export const NEW_CHAT_PARTICIPANTS = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}facility-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/facility`
export const CARE_PROGRAM_ORDERS_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}care-programme-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/payment/care-program-orders`
export const DEPARTMENT_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}master-data-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/departments`
export const ROLES_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}practitioner-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/getterRoles`
export const SELECT_USER_SERVICE = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}practitioner-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/getter/practitioner-roles`
export const CARE_PROGRAM_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}care-programme-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/care-programmes/`
export const UPDATE_CARE_PROGRAM_PLAN_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}care-programme-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/care-programme-plans/`
export const CARE_PROGRAM_PLAN_TIER_SERVICE_URL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}care-programme-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/care-programme-plan/tier`
export const CARE_PROGRAM_INVOICE_MAIL_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}care-programme-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/payment/send-email/`
export const CARE_PROGRAM_QUESTION_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}care-programme-service/api/v1/`+process.env.REACT_APP_KEY_WORD
export const SPECIALITY_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}master-data-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/speciality`
export const QUALIFICATION_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}master-data-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/qualification`
export const AHP_BASEURL = `${process.env.REACT_APP_API_BASE_URL}${CONTEXT_PATH}ahp-service/api/v1/${process.env.REACT_APP_KEY_WORD}/ahps/`
export const CARE_PROGRAMS_GROUPS = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}group-service/api/v1/`+process.env.REACT_APP_KEY_WORD
export const TAG_BASEURL = process.env.REACT_APP_API_BASE_URL + `${CONTEXT_PATH}question-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/questionnaires`
export const USER_CARE_PROGRAM_PLAN_SERVICE_BASEURL = process.env.REACT_APP_USER_CARE_PROGRAM_PLAN_SERVICE_BASEURL + `/api/v1/`+process.env.REACT_APP_KEY_WORD+`/users/`

//UserNotes
export const USER_NOTES_URL = process.env.REACT_APP_API_BASE_URL+`${CONTEXT_PATH}user-notes-service/api/v1/`+process.env.REACT_APP_KEY_WORD+`/user-notes`

//Health Vault
export const  REPORT_TYPES = process.env.REACT_APP_FILE_BASEURL+`/api/v1/`+process.env.REACT_APP_KEY_WORD+`/attachment/attachment-types`
