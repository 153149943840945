export const ViewContent = ({document,viewOnlyDocs}:any) => {
const fileExtenstion = document && document?.uri?.split("?")[0].split(".")[document?.uri?.split("?")[0].split(".").length - 1];

const getFileType =(fileExtenstion : any)=>{
  switch (fileExtenstion){
    case "pdf":
     case "doc":
      case "ppt":
      return  <iframe height="100%" width="100%" src={viewOnlyDocs ?  `${document.uri}#toolbar=0` : document.uri}></iframe> 
    case "mp3":
      return  <audio controls>
      <source src={document.uri} type="audio/ogg" />
      <source src={document.uri} type="audio/mpeg" />
       </audio>
    case "mp4":
    case "mov":
      return <video style={{height:'inherit',width:'inherit'}} controls>
      <source src={document.uri} type="video/mp4"/>
       <source src={document.uri} type="video/ogg"/>
    </video>
    default :
    return  <img src={document.uri} style={{height:'inherit',width:'inherit'}}></img>
  }
}
  return (
    <div style={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '90%',
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0,0 10px,10px -10px,-10px 0px'
    }}>
      {getFileType(fileExtenstion)}
    </div>
  );
};
