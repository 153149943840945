import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({

    initialsDisplay:{
        fontSize: "16px",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "lightgray"
    }
}))

export default useStyles;