import { makeStyles, Theme } from "@material-ui/core";

const styles =  makeStyles<Theme>(() => ({
    toolTip: {
        "&.MuiTooltip-popper": {
            textAlign: 'center',           
            '& .MuiTooltip-tooltip': {
                margin: '0',
                background: '#FFFFFF',
                color: '#000000',
                fontWeight: '400',
                fontSize: '14px',
                letterSpacing: '0.3px',
                padding:'15px 15px',
                boxShadow:'0px 4px 40px rgba(0, 0, 0, 0.1)'
            },
            
        }
        
    },
    ellipsis:{
        display:'inherit',
        overflow:'hidden',
        whiteSpace:'nowrap',
        cursor:'pointer',
        textOverflow:'ellipsis'
    }
}
   
   
  ))
export default styles;