export const styles = (theme) => ({
    root: {
        "& .MuiPaper-root": {
            borderRadius: "0px",
            backgroundColor: "white"
        },
        "& .MuiDialogTitle-root": {
            "& .close-btn": {
                display: "inline-block",
                width: "fit-content",
                position: "absolute",
                top: "8px",
                right: "16px",

            }
        },
        "& .MuiDialogContent-root": {
            "& #react-doc-viewer": {
                height: "100%"
            }
        },
    },
    audioRendererRoot: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    video:{
        position:"fixed",
        top:"0",
        bottom:"0",
        left:"0",
        right:"0",
        height:"100%",
        width:"100%",
        backgroundColor:'#000',
        zIndex:999999,
        padding:'50px',
        border:'none !important',
        outline:'none !important',
        boxShadow:'none !important',
        transition: 'all .3s ease-in-out',
        transform: 'scale(.5)',
        opacity: '0',
        visibility: 'hidden',

        '&.showModal':{
            transform: 'scale(1)',
            opacity: '1',
            visibility: 'visible',
        },

        '& #video':{
            border:'none !important',
            outline:'none !important',
            boxShadow:'none !important',
            width:'100%',
            maxHeight:'100%',
            height:'100%'
        },
        '& ::focus':{
            border:'none !important',
            outline:'none !important',
            boxShadow:'none !important',
        },
    },
    CloseModalBtn:{
        position:'absolute',
        top:'15px',
        right:'15px',
        height:'40px',
        width:'40px',
        color:"white",
        cursor:"pointer"
    },
    rootDiv: {
        display: 'flex', 
        height: '100%', 
        flexDirection: 'column' 
    },
    message: {
        display: 'flex-start',
        alignItems: 'center',
        justifyContent: 'flex-end',
        zIndex: 1,
        color: '#000',
        fontWeight: 'bold',
        margin: '0 10px',
        padding: '0 10px',
        backgroundColor: '#fff',
        fontSize: '16px',
        minHeight: '50px'
    },
    imgDiv: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100%',
        border: '1px solid #000',
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0,0 10px,10px -10px,-10px 0px'
    },
    downloadMessage:{
        display: 'flex', 
        flexDirection: 'row', 
        fontSize: '20px', 
        marginBottom: '8px'
    },
    downloadBtn: {
        background: '#32529D !important',
        color: '#ffffff', 
        marginLeft: '16px', 
        fontSize: '16px', 
        borderRadius: '8px', 
        display: 'flex', 
        flexDirection: 'row'
    }
});