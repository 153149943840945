import { makeStyles, TextField } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import React, { Component } from 'react';
import CustomTextField from "../CustomTextField";
import { styles } from "./styles";
const useStyles = makeStyles(styles);
const CustomSearchSelect = ({ label,className, ...props }) => {
    const classes = useStyles(props);
    return (<Autocomplete
        renderInput={(params) => (<CustomTextField {...params} label={label}  placeholder={props?.placeholder && (!(props?.value) || props?.value?.length == 0 ) ? props?.placeholder : ""}/>)}
        popupIcon={<ExpandMore />}
        {...props}
        className={clsx(classes.root, className)}
    />);
}

export default CustomSearchSelect;