
export const styles = (theme) => ({
    chatRoot: {
        position: 'fixed',
        right: 25,
        bottom: 0,
        zIndex: 1201,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        "& .chatPatientListWrap": {
            width: '310px',
            filter: 'drop-shadow(4px 8px 14px rgba(0, 0, 0, 0.2))',
            opacity: '0',
            overflow: 'hidden',
            visibility: 'hidden',
            backgroundColor: '#ffffff',
            position: 'absolute',
            bottom: '48px',
            borderRadius: '20px',
            right: '10px',
            transition: 'all .5s cubic-bezier(0.18, 0.89, 0.32, 1.28)',
            transform: 'scale(0)',
            transformOrigin: 'bottom',
            '&.toggle':{
                opacity:1,
                visibility: 'visible',
                transform: 'scale(1)',
                transformOrigin: 'bottom',
            },
            "& .chatHead": {
                backgroundColor: "#F3F7F9",                
                display: 'flex',
                alignItems: 'center',
                justifyContent: "space-between",
                cursor: 'pointer',
                padding: '15px 15px 15px 0',
                "& .chatTitle": {
                    color: "#223345",
                    fontWeight: 600,
                    fontSize: "16px",
                    padding: '0 20px',
                },
                "& .chatHeadArrow": {
                    color: "#35C5EA",
                    fontSize: '40px',
                    paddingRight: '10px',
                }                                
            },
            "& .chatPatientList": {
                padding: '16px',
            },
            '& legend':{
                display:'none !important'
            }
        },
    },
    chatListWrap: {
        height: 400,
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: '16px',
        "& ul": {
            padding:0
        }
    },
    AvatarIcons: {
        width: 45,
        height: 45,

    },
    chatItem: {
        padding: '10px 5px',
        borderBottom: '1px solid #EEEEEE',
        "&:first-of-type": {
            borderTop: '1px solid #EEEEEE'
        }
    },
    UserTimeCount: {
        textAlign: 'right',
        maxWidth: '90px',
        marginTop: '10px',
        marginBottom: 0,
        '& span': {
            fontSize: 11,
            color: '#77838F',
            marginTop:"14px"
        },
        '& p': {
            width: 23,
            height: 23,
            lineHeight: '23px',
            display: 'inline-block',
            backgroundColor: '#40c5ea',
            borderRadius: '50%',
            color: '#fff',
            marginTop: 10,
            textAlign: 'center',
        }
    },
    UserNameMessage: {
        width: '128px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& span': {
            fontSize: 14,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            'font-weight': '500',
            ['@media(max-width: 1366px)']:{
                fontSize: 14,
            }
        },
        '& p': {
            fontSize: 12,
            'white-space': 'nowrap',
            'text-overflow': 'ellipsis',
            display: 'block',
            overflow: 'hidden',
            "& span a":{
                color:"rgba(0, 0, 0, 0.54) !important",
                textDecoration:"none",
                fontSize: 16,
                ['@media(max-width: 1366px)']:{
                    fontSize: 14,
                }
            },
            ['@media(max-width: 1366px)']:{
                fontSize: 12,
            }
        },
        '& a':{
            color:'#151515',
            '&:hover':{
                color: "#106ba3",
            },
        }
        
    },
    noResult:{
        textAlign: 'center',
        color: '#32529D',
        fontSize: '18px',
        fontWeight: 600,
        height: '100%',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
    },
    FloatChatIcon:{
        height:50,
        width:50,
        backgroundColor:'#34519C',
        borderRadius:'100%',
        position: 'absolute',
        bottom: '10px',
        right: '5px',
        zIndex: '99',
        '&:hover':{
            backgroundColor:'#34519C',
        }
    },
    CloseChatIcon:{
        color:'#fff'
    },
    chatMultiWindowWrapper:{
        width: '100%',
        display: 'flex',
        marginRight: '310px',
        alignItems:'flex-end'
    },
    topicons:{

    },
    HeadCloseIcon:{
        color:'#616161'
    }
})