import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Participant } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useIsRecording from '../../../hooks/useIsRecording/useIsRecording';
import useParticipantIsReconnecting from '../../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import usePublications from '../../../hooks/usePublications/usePublications';
import useTrack from '../../../hooks/useTrack/useTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useLocation } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { checkRoomIdValid } from '../../../api/InstantVideo/Instant';
import { Avatar } from '@material-ui/core';
import { UpdateRoomStatus } from '../../../store/actions';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  containerCollapse: {
    width: 'calc(330px - 1em)',
    height: 'calc(215px - 1em)',
    position: 'absolute',
    justifyContent: 'flex-end',
    bottom: 0,
    right: 0,
    padding: '0 16px 16px 0',
  },
  identity: {
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '0.1em 0.3em 0.1em 0',
    display: 'inline-flex',
    '& svg': {
      marginLeft: '0.3em',
    },
    marginRight: '0.4em',
    alignItems: 'center',
  },
  infoContainer: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: '16px',
  },
  reconnectingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection:"column",
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(40, 42, 43, 0.75)',
    zIndex: 1,
    gap:"1rem",
    "& .AvatarStyle":{
      background:'#131B30',
      width:"5rem",
      height:'5rem',
      color:"#3A4358",
    },
    "& .Text":{
      color:"white"
    }
  },
  CollapseContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingBottom:'10%',
    display: 'flex',
    flexDirection:"column",
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(40, 42, 43, 0.75)',
    zIndex: 1,
    "& .AvatarStyle":{
      background:'#131B30',
      width:"3rem",
      height:'3rem',
      color:"#3A4358",
    },
    "& .Text":{
      color:"white"
    },
    "& .OverflowHide":
    {
      maxWidth:'100px',
      display:"block",
      overflow: 'hidden',
      whiteSpace: "nowrap",
      textOverflow: 'ellipsis',
    }
  },
  fullWidth: {
    gridArea: '1 / 1 / 2 / 3',
    [theme.breakpoints.down('sm')]: {
      gridArea: '1 / 1 / 3 / 3',
    },
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    position: 'absolute',
    color:"#131B30",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    "& .AvatarStyle":{
      background:'#131B30',
      width:"7rem",
      height:'7rem',
      color:"#3A4358",
    },
  },
  recordingIndicator: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '0.1em 0.3em 0.1em 0',
    fontSize: '1.075rem',
    height: '28px',
    [theme.breakpoints.down('sm')]: {
      bottom: 'auto',
      right: 0,
      top: 0,
    },
  },
  circle: {
    height: '12px',
    width: '12px',
    background: 'red',
    borderRadius: '100%',
    margin: '0 0.6em',
    animation: `1.25s $pulsate ease-out infinite`,
  },
  '@keyframes pulsate': {
    '0%': {
      background: `#A90000`,
    },
    '50%': {
      background: '#f00',
    },
    '100%': {
      background: '#A90000',
    },
  },
}));

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  isVideoCollapsed?: any;
  setshow:any;
}

let isShowToaster : boolean = true;

export default function MainParticipantInfo({ participant, children, isVideoCollapsed,setshow}: MainParticipantInfoProps) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const dispatch = useDispatch();
  const identityObject= JSON.parse(participant.identity);
  const localParticipant = room?.localParticipant;
  const isLocal = localParticipant === participant;
  const publications = usePublications(participant);
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));
  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack: any = useTrack(audioPublication);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const isRecording = useIsRecording();
  
  const location = useLocation()
  const {row} : any = location?.state || {}
  const { userRole } = useSelector((state: RootStateOrAny) => state?.auth);
  const [isVideoSwitchedOff, setIsVideoSwitchedOff] = useState<boolean>(true)
  let videoPublication : any;
  participant.videoTracks.forEach(t=>{ videoPublication = t})
  const track = useTrack(videoPublication);
  useEffect(()=>{
    participant.on('disconnected',()=>{
      if(JSON.parse(participant.identity).role === 'consumer'){
        isShowToaster = true;
        checkRoomId()
      }
    })
    participant.on('reconnected',()=>{
      if(JSON.parse(participant.identity).role === 'consumer'){
        isShowToaster = false;
      }
    })
    participant.on('trackStarted',()=>{
      if(JSON.parse(participant.identity).role === 'consumer'){
        isShowToaster = false;
      }
    })
  },[participant])

  useEffect(()=>{
    if(track && JSON.parse(participant.identity).role === 'consumer'){
      track.on('started', ()=>{
        setIsVideoSwitchedOff(false)
      });
      track.on('enabled', ()=>{
        setIsVideoSwitchedOff(false)
      });
      track.on('disabled', ()=>{
        setIsVideoSwitchedOff(true)
      });
      track.on('stopped', ()=>{
        setIsVideoSwitchedOff(true)
      });
    }
  },[track,participant])

  const checkRoomId = async() => {
    const consultType = location.pathname === '/Appointments/room' ? "VIRTUAL_CONSULTATION" : "INSTANT"
    const searchVal = location.search.split('?')[1];
    let roomId = ''
      if(searchVal?.split('/').length > 1){
        roomId = searchVal?.split('/')[1];
      } else {
        roomId = searchVal;
      }
    const id = location.pathname === '/Appointments/room' ? row?.row?._id : roomId
    const res = await checkRoomIdValid(id,consultType, userRole,searchVal?.split('/').length > 1)
    if(isShowToaster){
      if(res?.data?.roomStatus === "AVAILABLE"){
        dispatch(UpdateRoomStatus(true));
        setshow({show: true, message: `${process.env.REACT_APP_PATIENT} has left the call!`});
      } else {
        dispatch(UpdateRoomStatus(false));
        setshow({show: true, message: `${process.env.REACT_APP_PATIENT} has end the call!`})
      }
    }
  }

  return (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={clsx(classes.container)}
    >
      <div className={classes.infoContainer}>
        <div style={{ display: !isVideoCollapsed ? 'flex' : 'none' }}>
          <div className={classes.identity}>
            <Typography variant="body1" color="inherit" style={{ paddingLeft: '8px' }}>
              {identityObject?.fullName}
              {isLocal && ' (You)'}
              {screenSharePublication && ' - Screen'}
            </Typography>
            <AudioLevelIndicator audioTrack={audioTrack} />
          </div>
          {/* <NetworkQualityLevel participant={localParticipant} /> */}
        </div>
        {isRecording && (
          <Tooltip
            title="All participants' audio and video is currently being recorded. Visit the app settings to stop recording."
            placement="top"
          >
            <div className={classes.recordingIndicator}>
              <div className={classes.circle}></div>
              <Typography variant="body1" color="inherit" data-cy-recording-indicator>
                Recording
              </Typography>
            </div>
          </Tooltip>
        )}
      </div>
      {/* To Do add isVideoEnabled */}
      {(participant.videoTracks.size  === 0  || (isVideoSwitchedOff && !isLocal) ) && !isParticipantReconnecting &&  (
        <div className={classes.avatarContainer}>
           <Avatar className='AvatarStyle'/>
        </div>
      )}
      {isParticipantReconnecting && (
        <div className={isVideoCollapsed ? classes.CollapseContainer : classes.reconnectingContainer}>
         <Avatar className='AvatarStyle'/>
          <Typography variant={isVideoCollapsed ? "body2" : "body1"} className='Text'>
          {!isVideoCollapsed ? identityObject?.fullName + ' Disconnected!' : <div style={{display:"flex"}}><label className='OverflowHide'>{identityObject?.fullName}</label><label> &nbsp;Disconnected!</label></div> }
          </Typography>
          <Typography  variant={isVideoCollapsed ? "caption" : "body2"} className='Text'>
           Trying to reconnect...
          </Typography>
        </div>
      )}
        {!isParticipantReconnecting && children}
    </div>
  );
}
