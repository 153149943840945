import  { useAppState } from '../../state';
import { VideoProvider } from '../../components/consultation/VideoProvider';
import useConnectionOptions from '../../utils/useConnectionOptions/useConnectionOptions';
import ErrorDialog from '../../components/consultation/ErrorDialog/ErrorDialog';
import ConsultationVideoCall from './ConsultationVideoCall';
import { useEffect, useState, useRef } from 'react';
import Consultation from '../../pages/AppointmentManagement/Consultation'
import Notify from '../../components/common/CommonToast';
import { useLocation } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { checkRoomIdValid } from '../../api/InstantVideo/Instant';
import FullScreenLoader from '../../components/common/FullScreenLoader';
import { UpdateRoomStatus } from '../../store/actions';
import { sendInstant, ws } from '../../components/Websocket/InstantVideo';
import { USER_ROLES } from '../../utils/constants';

const ConsultationRender = () => {
  const location = useLocation()
  const { row }: any = location?.state || {}
  const { userRole , user} = useSelector((state: RootStateOrAny) => state?.auth);
  const { error, setError } : any = useAppState();
  const connectionOptions = useConnectionOptions();
  const [isCallJoined, setCallJoined] = useState<boolean>(false);
  const [isCallBackUserJoind, setIsCallBackUserJoined] = useState<boolean>(false);
  const [roomStatus, setRoomStatus] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [isVideoCollapsed, setVideoCollapsed] = useState<boolean>(false);
  const roomState = useRef();
  const dispatch = useDispatch();
  const [show, setshow] = useState({
    show: false,
    message: ''
  });
  const searchVal = location.search.split('?')[1];
  const { instantdata } = useSelector((state: RootStateOrAny) => state?.instantVideo);
  const check = () => {
    setVideoCollapsed(!isVideoCollapsed);
  };
const changeRoomState = (value) =>
{
  roomState.current = value ;
}
  // Commenting this for now only, we will enable this for later.
  // useEffect(() => {
  //   if(unSavedChanges){
  //   window.addEventListener('beforeunload', (event) => {
  //     event.preventDefault();
  //     return (event.returnValue = "");
  //   }, { capture: true });
  //   return () => {  
  //       window.removeEventListener('beforeunload', (event) => {
  //       event.preventDefault();
  //       return (event.returnValue = "");
  //     }, { capture: true })
  //   }
  //   }
  // },[unSavedChanges]);

  useEffect(()=>{
    if (location.pathname === '/Appointments/room') {
      roomIdValidation(row?.row?._id)
    } else {
        if(searchVal?.split('/').length > 1){
          roomIdValidation(searchVal?.split('/')[1])
        } else {
          roomIdValidation((searchVal && searchVal !== 'undefined') ? searchVal : instantdata?.roomId)
        }
      
    }
  },[location])

  const roomIdValidation = async (roomId: string) => {
    try {
      const consultType = location.pathname === '/Appointments/room' ? "VIRTUAL_CONSULTATION" : "INSTANT"
      const res = await checkRoomIdValid(roomId, consultType, userRole, searchVal?.split('/').length > 1)
      if (res?.data?.state === "UNAVAILABLE") {
        setError({ code: -100001, message: 'Invalid Meeting Id' })
        setState(res?.data?.state)
      }
      setRoomStatus(res?.data?.roomStatus)
      if (res?.data?.roomStatus === "UNAVAILABLE" && !isVideoCollapsed) {
        check()
      }
    } catch (e) {
      setError({ code: -100001, message: 'Invalid Meeting Id' })
    }
  }

  useEffect(() => {
      if(roomStatus)
        {
          if(roomStatus === "AVAILABLE")
          dispatch(UpdateRoomStatus(true));
          else
          dispatch(UpdateRoomStatus(false));
        }
  },[roomStatus])

  useEffect(() => {
    return () => {
      ws?.readyState === 1
      sendInstant &&
      (userRole === USER_ROLES.receptionist ||
      userRole === USER_ROLES.opd_ops ||
      userRole === USER_ROLES.facility_receptionist
        ? sendInstant(
            JSON.stringify({
              userId: user?._id,
              role: userRole,
              method: "receptionistFirstCheck",
            })
          )
        : sendInstant(
            JSON.stringify({
              userId: user?._id,
              role: userRole,
              method: "doctorFirstCheck",
            })
          ));
    } 
  },[])
  return ( 
    <div style={(isVideoCollapsed && isCallJoined)? { height:'auto', display: 'grid', gridTemplateRows: '1fr auto', background:'#F3F3F3' ,padding:'74px 1% 1% 1%'} : { height:'100%', display: 'grid', gridTemplateRows: '1fr auto', background:'#F3F3F3', paddingTop: '58px'}} >
      {roomStatus === '' && <FullScreenLoader />}
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} isVideoCollapsed={isVideoCollapsed} check={check} roomState={roomState}/>
        {isCallJoined && show?.show && <Notify message={show?.message} severity={"error"} title={"Alert"}/>}
        {(isCallJoined || (roomStatus === "UNAVAILABLE" && state === '')) && <div style={{ display: isVideoCollapsed ? 'block' : 'none' }}>
          <Consultation/>
        </div>}
        {(roomStatus === "AVAILABLE") && <ConsultationVideoCall
          check={check}
          isVideoCollapsed={isVideoCollapsed}
          setVideoCollapsed={setVideoCollapsed}
          setCallJoined={setCallJoined}
          isCallJoined={isCallJoined}
          setshow={setshow}
          setError={setError}
          changeRoomState={changeRoomState}
          setIsCallBackUserJoined={setIsCallBackUserJoined}
          isCallBackUserJoind={isCallBackUserJoind}
        />} 

      </VideoProvider>
    </div>
  );
}

export default ConsultationRender