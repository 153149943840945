import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
   SecondaryContainer: {
      display: "flex",
      gap: "0.5rem",
      marginTop: "0.65rem",
   },
   //chat
   outerBox: {
      position: "fixed",
      bottom: 0,
      right: '50%',
      left: '50%',
      zIndex: 300,
      borderRadius: '8px 8px 0px 0px',
      "& .MuiPaper-root": {
         boxShadow: '0px 4px 44px rgba(0, 0, 0, 0.21) !important'
      }
   },
   mainNormalAccordion: {
      width: "330px",
      borderRadius: "15px 15px 0px 0px",
      "& .MuiAccordionSummary-root.Mui-expanded": {
         display: 'flex',
         padding: '0px',
         minHeight: '50px'
      },
      "& .MuiAccordionSummary-root": {
         flexDirection: 'row-reverse',
         gap: '10px',
         height: '50px',
         alignItems: 'center',
         padding: '0px',
      },
      "& .MuiAccordionDetails-root": {
         padding: '8px 10px',
         borderTop: "2px solid #F1F1F1",
         "& .MuiIconButton-root": {
            padding: '0px !important'
         }
      },
   },
   userPhoto: {
      height: "35px",
      width: "35px",
      backgroundSize: "cover",
      borderRadius: "50%",
      backgroundColor: "lightgrey",
      marginRight: "10px"
   },
   chatUserName: {
      lineHeight: "40px",
      width: '200px',
      fontWeight: 600,
   },
   chatDetails: {
      height: "350px",
      overflowY: "auto",
      zIndex: 1,
      "& .Disconnect": {
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         width: '100%',
         height: '100%'
      }
   },
   chatText: {
      width: "100%",
      marginTop: '6px',
      border: 'none',
      resize: 'none',
      maxHeight: '100px',
      overflow: 'scroll',
      "&:focus": {
         border: 'none !important',
         outline: "0px solid transparent",
      }
   },
   sendermessage: {
      backgroundColor: "#34519C",
      padding: " 10px",
      color: "white",
      borderRadius: "12px 0px 12px 12px",
      maxWidth: '-webkit-fill-available',
      wordBreak:'break-word'
   },
   sendername: {
      fontWeight: 500,
      textAlign: "end",
      fontSize: "11px",

   },
   receivermessage: {
      backgroundColor: "#EBEFF4",
      padding: "10px",
      color: "black",
      borderRadius: "0px 12px 12px 12px",
      maxWidth: '-webkit-fill-available'
   },
   receivername: {
      fontWeight: 500,
      textAlign: "start",
      fontSize: "11px"
   },
   senderStyle: {
      width:'290px',
      marginTop: "3px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      "& .TextWrap": {
         wordWrap: "break-word",
         whiteSpace: 'pre-line'
      },
      "& .DateTime": {
         marginTop: '2px',
         color: '#717171',
         fontSize: "9px",
         fontWeight: 300,
         lineHeight: '14.25px'
      }
   },
   receiverStyle: {
      marginTop: "3px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "& .TextWrap": {
         wordWrap: "break-word",
         whiteSpace: 'pre-line'
      },
      "& .DateTime": {
         marginTop: '2px',
         color: '#717171',
         fontSize: "9px",
         fontWeight: 300,
         lineHeight: '14.25px'
      }
   },
   imagePreview: {
      width: '250px !important',
      filter: 'invert(0) !important',
      height: '174px',
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: 15
   },
   DocumentName: {
      flex: 1,
      display: 'flex',
      gap: '10px',
      textAlign: 'left',
      cursor: "pointer",
      height: '60px',
      alignItems: 'center',
      '& .filename': {
         width: '200px',
         whiteSpace: 'nowrap',
         overflow: 'hidden !important',
         textOverflow: 'ellipsis !important',
      }
   },
   IconButton: {
      "& .MuiButtonBase-root": {
         padding: "5px !important"
      }
   },
   cancelBtn: {
      backgroundColor: '#B7BDCE !important',
      padding: '8.5px 25px',
      color: '#FFFFFF',
      borderRadius: "4px",
      lineHeight: "26px",
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "none"
   },
   titleRoot: {
      borderBottom: '1px solid #CCCCCC',
      "& .close-btn": {
         display: "inline-block",
         width: "fit-content",
         position: "absolute",
         top: "12px",
         right: "16px",
      }
   },
   Date: {
      marginTop: '2px',
      color: '#717171',
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: '16.25px',
      textAlign:'center',
      width:'100%'
   },
   ChatPage:{
      padding:"74px 1% 1% 1%",
   },
   reconnect:{
      color:'red',position:'absolute',padding:"0px 30%",
      "& .loader":{
         height:"15px",width:'15px',margin:'2px 2px 0px 0px'
      },
      "& .text":{
         background:"white",
      }
   }
}));

export default useStyles