
import Services from "../../src/api/services";
import heic2any from "heic2any";

const bytesToMegaBytes = bytes => bytes / (1024*1024);
const fileInstance = new Services().getServiceInstance();
export const uploadToS3 = async ( file, prefix, payload) => { 
  const payloadCall =async(file,tagname)=>{
      const {size:finalSize } = file;
      const fileMBSize = bytesToMegaBytes(finalSize);
      try {
        if (fileMBSize > 5) {
          let error = { response: { data: { message: "File size more than 5 MB is not supported" } } };
          throw error;
        }     
        const s3prefix = prefix || tagname ;
        const formdata = new FormData();
        formdata.append('files', file);
        formdata.append("tag", s3prefix);
        payload && formdata.append(payload?.key, payload?.value);
        const response = await fileInstance.post(process.env.REACT_APP_FILE_BASEURL + "/api/v1/"+process.env.REACT_APP_KEY_WORD+"/uploadFile", formdata);                
        const { s3Url, keyPath } = response?.data?.map((x)=>x)[0]?.response||{};
        return { url: s3Url.replace(/%2F/gi, "/"), err: null, keyPath , fileSize: response?.headers["content-length"]};
        }catch(error){
          return{url:undefined,err:error}
        }
  }
  try {
    const { name, size } = file;
    let fileSizeMB = size / (1024*1024)
    const fileExtension = name.split(".").pop()?.toLowerCase();
    const tagname = name.split(".").slice(0, -1).join(".");
      if (fileExtension === "heic" || fileExtension === "heif") {
        const blobToFile = (theBlob, fileName) => {
          return new File([theBlob], fileName, {
            lastModified: new Date().getTime(),
            type: theBlob.type,
          });
        }
        return heic2any({ blob: file, toType: "image/jpeg", quality: fileSizeMB }).then(
          async (newImage) => {
            file = blobToFile(newImage, name);
            return await payloadCall(file,tagname)
          }
        ).catch(async (error) => {
          if(error?.message === 'ERR_LIBHEIF format not supported' ||error?.message=== 'ERR_USER Image is already browser readable: image/jpeg'){
            return await payloadCall(file,tagname)
          }
          throw new Error("Invalid File")
          })
    }else{
     return await payloadCall(file,tagname)

  }
  } catch (err) {
    console.log({ err }, "uploadToS3");
    return { url: undefined, err };
  }
};
