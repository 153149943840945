import Services from "../services";
import {
  CREATE_SLOT,
  GET_ALL_FACILITIES,
  GET_DOCTORS_SLOT_BY_FACILITY,
  apiRolesByFacility,
  apiPractitionersByRoleAndFacility,
  getRolesForConsultation,
  DELETE_SLOT,
  GET_SLOTS_BY_PRACTITIONER,
  GET_CONSULT_ARRAY,
  FACILITY_SIGNUP_URL
} from "../../config/url.config";
import { ICreateSlotData, IDoctorSlotsPayload } from "../../models/Scheduler/slot-management";

const instance = new Services().getServiceInstance();

/* Get all Facilities or Hospitals or Clinics */
export const getAllFacilities = async (key: string, payload: any) => {
  const params = { search: payload.search, pageSize: 10, pageNumber: payload.pageNumber }
  const { data } = await instance.get(`${GET_ALL_FACILITIES}`,{params});
  return data.data;
};

/* Get all Roles for a Facility or Hospital or Clinic */
export const getAllRoles = async (_: string, facilityId: string) => {
  const { data } = await instance.get(apiRolesByFacility(facilityId));
  return data;
};

export const getRolesConsultation = async (_: string, settingId: string) => {
  const { data } = await instance.get(getRolesForConsultation(settingId));
  return data.data;
};

/* Get all Facilities or Hospitals or Clinics */
export const getAllPractitioners = async (_: string, roleId: string, facilityId: string ,payload) => {
  const params = { search: payload.search, pageSize: 10, pageNumber: payload.pageNumber, active: payload.active }
  if(payload?.active){
    params['active']=payload?.active
  }
  const { data } = await instance.get(apiPractitionersByRoleAndFacility(roleId, facilityId),{params});
  return data.data;
};

export const getProviderPractitionerNames = async (_: string,payload:any) => {
  const { data } = await instance.post(`${FACILITY_SIGNUP_URL}/getProviderPractitionerNames`, payload);
  return data.data;
};

/* Create Slot for Practitioner */
export const createSlot = async (payload: ICreateSlotData) => {
  const { data } = await instance.post(CREATE_SLOT, payload);
  return data
};

/* Get All Doctors slots by Facility for Table view with Pagination */
export const getSlotsForFacility = async (reqPayload: IDoctorSlotsPayload, pageNumber?: number) => {
  if (reqPayload.isListView) {
    delete reqPayload.date
  } else {
    delete reqPayload.isListView;
  }

  const payload = reqPayload.isListView ? {
    ...reqPayload,
    pageNumber,
    pageSize: process.env.REACT_APP_LIST_ROWS_PER_PAGE,
  } : reqPayload

  const { data } = await instance.post(GET_DOCTORS_SLOT_BY_FACILITY, payload)
  return data
}

export const deleteSlot = async (reqPayload: Record<string, any>) => {
  const { slotStartTime, slotEndTime, practitionerId, date, dayOfTheWeek, isSeriesDelete, isCancelAppointments, slotId, isListView, groupId, facilityPractitionerId } = reqPayload
  const payload = {
    slotStartTime,
    slotEndTime,
    date,
    dayOfTheWeek,
    isSeriesDelete,
    isCancelAppointments,
    practitionerId,
    slotId,
    isListView,
    groupId,
    facilityPractitionerId
  };

  if (!isListView) {
    delete payload.isListView
    delete payload.groupId
  }

  const response = await instance.post(DELETE_SLOT, payload);
  return response
};

export const getSlotsByPractitioner = async (payload: any) => {
  const { data } = await instance.post(`${GET_SLOTS_BY_PRACTITIONER}`, payload);
  return data
}

export const getConsultationData = async (facilityId: string) => {
  const { data } = await instance.get(`${GET_CONSULT_ARRAY}?facilityId=${facilityId}`);
  return data;
};