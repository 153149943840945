import * as actionTypes from "../actions/actionTypes";

const initialState = {
  token: null,
  user: null,
  error: null,
  loading: false,
  userId: "",
  inProgress: false,
  userRole: "",
  isLoggedUser:false,
  facility: null,
  chatUserId: "",
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return { ...state, error: null, loading: true };
    case actionTypes.AUTH_SUCCESS:
      const userData = action.payload;
      delete userData.accessToken;
      delete userData.refreshToken;
      return {
        ...state,
        user: userData,
        userId: userData.userId,
        chatUserId: userData.chatUserId,
        userRole: action.role,
        inProgress: true,
        isLoggedUser:true,
        loading: false,
      };
    case actionTypes.FACILITY_LIST :
      const data = action.payload
      return {... state,facility:data};

    case actionTypes.AUTH_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.AUTH_LOGOUT:
      return initialState;
    case actionTypes.AUTH_TOKEN:
      const user = action.payload;
      if (user) {
        delete user.accessToken;
        delete user.refreshToken;
      }
      
      return {
        ...state,
        user: user,
        userId: action.payload._id,
        userRole: action.role,
        isLoggedUser:true,
        inProgress: action.inProgress,
        error: null,
        loading: false,
      };
      case actionTypes.AUTH_COMPLETED:
      return {
        ...state,
        inProgress: action.payload,
      };
    case actionTypes.SWITCH_USER_ROLE:
      return {
        ...state,
        userRole: action.role
      };
      case actionTypes.DB_DATA:
      return {
        ...state,
        dbData: action.payload
      };
    case actionTypes.UPDATE_USER_DETAILS:
      const userdata=action.payload
      return {
        ...state,
        user:userdata
      }
    default:
      return state;
  }
};

export default auth;
