const host = process.env.REACT_APP_INSTANT_CWS;

// We create an exported variable `send`, that we will assign
// later (just know that it is exported for now)
export let send, ws, close;
// The onMessageCallback is also assigned later, as we will soon see
let onMessageCallback, startInterval;
// This exported function is used to initialize the websocket connection
// to the server
export const startWebsocketConnection = (userId, roomId, participantId) => {
  // A new Websocket connection is initialized with the server
  ws = new window.WebSocket(host + userId) || {};
  const socketWebWorker = new Worker("/interimSocketWorker.js")
  // If the connection is successfully opened, we log to the console
  ws.onopen = () => {
    // startInterval = setInterval(function() {
    //   ws.send(JSON.stringify({ action: "keepAlive" }));
    // }, 10000);
    socketWebWorker.postMessage({method:'START',repeat:"ALIVE",message:JSON.stringify({ "action": "keepAlive" })});
    ws.readyState === 1;
    // if (userId) {
    //   send(
    //     JSON.stringify({
    //       action: "get-rooms-v2",
    //     })
    //   );
    // }
    if(userId){
      ws.send(JSON.stringify({
          "action": "connect",
          "body": {
              "chatUser": userId,
          }}))
    }
  
    console.log("opened ws connection");
  };

  // If the connection is closed, we log that as well, along with
  // the error code and reason for closure
  ws.onclose = e => {
   // clearInterval(startInterval);
    socketWebWorker.postMessage({method:'STOP-ALL'});
    socketWebWorker.terminate();
    console.log("close ws connection: ", e.code, e.reason);
  };

  // This callback is called everytime a message is received.
  ws.onmessage = e => {
    // The onMessageCallback function is called with the message
    // data as the argument
    onMessageCallback && onMessageCallback(e.data);
  };

  ws.onerror = e => {
    console.log("error ws connection: ", e);
  };

  // We assign the send method of the connection to the exported
  // send variable that we defined earlier
  socketWebWorker.onmessage = (res) =>{
     ws.send(res?.data)
  }
  send = ws.send.bind(ws);
  close = ws.close.bind(ws);
};

// This function is called by our React application to register a callback
// that needs to be called everytime a new message is received
export const registerOnMessageCallback = fn => {
  // The callback function is supplied as an argument and assigned to the
  // `onMessageCallback` variable we declared earlier
  onMessageCallback = fn;
};

export const recieve = (roomId, limit = 30, skip = 0) => {
  ws.send(
    JSON.stringify({
      action: "get-message",
      body: { limit, skip, room: roomId }
    })
  );
};
