import { Grid, Typography, CircularProgress } from "@material-ui/core";
import useStyles from "./styles";
import view from "../../../../assets/images/appointment/ic_dark-eye.svg";
import ic_prescription_grey from "../../../../assets/images/consultation/ic_prescription_grey.png";
import moment from "moment";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { IpastConsultProps, IPastConsulData } from "../../../../models/consultation";
import { useSelector, RootStateOrAny } from "react-redux";
import { USER_ROLES } from "../../../../utils/constants";
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from "@material-ui/icons";
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import ic_new_video from "../../../../assets/images/tele-medicine/ic_new_virtual.svg";
import ic_new_chat from "../../../../assets/images/tele-medicine/ic_new_chat.svg";
import ic_new_instant from "../../../../assets/images/tele-medicine/ic_new_instant.svg";

const PastConsultations = ({ pastConsultationArray, setPageNumber, pageNumber, isPastLoading }: IpastConsultProps) => {  
  const TYPES =
    {
      VIRTUAL_CONSULTATION : "Virtual Consult",
      PHYSICAL_CONSULTATION : "Physical Consult",
      INSTANT : "Instant Call",
      CHAT :'Instant Chat'
    }
  const classes = useStyles();
  const { userRole } = useSelector((state: RootStateOrAny) => state?.auth);
  const roleArr=[USER_ROLES.facility_receptionist, USER_ROLES.receptionist, USER_ROLES.opd_ops]

  const getPaginationComponent = (item) => {
    switch (item.type) {
      case "first":
        return (
          <PaginationItem
            {...item}
            component={(props) => (
              <button {...props} className={`${props.className}`}><KeyboardDoubleArrowLeftRoundedIcon /></button>
            )}
          />
        );
      case "previous":
        return (
          <PaginationItem
            {...item}
            component={(props) => (
              <button {...props} className={`${props.className} navigation`}><KeyboardArrowLeftOutlined />Prev</button>
            )}
          />
        );
      case "next":
        return (
          <PaginationItem
            {...item}
            component={(props) => (
              <button {...props} className={`${props.className} navigation`}>Next<KeyboardArrowRightOutlined/></button>
            )}
          />
        );
      case "last":
        return (
          <PaginationItem
            {...item}
            component={(props) => (
              <button {...props} className={`${props.className}`}><KeyboardDoubleArrowRightRoundedIcon /></button>
            )}
          />
        );
      default:
        return <PaginationItem {...item} />;
    }
  };

  const renderContainer = ({
    consultationType,
    date,
    practitionerName,
    slotStartTime,
    caseSheetId,
    caseSheetURL,
    _id,
  }: IPastConsulData) =>(
    <Grid container className={classes.prescriptionCover} key={_id}>
      <Grid item xs={12} className={classes.headerWrapper}>
        <Grid item xs={4} className={classes.cidNumberCard}>
          {caseSheetId}
        </Grid>
        {caseSheetURL && !roleArr.includes(userRole) && 
        <Grid item xs={8} className={classes.viewPrescribeCard} >
           <a
              href={caseSheetURL}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.viewPrescribeCard}
            >
          <img className={classes.viewPrescribe} src={view} alt="preview" />
          <span>View Case Sheet</span>
          </a>
        </Grid>
        }
      </Grid>
      <Grid item xs={12} className={classes.tableDataCard}>
        <Grid item xs={4} spacing={2} style={{marginRight:"1rem"}}>
          <Typography className={classes.tabArrContainer}>
            Clinician
          </Typography>
          <Typography className={classes.tabDataContainer}>
            {practitionerName?.fullName}
          </Typography>
        </Grid>
        <Grid item xs={3} spacing={3}>
          <Typography className={classes.tabArrContainer}>Date</Typography>
          <Typography className={classes.tabDataContainer}>{moment(date).format("DD/MM/YYYY")}</Typography>
        </Grid>
        <Grid item xs={3} spacing={3}>
          <Typography className={classes.tabArrContainer}>Time</Typography>
          <Typography className={classes.tabDataContainer}>
            {moment(slotStartTime).utc().format("hh:mm a")}
          </Typography>
        </Grid>
        <Grid item xs={3} spacing={3}>
          <Typography className={classes.tabArrContainer}>
            Consultation Type
          </Typography>
          <Typography className={classes.tabDataContainer}>
            <img src={consultationType==="VIRTUAL_CONSULTATION"? ic_new_video : consultationType==="INSTANT" ? ic_new_instant:ic_new_chat} alt="type"/>
            {TYPES[consultationType]}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };
  
  return (
    <>
      {pastConsultationArray?.data?.data?.length === 0 ?
        <div className={classes.emptyPrescription}>
          <div className="iconWrapper">
            <img src={ic_prescription_grey} alt="placeholder" />
          </div>
          <span className="desc">No Past Consultation to display</span>
        </div>
        :
        <Grid item xs={12} className={classes.prescribeContainer}>
          {pastConsultationArray?.data?.data?.map(renderContainer)}
          {isPastLoading && (
                <CircularProgress className={classes.progressBarPast} />
          )}
          <Grid container justifyContent="space-between" alignItems="center" style={{ margin: "20px", width:"96%" }}>
          <Grid className={classes.gridFooter}>
            Showing {pastConsultationArray?.data?.data?.length ? pastConsultationArray?.data?.data?.length : 0} of {pastConsultationArray?.data?.recordCount ? pastConsultationArray?.data?.recordCount : 0}
          </Grid>
          <Grid item>
            <div className={classes.paginationContainer}>
              <Pagination
                count={Math.ceil(
                  (pastConsultationArray?.data?.recordCount) / 10
                )}
                renderItem={getPaginationComponent}
                page={pageNumber || 1}
                shape="rounded"
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
        </Grid>
      </Grid>
        </Grid>
      }
    
    </>
  );
};

export default PastConsultations;
