import * as actionTypes from "./actionTypes";

export const ChatConnected =()=>({
    payload:true,
    type:actionTypes.INSTANT_CHAT_CONNECTED,
});

export const ChatDisConnected =()=>({
    payload:false,
    type:actionTypes.INSTANT_CHAT_DISCONNECTED,
});

export const ChatRoomReconnect =(data) =>({
    payload:data,
    type:actionTypes.INSTANT_CHAT_RECONNECT,
})
export const ChatDetails =(data)=>({
    payload:data,
    type:actionTypes.INSTANT_CHAT_DETAILS,
});

export const UpdateMessagelist =(data) =>({
    payload:data,
    type:actionTypes.UPDATE_MESSAGE_LIST
});

export const EmptyMessagelist =()=>({
    payload:[],
    type:actionTypes.EMPTY_MESSAGE_LIST
});
export const replaceMessagelist =(data)=>({
    payload:data,
    type:actionTypes.REPLACE_MESSAGE_LIST
});

export const instantChatSlot =(data)=>({
    payload:data,
    type:actionTypes.INSTANT_CHAT_SLOTID
});

export const OnchatBusy =(data) =>({
    payload:data,
    type:actionTypes.ON_CHAT_BUSY
});

export const UpdateSkipCount =(data) =>({
    payload:data,
    type:actionTypes.SKIP_COUNT
})

export const NewMsgReceived = (data) =>({
    payload:data,
    type:actionTypes.NEW_MSG_RECEIVED
})

export const EmptyChatState =() =>({
    payload:'',
    type:''
})

export const DisconnectMsg =(data) =>({
    payload: data,
    type:actionTypes.DISCONNECT_MSG
})