import { makeStyles } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import React, { useState } from "react";
import { styles } from "./styles";

const useStyles = makeStyles(styles);

const CustomVideoRenderer = ({onClose,show}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.video} ${show && 'showModal'}`}>
        <HighlightOff 
          className={classes.CloseModalBtn} 
          onClick={onClose}
        />

        <video id="video" controls>
          <source src={show} type="video/mp4"/>
          Your browser does not support the video tag.
        </video> 
      </div>
  );
};

export default CustomVideoRenderer;