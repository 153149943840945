import io from 'socket.io-client';
import { authStorage } from '../../utils/login';
export var sendMsg =(value1?,value2?)=>{}, closeSocket =()=>{}, UpdateAction ,chatRoomDetail ={},currentRoomID;
export const ChatRoomDetails =()=>{
return chatRoomDetail;
}
export const ValueSet = (value) =>{
  chatRoomDetail = value ;
}
export const setcurrentRoomID = (value) => {
  currentRoomID = value ;
}
export const getcurrentRoomID = () => {
  return currentRoomID ;
}
export const instantChatIO = (fn ,userId) => {
  UpdateAction = fn.bind(fn);
  const socket = io(`${process.env.REACT_APP_API_BASE_URL}`, {
    auth:{ token : authStorage.authToken},
    path: `${process.env.REACT_APP_SOCKET_IO}`,
    reconnection: true,
    reconnectionAttempts: 2,
    reconnectionDelay: 1000,
    timeout: 20000,
    transports: ["websocket", "polling"],
    query:{
      userId : userId ,
      portal : 'web',
    }
  });

  socket.on("connect_error", (reason) => {
    console.log(reason);
    console.log("reconnecting");
    UpdateAction({ action: 'RECONNECTING', data: true })
  });

  socket.on("connect", () => {
    UpdateAction({ action: 'CONNECTED', data: true })
   });

  socket.on("disconnect", () => {
    UpdateAction({ action: 'DISCONNECTED', data: false })
  });

  socket.on("callBack", (event) => {
    UpdateAction({ action: event?.type, data: event })
  });

  socket.on("message", (event) => {
    UpdateAction({ action: 'UPDATE_MESSAGE_LIST', data: event })
  });
socket.on("roomData",(event)=>{
  if(event?.type === 'disconnected')
  UpdateAction({ action: 'PATIENT_DISCONNECT', data: event })
  else
  chatRoomDetail=event;
})
  sendMsg = socket.emit.bind(socket);
  closeSocket = socket.disconnect.bind(socket);
}