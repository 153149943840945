import { makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles<Theme>((theme) => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "white",
            minHeight: "100vh",
            maxHeight: "100vh",
            position: 'absolute',
            right: 0,
            top: 0,
            borderRadius: 0,
            overflow:'scroll'
        },
        "& .MuiDialogContent-root": {
            maxWidth: "100%",
            padding: "8px 24px",
            borderBottom: '1px solid #CCCCCC',
            "& p": {
                fontSize: "18px"
            }
            ,
        },
        "& .MuiDialogTitle-root": {
            color: '#32529D',
            borderBottom: '1px solid #CCCCCC',
            "& .close-btn": {
                display: "inline-block",
                width: "fit-content",
                position: "absolute",
                top: "8px",
                right: "16px",

            }
        },
        "& .MuiDialog-paper": {
            margin: "0px"
        }
    },
    title: {
        color: "#747474"
    }
}))

export default styles;