import * as actionTypes from "../actions/actionTypes";
const initialstate = {
    instantdata: {},
    bookedId: '',
    onCall: false,
    Chatmessages: [],
    connecting:{},
    type: '',
    callID: '',
    callBackData: {},
    roomStatus: false,
    isRejoin: false
}
const instantVideo = (state = initialstate, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_INST_INFO:
            return { ...state, instantdata: action.payload };

        case actionTypes.UPDATE_BOOK_ID:
            return { ...state, bookedId: action.payload }

        case actionTypes.UPDATE_CALL_INFO:
        return {...state,onCall:action.payload}

        case actionTypes.UPDATE_CONNECTING_INFO:
        return {...state,connecting:action.payload}

        case actionTypes.INSTANT_VIDEO_TYPE:
        return {...state,type:action.payload}

        case actionTypes.UPDATE_CALL_ID:
            return { ...state, callID: action.payload }

        case actionTypes.UPDATE_CALLBACK_INFO:
            return { ...state, callBackData: action.payload };
        
        case actionTypes.ROOM_STATUS:
            return {...state,roomStatus:action.payload}
        case actionTypes.UPDATE_IS_REJOIN:
            return {...state, isRejoin:action.payload}
        case actionTypes.EMPTY_INSTANT_VIDEO:
            return state = initialstate ;              
        default :
        return state ;
    }
}

export default instantVideo;